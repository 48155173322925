import { useContext, useEffect } from 'react';
import { endpoints, fetcher } from 'services';
import useSWR from 'swr';
import MyInfoContext from 'contexts/MyInfoContext';
import useMyInfo from './useMyInfo';
import GradeContext from 'contexts/GradeContext';

const useUserRank = () => {
  // const { info } = useMyInfo();
  const info = useContext(MyInfoContext);
  const gradeContext = useContext(GradeContext);

  const { data: gradeData } = useSWR<any>(info && info.isLogin ? endpoints.common.userRank : null, () =>
    fetcher.common.getUserRank().then(res => {
      if (res.resultCode === 200) {
        gradeContext.setGradeData(res.userRankList as UserRankResDto[]);
      }
      return res;
    })
  );

  // const getNextRankScore = (score: number) => {
  //   let nextRankScore = 0;
  //   const userRankList = gradeData?.userRankList;

  //   for (let i = 0, length = userRankList?.length; i < length; i += 1) {
  //     if (score < userRankList[i].score) {
  //       nextRankScore = userRankList[i].score;
  //       break;
  //     }
  //   }

  //   return nextRankScore || score;
  // };

  // useEffect(() => {
  //   gradeContext.setGradeData(gradeData.userRankList);
  // }, [gradeData])

  return {
    gradeData,
    // getGrade: (score: number) => {
    //   if (gradeData && gradeData.userRankList) {
    //     const list = gradeData.userRankList.sort((a, b) => a.stepNum - b.stepNum);
    //     if (score <= list[0].score) {
    //       return 'bronze';
    //     } else if (score <= list[1].score) {
    //       return 'silver';
    //     } else if (score <= list[2].score) {
    //       return 'gold';
    //     } else if (score <= list[3].score) {
    //       return 'dia';
    //     } else return 'crown';
    //   }
    // },
    // getNextRankScore,
  };
};

export default useUserRank;
