import React, { useEffect, useRef, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { Portal } from 'react-portal';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import classNames from 'classnames';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import VideoTimeStampArea from './VideoDuration/VideoTimeStampArea';
import dompurify from 'dompurify';
import { getHtmlFormatByEscapeHtmlData } from '../../../utils/LGLifeUtils';
import moment from 'moment';

interface itemFormat {
  original: string;
}

interface ScriptContentProps {
  className: string;
  innerClassName: string;
  detailDesc: any;
  videoElements: Element[] | undefined;
  isCardNews?: boolean;
  cardNewsImages?: itemFormat[];
  isDimEvent?: boolean;
  /** 미디어 상세 정보 */
  mediaDetailData?: MediaDetailResDto;
  /** 타임 스탬프 클릭 이벤트 */
  onClickTimeStampItemHandler?: (timeStamp: moment.Moment) => void;
}

/** 영상 되감기/빨리 감기 시간 간격(초) */
const JUMPING_VIDEO_TERM_BY_SECOND: number = 5;

const ScriptContent = (props: ScriptContentProps) => {
  const [rendered, setRendered] = useState(false);
  const articleInnerRef = useRef<HTMLDivElement>(null);
  const vRef = useRef<any>();

  const {
    className,
    innerClassName,
    videoElements,
    detailDesc,
    isCardNews,
    cardNewsImages,
    isDimEvent,
    mediaDetailData,
    onClickTimeStampItemHandler,
  } = props;

  /**
   * 영상 10초 뒤로 되감기
   */
  const rewindVideoTime = () => {
    if (!vRef.current) return;
    const controlBar = vRef.current.player;
    const initialTime = controlBar.currentTime();

    let videoTime: number;
    if (initialTime >= JUMPING_VIDEO_TERM_BY_SECOND) {
      videoTime = initialTime - JUMPING_VIDEO_TERM_BY_SECOND;
    } else {
      videoTime = 0;
    }
    controlBar.currentTime(videoTime);
  };

  /**
   * 영상 10초 앞으로 빨리 감기
   */
  const fastForwardVideoTime = () => {
    if (!vRef.current) return;
    const controlBar = vRef.current.player;
    const videoDuration = controlBar.duration();
    const initialTime = controlBar.currentTime();

    let videoTime: number;
    const forwardedVideoTime = initialTime + JUMPING_VIDEO_TERM_BY_SECOND;
    if (forwardedVideoTime <= videoDuration) {
      videoTime = forwardedVideoTime;
    } else {
      videoTime = videoDuration;
    }
    controlBar.currentTime(videoTime);
  };

  const onSuccess = (success: any, collected: { width?: number; height?: number } & any) => {
    const player = success.ref;
    player.muted(false);
    success.ref.dimensions(collected.width, collected.height);

    const jumpControlBar = player.el_.getElementsByClassName('vjs-control-bar')[0];
    const insertBeforeNodeByJumpingPlayTime = player.el_.getElementsByClassName('vjs-volume-panel')[0];
    const videoPlayArea = document.getElementsByClassName('brightcove-react-player-loader')[0] as HTMLDivElement;

    videoPlayArea.setAttribute('tabindex', '0');

    videoPlayArea.addEventListener('keydown', e => {
      if (e.key === 'ArrowLeft') rewindVideoTime();
      if (e.key === 'ArrowRight') fastForwardVideoTime();
    });

    // 되감기 버튼 추가
    if (!document.getElementById('rewindButton')) {
      const newElementRewind = document.createElement('div');
      const newImageRewindImg = document.createElement('img');

      newElementRewind.id = 'rewindButton';
      newElementRewind.className = 'vjs-fullscreen-control vjs-control vjs-button';
      newImageRewindImg.setAttribute(
        'src',
        'https://player.support.brightcove.com/assets/images/code-samples/brightcove-player-sample-back-forward-buttons/back-button.png'
      );
      newImageRewindImg.setAttribute('style', 'width: 100% !important; height: 100% !important;');
      newElementRewind.appendChild(newImageRewindImg);
      jumpControlBar.insertBefore(newElementRewind, insertBeforeNodeByJumpingPlayTime);

      newElementRewind.addEventListener('click', () => {
        rewindVideoTime();
      });
    }

    // 빨리 감기 버튼 추가
    if (!document.getElementById('fastForwardButton')) {
      const newElementFastForward = document.createElement('div');
      const newImageFastForwardImg = document.createElement('img');

      newElementFastForward.id = 'fastForwardButton';
      newElementFastForward.className = 'vjs-fullscreen-control vjs-control vjs-button';

      newImageFastForwardImg.setAttribute(
        'src',
        'https://player.support.brightcove.com/assets/images/code-samples/brightcove-player-sample-back-forward-buttons/forward-button.png'
      );
      newImageFastForwardImg.setAttribute('style', 'width: 100% !important; height: 100% !important;');
      newElementFastForward.appendChild(newImageFastForwardImg);
      jumpControlBar.insertBefore(newElementFastForward, insertBeforeNodeByJumpingPlayTime);

      newElementFastForward.addEventListener('click', () => {
        fastForwardVideoTime();
      });
    }
  };

  useEffect(() => {
    if (!videoElements) return;
    if (detailDesc && videoElements.length > 0) {
      setRendered(true);
    }
  }, [detailDesc, videoElements]);

  return (
    <div ref={articleInnerRef} className={classNames([className, { event_end: isDimEvent }])}>
      {isDimEvent && (
        <>
          <img src="/assets/images/img/event-end-banner.png" alt="이벤트 종료" />
        </>
      )}
      {!isCardNews && (
        <>
          <InnerHTML className={classNames(innerClassName)} html={detailDesc || ''} />
        </>
      )}
      {isCardNews && (
        <div style={{ width: '547px', maxHeight: '820px', margin: 'auto', marginTop: '15px' }}>
          <ImageGallery
            items={cardNewsImages}
            showIndex
            showPlayButton={false}
            showFullscreenButton={false}
            infinite={false}
          />
        </div>
      )}
      {rendered &&
        videoElements?.map((el, index) => {
          const videoEl = el as HTMLVideoElement;
          if (!videoEl) return <></>;
          if (!videoEl.dataset) return <></>;
          const { account, videoId } = videoEl.dataset;
          if (!account || !videoId) return <></>;
          const target = articleInnerRef.current?.querySelector(`#video-container-${index}`);
          let collected = {} as { width?: number; height?: number } & any;

          Array.from(el.attributes).forEach(attr => {
            collected = { ...collected, [(attr as any).name as string]: (attr as any).value };
          });

          return (
            <Portal node={target} key={index}>
              <ReactPlayerLoader
                accountId={account}
                videoId={videoId}
                muted={false}
                ref={vRef}
                onSuccess={(success: any) => {
                  onSuccess(success, collected);
                }}
              />
            </Portal>
          );
        })}
      {isCardNews && (
        <>
          <InnerHTML className={classNames(innerClassName)} html={detailDesc || ''} />
        </>
      )}
      {/* 영상 타임 스탬프 */}
      {mediaDetailData &&
        onClickTimeStampItemHandler &&
        mediaDetailData?.videoDurationTimeCodeList &&
        !!mediaDetailData?.videoDurationTimeCodeList?.length && (
          <VideoTimeStampArea
            key={mediaDetailData.mediaId}
            videoDurationTimeCodeList={mediaDetailData?.videoDurationTimeCodeList}
            onClickTimeStampHandler={timeStamp => onClickTimeStampItemHandler(timeStamp)}
          />
        )}
      {/* 제작진/연출 정보 */}
      {mediaDetailData && mediaDetailData?.editorAssistantMemberInfo && (
        <span
          dangerouslySetInnerHTML={{
            __html: mediaDetailData.editorAssistantMemberInfo,
          }}
        />
      )}
    </div>
  );
};

export default ScriptContent;
