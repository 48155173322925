import React, { useCallback, useRef } from 'react';
import { parse } from 'query-string';
import { getHtmlFormatByEscapeHtmlData } from '../../../utils/LGLifeUtils';
import { all_click_datalayer_push, getFAQProps } from '../../../utils/GAUtils';

interface AccordionItemProps {
  category: string;
  q: string;
  a: string;
}

const AccordionItem = (props: AccordionItemProps) => {
  const qRef = useRef<HTMLLIElement>(null);

  return (
    <>
      <li className="faq_accor_item" ref={qRef}>
        <a
          className="faq_accor_link"
          {...getFAQProps(props.category, {
            dataclickname: props.q,
          })}
          onClick={e => {
            all_click_datalayer_push(e.currentTarget);
            qRef.current?.classList.toggle('is-active');
          }}
        >
          <span className="faq_accor_category">{props.category}</span>
          <div className="faq_accor_q" dangerouslySetInnerHTML={{ __html: props.q }}></div>
        </a>
        <div className="faq_accor_a">
          <p className="text" dangerouslySetInnerHTML={{ __html: props.a.replace(/\n/g, '<br/>') || '' }}></p>
        </div>
      </li>
    </>
  );
};

interface Props {
  list?: FaqResDto[];
  /** 상단 제목 노출 */
  showTopTitle?: boolean;
  count?: number;
}

const SearchFaq = ({ list = [], count = 0, showTopTitle = false }: Props) => {
  const keyword = parse(location.search).keyword as string;

  const highlightTarget = useCallback(
    (input: string) => {
      const regExp = new RegExp(`${getHtmlFormatByEscapeHtmlData(keyword, { revertEscapeHtmlData: true })}`, 'gi');
      const result = regExp.exec(input);

      return result?.length
        ? input.replace(regExp, `<span class="is-target">${getHtmlFormatByEscapeHtmlData(result[0])}</span>`)
        : input;
    },
    [keyword]
  );

  return (
    <>
      {showTopTitle && (
        <div className="search_inner">
          <div className="search_top">
            <h3 className="search_top_title">
              FAQ
              <span className="is-num" {...{ id: 'faqSearchNum', datasearchnum: count }}>
                ({count}개)
              </span>
            </h3>
          </div>
        </div>
      )}
      <div className="search_row">
        <div className="search_faq">
          <ul className="faq_accor">
            {list?.map(item => (
              <AccordionItem
                category="일상"
                q={highlightTarget(item.question as string)}
                a={highlightTarget(item.reply as string)}
                key={item.faqId}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SearchFaq;
