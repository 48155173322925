import request from 'services/request';
import { endpoints } from 'services';

export interface FaqRequest {
  cateId?: string;
  page?: number;
  popularitySortType?: string;
  size: number;
  sort: string[];
}

export default {
  getFaq: () =>
    request({
      url: endpoints.faq.faq,
      method: 'get',
    }),
};
