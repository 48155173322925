import React from 'react';

type MaybeProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  test: boolean;
  children: JSX.Element;
};

const Maybe = ({ test, children }: React.PropsWithChildren<MaybeProps>): React.ReactElement => {
  return <>{test && children}</>;
};

export default Maybe;
