import React, { useState } from 'react';
import RegisterContext from 'contexts/RegisterContext';

const RegisterProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [empNum, setEmpNum] = useState('');
  const [password, setPassword] = useState('');
  const [groupNm, setGroupNm] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [positionNm, setPositionNm] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [department, setDepartment] = useState('');

  const initialValue = {
    email: email,
    setEmail: setEmail,
    name: name,
    setName: setName,
    empNum: empNum,
    setEmpNum: setEmpNum,
    password: password,
    setPassword: setPassword,
    groupNm: groupNm,
    setGroupNm: setGroupNm,
    groupCode: groupCode,
    setGroupCode: setGroupCode,
    positionNm: positionNm,
    setPositionNm: setPositionNm,
    phoneNum: phoneNum,
    setPhoneNum: setPhoneNum,
    department: department,
    setDepartment: setDepartment,
  };

  return <RegisterContext.Provider value={initialValue}>{children}</RegisterContext.Provider>;
};

export default RegisterProvider;
