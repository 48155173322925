import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import AlertPopup from 'components/organisms/Popup/AlertPopup';

export interface FileUploaderProps {
  onAddFiles: (files: Array<File>) => void;
  onRemoveFile: (index: number, isLocal: boolean) => void;
  maxCount: number;
  maxMBSize: number;
  selectedFiles: File[];
  uploadedFiles: AttachFileResDto[];
}

const FileUploader = ({
  onAddFiles,
  onRemoveFile,
  maxCount,
  selectedFiles,
  uploadedFiles,
  maxMBSize,
}: FileUploaderProps) => {
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');

  /** 첨부 파일 버튼 노출 여부 */
  const showAttachFileButton = useMemo(() => {
    if (!selectedFiles?.length && !uploadedFiles?.length) return true;
    if (!!uploadedFiles?.length) {
      return uploadedFiles.length < 5;
    }
    return selectedFiles.length < 5;
  }, [selectedFiles, uploadedFiles]);

  const fileInputChangeHandler = e => {
    addFiles(e.currentTarget.files);
  };

  const dropZoneDragOverHandler = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dropZoneDragHandler = e => {
    e.stopPropagation();
    e.preventDefault();

    const { files } = e.dataTransfer;
    addFiles(files);
  };

  const addFiles = (files: File[]) => {
    if (uploadedFiles.length + selectedFiles.length + files.length > maxCount) {
      setAlertPopContents('파일은 최대 ' + maxCount + '개 까지 가능합니다.');
      setIsAlertPopupOpen(true);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxMBSize * 1024 * 1024) {
        setAlertPopContents(maxMBSize + 'MB 이상의 파일은 업로드를 할 수 없습니다.');
        setIsAlertPopupOpen(true);
        return;
      }
    }

    onAddFiles(Array.from(files));
  };

  const FileItem = ({ index, fileName, isLocal }) => (
    <div className="attached_file">
      <span className="attached_file_name">{fileName}</span>
      <button type="button" className="remove_attached_file" onClick={() => onRemoveFile(index, isLocal)}></button>
    </div>
  );

  return (
    <>
      <div
        className="write_section_input write_drawable"
        onDrop={dropZoneDragHandler}
        onDragOver={dropZoneDragOverHandler}
      >
        <div className={classNames(['drawable_area', { 'is-show': showAttachFileButton }])}>
          <p className="drawable_area_text">
            <span className="drawable_area_em">
              최대 {maxMBSize}MB의 파일 {maxCount}개
            </span>
            <span className="is-pc">를 이곳에 끌어오세요.</span>
          </p>
          <label htmlFor="writeFile" className="write_file">
            or 파일선택
          </label>
          <input type="file" id="writeFile" onChange={fileInputChangeHandler} />
        </div>
        <div
          className={classNames(['attached_area', { 'is-show': selectedFiles.length > 0 || uploadedFiles.length > 0 }])}
        >
          <span className="attached_title">첨부파일</span>
          {uploadedFiles.map((file, i) => (
            <FileItem key={i} index={i} fileName={file.fileDsplNm} isLocal={false} />
          ))}
          {selectedFiles.map((file, i) => (
            <FileItem key={i} index={i} fileName={file.name} isLocal={true} />
          ))}
        </div>
      </div>
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
    </>
  );
};

export default FileUploader;
