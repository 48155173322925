/* eslint-disable prefer-promise-reject-errors */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { endpoints } from 'services';
import Paths from 'commons/paths';
import { SERVER_URL } from './endpoints';
import { setCicUpdate } from 'utils/LGLifeUtils';

interface RequestProps {
  multiPart?: boolean;
  noAuth?: boolean;
  verifyRefresh?: string;
}

async function call(axiosConfig: AxiosRequestConfig, requestProp?: RequestProps | undefined) {
  if (!requestProp?.noAuth) {
    let authorization = 'Bearer ';

    if (requestProp?.verifyRefresh) {
      authorization += requestProp.verifyRefresh;
    } else {
      authorization += localStorage.getItem('llAccessToken');
    }

    axios.defaults.headers.common.Authorization = authorization;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  if (requestProp?.multiPart) {
    axios.defaults.headers['Content-Type'] = 'multipart/form-data';
  } else {
    axios.defaults.headers['Content-Type'] = 'application/json';
  }

  try {
    const { data, status }: AxiosResponse = await axios(axiosConfig);
    if (status !== 200) {
      console.error('reject', status, data);
      return Promise.reject({ status, data });
    }

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ error: error || 'Something bad happened' });
  }
}

async function refresh(): Promise<any> {
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Authorization'] = undefined;

  // accessToken 갱신시도
  await axios({
    baseURL: SERVER_URL,
    method: 'POST',
    url: endpoints.auth.tokenRefresh,
    data: {
      refreshToken: localStorage.getItem('llRefreshToken'),
    },
  }).then(response => {
    if (response.data.resultCode === 200 && response.data.accessToken) {
      // accessToken 갱신성공
      setCicUpdate(response.data.shouldSyncCic);

      localStorage.setItem('llAccessToken', response.data.accessToken);

      axios.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;

      return Promise.resolve();
    } else if (response.data.resultCode === 1002) {
      // refresh Token 만료되어 로그인 페이지로 보냄
      localStorage.removeItem('llAccessToken');
      localStorage.removeItem('llRefreshToken');

      window.location.href = Paths.getPath('login');
      // alert('토큰이 만료되어 로그인 페이지로 이동합니다.');
      return Promise.reject();
    }

    console.error('error case');
    return Promise.reject();
  });
}

async function request(axiosConfig: AxiosRequestConfig, requestProp?: RequestProps | undefined): Promise<any> {
  axiosConfig.baseURL = SERVER_URL;
  if (!axiosConfig.url) {
    return Promise.reject();
  }

  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['Pragma'] = 'no-cache';
  axios.defaults.headers['Expires'] = '0';

  return call(axiosConfig, requestProp).then(function (data) {
    if (data.resultCode === 1001) {
      return refresh().then(function () {
        return call(axiosConfig, requestProp);
      });
    }

    return Promise.resolve(data);
  });
}

export default request;
