import useSWR from 'swr';
import useMyInfo from './useMyInfo';
import { endpoints, fetcher } from '../services';

const useSeriesClassification = ({ seriesContentsType = 'VIDEO' } = {}) => {
  const { info } = useMyInfo();

  const {
    data: seriesClassificationData,
    mutate,
    revalidate,
  } = useSWR<any>(info ? endpoints.series.series : null, () =>
    fetcher.media
      .getSeriesClassificationList({
        seriesContentsType: seriesContentsType,
      })
      .then(res => res)
  );

  return {
    seriesClassificationData,
    mutate,
    revalidate,
  };
};

export default useSeriesClassification;
