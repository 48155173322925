import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';
import { PagingReqDto } from '../../types';

export interface MentionsBoardsRequest extends PagingReqDto {
  /** 카테고리 목록 */
  cateList?: string;
  /** 게시판 유형 */
  boardType?: string;
  /** 상단 통함 검색어 */
  topSearchWord?: string;
  /** 게시판 내 검색어 */
  searchWordIn?: string;
}

export interface MentionsBoardUpdateReq extends MentionsBoardDetailReqDto {
  uploadedFiles: AttachFileResDto[];
}

export interface MentionsBoardLikeReq {
  boardId: string;
}

export interface MentionsBoardDeleteReq {
  boardId: string;
}

export default {
  getMentionsBoardList: (params: MentionsBoardsRequest): Promise<MentionsBoardListResDto> =>
    request({
      url: endpoints.mentionsBoards.mentionsBoard,
      method: 'get',
      params,
    }),
  getMentionsBoardDetail: (url: string): Promise<MentionsBoardDetailResDto> =>
    request({
      url,
      method: 'get',
    }),
  postMentionsBoardWrite: (params: MentionsBoardDetailReqDto): Promise<BaseResDto> => {
    const postParams = {
      ...params,
      boardType: params.boardType || 'MENTIONS_BOARD',
    } as MentionsBoardDetailReqDto;

    if (!!params.attachFiles?.length) {
      for (let i = 0; i < params.attachFiles.length; i++) {
        postParams['attachFiles[' + i + '].attachFile'] = params.attachFiles[i];
        postParams['attachFiles[' + i + '].attachFileId'] = '0';
      }
    }

    if (!params.attachFiles?.length) {
      delete postParams.attachFiles;
    }

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: endpoints.mentionsBoards.mentionsBoardWrite,
        method: 'post',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  putMentionsBoardUpdate: (url: string, params: MentionsBoardUpdateReq): Promise<BaseResDto> => {
    const postParams = {
      ...params,
      boardType: params.boardType || 'MENTIONS_BOARD',
    } as MentionsBoardUpdateReq;

    const uploadedFilesLength = params.uploadedFiles.length;

    for (let i = 0; i < uploadedFilesLength; i++) {
      postParams['attachFiles[' + i + '].attachFileId'] = params.uploadedFiles[i].fileId;
    }

    if (params.attachFiles?.length) {
      for (let i = 0; i < params.attachFiles.length; i++) {
        postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFile'] = params.attachFiles[i];
        postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFileId'] = '0';
      }
    }

    if (!params.attachFiles?.length) {
      delete postParams.attachFiles;
    }

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: url,
        method: 'put',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  postMentionsBoardLike: (params: MentionsBoardLikeReq): Promise<BaseResDto> =>
    request({
      url: endpoints.mentionsBoards.mentionsBoardLike,
      method: 'post',
      params,
    }),
  deleteMentionsBoard: (url: string, params?: MentionsBoardDeleteReq): Promise<BaseResDto> =>
    request({
      url,
      method: 'delete',
      params,
    }),
  shareMentionsBoard: (params: ShareReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.mentionsBoards.mentionsBoardShare,
      method: 'post',
      data: params,
    }),
};
