import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';

const usePopularityKeyword = () => {
  const { info } = useMyInfo();

  const { data: popularityKeyword } = useSWR<any>(info ? endpoints.common.popularityKeyword : null, () =>
    fetcher.common.getPopularityKeyword().then(res => {
      return res;
    })
  );

  return {
    popularityKeyword,
  };
};

export default usePopularityKeyword;
