import request from 'services/request';
import { endpoints } from 'services';

export interface RecommendsRequest {
  page?: number;
  popularitySortType?: string;
  size: number;
  sort: string[];
}

export default {
  getRecommends: (params: RecommendsRequest): Promise<any> =>
    request({
      url: endpoints.recommends.recommends,
      method: 'get',
      params,
    }),
  getRecommendKeywords: (): Promise<any> =>
    request({
      url: endpoints.recommends.keywords,
      method: 'get',
    }),
};
