import ProfileImage from 'components/atoms/ProfileImage';
import CommentItem from 'components/molecules/CommentItem';
import SimpleComment from 'components/molecules/CommentItem/SimpleComment';
import EmojiComment from 'components/molecules/EmojiComment';
import { useCommentList } from 'hooks';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';
import MyInfoContext from 'contexts/MyInfoContext';
import { CELEBRATION_MESSAGE_WINNER } from '../../../commons/constants';
import AlertPopup from '../Popup/AlertPopup';
import MentionAddComment from '../../molecules/MentionComment/MentionAddComment';
import { ContentsPostType } from '../../../types/enum/ContentsPostType';
import { CommentPostType } from '../../../types/enum/CommentPostType';
import { ContentsDetailType } from '../../../types/enum/ContentsDetailType';

export interface DetailCommentProps {
  contentsId: string;
  commentPostType: CommentPostType;
  magazineType?: 'CARD' | 'CARD_COMICS' | 'WEEK_NEWSLETTER';
  newsType?: 'PRESS' | 'NEWS';
  tags: string;
  title: string;
  category: string;
  contentCreatorNm: string;
  time?: number;
  brightcoveId?: string;
  eventType?: 'EDITOR' | 'SURVEY' | 'M_QUIZ' | 'QUIZ_WINNER' | 'SIMPLE_SURVEY';
  eventStatus?: 'ALL' | 'ING' | 'END';
  isNews?: boolean;
  mediaContentType?: string;
}

const DetailComment = (props: DetailCommentProps) => {
  const history = useHistory();
  const { data, mutate, addComment, likeComment, modifyComment, deleteComment, resultMessage, resultCode } =
    useCommentList(props.contentsId, props.commentPostType);

  const info = useContext(MyInfoContext);

  const [totalCnt, setTotalCnt] = useState(0);
  const [userInfo, setUserInfo] = useState<any>();
  const [commentList, setCommentList] = useState([] as CommentResDto[]);
  const [bestComment, setBestComment] = useState<CommentResDto>();
  const [fixedComment, setFixedComment] = useState<CommentResDto>();
  const [currentDt, setCurrentDt] = useState('');
  const [currentCmtPage, setCurrentCmtPage] = useState(1);

  const cmtDivRef = useRef<HTMLDivElement>(null);
  const cmtInputRef = useRef<HTMLDivElement>(null);
  const detailCmtRef = useRef<HTMLDivElement>(null);
  const cmtTextareaRef = useRef<HTMLTextAreaElement>(null);
  const scrollParentRef = useRef<HTMLDivElement>(null);
  const [screenSize, setScreenSize] = useState('lg');
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState(false);
  const [alertPopupContents, setAlertPopupContents] = useState('컨텐츠를 불러오는 중 에러가 발생했습니다.');

  const isMobile = useMemo(() => window.innerWidth < 1024, [window.innerWidth]);

  /** 댓글 id 가 query string 값에 포함 되어 있는지 여부 */
  const hasCommentId = useMemo(() => {
    const query = queryString.parse(location.search);
    const commentId = query.commentId;
    return !!commentId;
  }, [history.location.pathname, history.location.state, location.search]);

  /** 대댓글 id 가 query string 값에 포함 되어 있는지 여부 */
  const hasReplyCommentId = useMemo(() => {
    const query = queryString.parse(location.search);
    const replyCommentId = query.replyId;
    if (!hasCommentId) return false;
    return !!replyCommentId;
  }, [history.location.pathname, history.location.state, location.search, hasCommentId]);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  const commentSearchMailWidth = () => {
    const mail_list = document.querySelectorAll('.result_list_mail');
    const mail_length = mail_list.length;

    let max = 0;
    for (let i = 0; i < mail_length; i++) {
      const mail_wid = mail_list[i].clientWidth;

      if (mail_wid > max) {
        max = mail_wid;
      }
    }

    mail_list.forEach(el => {
      el.setAttribute('style', 'width:' + (max + 2) + 'px');
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.querySelector('html')?.classList.remove('no-scroll');
      document.querySelector('body')?.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    if (isMobile && (hasCommentId || hasReplyCommentId)) {
      openComments();
      return;
    }
    closeComments();
  }, [history.location.pathname, history.location.state, screenSize, hasCommentId]);

  useEffect(() => {
    if (!isMobile) {
      document.querySelector('html')?.classList.remove('no-scroll');
      document.querySelector('body')?.classList.remove('no-scroll');
      closeComments();
    }
  }, [isMobile]);

  useEffect(() => {
    if (data) {
      if (resultCode !== 200) {
        setIsAlertPopupOpen(true);
        setAlertPopupContents(resultMessage);
        return;
      }

      setCommentList(data.commentList);
      setBestComment(data.bestComment);
      setFixedComment(data.fixedComment);
      setTotalCnt(data.totalCommentCnt);
      setCurrentDt(data.currentDt);
    }
  }, [data]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    const commentId = query.commentId;
    const replyCommentId = query.replyId;

    if (commentList && (commentId || replyCommentId)) {
      commentList.map((c, index) => {
        if (c.commentId == commentId) {
          setCurrentCmtPage(index / 5 + 2);
        }
      });
    }
  }, [commentList, hasReplyCommentId]);

  useEffect(() => {
    if (info) setUserInfo(info);
  }, [info]);

  const openComments = () => {
    window.scrollTo(0, 0);
    detailCmtRef.current?.classList.remove('is-collapsed');
    document.querySelector('html')?.classList.add('no-scroll');
    document.querySelector('body')?.classList.add('no-scroll');
  };

  const closeComments = () => {
    detailCmtRef.current?.classList.add('is-collapsed');
    document.querySelector('html')?.classList.remove('no-scroll');
    document.querySelector('body')?.classList.remove('no-scroll');
  };

  /** 75주년 메시지 당첨자 관련 이벤트 여부 확인 */
  const isCelebrationWinnerMessage = propsInfo => {
    if (propsInfo.contentsType !== 'EVENT') return false;
    return CELEBRATION_MESSAGE_WINNER(process.env.REACT_APP_CURRENT_ENV_NAME) === propsInfo.contentsId;
  };

  const commentDivFocus = () => {
    const sel = window.getSelection();
    const innerDiv = cmtDivRef.current;

    if (innerDiv) {
      if (innerDiv.lastChild) {
        sel?.collapse(innerDiv.lastChild, innerDiv?.lastChild?.textContent?.length || 0);
      } else {
        innerDiv.innerHTML = '';
        sel?.collapse(innerDiv, 0);
      }

      cmtDivRef.current.focus();
    }
  };

  return (
    !isCelebrationWinnerMessage(props) && (
      <>
        <a
          className="detail_comment_intro"
          onClick={() => {
            if (isMobile) openComments();
          }}
        >
          <span className="comment_title">댓글</span>
          <span className="comment_value">
            <span className="is-value">{totalCnt}</span>개
          </span>
          <span className="comment_more is-mobile">
            <img src="/assets/images/icon/ico-comment-more.png" alt="more" />
          </span>

          {/* <!-- 베스트 댓글(모바일) --> */}
          {data && bestComment ? (
            <div className="comment_intro_best is-mobile">
              <SimpleComment
                {...bestComment}
                {...props}
                isBest={true}
                currentDt={data.currentDt}
                commentSearchMailWidth={commentSearchMailWidth}
              />
            </div>
          ) : (
            <div className="comment_intro_default is-mobile">
              <div className="comment_item">
                <div className="comment_item_wrap">
                  <div className="comment_form">
                    <div className="comment_profile">
                      <ProfileImage
                        name={userInfo?.name || ''}
                        score={userInfo?.actScore as number}
                        image={userInfo?.profileImage}
                        isAdmin={userInfo?.isAdmin === 'Y'}
                      />
                    </div>
                    <div className="comment_input">
                      <div className="comment_placeholder">'@'를 입력해 보세요. 동료 임직원을 소환할 수 있어요!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </a>
        <div className="detail_comment" ref={detailCmtRef}>
          <div className="detail_comment_inner">
            <div className="comment_title_mob is-mobile">
              <span className="comment_title">댓글</span>
              <span className="comment_value">
                <span className="is-value">{totalCnt}</span>개
              </span>
              <button className="comment_close" onClick={closeComments}>
                <img src="/assets/images/icon/ico-action-24-close.svg" alt="close" />
              </button>
            </div>
            <div className="comment_wrap" ref={scrollParentRef}>
              <div className="comment_top">
                <div className="comment_con">
                  <div className="comment_form">
                    <div className="comment_profile">
                      <ProfileImage
                        name={userInfo?.name || ''}
                        score={userInfo?.actScore as number}
                        image={userInfo?.profileImage}
                        isAdmin={userInfo?.isAdmin === 'Y'}
                      />
                    </div>
                    <div className="comment_input" ref={cmtInputRef}>
                      <a
                        className="comment_placeholder is-mobile"
                        onClick={e => {
                          const cmtInput = cmtInputRef.current;
                          if (!cmtInput?.classList.contains('is-active')) {
                            cmtInput?.classList.add('is-active');
                            cmtDivRef.current?.focus();
                            document.querySelector('nav')?.style.setProperty('display', 'none');
                            document.querySelector('.detail_comment_inner')?.classList.add('is-active');
                          }
                        }}
                      >
                        '@'를 입력해 보세요. 동료 임직원을 소환할 수 있어요!
                      </a>
                      <MentionAddComment
                        {...props}
                        isReply={false}
                        isMobile={isMobile}
                        cmtDivRef={cmtDivRef}
                        cmtInputRef={cmtInputRef}
                        commentSearchMailWidth={commentSearchMailWidth}
                      />
                    </div>
                  </div>
                  <div className="comment_emoji is-pc">
                    {/* emoji */}
                    <EmojiComment
                      clickEmoji={emoji => {
                        if (cmtDivRef.current) {
                          cmtDivRef.current.innerHTML = cmtDivRef.current?.innerHTML + emoji;
                          commentDivFocus();
                        }
                      }}
                      {...props}
                    />
                  </div>
                </div>
              </div>
              <div className="comment_box">
                {/* 고정 - 고정댓글, 베스트댓글 */}
                {data && (bestComment || fixedComment) && (
                  <div className="comment_fix">
                    {fixedComment && (
                      <CommentItem
                        {...fixedComment}
                        like={likeComment}
                        update={mutate}
                        currentDt={data.currentDt}
                        {...props}
                        modify={modifyComment}
                        delete={deleteComment}
                        closeCmt={closeComments}
                        eventStatus={props.eventStatus}
                        eventType={props.eventType}
                        isfixed={true}
                        mediaContentType={props.mediaContentType}
                        commentSearchMailWidth={commentSearchMailWidth}
                      />
                    )}
                    {bestComment && (
                      <CommentItem
                        {...bestComment}
                        isBest={true}
                        like={likeComment}
                        update={mutate}
                        currentDt={data.currentDt}
                        {...props}
                        modify={() => {}}
                        delete={() => {}}
                        closeCmt={closeComments}
                        eventStatus={props.eventStatus}
                        eventType={props.eventType}
                        commentSearchMailWidth={commentSearchMailWidth}
                      />
                    )}
                  </div>
                )}
                <div className="comment_list">
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={() => {
                      setCurrentCmtPage(currentCmtPage + 1);
                    }}
                    hasMore={currentCmtPage * 5 < (commentList?.length || 0)}
                    loader={<h1 key={Date()}>loading...</h1>}
                    threshold={0}
                    useWindow={!isMobile}
                    getScrollParent={() => scrollParentRef.current}
                  >
                    {commentList.slice(0, currentCmtPage * 5).map((cmt, idx) => (
                      <CommentItem
                        {...cmt}
                        like={likeComment}
                        update={mutate}
                        currentDt={currentDt}
                        key={idx}
                        {...props}
                        modify={modifyComment}
                        delete={deleteComment}
                        mutateCmt={mutate}
                        closeCmt={closeComments}
                        eventStatus={props.eventStatus}
                        eventType={props.eventType}
                        magazineType={props.magazineType}
                        commentSearchMailWidth={commentSearchMailWidth}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
                {!bestComment && !fixedComment && commentList && commentList.length === 0 && (
                  <div className="comment_box">
                    <div className="comment_empty">“첫 댓글의 주인공이 돼 보세요”</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <AlertPopup
          isOpen={isAlertPopupOpen}
          onClose={() => {
            setIsAlertPopupOpen(false);
            if (document.referrer) {
              window.close();
              return;
            }
            window.history.back();
          }}
        >
          {alertPopupContents}
        </AlertPopup>
      </>
    )
  );
};

// @ts-ignore
export default React.memo(DetailComment, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
