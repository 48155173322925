import request from 'services/request';
import { endpoints } from 'services';

export interface MediaRequest {
  cateType: string;
  page?: number;
  popularitySortType?: string;
  size: number;
  sort: Array<string>;
  tagList?: Array<string>;
}

export interface MediaDetailRequest {}

export interface MediaBasicTagsReq {
  tagType: string;
}

export interface MediaBookmarkReq {
  mediaId: string;
}

export interface MediaLikeReq {
  mediaId: string;
}

export interface MediaPlaylistReq {}

export default {
  getMedia: (params: MediaRequest): Promise<any> =>
    request({
      url: endpoints.media.media,
      method: 'get',
      params,
    }),
  getMediaDetail: (url: string, params?: MediaDetailRequest): Promise<any> =>
    request({
      url,
      method: 'get',
      params,
    }),
  getMediaBasicTags: (params: MediaBasicTagsReq): Promise<any> =>
    request({
      url: endpoints.media.basicTags,
      method: 'get',
      params,
    }),
  postMediaBookmark: (params: MediaBookmarkReq): Promise<any> =>
    request({
      url: endpoints.media.bookmark,
      method: 'post',
      params,
    }),
  deleteMediaBookmark: (params: MediaBookmarkReq): Promise<any> =>
    request({
      url: endpoints.media.bookmark,
      method: 'delete',
      params,
    }),
  getMediaContinue: (): Promise<any> =>
    request({
      url: endpoints.media.continue,
      method: 'get',
    }),
  postMediaContinue: (params: MediaContinueListReqDto): Promise<any> =>
    request({
      url: endpoints.media.continue,
      method: 'post',
      data: params,
    }),
  postMediaLike: (params: MediaLikeReq): Promise<any> =>
    request({
      url: endpoints.media.like,
      method: 'post',
      params,
    }),
  getMediaPlaylist: (url: string, params?: MediaPlaylistReq): Promise<any> =>
    request({
      url,
      method: 'get',
      params,
    }),
  getRelatedMedia: (url: string): Promise<any> =>
    request({
      url,
      method: 'get',
    }),
  postMediaDownload: (params: MediaDownloadReqDto): Promise<any> =>
    request({
      url: endpoints.media.download,
      method: 'post',
      data: params,
    }),
  putMediaShare: (params: ShareReqDto): Promise<any> =>
    request({
      url: endpoints.media.share,
      method: 'put',
      data: params,
    }),
  getMediaEsg: (): Promise<any> =>
    request({
      url: endpoints.media.esg,
      method: 'get',
    }),
  /** 명예의 전당 연관 컨텐츠 목록 조회 */
  postLgAwardsRelationContentsList: (params): Promise<any> =>
    request({
      url: endpoints.media.lgAwardsRelationContents,
      method: 'post',
      data: params,
    }),
  /** 명예의 전당 연도별 조회 건수 집계 */
  getAddLgAwardsPageViewCount: (params): Promise<any> =>
    request({
      url: endpoints.media.addLgAwardsPageViewCount,
      method: 'get',
      params,
    }),
  getSeriesClassificationList: (params: { seriesContentsType: string }): Promise<any> =>
    request({
      url: endpoints.series.series,
      method: 'get',
      params,
    }),
  getSeriesClassificationDetail: (
    url: string,
    params: {
      seriesContentsType: string;
    }
  ): Promise<any> =>
    request({
      url: url,
      method: 'get',
      params,
    }),
};
