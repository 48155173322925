import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';

const useMain = () => {
  const { info } = useMyInfo();

  const { data: ad } = useSWR<any>(info ? endpoints.main.ad : null, () =>
    fetcher.main.getAd({}).then(res => {
      return res;
    })
  );
  const { data: banner } = useSWR<any>(info ? [endpoints.main.banner, info] : null, () =>
    fetcher.main.getBanner({}).then(res => {
      return res;
    })
  );
  const { data: board } = useSWR<any>(info ? endpoints.main.board : null, () =>
    fetcher.main.getBoard({}).then(res => {
      return res;
    })
  );
  const { data: hero } = useSWR<any>(info ? endpoints.main.hero : null, () =>
    fetcher.main.getHero({}).then(res => {
      return res;
    })
  );
  const { data: news } = useSWR<any>(info ? endpoints.main.news : null, () =>
    fetcher.main.getNews({}).then(res => {
      return res;
    })
  );
  const { data: playList } = useSWR<any>(info ? endpoints.main.playlist : null, () =>
    fetcher.main.getPlayList({}).then(res => {
      return res;
    })
  );
  const { data: popularContent } = useSWR<any>(info ? endpoints.main.popularContent : null, () =>
    fetcher.main.getPopularContent({}).then(res => {
      return res;
    })
  );
  const { data: recommend } = useSWR<any>(info ? endpoints.main.recommend : null, () =>
    fetcher.main.getRecommend({}).then(res => {
      return res;
    })
  );
  const { data: section } = useSWR<any>(info ? endpoints.main.section : null, () =>
    fetcher.main.getSection({}).then(res => {
      return res;
    })
  );
  const { data: sns } = useSWR<any>(info ? endpoints.main.sns : null, () =>
    fetcher.main.getSns({}).then(res => {
      return res;
    })
  );

  return {
    ad,
    banner,
    board,
    hero,
    news,
    playList,
    popularContent,
    recommend,
    section,
    sns,
  };
};

export default useMain;
