import Pagination from 'react-js-pagination';
import { useMyPage } from 'hooks';
import BoardListItem from 'components/molecules/Community/Board/BoardList';
import Intro from '../../../templates/MyPage/Intro';
import React from 'react';

const ITEMS_PER_PAGE = 10;
const PAGE_RANGE = 5;

interface Props {
  list: BoardCardResDto[];
  count: number;
  limit: number;
  ga_belong: string;
}

export const MyBoardTable = ({ list, count, limit, ga_belong }: Props) => {
  return (
    <>
      {count > 0 ? (
        <table>
          <colgroup>
            <col style={{ width: '150px' }} />
            <col style={{ width: '200px' }} />
            <col style={{ width: '50%' }} />
            <col style={{ width: '8%' }} />
            <col style={{ width: '8%' }} />
            <col style={{ width: '8%' }} />
            <col style={{ width: '93px' }} />
          </colgroup>
          <thead>
            <tr>
              <th className="list_head">구분</th>
              <th className="list_head">작성자</th>
              <th className="list_head">제목</th>
              <th className="list_head">조회수</th>
              <th className="list_head">댓글</th>
              <th className="list_head">좋아요</th>
              <th className="list_head">작성일</th>
            </tr>
          </thead>
          <tbody>
            {list.slice(0, limit).map((contents: BoardCardResDto) => (
              <BoardListItem {...contents} ga_belong={ga_belong} key={contents.boardId} />
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mypage_empty">작성한 게시글이 없습니다</div>
      )}
    </>
  );
};

const Board = () => {
  const { boards, page, setPage } = useMyPage({});

  return (
    <div className="content_inner">
      <div className="mypage">
        <Intro />

        <div className="mypage_section">
          <div className="mypage_inner">
            <div className="mypage_top">
              <h3 className="mypage_top_title">작성한 게시글 - 자유 게시판</h3>
            </div>
          </div>
          <div className="mypage_board list_table">
            <MyBoardTable
              list={boards?.mainBoardList || ([] as BoardCardResDto[])}
              count={Number(boards?.totalAmount)}
              limit={ITEMS_PER_PAGE}
              ga_belong="마이페이지/작성한 게시글 - 자유 게시판"
            />
            {Number(boards?.totalAmount || 0) > ITEMS_PER_PAGE ? (
              <div className="list_pagination">
                <Pagination
                  activePage={parseInt(page)}
                  itemsCountPerPage={ITEMS_PER_PAGE}
                  totalItemsCount={boards?.totalAmount || 0}
                  pageRangeDisplayed={PAGE_RANGE}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemClassFirst="first"
                  itemClassPrev="prev"
                  itemClassNext="next"
                  itemClassLast="last"
                  onChange={page => {
                    setPage(page);
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Board;
