import dompurify from 'dompurify';
import { getHtmlFormatByEscapeHtmlData } from '../../../../utils/LGLifeUtils';
import moment from 'moment';

interface VideoTimeStampItemProps {
  /** 타임 스탬프 정보 */
  videoDurationTimeStampItem: VideoDurationTimeCodeResDto;
  /** 타임 스탬프 클릭 이벤트 */
  onClickTimeStampHandler: (timeStamp: moment.Moment) => void;
}

const TIME_FORMAT = 'HH:mm:ss';

const VideoTimeStampItem = (props: VideoTimeStampItemProps) => {
  const { videoDurationTimeStampItem } = props;

  /**
   * 시,분,초 시간 값에 맞게 필요 없는 시간 값 제거
   * @param durationTime
   */
  const getPrettierTimeFormat = (durationTime: string) => {
    if (!durationTime || !durationTime?.includes(':')) return '00:00';

    const timeParts = durationTime.split(':');

    // 시간이 올바르게 분리되지 않았을 경우
    if (timeParts?.length < 3) return '00:00';

    const [hours, minutes, seconds] = timeParts;

    // 시간을 모두 체크하여 알맞은 형식 반환
    if (hours !== '00') return `${hours}:${minutes}:${seconds}`;
    if (minutes !== '00') return `${minutes}:${seconds}`;
    if (seconds !== '00') return `00:${seconds}`;

    return '00:00';
  };

  /**
   * 타임 스탬프 시간 값 클릭 이벤트
   * @param timeStamp
   */
  const onClickVideoTimeStamp = (timeStamp: string) => {
    const timeStampMoment = moment(timeStamp || '00:00:00', TIME_FORMAT);
    props.onClickTimeStampHandler(timeStampMoment);
  };

  return (
    <p key={videoDurationTimeStampItem.timeCodeId}>
      <a
        key={videoDurationTimeStampItem.timeCodeId}
        style={{ cursor: 'pointer' }}
        onClick={() => onClickVideoTimeStamp(videoDurationTimeStampItem.durationTime)}
      >
        {getPrettierTimeFormat(videoDurationTimeStampItem?.durationTime as string) || '00:00'}
      </a>
      <span
        dangerouslySetInnerHTML={{
          __html: (videoDurationTimeStampItem.description as string) || '',
        }}
      />
    </p>
  );
};

VideoTimeStampItem.displayName = 'VideoTimeStampItem';

export default VideoTimeStampItem;
