import { fetcher } from 'services';
import { ContentsPostType } from '../types/enum/ContentsPostType';

const useShare = () => {
  return {
    // 회사 옆 맛집 게시판 공유
    shareTopEatsBoard: (data: ShareReqDto) => {
      return fetcher.topEatsBoards.shareTopEatsBoard(data);
    },
    // 칭찬합니다 게시판 공유
    shareMentionsBoard: (data: ShareReqDto) => {
      return fetcher.mentionsBoards.shareMentionsBoard(data);
    },
    // 회사 속 직무 게시판 공유
    shareDepartmentBoard: (data: ShareReqDto) => {
      return fetcher.departmentBoards.shareDepartmentBoard(data);
    },
    // 회사 밖 소모임 게시판 공유
    shareSocietyBoard: (data: ShareReqDto) => {
      return fetcher.societyBoards.shareSocietyBoard(data);
    },
    // 자유 게시판 공유
    shareBoard: (data: ShareReqDto) => {
      return fetcher.boards.shareBoard(data);
    },
    mediaShare: (data: ShareReqDto) => {
      return fetcher.media.putMediaShare(data);
    },
    eventShare: (data: ShareReqDto) => {
      return fetcher.event.postEventShare(data);
    },
    storyShare: (data: ShareReqDto) => {
      return fetcher.story.postStoryShare(data);
    },
    shareCount: (params: { contentsId: string; contentsPostType: string }) => {
      const _params = {
        ...params,
      } as CountAddReqDto;
      if (!params.contentsId) return;
      return fetcher.common.putShareCount(_params).then(res => res);
    },
  };
};

export default useShare;
