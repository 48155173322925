import request from 'services/request';
import { endpoints } from 'services';

export default {
  getSearchResult: (params: { searchWord: string }): Promise<SearchResultListResDto> =>
    request({
      url: endpoints.search.search,
      method: 'GET',
      params,
    }),
  getAutoComplete: (params: { searchWord: string }): Promise<any> =>
    request({
      url: endpoints.search.autoComplete,
      method: 'GET',
      params,
    }),
};
