import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { parse, stringify } from 'query-string';
import paths from 'commons/paths';
import { all_click_datalayer_push, getContentsGAProps, getGAClickProps } from 'utils/GAUtils';
import { ContentCard } from 'components/molecules';
import { ScreenSize } from '../MyPage';

interface Props {
  list?: MediaCardResDto[];
  count?: number;
  currentDt?: string;
  /** 상단 메뉴 노출 */
  showTopTitle?: boolean;
  screenSize?: ScreenSize;
  getTimeLine?: (mediaId?: string) => number;
}

const SearchVideo = ({
  list = [],
  count = 0,
  currentDt = new Date().toString(),
  screenSize = 'lg',
  getTimeLine = () => 0,
  showTopTitle = true,
}: Props) => {
  const history = useHistory();
  const keyword = parse(location.search).keyword as string;

  return (
    <>
      <div className="search_inner">
        <div className="search_top">
          {showTopTitle && (
            <h3 className="search_top_title">
              영상
              <span className="is-num" {...{ id: 'videoSearchNum', datasearchnum: count }}>
                ({count}개)
              </span>
            </h3>
          )}
          {count > 4 ? (
            <a
              {...getGAClickProps('모두보기', '영상')}
              href="#seeAllVideos"
              className="search_top_link"
              onClick={e => {
                e.preventDefault();
                all_click_datalayer_push(e.currentTarget);
                history.push({
                  pathname: paths.getPath('videoList'),
                  search: stringify({ keyword: keyword }),
                });
              }}
            >
              모두보기
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="search_row">
        {screenSize === 'lg' ? (
          <div className="card_row is-small videoSwiper has-hover">
            <div className="card_wrapper">
              {list?.map(media => (
                <ContentCard
                  {...media}
                  currentDt={currentDt}
                  timeline={getTimeLine(media.mediaId)}
                  path={paths.getPath('videoDetail').replace(':mediaId', media.mediaId || '')}
                  isSwiperSlide={false}
                  key={media.mediaId}
                  GAObj={getContentsGAProps({
                    contentsType: media.contentsType as string,
                    title: media.title as string,
                    tags: media.mediaTag as string,
                    category: media.cateNm as string,
                    dataContentBelong: '콘텐츠',
                    dataContentType: '영상',
                    maker: media.creatorNm as string,
                    time: media.videoTotalTime,
                    brightcoveId: media.videoId as string,
                    mediaContentsType: media.mediaContentsType as string,
                    dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', media.mediaId || '')}`,
                    dataImgUrl: `${media?.thumbImageUri as string}`,
                  })}
                />
              ))}
            </div>
          </div>
        ) : (
          <Swiper
            className="card_row is-small videoSwiper"
            slidesPerView={1.4}
            threshold={70}
            breakpoints={{ 768: { slidesPerView: 3.1 } }}
          >
            {list?.map(media => (
              <SwiperSlide className="card" key={media.mediaId}>
                <ContentCard
                  {...media}
                  currentDt={currentDt}
                  timeline={getTimeLine(media.mediaId)}
                  path={`${paths.getPath('videoDetail').replace(':mediaId', media.mediaId as string)}`}
                  isSwiperSlide={true}
                  key={media.mediaId}
                  GAObj={getContentsGAProps({
                    contentsType: media.contentsType as string,
                    title: media.title as string,
                    tags: media.mediaTag as string,
                    category: media.cateNm as string,
                    dataContentBelong: '콘텐츠',
                    dataContentType: '영상',
                    maker: media.creatorNm as string,
                    time: media.videoTotalTime,
                    brightcoveId: media.videoId as string,
                    mediaContentsType: media.mediaContentsType as string,
                    dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', media.mediaId || '')}`,
                    dataImgUrl: `${media?.thumbImageUri as string}`,
                  })}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  );
};

export default SearchVideo;
