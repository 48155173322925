import React from 'react';
import { Link } from 'react-router-dom';
import CardRecord from 'components/atoms/CardRecord';
import { ddayCalc, imageUrl, relativeTime } from 'utils/utils';
import { all_click_datalayer_push, getEventGAProps } from 'utils/GAUtils';
import classNames from 'classnames';
import { getHtmlFormatByEscapeHtmlData } from '../../../utils/LGLifeUtils';
import paths from '../../../commons/paths';

interface EventCardProps extends EventResDto {
  currentDt: string;
  mainCard?: boolean;
  messageEvent?: boolean;
  /** D-Day 숨길지 여부 */
  hideDayOfDay?: boolean;
}

export const EventListItem = (props: EventCardProps) => {
  return (
    <>
      {props.eventStatus === 'ING' && (
        <>
          <td className="list_cell cell_divider cell_tag">
            <span className="card_thumb_flag is-event">진행 중</span>
          </td>
          {props.hideDayOfDay ? (
            <></>
          ) : (
            <td className="list_cell cell_dday">
              <span className="list_dday">{`D-${ddayCalc(props.eventEndDt)}`}</span>
            </td>
          )}
        </>
      )}
      {props.eventStatus === 'END' && (
        <>
          <td className="list_cell cell_divider cell_tag">
            <span className="card_thumb_flag is-done">종료</span>
          </td>
          {props.hideDayOfDay ? (
            <></>
          ) : (
            <td className="list_cell cell_dday">
              <span className="list_dday is-done">D-0</span>
            </td>
          )}
        </>
      )}
      <td className="list_cell cell_title">
        <Link
          to={paths.getPath('eventDetail').replace(':eventId', props.eventId || '')}
          className="list_title_link"
          {...getEventGAProps({
            type: props.eventType,
            url: `${window.location.hostname}${props.linkUri ? props.linkUri : ''}`,
            title: getHtmlFormatByEscapeHtmlData(props?.title || ''),
            dataContentBelong: '이벤트',
            status: props.eventStatus,
          })}
          onClick={e => {
            all_click_datalayer_push(e.currentTarget);
          }}
        >
          <span className="list_title">{getHtmlFormatByEscapeHtmlData(props.title || '')}</span>
        </Link>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-action-20-view.svg" className="list_text_icon" alt="views" />
        <span className="list_text list_view">{props.readCnt}</span>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-comments.png" className="list_text_icon" alt="comments" />
        <span className="list_text list_comments">{props.commentCnt}</span>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-likes.png" className="list_text_icon" alt="likes" />
        <span className="list_text list_likes">{props.likeCnt}</span>
      </td>
      <td className="list_cell">
        <div className="list_date">{relativeTime(props.postDt, props.currentDt)}</div>
      </td>
    </>
  );
};

const EventCard = (props: EventCardProps) => {
  /** 이벤트 종료 dim 처리 여부 */
  const isDimEvent = props.eventStatus === 'END';
  return (
    <>
      {/* <div className="card_item"> */}
      <div className={classNames(['card_thumb', { event_end: isDimEvent }])}>
        <img
          src={imageUrl(props.thumbImageUri) || '/assets/images/illust/illust-img-event.png'}
          alt=""
          className="card_thumb_img"
        />
        {props.eventStatus === 'ING' && <span className="card_thumb_flag is-event">진행 중</span>}
        {props.eventStatus === 'END' && (
          <>
            <div className="card_dim"></div>
            <span className="card_thumb_flag is-done">종료</span>
          </>
        )}
      </div>
      <div className="card_con">
        <div className="card_title has-dday">
          {getHtmlFormatByEscapeHtmlData(props?.title || '')}
          {props.hideDayOfDay ? (
            <>
              {props.eventStatus === 'ING' && <span className="card_title_dday"></span>}
              {props.eventStatus === 'END' && <span className="card_title_dday is-done"></span>}
            </>
          ) : (
            !props.messageEvent && (
              <>
                {props.eventStatus === 'ING' && (
                  <span className="card_title_dday">{`D-${ddayCalc(props.eventEndDt)}`}</span>
                )}
                {props.eventStatus === 'END' && <span className="card_title_dday is-done">D-0</span>}
              </>
            )
          )}
        </div>
        {props.mainCard && <div className="card_desc">&nbsp;</div>}
        <div className="card_sub">
          {!props.messageEvent && (
            <>
              <CardRecord
                views={props.readCnt as number}
                comments={props.commentCnt as number}
                likes={props.likeCnt as number}
              />
              <div className="card_time">{relativeTime(props.postDt, props.currentDt)}</div>
            </>
          )}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default EventCard;
