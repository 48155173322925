import React from 'react';
import { useHistory } from 'react-router-dom';
import { stringify } from 'querystring';
import { cache } from 'swr';
import { useMyInfo } from 'hooks';
import Paths from 'commons/paths';

const useCheckAuthTokens = () => {
  const history = useHistory();
  const { isAppLogined } = useMyInfo();

  const gotoLogin = () => {
    cache.clear();
    localStorage.clear();

    history.replace({
      pathname: Paths.getPath('login'),
      search: stringify({
        redirect: window.location.href,
      }),
    });
  };

  return {
    checkLogin() {
      if (!localStorage.getItem('llAccessToken') || !localStorage.getItem('llRefreshToken')) {
        gotoLogin();
      }
    },
  };
};

export default useCheckAuthTokens;
