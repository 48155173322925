import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { all_click_datalayer_push } from '../../../utils/GAUtils';

export interface BoardOkPopupProps {
  isOpen: boolean;
  contents: string;
  className: string;
  okString: string;
  onRegist: () => void;
  onCancel: () => void;
  GAObj?: any;
}

const BoardOkPopup = ({ isOpen, contents, okString, className, onRegist, onCancel, GAObj }: BoardOkPopupProps) => {
  const registerPopupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      registerPopupRef.current?.classList.add('is-active');
    } else {
      registerPopupRef.current?.classList.remove('is-active');
    }
  }, [isOpen]);

  return (
    <div className={classNames('confirm_popup', className)} ref={registerPopupRef}>
      <div className="popup_dim"></div>
      <div className="popup_inner">
        <p className="confirm_popup_text" dangerouslySetInnerHTML={{ __html: contents }}></p>
        <ul className="confirm_button_list">
          <li className="confirm_button_item">
            <button
              type="button"
              className="confirm_popup_button is-dim popup_button_cancel"
              onClick={() => {
                onCancel();
              }}
            >
              취소
            </button>
          </li>
          <li className="confirm_button_item">
            <a
              href="#"
              {...GAObj}
              className="confirm_popup_button popup_button_confirm"
              onClick={e => {
                e.preventDefault();
                all_click_datalayer_push(e.currentTarget);
                onRegist();
              }}
            >
              {okString}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BoardOkPopup;
