import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { useState } from 'react';
import { sortAsc } from 'utils/LGLifeUtils';
import useMyInfo from './useMyInfo';

const useCommon = () => {
  const { info } = useMyInfo();
  const [groupCode, setGroupCode] = useState<string>('');

  let { data: companies } = useSWR<any>(endpoints.common.groupCompanies, () =>
    fetcher.common.getGroupCompanies({}).then(res => {
      return res;
    })
  );

  let { data: domains } = useSWR<any>(groupCode ? endpoints.common.groupDomains + '-' + groupCode : null, () =>
    fetcher.common.getGroupDomains({ groupCode }).then(res => {
      return res;
    })
  );

  let { data: jobs } = useSWR<JobListResDto>(endpoints.common.job, () =>
    fetcher.common.getJobs().then(res => {
      return res;
    })
  );

  if (companies && companies.groupCompanyList) {
    companies.groupCompanyList = sortAsc(companies.groupCompanyList);
  }

  if (domains && domains.groupDomainList) {
    domains.groupDomainList = sortAsc(domains.groupDomainList);
  }

  if (jobs && jobs.jobList) {
    jobs.jobList = sortAsc(jobs.jobList);
  }

  return {
    companies,
    domains,
    jobs,
    setGroupCode,
  };
};

export default useCommon;
