import useSWR from 'swr';
import { useState } from 'react';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';
import { PAGE_ITEM_COUNT } from 'commons/constants';

const useNews = () => {
  const { info } = useMyInfo();
  const [searchOption, setSearchOption] = useState<any>({
    cateType: 'NEWS',
    size: PAGE_ITEM_COUNT,
    sort: 'postDt,desc',
  });

  const { data } = useSWR<any>(info ? [endpoints.media.media, searchOption] : null, () =>
    fetcher.media.getMedia(searchOption).then(res => {
      return res;
    })
  );

  const { data: newsTagsData } = useSWR<any>(info ? [endpoints.media.basicTags, 'NEWS'] : null, () =>
    fetcher.media.getMediaBasicTags({ tagType: 'NEWS' }).then(res => {
      return res;
    })
  );

  return { data, newsTagsData, searchOption, setSearchOption };
};

export default useNews;
