import useSWR from 'swr';
import { endpoints, fetcher } from 'services';

const useCount = () => {
  return {
    addReadCount: (params: CountAddReqDto) => {
      return fetcher.common.putReadCount(params);
    },
    addShareCount: (params: CountAddReqDto) => {
      fetcher.common.putShareCount(params).then(res => res);
    },
  };
};

export default useCount;
