import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import paths from 'commons/paths';
import { getGAClickProps, all_click_datalayer_push } from 'utils/GAUtils';
import { stringify } from 'query-string';

import 'swiper/components/pagination/pagination.scss';
import EditorsItem from './EditorsItem';

SwiperCore.use([Pagination]);

interface EditorsProps extends MainEditorsPlanListResDto {
  // editorsPlanList: Array<EditorsPlan>;
}

const Editors = (props: EditorsProps) => {
  const { editorsPlanList } = props;
  const history = useHistory();
  const [screenSize, setScreenSize] = useState('lg');

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="content_black">
        <div className="editors">
          <div className="main_inner">
            <div className="con_top is-white">
              <h3 className="con_top-title">Editor’s 기획</h3>
              <a
                {...getGAClickProps('모두보기', 'Editor’s 기획')}
                href="#"
                className="con_top-link"
                onClick={e => {
                  e.preventDefault();
                  history.push({
                    pathname: paths.getPath('videoList'),
                    search: stringify({ keyword: 'Editor’s 기획' }),
                  });
                  all_click_datalayer_push(e.currentTarget);
                }}
              >
                모두보기
              </a>
            </div>
          </div>
          {screenSize === 'sm' ? (
            <>
              <Swiper
                className="editors_box editorsSwiper"
                slidesPerView={1}
                spaceBetween={16}
                threshold={10}
                hashNavigation={{ watchState: true }}
                pagination={{ el: '.editors_pagination' }}
              >
                {editorsPlanList &&
                  editorsPlanList.map((e, idx) => (
                    <SwiperSlide className="editors_slide" key={idx}>
                      <EditorsItem
                        editorsPlan={e.editorsPlan}
                        editorsPlanPlaylist={e.editorsPlanPlaylist}
                        currentDt={props.currentDt as string}
                      />
                    </SwiperSlide>
                  ))}
                <div className="editors_pagination swiper-pagination"></div>
              </Swiper>
            </>
          ) : (
            <div className="editors_box editorsSwiper">
              <ul className="editors_wrapper">
                {editorsPlanList &&
                  editorsPlanList.map((e, idx) => (
                    <li className="editors_slide" key={idx}>
                      <EditorsItem
                        editorsPlan={e.editorsPlan}
                        editorsPlanPlaylist={e.editorsPlanPlaylist}
                        currentDt={props.currentDt as string}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Editors;
