import { fetcher } from 'services';

const usePopupInfo = () => {
  /**
   * 노출 중인 온보딩 팝업 정보
   */
  const getOnboardingPopupInfo = (isBypassOnboardingYn?: boolean) => {
    if (isBypassOnboardingYn) {
      return fetcher.popupInfo.getOnboardingInfo({
        isBypassOnboardingYn: isBypassOnboardingYn,
      });
    }
    return fetcher.popupInfo.getOnboardingInfo();
  };

  /**
   * 온보딩 팝업 열람 완료
   */
  const readOnboardingPopupInfo = () => {
    return fetcher.popupInfo.readOnboarding();
  };

  return {
    getOnboardingPopupInfo,
    readOnboardingPopupInfo,
  };
};

export default usePopupInfo;
