import React, { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import Paths from 'commons/paths';
import Header from 'components/organisms/Header';
import Sider from 'components/organisms/Sider';
import Footer from 'components/organisms/Footer';
import classNames from 'classnames';
import MainVideoPlayer from 'components/organisms/MainVideoPlayer';
import { useCheckAuthTokens, useGAObject, useUserRank, useVideo } from 'hooks';
import { AppError, Error404 } from 'components/pages';
import { ALLOWED_COOKIES_DOMAIN_TYPE_LIST } from '../../commons/constants';
import { isIEBrowser, mobileCheck } from '../../utils/utils';
import WarningPopup from '../organisms/Popup/WarningPopup';
import moment from 'moment';
import useMyInfo from '../../hooks/useMyInfo';
import OnboardingPopup from '../organisms/Popup/OnboardingPoup';
import usePopupInfo from '../../hooks/usePopupInfo';
import { ErrorSystem, ErrorSystemEP } from '../pages';
import paths from 'commons/paths';

export interface DefaultLayoutProps {}

/** 정기 PM 작업 에러 페이지 노출 시작일 */
const SHOW_PM_ERROR_PAGE_DATE = new Date('2024.05.18 22:30:00');
/** 정기 PM 작업 에러 페이지 노출 종료일 */
const HIDE_PM_ERROR_PAGE_DATE = new Date('2024.05.19 06:00:00');
/** 정기 PM 작업 시작일 */
const PM_SERVER_ERROR_CHECKED_DATE_START = new Date('2024.05.18 23:00:00');
/** 정기 PM 작업 종료일 */
const PM_SERVER_ERROR_CHECKED_DATE_END = new Date('2024.05.19 06:00:00');

const DefaultLayout = ({ children }: React.PropsWithChildren<DefaultLayoutProps>): React.ReactElement => {
  const { data } = useVideo();
  const location = useLocation();
  const { checkLogin } = useCheckAuthTokens();
  const { getOnboardingPopupInfo } = usePopupInfo();
  const { info } = useMyInfo();

  /** IE 경고 팝업 열기/닫기 */
  const [isOpenWarningPopup, setIsOpenWarningPopup] = useState(false);
  /** 온보딩 팝업 노출 여부 */
  const [showOnboardingPopup, setShowOnboardingPopup] = useState<boolean>(false);
  /** 온보딩 팝업 상세 정보 */
  const [onboardingPopupData, setOnboardingPopupData] = useState<PopupInfoDetailResDto>();

  const isShowIEWarningPopup = useMemo(() => {
    if (!isIEBrowser()) return false;
    if (
      !localStorage.getItem('hide-warning-popup-expired-date') ||
      !moment(localStorage.getItem('hide-warning-popup-expired-date'), 'YYYY.MM.DD').isValid()
    )
      return true;
    const hideExpiredDate = moment(localStorage.getItem('hide-warning-popup-expired-date'), 'YYYY.MM.DD');
    return moment(new Date(), 'YYYY.MM.DD').isSameOrAfter(hideExpiredDate);
  }, [location.pathname]);

  /** PM 에러 페이지 노출 여부 */
  const hasPMServerCheckPeriod = useMemo(() => {
    const nowDate = moment(new Date());
    const pmStartDt = moment(SHOW_PM_ERROR_PAGE_DATE);
    const pmEndDt = moment(HIDE_PM_ERROR_PAGE_DATE);
    if (nowDate.isBefore(pmStartDt)) return false;
    if (nowDate.isAfter(pmEndDt)) return false;
    return nowDate.isSameOrAfter(pmStartDt) && nowDate.isSameOrBefore(pmEndDt);
  }, [location.pathname]);

  // 임시 시스템 점검 에러 페이지 (정기 PM 작업)
  if (hasPMServerCheckPeriod) {
    if (Paths.getPageName(location.pathname) === 'EP Panel') {
      return (
        <ErrorSystemEP
          startErrorDate={PM_SERVER_ERROR_CHECKED_DATE_START}
          endErrorDate={PM_SERVER_ERROR_CHECKED_DATE_END}
        />
      );
    }
    // 정기 PM 작업 EP 패널만 점검 페이지로 이동
    return (
      <ErrorSystem
        startErrorDate={PM_SERVER_ERROR_CHECKED_DATE_START}
        endErrorDate={PM_SERVER_ERROR_CHECKED_DATE_END}
      />
    );
  }

  //
  useGAObject();
  useUserRank();

  // mobilelife.lg.com 으로 접속 시  모바일 App이 아닌 경우 오류 페이지로 이동
  if (new URL(window.location.href).hostname === 'mobilelife.lg.com') {
    const lifeAuth = Cookies.get('lifeAuth');

    if (ALLOWED_COOKIES_DOMAIN_TYPE_LIST.every(item => item.code !== lifeAuth) && lifeAuth !== 'EMS') {
      return <AppError />;
    }
  }

  const noLayout =
    !!matchPath(location.pathname, paths.getPath('user')) ||
    !!matchPath(location.pathname, paths.getPath('ep')) ||
    !!matchPath(location.pathname, paths.getPath('mobileCheck')) ||
    !!matchPath(location.pathname, paths.getPath('autoLoginCheck'));

  const contentsRef = useRef<HTMLDivElement>(null);

  /**
   * 상세 페이지 여부
   */
  const isDetailPage = useMemo(() => {
    return (
      location.pathname.includes('/detail') ||
      location.pathname.includes('/anniversary75') ||
      location.pathname.includes('/event/campaign_1')
    );
  }, [location.pathname]);

  /**
   * 커뮤니티 게시판 작성 페이지 여부
   */
  const isCommunityWritePage = useMemo(() => {
    return (
      location.pathname === Paths.getPath('boardWrite') ||
      location.pathname === Paths.getPath('topEatsBoardWrite') ||
      location.pathname === Paths.getPath('mentionsBoardWrite') ||
      location.pathname === Paths.getPath('departmentWrite')
    );
  }, [location.pathname]);

  /**
   * 영상 메뉴 상세페이지 여부
   */
  const isVideoDetailPage = useMemo(() => {
    return location.pathname.includes(Paths.getPath('videoDetail').replace('/:mediaId', ''));
  }, [location.pathname]);

  if (Paths.getPageName(location.pathname) === '404') {
    return <Error404 />;
  }

  if (!noLayout) {
    checkLogin();
  }

  const handleResize = () => {
    if (window.innerWidth < 1024) document.querySelector('body')?.classList.add('mobile');
    else document.querySelector('body')?.classList.remove('mobile');
  };

  useEffect(() => {
    setIsOpenWarningPopup(isShowIEWarningPopup);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (mobileCheck()) {
      document.querySelector('body')?.classList.add('real-mobile');
      return;
    }

    document.querySelector('body')?.classList.remove('real-mobile');
  }, [location.pathname]);

  useEffect(() => {
    if (info) {
      getOnboardingPopupInfo().then(res => {
        if (res.resultCode !== 200) {
          console.error(res.resultMessage);
          return;
        }
        setOnboardingPopupData(res);
        setShowOnboardingPopup(true);
      });
    }
  }, [info]);

  const isSearchPage = useMemo(() => /^\/search/gi.test(location.pathname), [location.pathname]);

  const Content = useMemo(() => <>{children}</>, []);

  return (
    <>
      <div className={classNames('wrapper', { search: isSearchPage })}>
        {noLayout ? (
          <div>{Content}</div>
        ) : (
          <>
            <Header />
            <div
              className={classNames('container', {
                detail: isDetailPage || isCommunityWritePage,
                'pip-mode': data.pip,
                article:
                  (isDetailPage && !isVideoDetailPage && (!data.visible || (data.pip && data.visible))) ||
                  isCommunityWritePage,
                list:
                  location.pathname.includes('community') &&
                  !isCommunityWritePage &&
                  location.pathname !== Paths.getPath('eventSimpleSurveyList'),
              })}
            >
              {/* <div className={classNames('container', { detail: isDetailPage, 'pip-mode': data.pip })}> */}
              <Sider />
              <div className="content" id="content" ref={contentsRef}>
                {Content}
              </div>
              <MainVideoPlayer />
            </div>
            <Footer></Footer>
          </>
        )}
      </div>
      <WarningPopup
        isOpen={isOpenWarningPopup}
        popUpContents={
          '' +
          `※크롬/엣지 브라우저를 사용해주세요※<br>
          지금 사용하고 계신 브라우저는 LG LIFE<br>
          이용 시 불편이 있을 수 있습니다.<br>
          원활한 서비스 이용을 위해<br>
          크롬, 엣지 브라우저를 사용해주세요 :)<br>` +
          ''
        }
        registerHidePopUpOptions={{
          contents: '일주일간 보지 않기',
          term: 7,
        }}
        onClose={() => setIsOpenWarningPopup(false)}
      />
      <OnboardingPopup
        isOpen={showOnboardingPopup}
        linkUrl={onboardingPopupData?.linkUri as string}
        popupImageUri={onboardingPopupData?.imageUri as string}
        onClose={() => setShowOnboardingPopup(false)}
      />
    </>
  );
};

export default DefaultLayout;
