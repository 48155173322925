import { useEffect, useMemo, useState } from 'react';
import { endpoints, fetcher } from 'services';
import useSWR from 'swr';
import useMyInfo from './useMyInfo';
import { isDetailsFor } from 'utils/utils';
import Paths from '../commons/paths';

const useEventDetail = (eventId: string) => {
  const { info } = useMyInfo();

  const [detailDesc, setDetailDesc] = useState<string>('');
  const [videoElements, setVideoElements] = useState<Element[]>();

  const isEventDetailPage = useMemo(
    () => isDetailsFor(Paths.getPath('eventList').replace('/list', '')),
    [location.pathname]
  );

  const { data: eventDetailData, mutate } = useSWR<any>(
    info && isEventDetailPage && eventId ? [`${endpoints.event.event}/${eventId}`, eventId] : null,
    url =>
      fetcher.event.getEventDetail(url).then(res => {
        return res;
      })
  );

  useEffect(() => {
    if (eventDetailData) {
      const container = document.createElement('div');
      container.innerHTML = eventDetailData?.contents as string;

      const videoEls = Array.from(container.querySelectorAll('.video-js'));

      videoEls.forEach((el, index) => {
        const parentEl = el.parentNode;
        const videoContainer = document.createElement('div');
        videoContainer.id = `video-container-${index}`;

        if (parentEl) {
          parentEl.replaceChild(videoContainer, el as Node);
        }
      });

      setVideoElements(videoEls);
      setDetailDesc(container.innerHTML ? container.innerHTML : ' ');
    }
  }, [eventDetailData]);

  return {
    eventDetailData,
    detailDesc,
    videoElements,
    likeEvent() {
      fetcher.event.postEventLike({ eventId }).then(res => {
        if (res.resultCode === 200) mutate();
      });
    },
    mutate,
  };
};

export default useEventDetail;
