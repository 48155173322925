import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { imageUrl, mobileCheck, relativeTime, videoTimeFormat } from 'utils/utils';
import { all_click_datalayer_push } from 'utils/GAUtils';
import { isDNOPortalMobileApp } from '../../../utils/lgUtilsByJs';
import { getCommentContentsByReplacedMentionTagToGoalBangYee } from '../../../utils/mentionUtils';
import { MobilePopUpOpen, removeHtmlTag } from '../../../utils/LGLifeUtils';

export interface ContentCardProps extends MediaCardResDto {
  showSummary?: boolean;
  isAd?: boolean;
  isNews?: boolean;
  currentDt: string;
  timeline?: number;
  path?: string;
  target?: string;
  isSwiperSlide?: boolean;
  GAObj?: any;
}

const ContentCard = (props: ContentCardProps) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const cardRef = useRef<HTMLAnchorElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const time = useMemo(() => relativeTime(props.postDt, props.currentDt), [props.postDt, props.currentDt]);

  const defaultImage = props.isNews
    ? '/assets/images/illust/illust-img-news.png'
    : '/assets/images/illust/illust-img-media.png';

  return (
    <>
      <Link
        target={props?.target ? props.target : '_self'}
        to={props.path || '/'}
        className={classNames({ card: !props.isSwiperSlide })}
        ref={cardRef}
        {...props.GAObj}
        onClick={e => {
          all_click_datalayer_push(e.currentTarget);
          if (mobileCheck() && !isDNOPortalMobileApp() && props.path) {
            MobilePopUpOpen(props.path);
          }
        }}
        onMouseEnter={() => {
          if (props.mediaContentsType === 'MAGAZINE' || !props.previewVideoUri) return;

          cardRef.current?.classList.add('is-play');
          if (videoRef.current && !videoRef.current.src) {
            videoRef.current.src = imageUrl(props.previewVideoUri);
            videoRef.current.load();
          }
          setTimeout(() => {
            if (videoRef.current) {
              if (!isNaN(videoRef.current.duration)) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
              }
            }
          }, 500);
          setTimer(
            setTimeout(() => {
              cardRef.current?.classList.remove('is-play');
              cardRef.current?.classList.add('is-play-over');
            }, 6500)
          );
        }}
        onMouseLeave={() => {
          if (props.mediaContentsType === 'MAGAZINE' || !props.previewVideoUri) return;

          cardRef.current?.classList.remove('is-play');
          cardRef.current?.classList.remove('is-play-over');
          if (videoRef.current) {
            if (!isNaN(videoRef.current.duration)) {
              videoRef.current.currentTime = 0;
              videoRef.current.pause();
            }
          }
          clearTimeout(timer || setTimeout(() => {}));
          setTimer(undefined);
        }}
      >
        <div className="card_item">
          <div className="card_thumb">
            <img src={imageUrl(props.thumbImageUri) || defaultImage} alt="" className="card_thumb_img" />
            {/* preview */}
            {props.previewVideoUri && <video className="card_thumb_video" ref={videoRef} muted></video>}
            {/* badge */}
            {props.badgeNm && (
              <span
                className="card_thumb_flag is-special"
                style={{ backgroundColor: `#${props.badgeColor}` || '#be004e' }}
              >
                {props.badgeNm}
              </span>
            )}
            {props.mediaContentsType === 'VIDEO' && (
              <>
                {props.playListYn === 'Y' && <span className="card_thumb_group">+{props.playListCnt}</span>}
                <span className="card_thumb_play">
                  <img src="/assets/images/icon/ico-action-32-non-playing.png" alt="play" />
                </span>
                {props.playListYn === 'N' && (
                  <span className="card_thumb_time">{videoTimeFormat(props.videoTotalTime)}</span>
                )}
                {props.timeline && props.timeline >= 0 && (
                  <span
                    className="card_thumb_progress"
                    style={{ width: `${(props.timeline * 100) / (props.videoTotalTime || props.timeline)}%` }}
                  ></span>
                )}
              </>
            )}
          </div>
          <div
            className={classNames('card_con', {
              'no-desc': !props.summary?.trim() || props.summary?.trim().length === 0,
            })}
          >
            <div className={classNames(['card_title', { 'is-two': !props.showSummary }])}>{props.title}&nbsp;</div>
            {props.summary && props.summary.trim().length > 0 && !props.isAd && props.showSummary ? (
              <div className="card_desc">{props.summary.trim()}</div>
            ) : (
              ''
            )}
            <div className="card_sub">
              {props.contentsType !== 'GROUP' && props.playListYn !== 'Y' && (
                <>
                  <ul className="card_record">
                    <li>
                      <img src="/assets/images/icon/ico-views.png" alt="views" />
                      {props.readCnt}
                    </li>
                    <li>
                      <img src="/assets/images/icon/ico-comments.png" alt="comments" />
                      {props.commentCnt}
                    </li>
                    <li>
                      <img src="/assets/images/icon/ico-likes.png" alt="likes" />
                      {props.likeCnt}
                    </li>
                  </ul>
                </>
              )}
              <div className="card_time">{time}</div>
            </div>
            <div className="card_comment">
              <div className="card_comment_wrapper">
                {props.bestCommentCreatorNm ? (
                  <>
                    <div className="card_comment_name">
                      {props.bestCommentCreatorNm}
                      <span className="card_comment_company">{props.bestCommentCreatorGroup}</span>
                    </div>
                    <div className="card_comment_con">
                      <div className="card_comment_desc">
                        {removeHtmlTag(
                          getCommentContentsByReplacedMentionTagToGoalBangYee(String(props.bestCommentContents || ''))
                        )}
                      </div>
                      <div className="card_comment_likes">{props.bestCommentLikeCnt}</div>
                    </div>
                  </>
                ) : (
                  <div className="card_comment_empty">{'"첫 댓글의 주인공이 되어 보세요"'}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default React.memo(ContentCard, (prev, next) => {
  const copyPrevJsonObj = JSON.parse(JSON.stringify(prev));
  const copyNextJsonObj = JSON.parse(JSON.stringify(next));

  delete copyPrevJsonObj.currentDt;
  delete copyNextJsonObj.currentDt;

  const newPrevJson = JSON.stringify(copyPrevJsonObj);
  const newNextJson = JSON.stringify(copyNextJsonObj);

  // console.log("newPrevJson", newPrevJson);
  // console.log("newNextJson", newNextJson);

  return newPrevJson === newNextJson;
});
