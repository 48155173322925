import React, { useEffect, useRef, useState } from 'react';
import useMember from 'hooks/useMember';
import ShareCancelPopup from './ShareCancelPopup';
import classNames from 'classnames';
import { useLogging, useShare } from 'hooks';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import { useMyInfo } from '../../../hooks';
import { BoardType } from '../../../types/enum/BoardType';
import { ContentsPostType } from '../../../types/enum/ContentsPostType';

interface SharePopupProps extends CountAddReqDto {
  onClose: () => void;
  title: string;
  contentsId: string;
}

const SharePopup = (props: SharePopupProps) => {
  const { companiesData, memberData, searchOptions, setSearchOptions } = useMember();
  const {
    shareBoard,
    shareDepartmentBoard,
    shareMentionsBoard,
    shareSocietyBoard,
    shareTopEatsBoard,
    mediaShare,
    eventShare,
    storyShare,
    shareCount,
  } = useShare();
  const { shareLogging } = useLogging();
  const { info } = useMyInfo();

  const [memberList, setMemberList] = useState([] as CicResDto[]);
  const [companies, setCompanies] = useState<GroupCompanyListResDto>();
  const [company, setCompany] = useState<GroupCompanyResDto>();
  const [selectedMem, setSelectedMem] = useState([] as CicResDto[]);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [shareBtn, setShareBtn] = useState(false);

  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');

  const selectboxRef = useRef<HTMLDivElement>(null);
  const selectlistRef = useRef<HTMLUListElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    document.querySelector('body')?.classList.add('fix-overflow');
    document.querySelector('.nav')?.setAttribute('style', 'display: none');
    return () => {
      document.querySelector('body')?.classList.remove('fix-overflow');
      document.querySelector('.nav')?.setAttribute('style', '');
    };
  }, []);

  useEffect(() => {
    if (memberData) setMemberList(memberData.cicList || []);
  }, [memberData]);

  useEffect(() => {
    if (companiesData) setCompanies(companiesData);
  }, [companiesData]);

  useEffect(() => {
    if (company?.groupNm) setSearchOptions({ ...searchOptions, groupCode: company.groupCode as string });
    else setSearchOptions({ ...searchOptions, groupCode: '' });
  }, [company]);

  useEffect(() => {
    if (selectedMem.length > 0) setShareBtn(true);
    else setShareBtn(false);
  }, [selectedMem]);

  const shareContents = () => {
    if (!shareBtn) {
      setAlertPopContents('수신자를 선택해주세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    const contentsPostType = props.contentsPostType || 'STORY';

    const shareData = {
      title: titleRef.current?.value || props.title,
      targetEmailList: selectedMem.map(s => s.email) as [],
      contentsId: props.contentsId,
      contents: textareaRef.current?.value,
    } as ShareReqDto;

    switch (contentsPostType) {
      /** 회사 밖 소모임 게시판 */
      case 'SOCIETY_BOARD':
        shareSocietyBoard(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.SOCIETY_BOARD,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
      /** 회사 속 직무 게시판 */
      case 'DEPARTMENT_BOARD':
        shareDepartmentBoard(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.DEPARTMENT_BOARD,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
      /** 칭찬합니다 게시판 */
      case 'MENTIONS':
        shareMentionsBoard(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.MENTIONS,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
      /** 회사 옆 맛집 게시판 */
      case 'TOP_EATS':
        shareTopEatsBoard(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.TOP_EATS,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
      /** 자유 게시판 */
      case 'BOARD':
        shareBoard(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.BOARD,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
      case 'MEDIA':
        mediaShare(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.MEDIA,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
      case 'EVENT':
        eventShare(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.EVENT,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
      case 'STORY':
        storyShare(shareData).then(res => {
          if (res.resultCode === 200) {
            setConfirmPopup(true);
            shareCount({
              contentsId: props.contentsId,
              contentsPostType: ContentsPostType.STORY,
            });
            shareLogging();
          } else console.log('fail');
        });
        break;
    }
  };

  const onKeyPressInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const onSearch = () => {
    if (searchInputRef.current?.value.trim().length === 0) {
      setAlertPopContents('검색어를 입력하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    setSearchOptions({ ...searchOptions, searchWord: searchInputRef.current?.value || '' });
  };

  return (
    <>
      <div className="popup share_popup is-active">
        <div className="popup_dim"></div>
        <div className="popup_inner popup_large popup_mobile_full">
          <div className="popup_header">
            <strong className="popup_title">콘텐츠 공유하기</strong>
          </div>
          <div className="popup_cont">
            <div className="search_input_title">수신자 검색</div>
            <div className="search_group">
              <div className="selectbox selectbox_small" ref={selectboxRef}>
                <a
                  className="selectbox_label"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (selectboxRef.current?.classList.contains('is-active')) {
                      selectlistRef.current?.style.setProperty('display', 'none');
                      selectboxRef.current?.classList.remove('is-active');
                    } else {
                      selectlistRef.current?.style.setProperty('display', 'block');
                      selectboxRef.current?.classList.add('is-active');
                    }
                  }}
                >
                  {company?.groupNm ? company.groupNm : '전체 계열사'}
                </a>
                <ul className="select_list" ref={selectlistRef}>
                  <li
                    className={classNames('select_item', { 'is-selected': !company?.groupNm })}
                    data-value="전체 계열사"
                  >
                    <a
                      className="select_opt"
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setCompany({});
                        selectlistRef.current?.style.setProperty('display', 'none');
                        selectboxRef.current?.classList.remove('is-active');
                      }}
                    >
                      전체 계열사
                    </a>
                  </li>
                  {companies?.groupCompanyList &&
                    companies.groupCompanyList
                      .sort((a, b) => {
                        if (a.orderNum && b.orderNum) return a.orderNum - b.orderNum;
                        else return 0;
                      })
                      .map((c, idx) => (
                        <li
                          className={classNames('select_item', { 'is-selected': c.groupNm === company?.groupNm })}
                          data-value={c.groupNm}
                          key={idx}
                        >
                          <a
                            className="select_opt"
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              setCompany(c);
                              selectlistRef.current?.style.setProperty('display', 'none');
                              selectboxRef.current?.classList.remove('is-active');
                            }}
                          >
                            {c.groupNm}
                          </a>
                        </li>
                      ))}
                </ul>
                {/* <!-- selectbox : mobile --> */}
                <select
                  className="select_mobile is-mobile"
                  onChange={e => {
                    if (e.target.value === 'ALL') setCompany({});
                    else setCompany(JSON.parse(e.target.value));
                  }}
                >
                  <option value="ALL" className="select_opt">
                    전체 계열사
                  </option>
                  {companies?.groupCompanyList &&
                    companies.groupCompanyList
                      .sort((a, b) => {
                        if (a.orderNum && b.orderNum) return a.orderNum - b.orderNum;
                        else return 0;
                      })
                      .map((c, idx) => (
                        <option value={JSON.stringify(c)} className="select_opt" key={idx}>
                          {c.groupNm}
                        </option>
                      ))}
                </select>
              </div>
              <div className="popup_search_box">
                <input
                  type="text"
                  className="search_input"
                  placeholder="이름, 전화번호 또는 이메일 주소를 입력해주세요."
                  ref={searchInputRef}
                  onKeyPress={onKeyPressInput}
                />
                <button
                  type="button"
                  className="popup_search_button ico_search_white"
                  onClick={() => {
                    onSearch();
                  }}
                ></button>
              </div>
            </div>
            {/* <!-- 수신자 검색 전에는 no-result 클래스추가 --> */}
            <div className="popup_search_result">
              <div className="popup_result_title">
                <span>
                  <span className="result_highlight">{memberList.length}</span>건이 검색되었습니다.
                </span>
              </div>

              {/* <!-- no search result: 검색 전 --> */}
              {memberList.length === 0 ? (
                <div className="popup_no_result">
                  <span>수신자를 검색해주세요.</span>
                </div>
              ) : (
                <ul className="popup_result_list custom_scroll">
                  {memberList.map((mem, idx) => (
                    <li className="popup_result_item" key={idx}>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="is-blind"
                          id={`check-${mem.empNum}`}
                          checked={
                            selectedMem.filter(s => {
                              if (s.empNum && mem.empNum) return s.empNum.indexOf(mem.empNum) > -1;
                            }).length > 0
                              ? true
                              : false
                          }
                          onChange={e => {
                            if (e.target.checked) setSelectedMem(selectedMem.concat(mem));
                            else setSelectedMem(selectedMem.filter(s => s.empNum !== mem.empNum));
                          }}
                        />
                        <label className="checkbox_label" htmlFor={`check-${mem.empNum}`}></label>
                      </div>
                      <div className="membs_search_result">
                        <div className="membs_info">
                          <strong className="membs_info_name">
                            <span className="membs_name">{mem.name}</span>
                            <span className="membs_pos">{}</span>
                          </strong>
                          <span className="membs_info_team">
                            <span className="membs_aff" dangerouslySetInnerHTML={{ __html: mem.groupNm || '' }}></span>
                            {/* <span className="membs_team">개발팀</span> */}
                          </span>
                        </div>
                        <div className="membs_contact">
                          <div className="membs_contact_info">
                            <span className="contact_info membs_tel">
                              {mem.telNum ? mem.telNum.replace(/null-/g, '').replace(/null/g, '') : ''}
                            </span>
                            <span className="contact_info membs_mob">
                              {mem.phoneNum ? mem.phoneNum.replace(/null-/g, '').replace(/null/g, '') : ''}
                            </span>
                          </div>
                          <div className="membs_contact_info">
                            <span className="contact_info membs_mail">
                              <a className="membs_mail_link">{mem.email}</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {/* <!-- /no search result --> */}

              {/* <!-- search result: 검색버튼 클릭 후 --> */}
            </div>

            <div className="popup_subtitle">
              선택된 수신자 목록
              <span className="popup_subtitle_count">
                전체[
                <span className="num">{selectedMem.length}/50</span>]
              </span>
            </div>

            <div className="popup_search_result is-receiver">
              {/* <!-- no result: 수신자 목록 선택 전 --> */}
              {selectedMem.length === 0 ? (
                <div className="popup_no_result">
                  <span>수신자 목록이 비어있습니다.</span>
                </div>
              ) : (
                <ul className="popup_result_list custom_scroll">
                  {selectedMem.map((s, idx) => (
                    <li className="popup_result_item" key={idx}>
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="is-blind"
                          id={`select-${idx}`}
                          checked={true}
                          onChange={e => {
                            if (e.target.checked) {
                            } else {
                              setSelectedMem(selectedMem.filter(sm => sm.empNum !== s.empNum));
                            }
                          }}
                        />
                        <label className="checkbox_label" htmlFor={`select-${idx}`}></label>
                      </div>
                      <div className="membs_search_result">
                        <div className="membs_info">
                          <strong className="membs_info_name">
                            <span className="membs_name">{s.name}</span>
                            {/* <span className="membs_pos">사원</span> */}
                          </strong>
                          <span className="membs_info_team">
                            <span className="membs_aff" dangerouslySetInnerHTML={{ __html: s.groupNm || '' }}></span>
                            {/* <span className="membs_team">개발팀</span> */}
                          </span>
                        </div>
                        <div className="membs_contact">
                          <div className="membs_contact_info">
                            <span className="contact_info membs_mail">
                              <a className="membs_mail_link">{s.email}</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {/* <!-- /no result --> */}

              {/* <!-- search result: 수신자 목록 선택 후 --> */}
            </div>

            <div className="popup_subtitle">제목</div>
            <div className="share_title">
              <input type="text" defaultValue={props.title} ref={titleRef} id="shareSubTitle" />
              <label htmlFor="shareSubTitle" className="ab_text">
                제목
              </label>
            </div>

            <div className="popup_subtitle">내용</div>
            <div className="share_title">
              <textarea title="내용" style={{ resize: 'none' }} ref={textareaRef}></textarea>
            </div>

            <div className="search_btn">
              <button type="button" className="is-pc popup_share_cancel" onClick={() => setCancelPopup(true)}>
                취소
              </button>
              {/* <!-- 입력 완료시 is-active 클래스 추가 --> */}
              <button
                type="button"
                className={classNames('is-share', { 'is-active': true })}
                id="shareThis"
                // disabled={!shareBtn}
                onClick={shareContents}
              >
                공유하기
              </button>
            </div>
          </div>
          <button type="button" className="popup_share_close ico_close" onClick={() => setCancelPopup(true)}></button>
        </div>
      </div>
      {cancelPopup && (
        <ShareCancelPopup closeSharePopup={props.onClose} closeCancelPopup={() => setCancelPopup(false)} />
      )}
      {confirmPopup && (
        <div className="confirm_popup share_complete_popup is-active">
          <div className="popup_dim"></div>
          <div className="popup_inner">
            <p className="confirm_popup_text">
              공유하기가 완료되었습니다.
              <br />
              선택하신 임직원 메일로
              <br />
              해당 정보가 전달됩니다.
            </p>
            <ul className="confirm_button_list is-full">
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_confirm share_complete_confirm"
                  onClick={() => {
                    setConfirmPopup(false);
                    props.onClose();
                  }}
                >
                  확인
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
      {/* <AlertPopup isOpen={validationPopup} onClose={() => setValidationPopup(false)}>
        {validationPopupContents}
      </AlertPopup> */}
    </>
  );
};

export default SharePopup;
