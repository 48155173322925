import request from 'services/request';
import { endpoints } from 'services/endpoints';

export interface VerifyRequest {
  refreshToken: string;
}

export default {
  getVerify: (params: VerifyRequest): Promise<any> =>
    request(
      {
        url: endpoints.mobile.verify,
        method: 'get',
        params,
      },
      {
        verifyRefresh: params.refreshToken,
      }
    ),
};
