/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import 'formdata-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import MyInfoProvider from 'providers/MyInfoProvider';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import VideoProvider from 'providers/VideoProvider';
import RegisterProvider from 'providers/RegisterProvider';
import GradeProvider from 'providers/GradeProvider';
import LoggingProvider from 'providers/LoggingProvider';

SwiperCore.use([Navigation, Autoplay]);

ReactDOM.render(
  <CookiesProvider>
    <MyInfoProvider>
      <VideoProvider>
        <RegisterProvider>
          <GradeProvider>
            <LoggingProvider>
              <App />
            </LoggingProvider>
          </GradeProvider>
        </RegisterProvider>
      </VideoProvider>
    </MyInfoProvider>
  </CookiesProvider>,
  document.getElementById('root')
);
