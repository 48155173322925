import { createContext } from 'react';

export const initialState = {
  lastLoggingPage: '',
  setLastLoggingPage: () => {},
} as {
  lastLoggingPage: string;
  setLastLoggingPage: React.Dispatch<React.SetStateAction<string>>;
};

const LoggingContext = createContext(initialState);

export default LoggingContext;
