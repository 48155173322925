import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const useSaveEmail = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [savedGroupCode, setSavedGroupCode] = useState<string>();
  const [savedEmail, setSavedEmail] = useState<string>();
  const [savedDomain, setSavedDomain] = useState<string>();

  useEffect(() => {
    if (cookies.savedGroupCode && cookies.savedEmail && cookies.savedDomain) {
      setSavedGroupCode(cookies.savedGroupCode);
      setSavedEmail(cookies.savedEmail);
      setSavedDomain(cookies.savedDomain);
    }
  }, [cookies.savedGroupCode, cookies.savedEmail, cookies.savedDomain]);

  return {
    savedGroupCode,
    savedEmail,
    savedDomain,
    saveEmail(groupCode: string, email: string, domain: string) {
      setCookie('savedGroupCode', groupCode, { path: '/' });
      setCookie('savedEmail', email, { path: '/' });
      setCookie('savedDomain', domain, { path: '/' });
    },
    removeEmail() {
      removeCookie('savedGroupCode', { path: '/' });
      removeCookie('savedEmail', { path: '/' });
      removeCookie('savedDomain', { path: '/' });
    },
  };
};

export default useSaveEmail;
