import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { useContinue, useVideoList } from 'hooks';
import { getContentsGAProps } from 'utils/GAUtils';
import { clearListCache } from '../../../utils/LGLifeUtils';
import ContentCard from 'components/molecules/ContentCard';
import ControlTag, { Tag } from 'components/atoms/ControlTag';
import ControlDropdown from 'components/atoms/ControlDropdown';
import SectionTitle from 'components/atoms/SectionTitle';
import ControlPopup from '../../organisms/Popup/ControlPopup';
import { AutoSizer, Grid, InfiniteLoader, WindowScroller } from 'react-virtualized';
import throttle from 'lodash/throttle';
import { VERTUAL_SCROLL_THRESHOLD, WINDOW_RESIZE_THROTTLE } from 'commons/constants';
import useSeriesClassification from '../../../hooks/useSeriesClassification';
import ControlImageTag, { SeriesTag } from 'components/atoms/ControlImageTag';
import Paths from '../../../commons/paths';

const Video = () => {
  const history = useHistory();
  const keyword = parse(location.search).keyword as string;

  const { videosData, searchOption, setSearchOption, videoTagsData } = useVideoList();
  const { seriesClassificationData, revalidate } = useSeriesClassification();
  const { getTimeLine } = useContinue();

  const [videoList, setVideoList] = useState([] as MediaCardResDto[]);
  const [currentDt, setCurrentDt] = useState('');
  const [controlPopup, setControlPopup] = useState(false);
  const [tags, setTags] = useState([] as Tag[]);
  const [sort, setSort] = useState('postDt,desc');
  const [popularitySortType, setPopularitySortType] = useState('');
  const [activeSeriesTagIndex, setActiveSeriesTagIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopState, setIsPopState] = useState(false);
  const totalPage = useRef<number>(0);

  const [columnCount, setColumnCount] = useState(4);
  const [rowHeight, setRowHeight] = useState<number>(0);

  const controlRef = useRef<HTMLDivElement>(null);

  const isMobile = useMemo(() => window.innerWidth < 1024, [window.innerWidth]);

  /**
   * 시리즈 분류 목록
   */
  const seriesClassificationCategoryList = useMemo(() => {
    if (!seriesClassificationData || !seriesClassificationData.seriesClassificationList?.length)
      return [] as SeriesTag[];
    return seriesClassificationData.seriesClassificationList as SeriesTag[];
  }, [seriesClassificationData]);

  const scrollSave = () => {
    const scrollPos = window.pageYOffset;
    localStorage.setItem('videoList', JSON.stringify(videoList));
    localStorage.setItem('scrollPos', scrollPos.toString());
    localStorage.setItem('currentPage', currentPage.toString());
  };

  useEffect(() => {
    revalidate().then(() => {
      //console.log('영상 뉴스 시리즈 갱신!')
    });
  }, [history.location]);

  useEffect(() => {
    if (history.action === 'POP' && videoList.length === 0 && localStorage.getItem('videoList')) {
      setVideoList(JSON.parse(localStorage.getItem('videoList') as string));
      setCurrentPage(Number(localStorage.getItem('currentPage')));
      setIsPopState(true);

      setTimeout(() => {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }, 100);
    } else if (history.action === 'PUSH') {
      clearListCache();
    }
  });

  useEffect(() => {
    const listen = history.listen(scrollSave);
    return () => {
      listen();
    };
  });

  useEffect(() => {
    if (controlPopup) document.querySelector('body')?.classList.add('fix-overflow');
    else document.querySelector('body')?.classList.remove('fix-overflow');
  }, [controlPopup]);

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));

    if (listData && history.action === 'POP' && listData.list === 'video') {
      setSort(listData.sort);
      setTags(listData.filter);
      setPopularitySortType(listData.popularitySortType);
      setActiveSeriesTagIndex(listData.activeSeriesTagIndex);
    } else {
      window.sessionStorage.clear();
    }

    updateThrottleHandler();

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateThrottleHandler);
    window.onbeforeunload = () => {
      clearListCache();
    };

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateThrottleHandler);
    };
  }, []);

  const updateSize = () => {
    const windowSize = { width: window.innerWidth, height: window.innerHeight };

    if (windowSize) {
      if (windowSize.width < 1024) {
        setColumnCount(1);
      } else {
        setColumnCount(4);
      }
    }
  };

  const updateThrottleHandler = useCallback(throttle(updateSize, WINDOW_RESIZE_THROTTLE), []);

  useEffect(() => {
    if (videosData) {
      totalPage.current = videosData.totalPage;

      if (isPopState && videosData.page === currentPage) {
        setIsPopState(false);
        return;
      }

      setCurrentDt(videosData.currentDt);
      if (currentPage === 1 && videosData.page === 1) {
        setVideoList(videosData.mediaCardList);
      } else if (videosData.totalAmount > videoList?.length) {
        setVideoList(videoList.concat(videosData.mediaCardList));
      } else {
        //console.log('sfsdfsdfsdfdfs');
      }
    }
  }, [videosData]);

  useEffect(() => {
    setVideoList([]);
    setCurrentPage(1);
  }, [keyword]);

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || '{}');

    const createTags = (tagsData: MediaBasicTagResDto[]) => {
      let newTags = tagsData.map(tag => ({
        orderNum: tag.orderNum === 1 ? 1 : Number(tag.orderNum || 1) + 1,
        title: tag.tagNm,
        isActive: tag.tagNm === 'LGCC' && !keyword,
      })) as Tag[];

      if (keyword) {
        const targetIndex = newTags.findIndex(tag => tag.title === keyword);
        if (targetIndex >= 0) {
          newTags[targetIndex].isActive = true;
          newTags[targetIndex].isSearchKeyword = true;
        } else {
          newTags.push({
            // 검색어 키워드는 제일 마지막에 위치해야 하므로, 순번을 9999 로 대체
            orderNum: 9999,
            title: keyword,
            isActive: true,
            isSearchKeyword: true,
            deletable: true,
          } as Tag);
        }
      }

      newTags = [{ title: '신년사', isActive: !keyword, orderNum: 2, isNYTag: true }, ...newTags];

      return newTags.sort((a, b) => a.orderNum - b.orderNum);
    };

    const newTags = createTags(videoTagsData?.mediaBasicTagList || []);

    if (videoTagsData?.mediaBasicTagList && (listData.list !== 'video' || listData.filter.length === 0)) {
      setTags(newTags);
      return;
    }

    if (videoTagsData?.mediaBasicTagList && ['PUSH', 'REPLACE'].includes(history.action)) {
      setTags(newTags);
      return;
    }
  }, [videoTagsData, keyword]);

  useEffect(() => {
    // console.log('useEffect currentPage', currentPage);
    setSearchOption({
      ...searchOption,
      tagList: tags
        .filter(t => t.isActive)
        .map(t => {
          return t.title === '신년사' && t.isNYTag ? 'NEWYEARLG' : t.title;
        })
        .join(','),
      sort,
      popularitySortType,
      page: currentPage,
      searchWord: keyword && tags.find(tag => tag.title === keyword)?.isActive ? keyword : '',
    });
    window.sessionStorage.setItem(
      'listData',
      JSON.stringify({
        list: 'video',
        sort: sort,
        filter: tags,
        currentPage: currentPage,
        popularitySortType: popularitySortType,
        activeSeriesTagIndex: activeSeriesTagIndex,
      })
    );
  }, [sort, popularitySortType, currentPage, tags, keyword, activeSeriesTagIndex]);

  const handleScroll = () => {
    if ((window.scrollY >= 279 && window.innerWidth > 1023) || (window.scrollY >= 226 && window.innerWidth < 1024))
      controlRef.current?.classList.add('is-sticky');
    else controlRef.current?.classList.remove('is-sticky');
  };

  const _isRowLoaded = ({ index }) => {
    const rowCount = Math.floor(videoList.length / columnCount);
    // console.log("_isRowLoaded (rowIndex, rowCount) :", index, rowCount);

    return index < rowCount - 1;
  };

  const _loadMore = ({ startIndex, stopIndex }) => {
    // console.log("_loadMore", videosData, totalPage.current);
    if (totalPage && currentPage < totalPage.current) {
      setCurrentPage(currentPage + 1);
    }
  };

  const _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    // console.log("_cellRenderer", key)
    const video = videoList[rowIndex * columnCount + columnIndex];

    if (!video) return;

    return (
      <div key={key} style={style}>
        <ContentCard
          {...video}
          currentDt={currentDt}
          timeline={getTimeLine(video.mediaId)}
          path={`${Paths.getPath('videoDetail').replace(':mediaId', video.mediaId as string)}`}
          isSwiperSlide={false}
          key={video.mediaId}
          GAObj={getContentsGAProps({
            contentsType: 'MEDIA',
            title: video.title as string,
            tags: video.mediaTag as string,
            category: video.cateNm as string,
            dataContentType: '영상',
            dataContentBelong: '콘텐츠',
            maker: video.creatorNm as string,
            time: video.videoTotalTime,
            brightcoveId: video.videoId as string,
            mediaContentsType: video.mediaContentsType as string,
            dataContentUrl: `${Paths.getPath('videoDetail').replace(':mediaId', video.mediaId as string)}`,
            dataImgUrl: `${video?.thumbImageUri as string}`,
          })}
        />
      </div>
    );
  };

  const _calculateRowHeight = (width: number) => {
    // console.log("_calculateRowHeight");

    // if (columnCount === 1) {
    if (window.innerWidth < 1024) {
      setRowHeight(width * 0.6047 + 126);
    } else {
      setRowHeight((width / columnCount) * 0.5644 + 140);
    }
  };

  const onAutoSizerResize = param => {
    const { height, width } = param;
    // console.log("onAutoSizerResize (height, width)", height, width);
    _calculateRowHeight(width);
  };

  const _videoList = useMemo(() => {
    return (
      <>
        {videoList && (
          <InfiniteLoader
            isRowLoaded={_isRowLoaded}
            loadMoreRows={_loadMore}
            rowCount={Math.ceil(videoList.length / columnCount) | 0}
            threshold={VERTUAL_SCROLL_THRESHOLD}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <AutoSizer disableHeight onResize={onAutoSizerResize}>
                    {({ width }) => {
                      if (width == 0) return;
                      return (
                        <Grid
                          cellRenderer={_cellRenderer}
                          columnWidth={width / columnCount}
                          columnCount={columnCount}
                          rowCount={Math.ceil(videoList.length / columnCount) | 0}
                          rowHeight={rowHeight}
                          width={width}
                          height={height}
                          ref={registerChild}
                          overscanRowCount={VERTUAL_SCROLL_THRESHOLD}
                          onSectionRendered={({
                            columnOverscanStartIndex,
                            columnOverscanStopIndex,
                            columnStartIndex,
                            columnStopIndex,
                            rowOverscanStartIndex,
                            rowOverscanStopIndex,
                            rowStartIndex,
                            rowStopIndex,
                          }) => {
                            onRowsRendered({
                              startIndex: rowStartIndex,
                              stopIndex: rowStopIndex,
                            });
                          }}
                          scrollTop={scrollTop}
                          autoHeight
                        />
                      );
                    }}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </>
    );
  }, [videoList, columnCount, rowHeight]);
  return (
    <>
      <div className="content_inner">
        <div id="movieList" className="section movie_list">
          <SectionTitle title="영상" />
          <div className="section_movie_list">
            <ControlImageTag
              tags={seriesClassificationCategoryList}
              activeSeriesTagIndex={activeSeriesTagIndex}
              setActiveSeriesTagIndex={setActiveSeriesTagIndex}
              seriesContentsType={'VIDEO'}
            />
          </div>
          <div className="section_cont section_sticky" ref={controlRef}>
            <div className="section_cont_top">
              <div className="section_info">
                <div className="control">
                  <ControlTag
                    tags={tags.sort((a, b) => a.orderNum - b.orderNum)}
                    clickTag={t => {
                      setTags(t);
                      setCurrentPage(1);
                      clearListCache();
                    }}
                    clickTagAll={bool => {
                      if (bool) {
                        setTags(tags.map(t => ({ ...t, isActive: false })));
                        setCurrentPage(1);
                        clearListCache();
                      }
                    }}
                  />
                  <div className="control_right is-pc">
                    <ControlDropdown
                      initial={sort}
                      initPop={popularitySortType}
                      clickSort={s => {
                        setSort(s);
                        setCurrentPage(1);
                        clearListCache();
                      }}
                      clickPop={p => {
                        setPopularitySortType(p);
                        setCurrentPage(1);
                        clearListCache();
                      }}
                      sortList={[
                        { title: '최신순', data: 'postDt,desc' },
                        {
                          title: '인기순',
                          data: 'popularity,desc',
                          sub: [
                            { title: '6개월 기준', data: 'SIXMONTH' },
                            { title: '1주 기준', data: 'ONEWEEK' },
                            { title: '1개월 기준', data: 'ONEMONTH' },
                            { title: '3개월 기준', data: 'THREEMONTH' },
                            { title: '1년 기준', data: 'ONEYEAR' },
                            { title: '전체', data: 'ALL' },
                          ],
                        },
                        { title: '조회수순', data: 'readCnt,desc' },
                        { title: '좋아요순', data: 'likeCnt,desc' },
                        { title: '댓글순', data: 'commentCnt,desc' },
                      ]}
                    />
                  </div>
                  <button type="button" className="filter-button is-mobile" onClick={() => setControlPopup(true)}>
                    <span className="ab_text">검색 필터</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="main_row card_list">
              <div className="card_row is-small has-hover">
                <div className="card_wrapper">{_videoList}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <ControlPopup
          initial={sort}
          initPop={popularitySortType}
          sortList={[
            { title: '최신순', data: 'postDt,desc' },
            {
              title: '인기순',
              data: 'popularity,desc',
              sub: [
                { title: '6개월 기준', data: 'SIXMONTH' },
                { title: '1주 기준', data: 'ONEWEEK' },
                { title: '1개월 기준', data: 'ONEMONTH' },
                { title: '3개월 기준', data: 'THREEMONTH' },
                { title: '1년 기준', data: 'ONEYEAR' },
                { title: '전체', data: 'ALL' },
              ],
            },
            { title: '조회수순', data: 'readCnt,desc' },
            { title: '좋아요순', data: 'likeCnt,desc' },
            { title: '댓글순', data: 'commentCnt,desc' },
          ]}
          isActive={controlPopup}
          clickSort={s => {
            setSort(s);
            setCurrentPage(1);
            clearListCache();
          }}
          clickPop={p => {
            setPopularitySortType(p);
            setCurrentPage(1);
            clearListCache();
          }}
          onClose={() => setControlPopup(false)}
        />
      )}
    </>
  );
};
export default Video;
