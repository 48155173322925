import React from 'react';
import moment from 'moment';
import { getDayOfWeekFromTargetDate } from '../../../utils/LGLifeUtils';

interface ErrorSystemEpProps {
  startErrorDate: Date;
  endErrorDate: Date;
}

const ErrorSystemEP = (props: ErrorSystemEpProps) => {
  return (
    <div className="ep_panel">
      <div className="ep_error">
        <div className={'logo_link'}>
          {/*<img src="/assets/images/icon/logo-lg-life.png" alt="LG LIFE" />*/}
          <span className="ab_text">LG LIFE</span>
        </div>
        <div className="ep_error_con">
          <img src="../assets/images/illust/illust-browser-error.png" alt="" className="ep_error_img" />
          <div className="ep_error_title">[LG LIFE 정기 점검 안내]</div>
          <div className="ep_error_desc">
            현재 LG LIFE 시스템 정기 점검 중 입니다.
            <br />
            점검이 완료될 때까지 잠시만 기다려 주십시오
            <br />
            (점검 일정 : {moment(props.startErrorDate).format('YYYY.MM.DD')} (
            {getDayOfWeekFromTargetDate(props.startErrorDate)}) {moment(props.startErrorDate).format('HH:mm')} ~{' '}
            {moment(props.endErrorDate).format('YYYY.MM.DD')} ({getDayOfWeekFromTargetDate(props.endErrorDate)}){' '}
            {moment(props.endErrorDate).format('HH:mm')})
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorSystemEP;
