import SectionTitle from 'components/atoms/SectionTitle';
import React, { useRef, useState } from 'react';
import { useMyInfo } from 'hooks';
import { encodingEucKr } from 'utils/utils';
import FontPopup from 'components/organisms/Popup/FontPopup';
import { all_click_datalayer_push, getLGLifeToolsGAProps } from '../../../utils/GAUtils';

const Tools = () => {
  const [isFontPopupOpen, setIsFontPopupOpen] = useState<boolean>(false);

  const toolsRef = useRef<HTMLDivElement>(null);
  const snsRef = useRef<HTMLDivElement>(null);
  const subRef = useRef<HTMLDivElement>(null);
  const shoppingRef = useRef<HTMLDivElement>(null);
  const { info, setIsLogin } = useMyInfo();

  const controlAccor = (ref: React.RefObject<HTMLDivElement>, e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ref.current?.classList.toggle('is-active');
    e?.currentTarget.classList.toggle('ico_drop_up');
    e?.currentTarget.classList.toggle('ico_drop_down');
  };

  return (
    <>
      <div className="content_inner">
        <div className="section faq_tools">
          <SectionTitle title="유용한 모음" />

          <div className="section_cont">
            <div className="tools_header">
              <strong className="cont_title">유용한 모음</strong>
              <p className="cont_desc">유용한 사이트를 쉽게 찾아보세요</p>
              <p className="cont_desc_sub">LG 관련 사이트들을 빠르게 방문할 수 있게 도와드립니다.</p>
            </div>

            <div className="tools_section">
              <div className="tools_card_list">
                {/* <div className="tools_card tools_card_guide">
                  <div className="tools_card_inner">
                    <span className="tools_card_text">LG LIFE 모바일 가이드</span>
                    <a
                      href="https://life.lg.com/ikep4-webapp/base/html/lglifeMobile.html?flagId=admin"
                      target="_blank"
                      className="tools_card_link"
                    >
                      바로보기
                    </a>
                  </div>
                </div> */}
                <div className="tools_card is-pc tools_card_font">
                  <div className="tools_card_inner">
                    <span className="tools_card_text">LG스마트체</span>
                    {/* <a
                      href="https://life.lg.com/ikep4-webapp/portal/main/secondParamUrl_reL2.do?mainFrameUrl=/lightpack/lgstory/boardItem/readBoardItemView_reL2.do?boardId=120000000872^itemId=120335860099^popupYn=false^viewMode=3^programId=07"
                      target="_blank"
                      className="tools_card_link"
                    >
                      바로보기
                    </a> */}
                    <a
                      {...getLGLifeToolsGAProps({
                        dataclickarea: 'LG스마트체',
                      })}
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        all_click_datalayer_push(e.currentTarget);
                        setIsFontPopupOpen(true);
                      }}
                      className="tools_card_link lg_font_link"
                    >
                      바로보기
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="tools_section">
              {/* <!-- tools_accor --> */}
              {/* <!-- 아코디언 펼침: is-active 클래스 추가 --> */}
              <div className="tools_accor is-active" ref={toolsRef}>
                <div className="tools_accor_head">
                  <strong className="tools_accor_title">유용한 사이트</strong>
                  {/* <!-- 아코디언 펼침 상태 버튼: is-active 클래스 추가 --> */}
                  <a
                    className="tools_accor_button ico_drop_up"
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      controlAccor(toolsRef, e);
                    }}
                  >
                    <span className="ab_text">더보기</span>
                  </a>
                </div>
                <div className="tools_accor_cont">
                  {/* <!-- tools_accor_row --> */}
                  <div className="tools_accor_row">
                    <ul className="tools_accor_list">
                      <li className="tools_accor_item">
                        <a
                          href="https://brand.lg.co.kr/"
                          target="_blank"
                          className="tools_accor_link ico_link"
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '유용한 사이트',
                            dataclickname: 'LG Brand Guide',
                          })}
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                        >
                          LG Brand Guide
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        {info && (
                          <a
                            href={`http://bizring.uplus.co.kr/groupRing/login_main.do?lgNAME=${encodingEucKr(
                              info.name as string
                            )}&brand=${encodingEucKr(info.groupNm as string)}&ctn=${encodingEucKr(
                              info.phoneNum as string
                            )}`}
                            target="_blank"
                            className="tools_accor_link ico_link"
                            {...getLGLifeToolsGAProps({
                              dataclickarea: '유용한 사이트',
                              dataclickname: 'LG 로고송 무료 통화연결음',
                            })}
                            onClick={e => {
                              all_click_datalayer_push(e.currentTarget);
                            }}
                          >
                            LG 로고송 무료 통화연결음
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /tools_accor_row --> */}
                </div>
              </div>
              {/* <!-- /tools_accor --> */}

              {/* <!-- tools_accor --> */}
              {/* <!-- 아코디언 펼침: is-active 클래스 추가 --> */}
              <div className="tools_accor is-active" ref={snsRef}>
                <div className="tools_accor_head">
                  <strong className="tools_accor_title">LG의 SNS 바로가기</strong>
                  {/* <!-- 아코디언 펼침 상태 버튼: is-active 클래스 추가 --> */}
                  <a
                    className="tools_accor_button ico_drop_up"
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      controlAccor(snsRef, e);
                    }}
                  >
                    <span className="ab_text">더보기</span>
                  </a>
                </div>
                <div className="tools_accor_cont">
                  <div className="tools_accor_row">
                    <span className="tools_accor_cate">
                      <img src="../assets/images/icon/ico-info-24-group.png" className="tools_cate_icon" alt="그룹" />
                      그룹
                    </span>
                    <ul className="tools_accor_list tools_sns_list">
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG그룹</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG그룹',
                                dataclicksns: 'youtube',
                              })}
                              href="http://www.youtube.com/user/LGSTORY"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG그룹',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/Lgtalks"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG그룹',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/lgtalks"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG그룹',
                                dataclicksns: 'instagram',
                              })}
                              href="https://www.instagram.com/lg_group_kr/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG사이언스파크</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG사이언스파크',
                                dataclicksns: 'youtube',
                              })}
                              href="https://bit.ly/2XgHUiq"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG사이언스파크',
                                dataclicksns: 'facebook',
                              })}
                              href="https://bit.ly/3buSoyO"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG사이언스파크',
                                dataclicksns: 'instagram',
                              })}
                              href="https://bit.ly/2UID0YE"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG AI 연구원</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG AI 연구원',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LG.ResearchAI/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG AI 연구원',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UCaoWx-ScnGYSrfKKcOn-YqA"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG AI 연구원',
                                dataclicksns: 'blog',
                              })}
                              href="https://www.lgresearch.ai/news"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '그룹',
                                dataclickname: 'LG AI 연구원',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/my.nhn?memberNo=52249799#"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="tools_accor_row">
                    <span className="tools_accor_cate">
                      <img
                        src="../assets/images/icon/ico-info-24-elec.png"
                        className="tools_cate_icon"
                        alt="전자 계열"
                      />
                      전자 계열
                    </span>
                    <ul className="tools_accor_list tools_sns_list">
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG전자</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG전자',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UCrIAnDo3VuWex3fywkGpB1g"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG전자',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/theLGstory"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG전자',
                                dataclicksns: 'blog',
                              })}
                              href="https://live.lge.co.kr/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG전자',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/lgepr"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG전자',
                                dataclicksns: 'instagram',
                              })}
                              href="https://www.instagram.com/lgelectronics_kr/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG디스플레이</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/user/mylgdisplay"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LGDisplayNewsroomKR"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'blog',
                              })}
                              href="http://blog.lgdisplay.com/"
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/my.naver?memberNo=53553628"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG이노텍</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'naver',
                              })}
                              href="https://www.youtube.com/user/LGInnotekpr"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LGInnotek.KR"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'blog',
                              })}
                              href="https://innotek.tistory.com"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '전자 계열',
                                dataclickname: 'LG디스플레이',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/my.nhn?memberNo=30454087"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="tools_accor_row">
                    <span className="tools_accor_cate">
                      <img
                        src="../assets/images/icon/ico-info-24-chemi.png"
                        className="tools_cate_icon"
                        alt="화학 계열"
                      />
                      화학 계열
                    </span>
                    <ul className="tools_accor_list tools_sns_list">
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG화학</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG화학',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/lgchemtube"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG화학',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LGChem/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG화학',
                                dataclicksns: 'blog',
                              })}
                              href="http://blog.lgchem.com/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG화학',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/my.nhn?memberNo=29922182"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG에너지솔루션</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG에너지솔루션',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/c/LGEnergySolution/featured"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG에너지솔루션',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LG-Energy-Solution-102973855312242/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG에너지솔루션',
                                dataclicksns: 'blog',
                              })}
                              href="https://inside.lgensol.com/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">팜한농</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: '팜한농',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UCyR3G-Lcsq2yGI388SfdghQ"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG생활건강</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '화학 계열',
                                dataclickname: 'LG생활건강',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UC60e4j7LVEFzLSSgt2DZTZg"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="tools_accor_row">
                    <span className="tools_accor_cate">
                      <img
                        src="../assets/images/icon/ico-info-24-conne_serv.png"
                        className="tools_cate_icon"
                        alt="통신·서비스 계열"
                      />
                      통신·서비스 계열
                    </span>
                    <ul className="tools_accor_list tools_sns_list">
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG유플러스</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG유플러스',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/user/Lgupluslte"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG헬로비전',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LGUplus"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG헬로비전',
                                dataclicksns: 'blog',
                              })}
                              href="http://blog.uplus.co.kr/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG헬로비전',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/my.nhn?memberNo=1217380"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG헬로비전',
                                dataclicksns: 'instagram',
                              })}
                              href="https://www.instagram.com/lguplus_/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG헬로비전</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG헬로비전',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UCNE1pYj5_Hmnaz1xnnJi_dQ"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG헬로비전',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LGHelloVision"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG헬로비전',
                                dataclicksns: 'blog',
                              })}
                              href="https://blog.naver.com/localtv23"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG CNS</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG CNS',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/user/lgcnstube"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG CNS',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/lgcnskorea"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG CNS',
                                dataclicksns: 'blog',
                              })}
                              href="https://www.lgcns.com/blog/home/  "
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG CNS',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/lgcns_official"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG CNS',
                                dataclicksns: 'instagram',
                              })}
                              href="https://www.instagram.com/lgcns_kr/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">D&O</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'D&O',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UCsdjEPuIifvoiY70jN-kZKg"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'D&O',
                                dataclicksns: 'blog',
                              })}
                              href="https://blog.naver.com/dnocorp"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">HSAD</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'HSAD',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UC_ZJaIPXYy-uFX5TaDzg68Q"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'HSAD',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/3Difference"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'HSAD',
                                dataclicksns: 'blog',
                              })}
                              href="http://hsad.tistory.com/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG트윈스</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG트윈스',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/c/LGTwinsTV"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG트윈스',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LGTWINSSEOUL/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG트윈스',
                                dataclicksns: 'instagram',
                              })}
                              href="https://www.instagram.com/lgtwinsbaseballclub/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG세이커스</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG세이커스',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/lgsakerssns"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG세이커스',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/my.nhn?memberNo=31235921"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '통신·서비스 계열',
                                dataclickname: 'LG세이커스',
                                dataclicksns: 'instagram',
                              })}
                              href="https://www.instagram.com/lgsakers/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="tools_accor_row">
                    <span className="tools_accor_cate">
                      <img src="../assets/images/icon/ico-info-24-foun.png" className="tools_cate_icon" alt="재단" />
                      재단
                    </span>
                    <ul className="tools_accor_list tools_sns_list">
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG공익재단</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG공익재단',
                                dataclicksns: 'youtube',
                              })}
                              href="http://www.youtube.com/@LGFoundationKr"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG공익재단',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/lgfoundation"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG공익재단',
                                dataclicksns: 'naver',
                              })}
                              href="https://post.naver.com/lgfoundationkr"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG아트센터</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG아트센터',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/channel/UCoKsFmnE1kzHIeZ0527dDFA"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG아트센터',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/LGArtsCenter"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG아트센터',
                                dataclicksns: 'blog',
                              })}
                              href="https://blog.naver.com/lgartscenter"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-blogger"
                              target="_blank"
                            >
                              <span className="ab_text">블로그 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">LG상남도서관</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG상남도서관',
                                dataclicksns: 'youtube',
                              })}
                              href="https://www.youtube.com/user/LGScienceLand"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-youtube"
                              target="_blank"
                            >
                              <span className="ab_text">유투브 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG상남도서관',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/sciforus"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: 'LG상남도서관',
                                dataclicksns: 'naver',
                              })}
                              href="https://m.post.naver.com/lgsangnam"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-naver"
                              target="_blank"
                            >
                              <span className="ab_text">네이버 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">화담숲</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: '화담숲',
                                dataclicksns: 'instagram',
                              })}
                              href="https://www.instagram.com/hwadamsup/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-instagram"
                              target="_blank"
                            >
                              <span className="ab_text">인스타그램 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">연암대학교</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: '연암대학교',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/yonamlg/?ref=br_rs"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="tools_accor_item">
                        <span className="tools_accor_link">연암공과대학교</span>
                        <ul className="tools_accor_sns">
                          <li className="tools_accor_icon">
                            <a
                              {...getLGLifeToolsGAProps({
                                dataclickarea: '재단',
                                dataclickname: '연암공과대학교',
                                dataclicksns: 'facebook',
                              })}
                              href="https://www.facebook.com/yonam.DT/"
                              onClick={e => {
                                all_click_datalayer_push(e.currentTarget);
                              }}
                              className="tools_sns_link sns-facebook"
                              target="_blank"
                            >
                              <span className="ab_text">페이스북 링크</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /tools_accor_row --> */}
                </div>
              </div>
              {/* <!-- /tools_accor --> */}

              {/* <!-- tools_accor --> */}
              {/* <!-- 아코디언 펼침: is-active 클래스 추가 --> */}
              <div className="tools_accor is-active" ref={subRef}>
                <div className="tools_accor_head">
                  <strong className="tools_accor_title">계열사 사업장</strong>
                  {/* <!-- 아코디언 펼침 상태 버튼: is-active 클래스 추가 --> */}
                  <a
                    className="tools_accor_button ico_drop_up"
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      controlAccor(subRef, e);
                    }}
                  >
                    <span className="ab_text">더보기</span>
                  </a>
                </div>

                <div className="tools_accor_cont">
                  {/* <!-- tools_accor_row --> */}
                  <div className="tools_accor_row">
                    <ul className="tools_accor_list">
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '계열사 사업장',
                            dataclickname: 'LG트윈타워',
                          })}
                          href="http://www.lgtwintowers.co.kr/mypage/mypage/mypage/mypage.dev"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          LG트윈타워
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '계열사 사업장',
                            dataclickname: 'LG광화문빌딩',
                          })}
                          href="http://www.lggwanghwamun.co.kr/common/index_cs.dev"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          LG광화문빌딩
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '계열사 사업장',
                            dataclickname: 'LG생활연수원',
                          })}
                          href="https://baekam.sni.co.kr/"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          LG생활연수원
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '계열사 사업장',
                            dataclickname: '곤지암리조트',
                          })}
                          href="http://www.konjiamresort.co.kr/"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          곤지암리조트
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '계열사 사업장',
                            dataclickname: '곤지암 화담숲',
                          })}
                          href="http://www.hwadamsup.com/"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          곤지암 화담숲
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '계열사 사업장',
                            dataclickname: 'LG아트센터',
                          })}
                          href="http://www.lgart.com/"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          LG아트센터
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /tools_accor_row --> */}
                </div>
              </div>
              {/* <!-- /tools_accor --> */}

              {/* <!-- tools_accor --> */}
              {/* <!-- 아코디언 펼침: is-active 클래스 추가 --> */}
              <div className="tools_accor is-active" ref={shoppingRef}>
                <div className="tools_accor_head">
                  <strong className="tools_accor_title">임직원몰</strong>
                  {/* <!-- 아코디언 펼침 상태 버튼: is-active 클래스 추가 --> */}
                  <a
                    className="tools_accor_button ico_drop_up"
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      controlAccor(shoppingRef, e);
                    }}
                  >
                    <span className="ab_text">더보기</span>
                  </a>
                </div>
                <div className="tools_accor_cont">
                  {/* <!-- tools_accor_row --> */}
                  <div className="tools_accor_row">
                    <ul className="tools_accor_list">
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '임직원몰',
                            dataclickname: 'LG라이프케어포탈',
                          })}
                          href="http://www.lglifecare.com/"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          LG라이프케어포탈
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '임직원몰',
                            dataclickname: '하이프라자',
                          })}
                          href="http://www.lgbestmall.co.kr/"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          하이프라자
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '임직원몰',
                            dataclickname: 'LG생활건강',
                          })}
                          href="https://www.lgcaremall.com"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          LG생활건강
                        </a>
                      </li>
                      <li className="tools_accor_item">
                        <a
                          {...getLGLifeToolsGAProps({
                            dataclickarea: '임직원몰',
                            dataclickname: 'redcap 투어',
                          })}
                          href="http://member.redcaptour.com/"
                          onClick={e => {
                            all_click_datalayer_push(e.currentTarget);
                          }}
                          target="_blank"
                          className="tools_accor_link ico_link"
                          rel="noreferrer noopener"
                        >
                          redcap 투어
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /tools_accor_row --> */}
                </div>
              </div>
              {/* <!-- /tools_accor --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- popup: page --> */}
      {isFontPopupOpen && <FontPopup onClose={() => setIsFontPopupOpen(false)} />}
    </>
  );
};

export default Tools;
