import { useState } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';

const useEvent = () => {
  const { info } = useMyInfo();
  const [searchOptions, setSearchOptions] = useState<any>({
    eventTypeList: 'EDITOR',
    eventStatus: 'ALL',
    size: 9,
    sort: 'postDt,desc',
  });

  const { data: eventData } = useSWR<any>(info ? [endpoints.event.event, searchOptions] : null, () =>
    fetcher.event.getEvent(searchOptions).then(res => {
      return res;
    })
  );

  return {
    eventData,
    searchOptions,
    setSearchOptions,
  };
};

export default useEvent;
