import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { useMyInfo, useAuth, useCommon, useTokens, useSaveEmail } from 'hooks';
import { getPageQuery, mobileCheck } from 'utils/utils';
import Paths from 'commons/paths';
import AccountSelect from './components/AccountSelect';
import { getGALoginClickProps, all_click_datalayer_push } from 'utils/GAUtils';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import QnaPopup from './components/QnaPopup';
import { openLeftSideMenu, setCicUpdate } from '../../../utils/LGLifeUtils';

const Login = ({ history }: RouteComponentProps) => {
  const { companies, domains, setGroupCode } = useCommon();
  const { postSignIn } = useAuth();
  const { saveTokens } = useTokens();
  const { savedGroupCode, savedEmail, savedDomain, saveEmail, removeEmail } = useSaveEmail();

  const [popupOpen, setPopupOpen] = useState<number>(-1);
  const { info, setIsLogin } = useMyInfo();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companySelectedIndex, setCompanySelectedIndex] = useState(-1);
  const [domainSelectedIndex, setDomainSelectedIndex] = useState(-1);

  const [isWarning, setIsWarning] = useState(false);
  const [isSaveEmail, setIsSaveEmail] = useState(false);
  const [failCount, setFailCount] = useState(-1);

  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');
  const [isQnaPopup, setIsQnaPopup] = useState(false);

  useEffect(() => {
    if (savedGroupCode && savedEmail && savedDomain) {
      setIsSaveEmail(true);
    }
  }, [savedGroupCode, savedEmail, savedDomain]);

  useEffect(() => {
    if (companySelectedIndex > -1) {
      setGroupCode(companies.groupCompanyList?.[companySelectedIndex]?.groupCode);
    }
  }, [companySelectedIndex]);

  useEffect(() => {
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, [savedEmail]);

  useEffect(() => {
    if (companies && savedGroupCode) {
      const index = companies.groupCompanyList?.findIndex(company => company.groupCode === savedGroupCode);

      if (index > -1) {
        setCompanySelectedIndex(index);
      }
    }
  }, [companies, savedGroupCode]);

  useEffect(() => {
    if (domains) {
      if (!domains.groupDomainList) {
        return;
      }

      if (domains.groupDomainList.length === 1) {
        setDomainSelectedIndex(0);
      } else {
        setDomainSelectedIndex(-1);
      }

      if (savedDomain) {
        const index = domains.groupDomainList.findIndex(domain => domain.domainNm === savedDomain);

        if (index > -1) {
          setDomainSelectedIndex(index);
        }
      }
    }
  }, [domains, savedDomain]);

  const onClickCompany = index => {
    setIsWarning(false);
    setCompanySelectedIndex(index);
  };

  const onClickDomain = index => {
    setIsWarning(false);
    setDomainSelectedIndex(index);
  };

  useEffect(() => {
    if (info) {
      const urlParams = new URL(window.location.href);
      const params = getPageQuery();

      let { redirect } = params as { redirect: string };

      if (redirect) {
        redirect = redirect.substr(urlParams.origin.length);
      }

      history.replace(redirect || '/');
      return;
    }

    // 회원 정보가 없을 경우 LNB 펼침 상태로 변경 (Local Storage)
    localStorage.setItem('isOpenedLNB', JSON.stringify(true));
  }, [info]);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWarning(false);
    setEmail(e.target.value.trim());
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWarning(false);
    setPassword(e.target.value.trim());
  };

  const onKeyPressPassword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const onClickLogin = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    login();
  };

  const login = () => {
    if (companySelectedIndex === -1) {
      setAlertPopContents('회사를 선택하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    if (email.trim() === '') {
      setAlertPopContents('이메일을 입력하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    if (domainSelectedIndex === -1) {
      setAlertPopContents('도메인을 선택하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    if (password.trim() === '') {
      setAlertPopContents('패스워드를 입력하세요.');
      setIsAlertPopupOpen(true);
      return;
    }

    const completedEmail = email + '@' + domains.groupDomainList?.[domainSelectedIndex]?.domainNm;

    postSignIn(completedEmail, password)
      .then(res => {
        if (res.resultCode === 200) {
          if (isSaveEmail) {
            saveEmail(
              companies.groupCompanyList?.[companySelectedIndex]?.groupCode,
              email,
              domains.groupDomainList?.[domainSelectedIndex]?.domainNm
            );
          } else {
            removeEmail();
          }

          localStorage.clear();
          saveTokens(res.accessToken, res.refreshToken);
          setIsLogin(true);
          setCicUpdate(res.shouldSyncCic);
        } else if (res.resultCode === 500) {
          setAlertPopContents('서버 오류');
          setIsAlertPopupOpen(true);
        } else if (res.resultCode === 1004) {
          if (res.loginFailCnt) setFailCount(res.loginFailCnt);
          else setFailCount(-1);
          setIsWarning(true);
        } else {
          setAlertPopContents(res.resultMessage);
          setIsAlertPopupOpen(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onChangeSaveEmail = () => {
    setIsSaveEmail(!isSaveEmail);
  };

  return (
    <div className="wrapper">
      <div className="container login">
        <div className="login_inner">
          <div className="login_top">
            <div className="login_logo">
              <h1>
                <span className="ab_text">LG LIFE</span>
              </h1>
              <div className="login_logo_text">고객의 삶을 바꿉니다</div>
            </div>
            <div className="login_img">
              <img src="/assets/images/icon/ico-login.png" alt="login" className="is-login" />
            </div>
          </div>

          <div className="login_box">
            <div className="login_title">로그인하기</div>
            <div className="login_con">
              <div className="account_row">
                <div className="account_item">
                  <div className="account_title">회사명</div>
                  <AccountSelect
                    initial={'회사선택'}
                    selectedIndex={companySelectedIndex}
                    onClickItem={onClickCompany}
                    list={
                      companies && companies.groupCompanyList && companies.groupCompanyList?.map(cate => cate.groupNm)
                    }
                  />
                </div>
              </div>

              <div className={classNames(['account_row', 'is-email'])}>
                <div className="account_item">
                  <div className="account_title">이메일</div>
                  <input
                    type="text"
                    className="account_value"
                    title="이메일"
                    placeholder="이메일 주소"
                    onChange={onChangeEmail}
                    value={email}
                  />
                  <>
                    <span className="account_at">@</span>
                    <AccountSelect
                      initial={'선택'}
                      selectedIndex={domainSelectedIndex}
                      onClickItem={onClickDomain}
                      list={domains && domains.groupDomainList && domains.groupDomainList.map(cate => cate.domainNm)}
                    />
                  </>
                </div>
              </div>
              <div className="account_row">
                <div className="account_item">
                  <div className="account_title">비밀번호</div>
                  <input
                    type="password"
                    className="account_value"
                    title="비밀번호"
                    placeholder="비밀번호 입력"
                    onKeyPress={onKeyPressPassword}
                    onChange={onChangePassword}
                    value={password}
                  />
                </div>
              </div>
              <div className="login_check">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    className="is-blind"
                    id="emailCheck"
                    checked={isSaveEmail}
                    onChange={onChangeSaveEmail}
                  />
                  <label className="checkbox_label" htmlFor="emailCheck">
                    이메일주소 저장
                  </label>
                </div>

                <Link className="login_check_link" to={Paths.getPath('findpw')}>
                  비밀번호 찾기
                </Link>
              </div>
              <div className={classNames('login_btn', { 'is-error': isWarning })}>
                <a
                  href="#;"
                  className="login_link is-active"
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                    onClickLogin(e);
                  }}
                  {...getGALoginClickProps({
                    memberAffiliate: domains?.groupDomainList?.[domainSelectedIndex]?.domainNm,
                  })}
                >
                  로그인
                </a>
                <div className="login_warn">
                  {failCount >= 0 ? (
                    <>
                      잘못된 비밀번호입니다.
                      <br />
                      비밀번호 {failCount}회 오류입니다. 5회 틀릴 경우 계정이 잠금처리 됩니다.
                      <br />
                      비밀번호를 찾아야하는 경우 '비밀번호 찾기'를 이용해주세요.
                    </>
                  ) : (
                    <>가입하지 않은 이메일입니다.</>
                  )}
                </div>
              </div>
              <div className="login_more">
                <div className="login_more_section">
                  <span className="login_more_txt">아직 LG-LIFE에 등록되지 않으셨나요?</span>
                  <Link className="login_more_link" to={Paths.getPath('confirmMember')}>
                    회원 가입 하러가기
                  </Link>
                </div>
                <div className="login_more_section">
                  <span className="login_more_txt">문의사항이 있으신가요?</span>
                  <a
                    href="#;"
                    id="qnaLoginBtn"
                    className="login_more_link"
                    onClick={e => {
                      e.preventDefault();
                      setIsQnaPopup(true);
                    }}
                  >
                    문의하기
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="login_info">
            <div className="login_info_item">
              <img src="/assets/images/icon/ico-login-info01.png" alt="Q&A" className="login_info_img" />
              <div className="login_info_title">로그인 / 회원 가입시 자주 묻는 질문</div>
              <div className="login_info_con">
                <div className="login_info_qna">
                  <span className="text">LG LIFE 로그인이 안됩니다. </span>
                  <a href="#;" className="link" onClick={() => setPopupOpen(0)}>
                    답변 보기
                  </a>
                </div>
                <div className="login_info_qna">
                  <span className="text">신규 회원 가입 방법이 궁금합니다.</span>
                  <a href="#;" className="link" onClick={() => setPopupOpen(1)}>
                    답변 보기
                  </a>
                </div>
                <div className="login_info_qna">
                  <span className="text">계열사 이동을 할 경우 LG LIFE 계정을 계속 사용할 수 있나요?</span>
                  <a href="#;" className="link" onClick={() => setPopupOpen(2)}>
                    답변 보기
                  </a>
                </div>
                <div className="login_info_qna">
                  <span className="text">회원가입이 되지않습니다.</span>
                  <a href="#;" className="link" onClick={() => setPopupOpen(3)}>
                    답변 보기
                  </a>
                </div>
              </div>
            </div>
            <div className="login_info_item">
              <img src="/assets/images/icon/ico-login-info03.png" alt="password" className="login_info_img" />
              <div className="login_info_title">비밀번호 찾기</div>
              <div className="login_info_con">
                <div className="login_info_txt">
                  비밀번호 찾기는 LG LIFE에 등록된 본인의 이메일 주소로 임시비밀번호를 발송해 드립니다.
                  <br />
                  <br />
                  LG LIFE에서 수신되는 이메일은 각사의 이메일시스템에 따라 <span className="is-em">약간의 시간</span>이
                  소요 될 수 있습니다.
                </div>
              </div>
            </div>
          </div>
          {/* <div className={classNames(['popup mail_popup', { 'is-active': popupOpen === 5 }])}>
              <div className="popup_dim"></div>
              <div className="popup_inner popup_large">
                <div className="popup_header">
                  <strong className="popup_title"></strong>
                </div>
                <div className="popup_cont">
                  <div className="mail_box">
                    <div className="mail_box_img">
                      <img src="/assets/images/icon/ico-check.png" alt="check" />
                    </div>
                    <div className="mail_box_con">
                      <div className="mail_box_title">LG LIFE에 신규등록 해주셔서 감사합니다.</div>
                      <div className="mail_box_desc">
                        본 메일은 LG임직원 확인을 위한 메일입니다.
                        <br />
                        홍길동 님이 맞으시면 아래 “LG LIFE 신규등록 확인” 버튼을 클릭해 주시기 바랍니다.
                      </div>
                      <div className="mail_box_subtxt">
                        * “LG LIFE 신규등록 확인” 버튼을 클릭하셔야 등록이 완료됩니다.
                      </div>
                      <div className="mail_box_btn">
                        <button type="button">LG LIFE 신규등록 확인</button>
                      </div>
                    </div>
                    <div className="mail_box_info">
                      <span className="text">신규등록 관련 문의사항</span>
                      <a href="#;" className="link">
                        문의하기
                      </a>
                    </div>
                  </div>
                </div>
                <button type="button" className="popup_close ico_close" onClick={() => setPopupOpen(-1)}></button>
              </div>
            </div> */}
          <div className={classNames(['popup qna_popup qna1', { 'is-active': popupOpen === 0 }])}>
            <div className="popup_dim"></div>
            <div className="popup_inner popup_large">
              <div className="popup_header">
                <strong className="popup_title"></strong>
              </div>
              <div className="popup_cont">
                <div className="qna_box">
                  <div className="qna_box_title">LG LIFE 로그인이 안됩니다.</div>
                  <div className="qna_box_con custom_scroll">
                    현재 각 계열사 업무포털에 접속하는 경우, LG LIFE 자동 로그인을 지원하고 있습니다.
                    <br />
                    업무포털 좌측의 LG LIFE 배너 클릭 후 자동 로그인이 되지 않을 경우 브라우저 캐시 삭제 후 재접속
                    해보시기 바랍니다.
                    <br />
                    만일, LG LIFE 사이트에 직접 로그인이 안되는 경우 LG LIFE 로그인창 하단의 '문의 하기'를 통해 문의
                    부탁드립니다.
                    <br />
                    <br />
                  </div>
                  <div className="qna_box_btn">
                    <button type="button" id="qnaConfirm" onClick={() => setPopupOpen(-1)}>
                      확인
                    </button>
                  </div>
                </div>
              </div>
              <button type="button" className="popup_close ico_close" onClick={() => setPopupOpen(-1)}></button>
            </div>
          </div>
          <div className={classNames(['popup qna_popup qna2', { 'is-active': popupOpen === 1 }])}>
            <div className="popup_dim"></div>
            <div className="popup_inner popup_large">
              <div className="popup_header">
                <strong className="popup_title"></strong>
              </div>
              <div className="popup_cont">
                <div className="qna_box">
                  <div className="qna_box_title">신규 회원 가입 방법이 궁금합니다.</div>
                  <div className="qna_box_con custom_scroll">
                    신규가입은 LG LIFE 홈페이지에서 가능합니다.
                    <br />
                    신규가입 시 회사 이메일, 사번을 통해 LG 임직원임을 확인합니다.
                    <br />
                    정확한 정보를 입력했음에도 회원가입이 불가하다면 로그인 창 하단의 '문의 하기' 버튼을 통해 문의
                    부탁드립니다
                    <br />
                    LG LIFE는 협력사 소속 직원(X,Y사번)은 가입이 제한 되어 있습니다.
                    <br />
                    다만, 업무상 필요한 경우에 한해 각 사 담당자 통해 신청해주시면 가입 가능합니다.
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="qna_box_btn">
                    <button type="button" id="qnaConfirm" onClick={() => setPopupOpen(-1)}>
                      확인
                    </button>
                  </div>
                </div>
              </div>
              <button type="button" className="popup_close ico_close" onClick={() => setPopupOpen(-1)}></button>
            </div>
          </div>
          <div className={classNames(['popup qna_popup qna3', { 'is-active': popupOpen === 2 }])}>
            <div className="popup_dim"></div>
            <div className="popup_inner popup_large">
              <div className="popup_header">
                <strong className="popup_title"></strong>
              </div>
              <div className="popup_cont">
                <div className="qna_box">
                  <div className="qna_box_title">계열사 이동을 할 경우 LG LIFE 계정을 계속 사용할 수 있나요?</div>
                  <div className="qna_box_con custom_scroll">
                    소속이 변경 될 경우 기존에 사용하셨던 계정은 사용하실 수 없고 기존 계정의 정보도 이관되지 않습니다.
                    <br />
                    새롭게 바뀐 소속의 이메일 주소로 LG LIFE에 신규 가입해 주시기 바랍니다.
                    <br />
                    <br />
                  </div>
                  <div className="qna_box_btn">
                    <button type="button" id="qnaConfirm" onClick={() => setPopupOpen(-1)}>
                      확인
                    </button>
                  </div>
                </div>
              </div>
              <button type="button" className="popup_close ico_close" onClick={() => setPopupOpen(-1)}></button>
            </div>
          </div>
          <div className={classNames(['popup qna_popup qna4', { 'is-active': popupOpen === 3 }])}>
            <div className="popup_dim"></div>
            <div className="popup_inner popup_large">
              <div className="popup_header">
                <strong className="popup_title"></strong>
              </div>
              <div className="popup_cont">
                <div className="qna_box">
                  <div className="qna_box_title">회원가입이 되지않습니다.</div>
                  <div className="qna_box_con custom_scroll">
                    현재 LG-LIFE는 회원가입 시 LG임직원정보(CIC)와 연동하여 임직원임을 체크하고 있습니다. 이메일주소와
                    사번을 다시 한번 확인 부탁드리겠습니다.
                    <br />
                    <br />
                    만일, 신규 입사자의 경우 업무포털 계정 등록 후 2~3일 뒤 회원가입이 가능하니 참고 부탁드립니다.
                    <br />
                    <br />
                  </div>
                  <div className="qna_box_btn">
                    <button type="button" id="qnaConfirm" onClick={() => setPopupOpen(-1)}>
                      확인
                    </button>
                  </div>
                </div>
              </div>
              <button type="button" className="popup_close ico_close" onClick={() => setPopupOpen(-1)}></button>
            </div>
          </div>
          <QnaPopup isOpen={isQnaPopup} onClose={() => setIsQnaPopup(false)} />
        </div>
      </div>
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
    </div>
  );
};

export default Login;
