import React, { ReactNode } from 'react';

interface AlertPopupProps {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  hasNewlineAlertPopup?: boolean;
}

const AlertPopup = ({ isOpen, children, onClose, hasNewlineAlertPopup = false }: AlertPopupProps) => {
  return (
    <>
      {isOpen && (
        <div className="confirm_popup share_complete_popup is-active">
          <div className="popup_dim"></div>
          <div className="popup_inner">
            <p className="confirm_popup_text" style={hasNewlineAlertPopup ? { whiteSpace: 'pre-line' } : undefined}>
              {children}
            </p>
            <ul className="confirm_button_list is-full">
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_confirm share_complete_confirm"
                  onClick={() => {
                    onClose();
                  }}
                >
                  확인
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertPopup;
