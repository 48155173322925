import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { match, matchPath, useHistory, useLocation } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import classNames from 'classnames';
import Paths from 'commons/paths';
import paths from 'commons/paths';
import { useContinue, useCount, useLogging, useMyInfo, useVideo, useVideoDetail } from 'hooks';
import { videoTimeFormat } from 'utils/utils';
import { callVidePlayGaEvent } from 'utils/GAUtils';
import DetailComment from '../DetailComment';
import { DetailBack } from 'components/atoms';
import { Maybe } from 'components/common';
import DetailInfo from 'components/molecules/DetailInfo';
import DetailScript from 'components/molecules/DetailScript';
import Playlist, { PlaylistItem } from 'components/molecules/Playlist';
import RelationContents from 'components/molecules/RelationContents';
import VideoDetailInfo from './components/VideoDetailInfo';
import SharePopup from '../Popup/SharePopup';
import ScriptContent from 'components/organisms/ScriptContent';
import queryString from 'query-string';
import { CommentPostType } from '../../../types/enum/CommentPostType';
import moment from 'moment';

/** 영상 경과 시간 업데이트 간격(초) */
const ELAPSED_TIME_UPDATE_INTERVAL = 1;

/** 영상 되감기/빨리 감기 시간 간격(초) */
const JUMPING_VIDEO_TERM_BY_SECOND: number = 5;

const MainVideoPlayer = () => {
  const history = useHistory();
  const location = useLocation();
  const { info } = useMyInfo();

  const pathnameId = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const [video, setVideo] = useState<MediaDetailResDto>();
  const [playlist, setPlaylist] = useState([] as PlaylistItem[]);
  const [related, setRelated] = useState([] as MediaPlaylistResDto[]);
  const [sharePopup, setSharePopup] = useState(false);
  const [videoPlayInfo, setVideoPlayInfo] = useState({ valueNow: 0, currentTime: '0:00', duration: '0:00' });

  const [returnCate, setReturnCate] = useState('');
  const [sclasId, setSclasId] = useState('');

  const [wasMediaDetailPage, setWasMediaDetailPage] = useState<match<{}> | null>(null);

  const { data, setPip, setVideoVisible, mutateVideoState } = useVideo();
  const {
    videoDetailData,
    likeVideo,
    bookmarkVideo,
    playlistData,
    id,
    setId,
    relatedData,
    mutate,
    mutatePlaylist,
    mutateRelated,
    detailDesc,
    videoElements,
  } = useVideoDetail(data.mediaId);
  const { getTimeLine, updateTime, updateMediaContinue } = useContinue();
  const { addReadCount } = useCount();
  const { postLogging } = useLogging();

  const playlistRef = useRef<HTMLDivElement>(null);
  const relationRef = useRef<HTMLDivElement>(null);
  const scriptRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<HTMLDivElement>(null);
  const detailLeftRef = useRef<HTMLDivElement>(null);
  const vRef = useRef<any>();
  const currentTimeRef = useRef<HTMLSpanElement>(null);
  const durationRef = useRef<HTMLSpanElement>(null);
  const valueNowRef = useRef<HTMLSpanElement>(null);

  const isMobile = useMemo(() => window.innerWidth <= 1024, []);

  useEffect(() => {
    // playlist 클릭한 경우 조회수 카운트
    if (id && id !== pathnameId) {
      addReadCount({ contentsId: id, contentsPostType: 'MEDIA' }).then(res => {
        if (res.resultCode === 200) {
          mutate();
          mutatePlaylist();
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (videoDetailData) {
      setVideo(videoDetailData);
      if (videoDetailData.mediaContentType === 'MAGAZINE') mutateVideoState({ ...data, visible: false });
    }
  }, [videoDetailData]);

  useEffect(() => {
    if (relatedData && relatedData.playlistList) setRelated(relatedData.playlistList);
  }, [relatedData]);

  useEffect(() => {
    // playlist가 존재하는 경우 plyalist의 첫번째 영상으로 videodetail을 다시 요청하기 위해 setId()
    if (playlistData && playlistData.playlistList && playlistData.playlistList.length > 0) {
      setPlaylist(playlistData.playlistList);
      if (!id || pathnameId === id) {
        setId(playlistData.playlistList[0].mediaId);
      } else if (location.state) {
        setId(location.state as string);
        location.state = '';
      }
    } else {
      setPlaylist([]);
    }
  }, [playlistData, id]);

  useEffect(() => {
    if (location.search.includes('sclasId=')) {
      setReturnCate('series');
      setSclasId((queryString.parse(location.search)?.sclasId as string) || '');
    } else if (location.pathname.indexOf(Paths.getPath('videoDetail').replace('/:mediaId', '')) >= 0)
      setReturnCate('video');
    else if (location.pathname.indexOf(Paths.getPath('recommendDetail').replace('/:mediaId', '')) >= 0)
      setReturnCate('recommend');
    else if (location.pathname.indexOf(Paths.getPath('newsDetail').replace('/:mediaId', '')) >= 0)
      setReturnCate('news');
    else if (location.pathname.indexOf(Paths.getPath('pressDetail').replace('/:mediaId', '')) >= 0)
      setReturnCate('press');
    else if (location.pathname.indexOf(Paths.getPath('ESGDetail').replace('/:esgId', '')) >= 0) setReturnCate('ESG');
    else if (location.pathname.indexOf('group/detail') >= 0) setReturnCate('group');
  }, [data.mediaId]);

  // 영상/뉴스 상세 페이지에서 나간 경우, 이어보기 데이터 서버 저장 요청
  useEffect(() => {
    if (wasMediaDetailPage) {
      updateMediaContinue();
    }
    const matchMediaDetailPage = matchPath(location.pathname, [
      Paths.getPath('videoDetail'),
      Paths.getPath('newsDetail'),
      Paths.getPath('pressDetail'),
      Paths.getPath('recommendDetail'),
    ]);
    setWasMediaDetailPage(matchMediaDetailPage);
  }, [location.pathname]);

  useEffect(() => {
    if (sharePopup) document.querySelector('.nav')?.setAttribute('style', 'display: none');
    else document.querySelector('.nav')?.setAttribute('style', '');
  }, [sharePopup]);

  const openScript = () => {
    relationRef.current?.style.setProperty('display', 'none');
    playlistRef.current?.style.setProperty('display', 'none');
    scriptRef.current?.classList.add('is-active');
  };

  const closeScript = () => {
    relationRef.current?.style.setProperty('display', '');
    playlistRef.current?.style.setProperty('display', '');
    scriptRef.current?.classList.remove('is-active');
  };

  const controlPlaylist = () => {
    playlistRef.current?.classList.toggle('is-open');
  };

  /**
   * 영상 10초 뒤로 되감기
   * @param initialTime 현재 영상 재생 시간
   */
  const rewindVideoTime = (initialTime: number) => {
    if (!vRef.current) return;
    const controlBar = vRef.current.player;
    let videoTime: number;
    if (initialTime >= JUMPING_VIDEO_TERM_BY_SECOND) {
      videoTime = initialTime - JUMPING_VIDEO_TERM_BY_SECOND;
    } else {
      videoTime = 0;
    }
    controlBar.currentTime(videoTime);
    updateTime(data.mediaId, videoTime);
  };

  /**
   * 영상 10초 앞으로 빨리 감기
   * @param initialTime 현재 영상 재생 시간
   */
  const fastForwardVideoTime = (initialTime: number) => {
    if (!vRef.current) return;
    const controlBar = vRef.current.player;
    const videoDuration = controlBar.duration();
    let videoTime: number;
    const forwardedVideoTime = initialTime + JUMPING_VIDEO_TERM_BY_SECOND;
    if (forwardedVideoTime <= videoDuration) {
      videoTime = forwardedVideoTime;
    } else {
      videoTime = videoDuration;
    }
    controlBar.currentTime(videoTime);
    updateTime(data.mediaId, videoTime);
  };

  const onSuccess = (success: any) => {
    const player = success.ref;
    player.muted(false);

    // 이어보기 재생
    if (getTimeLine(video?.mediaId)) {
      player.currentTime(getTimeLine(video?.mediaId) / 1000);
    }

    let initialTime = player.currentTime();

    // PIP 플러그인 적용하여 플로팅 버튼 노출 위해 주석 처리 - 2024.08.27
    // if (!document.getElementById('pipButton')) {
    //   const controlBar = player.el_.getElementsByClassName('vjs-control-bar')[0];
    //   const insertBeforeNode = player.el_.getElementsByClassName('vjs-fullscreen-control');
    //   const newElement = document.createElement('div');
    //   const newLink = document.createElement('button');
    //   const newImage = document.createElement('img');
    //   newElement.id = 'pipButton';
    //   newElement.className = 'vjs-control';
    //   newImage.setAttribute('src', '/assets/images/icon/ico-pip-mode.png'); //pip 아이콘
    //   newLink.onclick = () => {
    //     setPip(true);
    //
    //     if (returnCate.includes('series')) {
    //       history.push(Paths.getPath('videoSeries').replace(':sclasId', sclasId));
    //     } else if (returnCate !== 'group') {
    //       history.push(Paths.getPath(`${returnCate}List`));
    //     } else {
    //       history.push('/');
    //     }
    //
    //     if (vRef.current.refNode.querySelector('.vjs-play-control')?.getAttribute('title') === 'Pause')
    //       detailLeftRef.current?.classList.add('is-play');
    //     else detailLeftRef.current?.classList.remove('is-play');
    //   };
    //   newLink.appendChild(newImage);
    //   newElement.appendChild(newLink);
    //   if (controlBar) {
    //     controlBar.insertBefore(newElement, insertBeforeNode[0]);
    //   }
    // }

    const jumpControlBar = player.el_.getElementsByClassName('vjs-control-bar')[0];
    const insertBeforeNodeByJumpingPlayTime = player.el_.getElementsByClassName('vjs-volume-panel')[0];
    const videoPlayArea = document.getElementsByClassName('brightcove-react-player-loader')[0] as HTMLDivElement;

    videoPlayArea.setAttribute('tabindex', '0');

    videoPlayArea.addEventListener('keydown', e => {
      if (e.key === 'ArrowLeft') rewindVideoTime(initialTime);
      if (e.key === 'ArrowRight') fastForwardVideoTime(initialTime);
    });

    // 되감기 버튼 추가
    if (!document.getElementById('rewindButton')) {
      const newElementRewind = document.createElement('div');
      const newImageRewindImg = document.createElement('img');

      newElementRewind.id = 'rewindButton';
      newElementRewind.className = 'vjs-fullscreen-control vjs-control vjs-button';
      newImageRewindImg.setAttribute(
        'src',
        'https://player.support.brightcove.com/assets/images/code-samples/brightcove-player-sample-back-forward-buttons/back-button.png'
      );
      newImageRewindImg.setAttribute('style', 'width: 100% !important; height: 100% !important;');
      newElementRewind.appendChild(newImageRewindImg);
      jumpControlBar.insertBefore(newElementRewind, insertBeforeNodeByJumpingPlayTime);

      newElementRewind.addEventListener('click', () => {
        rewindVideoTime(initialTime);
      });
    }

    // 빨리 감기 버튼 추가
    if (!document.getElementById('fastForwardButton')) {
      const newElementFastForward = document.createElement('div');
      const newImageFastForwardImg = document.createElement('img');

      newElementFastForward.id = 'fastForwardButton';
      newElementFastForward.className = 'vjs-fullscreen-control vjs-control vjs-button';

      newImageFastForwardImg.setAttribute(
        'src',
        'https://player.support.brightcove.com/assets/images/code-samples/brightcove-player-sample-back-forward-buttons/forward-button.png'
      );
      newImageFastForwardImg.setAttribute('style', 'width: 100% !important; height: 100% !important;');
      newElementFastForward.appendChild(newImageFastForwardImg);
      jumpControlBar.insertBefore(newElementFastForward, insertBeforeNodeByJumpingPlayTime);

      newElementFastForward.addEventListener('click', () => {
        fastForwardVideoTime(initialTime);
      });
    }

    // video 재생 중
    const timer = setInterval(() => {
      if (!player.el_) {
        clearInterval(timer);
        return;
      }
      const valueNow = player.el_.querySelector('.vjs-progress-holder')?.getAttribute('aria-valuenow');
      const currentTime = player.el_.querySelector('.vjs-current-time-display')?.textContent;
      const duration = player.el_.querySelector('.vjs-duration-display')?.textContent;
      if (valueNowRef.current) valueNowRef.current.style.width = `${valueNow}%`;
      if (currentTimeRef.current) currentTimeRef.current.innerText = currentTime;
      if (durationRef.current) durationRef.current.innerText = duration;

      if (player.ended()) {
        clearInterval(timer);
      }
    }, 200);

    player.on('play', () => {
      if (video && player.currentTime() === 0) {
        callVidePlayGaEvent({
          gaEventType: 'customVideoPlay',
          dataContentType: '영상',
          title: video?.title as string,
          cateNm: video?.cateNm as string,
          creatorNm: video?.creatorNm as string,
          dataContentsLength: videoTimeFormat(video?.videoTotalTime),
          brightCoveId: video?.videoId as string,
        });
      }
    });

    player.on('ended', () => {
      callVidePlayGaEvent({
        gaEventType: 'customVideoDone',
        dataContentType: '영상',
        title: video?.title as string,
        cateNm: video?.cateNm as string,
        creatorNm: video?.creatorNm as string,
        dataContentsLength: videoTimeFormat(video?.videoTotalTime),
        brightCoveId: video?.videoId as string,
      });

      detailLeftRef.current?.classList.remove('is-play');

      updateTime(data.mediaId, 0);

      // 재생목록의 경우 다음 영상으로 이어보기
      playlist &&
        playlist.map((pl, idx) => {
          if (pl.mediaId === id && idx < playlist.length - 1) {
            setId(playlist[idx + 1].mediaId);
          }
        });
    });

    player.on('timeupdate', () => {
      const elapsedTime = player.currentTime();

      if (elapsedTime >= initialTime + ELAPSED_TIME_UPDATE_INTERVAL || elapsedTime < initialTime) {
        initialTime = elapsedTime;
        updateTime(data.mediaId, elapsedTime * 1000);
      }
    });
  };

  const isShowMoreButton = useCallback(() => {
    if (videoDetailData?.editorContents && !!videoDetailData?.editorContents?.length) return true;
    if (videoDetailData?.videoDurationTimeCodeList && !!videoDetailData?.videoDurationTimeCodeList?.length) return true;
    return !!(videoDetailData?.editorAssistantMemberInfo && !!videoDetailData?.editorAssistantMemberInfo?.length);
  }, [videoDetailData]);

  /**
   * 타임 스탬프 클릭 이벤트
   * @param timeStamp
   */
  const onClickTimeStampItemHandler = (timeStamp: moment.Moment) => {
    if (!vRef?.current || !timeStamp) return;
    const player = vRef.current.player;
    // Moment 객체를 초 단위로 변환
    const timeStampSeconds = timeStamp.hours() * 3600 + timeStamp.minutes() * 60 + timeStamp.seconds();
    const currentVideoDuration = player.duration();

    // 영상 전체 재생 시간을 초과할 경우, 마지막 시간대로 이동
    if (currentVideoDuration < timeStampSeconds) {
      // 포커스 영상 쪽으로 이동
      vRef.current.player.focus();
      // 재생 시간 변경
      player.currentTime(currentVideoDuration);
      updateTime(videoDetailData?.mediaId as string, currentVideoDuration);
      // 타임 스탬프 클릭 시, 영상이 멈춰 있을 경우 재생되도록 처리
      if (!detailLeftRef.current?.classList.contains('is-play')) {
        vRef.current.player.play();
        detailLeftRef.current?.classList.add('is-play');
      }
      return;
    }
    // 포커스 영상 쪽으로 이동
    vRef.current.player.focus();
    // 재생 시간 변경
    player.currentTime(timeStampSeconds);
    updateTime(videoDetailData?.mediaId as string, timeStampSeconds);
    // 타임 스탬프 클릭 시, 영상이 멈춰 있을 경우 재생되도록 처리
    if (!detailLeftRef.current?.classList.contains('is-play')) {
      vRef.current.player.play();
      detailLeftRef.current?.classList.add('is-play');
    }
  };

  return (
    <Maybe test={data.visible}>
      <>
        <div className={classNames('detail_wrap', { 'is-pip': data.pip })}>
          <div className="content_inner">
            <div className="detail_box">
              <div className={classNames('detail_left')} ref={detailLeftRef}>
                <div className="detail_con">
                  {video?.cateType === 'VIDEO' && video?.titlePosition === 'TOP' && (
                    <VideoDetailInfo
                      like={likeVideo}
                      openScript={openScript}
                      info={video}
                      bookmark={bookmarkVideo}
                      openSharePopup={() => setSharePopup(true)}
                    />
                  )}
                  {/* {(video?.cateType === 'NEWS' || video?.cateType === 'GROUP') && (
                    <DetailInfo
                      detailInfoData={video}
                      like={likeVideo}
                      openSharePopup={() => setSharePopup(true)}
                      mediaType="VIDEO"
                      openScript={openScript}
                    />
                  )} */}
                  <div className="detail_video">
                    <div className="brightcove">
                      {video && video.mediaId === id && (
                        <ReactPlayerLoader
                          accountId="5746988503001"
                          videoId={data.visible && video.videoId}
                          onSuccess={onSuccess}
                          ref={vRef}
                          // options={{ autoplay: 'muted' }}
                        />
                      )}
                    </div>
                    <a
                      className="detail_video_pip is-mobile"
                      onClick={() => {
                        document.querySelector('.container')?.classList.remove('detail');
                        document.querySelector('.container')?.classList.add('is-pip');
                        setPip(true);

                        if (returnCate.includes('series')) {
                          history.push(Paths.getPath('videoSeries').replace(':sclasId', sclasId));
                        } else if (returnCate !== 'group') {
                          history.push(Paths.getPath(`${returnCate}List`));
                        } else {
                          history.push('/');
                        }

                        if (vRef.current.refNode.querySelector('.vjs-play-control')?.getAttribute('title') === 'Pause')
                          detailLeftRef.current?.classList.add('is-play');
                        else detailLeftRef.current?.classList.remove('is-play');
                      }}
                    >
                      <img src="/assets/images/icon/ico-pip.png" alt="pip" />
                    </a>
                    <div className="detail_pip_hover is-pc">
                      <div className="detail_pip_hover_dim"></div>
                      <a
                        className="detail_pip_hover_return"
                        onClick={() => {
                          setPip(false);

                          if (returnCate === 'series') {
                            history.push(`${paths.getPath('videoDetail').replace(':mediaId', data.mediaId)}`);
                          } else if (returnCate === 'press') {
                            history.push(`${paths.getPath('pressDetail').replace(':mediaId', data.mediaId)}`);
                          } else if (returnCate === 'recommend') {
                            history.push(`${paths.getPath('recommendDetail').replace(':mediaId', data.mediaId)}`);
                          } else if (returnCate === 'news') {
                            history.push(`${paths.getPath('newsDetail').replace(':mediaId', data.mediaId)}`);
                          } else {
                            history.push(`${paths.getPath('videoDetail').replace(':mediaId', data.mediaId)}`);
                          }
                        }}
                      >
                        <img src="/assets/images/icon/ico-pip-return.png" alt="return" />
                      </a>
                      <a
                        className="detail_pip_hover_close"
                        onClick={() => {
                          setPip(false);
                          setVideoVisible(false);
                        }}
                      >
                        <img src="/assets/images/icon/ico-close-white.png" alt="close" />
                      </a>
                      <a
                        className="detail_pip_hover_play"
                        onClick={() => {
                          if (detailLeftRef.current?.classList.contains('is-play')) {
                            vRef.current.player.pause();
                            detailLeftRef.current?.classList.remove('is-play');
                          } else {
                            vRef.current.player.play();
                            detailLeftRef.current?.classList.add('is-play');
                          }
                        }}
                      >
                        <img src="/assets/images/icon/ico-pip-play.png" alt="play" className="pip_hover_btn is-play" />
                        <img
                          src="/assets/images/icon/ico-pip-pause.png"
                          alt="pause"
                          className="pip_hover_btn is-pause"
                        />
                      </a>
                      <span className="detail_pip_hover_time">
                        <span className="hover_time_now" ref={currentTimeRef}>
                          {videoPlayInfo.currentTime}
                        </span>
                        /
                        <span className="hover_time_all" ref={durationRef}>
                          {videoPlayInfo.duration}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="detail_pip">
                    <div
                      className="detail_pip_title"
                      onClick={() => {
                        if (isMobile) {
                          document.querySelector('.container')?.classList.add('detail');
                          document.querySelector('.container')?.classList.remove('is-pip');
                          setPip(false);

                          if (returnCate === 'series') {
                            history.push(`${paths.getPath('videoDetail').replace(':mediaId', data.mediaId)}`);
                          } else if (returnCate === 'press') {
                            history.push(`${paths.getPath('pressDetail').replace(':mediaId', data.mediaId)}`);
                          } else if (returnCate === 'recommend') {
                            history.push(`${paths.getPath('recommendDetail').replace(':mediaId', data.mediaId)}}`);
                          } else if (returnCate === 'news') {
                            history.push(`${paths.getPath('newsDetail').replace(':mediaId', data.mediaId)}}`);
                          } else {
                            history.push(`${paths.getPath('videoDetail').replace(':mediaId', data.mediaId)}}`);
                          }
                        }
                      }}
                    >
                      <span>{video?.title}</span>
                    </div>
                    <div className="detail_pip_control is-mobile">
                      <div
                        className="detail_pip_play"
                        onClick={() => {
                          if (detailLeftRef.current?.classList.contains('is-play')) {
                            vRef.current.player.pause();
                            detailLeftRef.current?.classList.remove('is-play');
                          } else {
                            vRef.current.player.play();
                            detailLeftRef.current?.classList.add('is-play');
                          }
                        }}
                      >
                        <button type="button" className="detail_pip_btn is-play">
                          <img src="/assets/images/icon/ico-pip-play-mo.png" alt="play" />
                        </button>
                        <button type="button" className="detail_pip_btn is-pause">
                          <img src="/assets/images/icon/ico-pip-pause-mo.png" alt="pause" />
                        </button>
                      </div>
                      <button
                        type="button"
                        className="detail_pip_close"
                        onClick={() => {
                          setPip(false);
                          setVideoVisible(false);
                        }}
                      >
                        <img src="/assets/images/icon/ico-pip-close-mo.png" alt="close" />
                      </button>
                    </div>
                    <div className="detail_pip_progress">
                      <span className="value" ref={valueNowRef}></span>
                    </div>
                  </div>
                  {video?.cateType === 'VIDEO' && video?.titlePosition === 'BOTTOM' && (
                    <VideoDetailInfo
                      like={likeVideo}
                      openScript={openScript}
                      info={video}
                      bookmark={bookmarkVideo}
                      openSharePopup={() => setSharePopup(true)}
                    />
                  )}
                  {(video?.cateType === 'NEWS' || video?.cateType === 'PRESS' || video?.cateType === 'GROUP') && (
                    <DetailInfo
                      detailInfoData={video}
                      like={likeVideo}
                      openSharePopup={() => setSharePopup(true)}
                      mediaType="VIDEO"
                      openScript={openScript}
                    />
                  )}
                  <div className="detail_desc is-appended" ref={detailRef}>
                    {/* 더보기 무조건 펼쳐져있도록 임시 수정 */}
                    {isShowMoreButton() && (
                      <a
                        className="detail_desc_more"
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          if (!detailRef.current?.classList.contains('is-appended')) {
                            detailRef.current?.classList.add('is-appended');
                            e.currentTarget.innerText = '닫기';
                          } else {
                            detailRef.current?.classList.remove('is-appended');
                            e.currentTarget.innerText = '더보기';
                          }
                        }}
                      >
                        {!detailRef.current?.classList.contains('is-appended') ? '더보기' : '닫기'}
                      </a>
                    )}
                    <ScriptContent
                      className="detail_desc_wrap"
                      innerClassName=""
                      detailDesc={detailDesc}
                      videoElements={videoElements}
                      mediaDetailData={videoDetailData}
                      onClickTimeStampItemHandler={onClickTimeStampItemHandler}
                    />
                  </div>
                  {videoDetailData && videoDetailData.cateType !== 'GROUP' && (
                    <DetailComment
                      contentsId={id}
                      commentPostType={CommentPostType.MEDIA}
                      contentCreatorNm={video?.creatorNm as string}
                      tags={video?.mediaTag as string}
                      title={video?.title as string}
                      category={video?.cateNm as string}
                      time={video?.videoTotalTime ? video.videoTotalTime : -1}
                      brightcoveId={video?.videoId}
                      isNews={video?.cateType === 'NEWS' || video?.cateType === 'PRESS'}
                      mediaContentType={video?.mediaContentType}
                    />
                  )}
                </div>
              </div>
              <div className="detail_right">
                {playlist && playlist.length > 0 && (
                  // 재생 목록인 경우
                  <div className="detail_right_con is-group is-open" ref={playlistRef}>
                    <Playlist
                      controlPlaylist={controlPlaylist}
                      playlist={playlist}
                      nowPlaying={id}
                      clickVideo={listId => {
                        window.scrollTo(0, 0);
                        setId(listId);
                        history.push(`${window.location.pathname}?videoId=${listId}`);
                      }}
                    />
                  </div>
                )}
                <div className="detail_right_con" ref={relationRef}>
                  <RelationContents related={related} cateType={video?.cateType} />
                </div>
                {/* <!-- 원고 --> */}
                <div className="detail_script" ref={scriptRef}>
                  <DetailScript closeScript={closeScript} scriptTitle={video?.title} scriptDesc={video?.videoScript} />
                </div>
                {/* <!-- /원고 --> */}
              </div>
            </div>
            <DetailBack />
          </div>
        </div>
        {sharePopup && (
          <SharePopup
            onClose={() => setSharePopup(false)}
            title={video?.title || ''}
            contentsId={id}
            contentsPostType={'MEDIA'}
          />
        )}
      </>
    </Maybe>
  );
};

export default React.memo(MainVideoPlayer, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
