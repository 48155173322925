export const MESSAGE_EVENT_IDS = [
  {
    environmentType: 'localhost',
    eventIds: [
      {
        eventId: 'EVT000000000000000000000080',
        messageEventType: 'ANNI75',
        linkUrl: '/event/anniversary75',
      },
      {
        eventId: 'EVT000000000000000000000330',
        messageEventType: 'CAMPAIGN1',
        linkUrl: '/event/campaign_1',
      },
    ],
  },
  {
    environmentType: 'h9development',
    eventIds: [
      {
        eventId: 'EVT000000000000000000000143',
        messageEventType: 'ANNI75',
        linkUrl: '/event/anniversary75',
      },
      {
        eventId: 'EVT000000000000000000000330',
        messageEventType: 'CAMPAIGN1',
        linkUrl: '/event/campaign_1',
      },
    ],
  },
  {
    environmentType: 'lgdevelopment',
    eventIds: [
      {
        eventId: 'EVT000000000000000000000134',
        messageEventType: 'ANNI75',
        linkUrl: '/event/anniversary75',
      },
      {
        eventId: 'EVT000000000000000000000138',
        messageEventType: 'CAMPAIGN1',
        linkUrl: '/event/campaign_1',
      },
    ],
  },
  {
    environmentType: 'production',
    eventIds: [
      {
        eventId: 'EVT000000000000000000000080',
        messageEventType: 'ANNI75',
        linkUrl: '/event/anniversary75',
      },
      {
        eventId: 'EVT000000000000000000000167',
        messageEventType: 'CAMPAIGN1',
        linkUrl: '/event/campaign_1',
      },
    ],
  },
];
