import React, { useState } from 'react';
import MyInfoContext from 'contexts/MyInfoContext';

const MyInfoProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isAppLogined, setIsAppLogined] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [actScore, setActScore] = useState(0);
  const [isAdmin, setIsAdmin] = useState('N');
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [groupNm, setGroupNm] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [department, setDepartment] = useState('');

  const init = () => {
    setIsLogin(false);
    setIsAppLogined(false);
    setProfileImage('');
    setActScore(0);
    setIsAdmin('');
    setUserId('');
    setName('');
    setGroupNm('');
    setPhoneNum('');
    setDepartment('');
  };

  const initialValue = {
    isLogin,
    setIsLogin,
    isAppLogined,
    setIsAppLogined,
    profileImage,
    setProfileImage,
    actScore,
    setActScore,
    isAdmin,
    setIsAdmin,
    userId,
    setUserId,
    name,
    setName,
    groupNm,
    setGroupNm,
    phoneNum,
    setPhoneNum,
    department,
    setDepartment,
    init,
  };

  return <MyInfoContext.Provider value={initialValue}>{children}</MyInfoContext.Provider>;
};

export default MyInfoProvider;
