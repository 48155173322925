import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import VideoContext from 'contexts/VideoContext';
import Paths from '../commons/paths';

const useVideo = () => {
  // const { data, mutate } = useSWR<any>('/video', { initialData: initialStore });
  const videoContext = useContext(VideoContext);
  const { pip, setPip, visible, setVisible, mediaId, setMediaId } = videoContext;

  const location = useLocation();

  useEffect(() => {
    if (
      !location.pathname.includes(`${Paths.getPath('videoDetail').replace('/:mediaId', '')}`) &&
      !location.pathname.includes(`${Paths.getPath('recommendDetail').replace('/:mediaId', '')}`) &&
      !location.pathname.includes(`${Paths.getPath('newsDetail').replace('/:mediaId', '')}`) &&
      !location.pathname.includes(`${Paths.getPath('pressDetail').replace('/:mediaId', '')}`) &&
      !location.pathname.includes(`${Paths.getPath('ESGDetail').replace('/:esgId', '')}`) &&
      !pip
      // !data.pip
    ) {
      // mutate({ ...data, visible: false });
      setVisible(false);
    }
  }, [location.pathname]);

  return {
    data: { pip, visible, mediaId },
    setPip: (pip: boolean) => {
      // mutate({ ...data, pip });
      setPip(pip);
    },
    setVideoVisible: (visible: boolean) => {
      // mutate({ ...data, visible });
      setVisible(visible);
    },
    setMediaId: (mediaId: string) => {
      // mutate({ ...data, mediaId });
      setMediaId(mediaId);
    },
    mutateVideoState: (newData: any) => {
      // mutate(newData);
      setPip(newData.pip);
      setVisible(newData.visible);
      setMediaId(newData.mediaId);
    },
    // mutate,
  };
};

export default useVideo;
