import React from 'react';
import { fetcher } from 'services';

const useMobileCheck = () => {
  const postMobileCheck = (domain: string, paramValue: string) => {
    return fetcher.auth.postMobileCheck({
      domain: domain,
      paramValue: paramValue,
    });
  };

  return {
    postMobileCheck,
  };
};

export default useMobileCheck;
