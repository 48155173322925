import { useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { anniversaryCalc, workPeriodCalc } from 'utils/utils';
import MyInfoContext from 'contexts/MyInfoContext';

const useMyInfo = () => {
  const myInfoContext = useContext(MyInfoContext);
  const { isLogin, setIsAppLogined } = myInfoContext;

  const setIsLogin = (isLogin, isApp = false) => {
    myInfoContext.setIsLogin(isLogin);
    setIsAppLogined(isApp);
  };

  const { data: info } = useSWR<UserDetailInfoResDto>(isLogin ? [endpoints.myPage.info, isLogin] : null, () =>
    fetcher.myPage.getInfo().then(res => {
      return res;
    })
  );

  const checkAnniversary = useMemo(() => {
    if (info) {
      const { hiredYmd, currentDt } = info;

      const isAnniversary = anniversaryCalc(hiredYmd, currentDt);
      const workPeriod = workPeriodCalc(hiredYmd, currentDt);

      if (Boolean(isAnniversary)) {
        return `${isAnniversary}주년`;
      } else if ([100, 1000, 3000, 5000, 10000].includes(workPeriod)) {
        return `${workPeriod}일`;
      }
    }
    return '';
  }, [info]);

  /**
   * 근무일 계산
   */
  const workDays = useMemo(() => {
    if (info) {
      const { hiredYmd, currentDt } = info;
      const workPeriod = workPeriodCalc(hiredYmd, currentDt);
      return `${workPeriod}`;
    }
    return '0';
  }, [info]);

  useEffect(() => {
    if (localStorage.getItem('llAccessToken') && location.pathname !== '/ep') {
      myInfoContext.setIsLogin(true);
    }
  });

  useEffect(() => {
    myInfoContext.setProfileImage(info?.profileImageUri as string);
    myInfoContext.setActScore(info?.actScore as number);
    myInfoContext.setIsAdmin(info?.adminYn as string);
    myInfoContext.setUserId(info?.userId as string);
    myInfoContext.setName(info?.name as string);
    myInfoContext.setGroupNm(info?.groupNm as string);
    myInfoContext.setPhoneNum(info?.phoneNum as string);
    myInfoContext.setDepartment(info?.department as string);
  }, [info]);

  return {
    setIsLogin,
    info,
    isLogin,
    isAppLogined() {
      return myInfoContext.isAppLogined;
    },
    checkAnniversary,
    workDays,
  };
};

export default useMyInfo;
