import auth from './auth';
import comment from './comment';
import common from './common';
import epPanel from './epPanel';
import main from './main';
import notice from './notice';
import videos from './videos';
import boards from './boards';
import media from './media';
import event from './event';
import faq from './faq';
import qna from './qna';
import recommends from './recommends';
import story from './story';
import myPage from './myPage';
import mobile from './mobile';
import search from './search';
import celebration from './celebration';
import topEatsBoards from './topEatsBoards';
import tempBoards from './tempBoards';
import popupInfo from './popupInfo';
import mentionsBoards from './mentionsBoards';
import executiveAppointment from './executiveAppointment';
import departmentBoards from './departmentBoards';
import societyBoards from './societyBoards';

export default {
  auth,
  comment,
  common,
  popupInfo,
  epPanel,
  main,
  notice,
  videos,
  boards,
  topEatsBoards,
  mentionsBoards,
  departmentBoards,
  societyBoards,
  tempBoards,
  media,
  event,
  faq,
  qna,
  recommends,
  story,
  myPage,
  mobile,
  search,
  celebration,
  executiveAppointment,
};
