import React, { LazyExoticComponent, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { CookiesProvider } from 'react-cookie';
import * as Pages from 'components/pages';
import { DefaultLayout } from 'components/templates';
import Paths from 'commons/paths';
import { mobileCheck } from 'utils/utils';

const Router = (): React.ReactElement => {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        errorRetryInterval: 3000,
      }}
    >
      <CookiesProvider>
        <Pages.ErrorBoundary>
          <DefaultLayout>
            <Suspense fallback={<div>{mobileCheck() && <div className="login_logo_text">로딩중...</div>}</div>}>
              <Switch>
                {Paths.flatPaths.map(p => {
                  const Component = p.component as LazyExoticComponent<any>;
                  return <Route exact key={p.key} path={p.key} component={(props: any) => <Component {...props} />} />;
                })}
              </Switch>
            </Suspense>
          </DefaultLayout>
        </Pages.ErrorBoundary>
      </CookiesProvider>
    </SWRConfig>
  );
};

export default Router;
