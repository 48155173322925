import classNames from 'classnames';
import { SortItem } from 'components/atoms/ControlDropdown';
import React, { useRef } from 'react';

interface ControlPopupProps {
  initial: string;
  initPop?: string;
  sortList: Array<SortItem>;
  isActive: boolean;
  clickSort: (s) => void;
  clickPop?: (p) => void;
  onClose: () => void;
}

const ControlPopup = (props: ControlPopupProps) => {
  const subListRef = useRef<HTMLUListElement>(null);

  return (
    <>
      <div
        className={`control_popup popup mobile_popup is-mobile ${props.isActive ? 'is-active' : ''}`}
        data-sort="filter"
      >
        <div className="popup_sort">
          <ul className="popup_sort_list">
            {props.sortList.map((s, idx) => (
              <li className={classNames(['popup_sort_item', { 'is-current': props.initial === s.data }])} key={idx}>
                <a
                  className={classNames('popup_sort_link', { popup_sort_sub: s.sub })}
                  onClick={() => {
                    if (!s.sub) {
                      props.clickSort(s.data);
                      subListRef.current?.style.setProperty('display', 'none');
                    } else {
                      subListRef.current?.style.setProperty('display', 'block');
                    }
                  }}
                >
                  {s.title}
                </a>
                {s.sub && (
                  <>
                    <ul className="popup_sort_list popup_sort_2depth" ref={subListRef}>
                      {s.sub.map((ss, idx) => (
                        <li
                          className={classNames('popup_sort_item popup_sort_2depth_item', {
                            'is-current': s.data === props.initial && ss.data === props.initPop,
                          })}
                          key={idx}
                        >
                          <a
                            className="popup_sort_link popup_sort_2depth_link"
                            onClick={() => {
                              props.clickSort(s.data);
                              if (props.clickPop) props.clickPop(ss.data);
                            }}
                          >
                            {ss.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </li>
            ))}
          </ul>
          <a className="popup_confirm" onClick={props.onClose}>
            확인
          </a>
          <button type="button" className="popup_close ico_close" onClick={props.onClose}></button>
        </div>
      </div>
    </>
  );
};

export default ControlPopup;
