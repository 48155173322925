import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useUserInfo, useUserRank } from 'hooks';
import ProfileImage from 'components/atoms/ProfileImage';
import GradeContext from 'contexts/GradeContext';

export interface UserInfo {
  actScore: number;
  email: string;
  groupNm: string;
  name: string;
  oneSentenceIntro: string;
  profileImageUri: string;
  resultCode: number;
  resultMessage: string;
}

export interface ProfilePopupProps {
  userId: string;
  close: () => void;
}

const ProfilePopup = (props: ProfilePopupProps) => {
  // const { getGrade } = useUserRank();
  const gradeContext = useContext(GradeContext);
  const { userInfoData } = useUserInfo(props.userId);
  const [user, setUser] = useState<UserInfoResDto>();

  useEffect(() => {
    if (userInfoData) setUser(userInfoData);
  }, [userInfoData]);

  const getGrade = (score: number) => {
    const list = gradeContext.gradeData.sort((a, b) => {
      if (a.stepNum && b.stepNum) return a.stepNum - b.stepNum;
      else return 0;
    });
    if (score <= (list[0].score as number)) {
      return 'bronze';
    } else if (score <= (list[1].score as number)) {
      return 'silver';
    } else if (score <= (list[2].score as number)) {
      return 'gold';
    } else if (score <= (list[3].score as number)) {
      return 'dia';
    } else return 'crown';
  };

  const grade = useMemo(() => getGrade(user?.actScore as number), [user?.actScore]);

  return (
    <>
      <div className="profile_popup popup is-active">
        <div className="popup_dim"></div>
        <div className="popup_profile">
          <div className="popup_profile_wrap">
            <div className="popup_profile_top">
              <div className="popup_profile_img">
                <ProfileImage name={user?.name || ''} score={user?.actScore as number} image={user?.profileImageUri} />
              </div>
              <div className="popup_profile_name">
                <span>{`${user?.name || ''} ${user?.groupNm || ''}`}</span>
                <img src={`/assets/images/icon/ico-grade-${grade}.png`} alt="" className="popup_profile_grade" />
              </div>
            </div>
            <div className="popup_profile_con">
              <div className="popup_profile_intro">한줄소개</div>
              <div className="popup_profile_desc">{user?.oneSentenceIntro}</div>
            </div>
            <a className="popup_profile_mail">{user?.email}</a>
            <button
              type="button"
              className="popup_close ico_close"
              onClick={e => {
                e.preventDefault();
                props.close();
              }}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePopup;
