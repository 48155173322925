import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Lottie from 'lottie-react';
import paths from 'commons/paths';
import annivJsonData from 'json/particle.json';
import { useMyInfo, useMyPage, useUserRank } from 'hooks';
import useOutsideClick from 'hooks/useOutsideClick';
import { workPeriodCalc } from 'utils/utils';
import ProfileImage from 'components/atoms/ProfileImage';
import GradeTooltip from 'components/molecules/GradeTooltip';
import TagPopup from '../../pages/Login/components/TagPopup';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import GradeContext from 'contexts/GradeContext';

const Intro = () => {
  const { briefInfo, putInterestKeyword } = useMyPage({});
  // const { getGrade, getNextRankScore } = useUserRank();
  const gradeContext = useContext(GradeContext);
  const { checkAnniversary, workDays } = useMyInfo();
  const rankGuideRef = useRef(null);

  const [tagPopupActive, setTagPopupActive] = useState(false);
  const [rankGuidePopupActive, setRankGuidePopupActive] = useState(false);
  const [intrKeywordList, setIntrKeywordList] = useState<string[]>([]);

  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');

  useOutsideClick(rankGuideRef, () => {
    if (rankGuidePopupActive) setRankGuidePopupActive(false);
  });

  const getGrade = (score: number) => {
    const list = gradeContext.gradeData.sort((a, b) => {
      if (a.stepNum && b.stepNum) return a.stepNum - b.stepNum;
      else return 0;
    });
    if (score <= (list[0].score as number)) {
      return 'bronze';
    } else if (score <= (list[1].score as number)) {
      return 'silver';
    } else if (score <= (list[2].score as number)) {
      return 'gold';
    } else if (score <= (list[3].score as number)) {
      return 'dia';
    } else return 'crown';
  };

  const getNextRankScore = (score: number) => {
    let nextRankScore = 0;
    const userRankList = gradeContext.gradeData;

    for (let i = 0, length = userRankList?.length; i < length; i += 1) {
      if (score < (userRankList[i].score as number)) {
        nextRankScore = userRankList[i].score as number;
        break;
      }
    }

    return nextRankScore || score;
  };

  const nextRankScore = useMemo(() => getNextRankScore(briefInfo?.actScore), [briefInfo?.actScore]);

  useEffect(() => {
    setIntrKeywordList(briefInfo?.intrKeyword?.slice(1).split('#'));
  }, [briefInfo?.intrKeyword]);

  if (!briefInfo) return <h1>Loading</h1>;

  return (
    <>
      <div className="mypage_intro">
        {Boolean(checkAnniversary) ? (
          <Lottie
            className={classNames({ mypage_anniv_bg: Boolean(checkAnniversary) })}
            animationData={annivJsonData}
            loop={true}
            autoplay={true}
          />
        ) : (
          <></>
        )}
        {/* </div> */}
        <div className="mypage_intro_wrap">
          <div className="mypage_profile">
            <div className="mypage_profile_inner">
              <div className="mypage_profile_img">
                <ProfileImage
                  image={briefInfo.profileImageUri}
                  name={briefInfo.name}
                  score={briefInfo.actScore}
                  isAdmin={briefInfo.adminYn === 'Y'}
                />
                <span className="mypage_profile_score is-mobile">
                  <span className="current">{briefInfo.actScore.toLocaleString()}</span>/
                  {nextRankScore.toLocaleString()}
                </span>
              </div>
              <div className="mypage_profile_con">
                <div className="mypage_profile_value">
                  <span className="mypage_profile_name">
                    <a
                      href="#rankGuide"
                      className="grade"
                      title="Rank Guide"
                      onClick={e => {
                        e.preventDefault();
                        setRankGuidePopupActive(!rankGuidePopupActive);
                      }}
                      onMouseEnter={() => {
                        setRankGuidePopupActive(true);
                      }}
                    >
                      {briefInfo.adminYn === 'N' ? (
                        <img src={`../assets/images/icon/ico-grade-${getGrade(briefInfo.actScore)}.png`} alt="" />
                      ) : (
                        <img src={'/assets/images/icon/ico-grade-admin.png'} />
                      )}
                    </a>
                    {briefInfo.name}
                    <span className="company">{briefInfo.groupNm}</span>
                  </span>
                  <span className="mypage_profile_score is-pc">
                    <span className="current">{briefInfo.actScore.toLocaleString()}</span>/
                    {nextRankScore.toLocaleString()}
                  </span>

                  {rankGuidePopupActive ? (
                    <GradeTooltip
                      active={rankGuidePopupActive}
                      setActive={setRankGuidePopupActive}
                      ref={rankGuideRef}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mypage_profile_desc">
                  {briefInfo.oneSentenceIntro || '본인을 한문장으로 소개해주세요.'}
                </div>
              </div>
            </div>
            <Link to={paths.getPath('edit')} className="mypage_profile_btn">
              회원정보 수정
            </Link>
          </div>
          <div className="mypage_day">
            <div className="mypage_day_inner">
              <div className="mypage_day_con">
                <div className="mypage_day_title">LG DAYS</div>
                <div className="mypage_day_desc">
                  {Boolean(checkAnniversary) ? `오늘은 LG와 함께한 지 ${checkAnniversary} 되는 날입니다.` : ''}
                </div>
              </div>
              <div className="mypage_day_value">
                <span className="em">+</span>
                {workDays}
              </div>
            </div>
          </div>
        </div>
        <div className="mypage_kyeword">
          <div className="mypage_kyeword_title">나의 관심 키워드</div>
          <div className="mypage_kyeword_box">
            <div className="mypage_kyeword_inner">
              <div className="write_tag_list">
                <button className="kyeword_add" type="button" onClick={() => setTagPopupActive(true)}>
                  <img src="../assets/images/icon/ico-plus-btn.png" alt="Add keyword" />
                </button>
                {intrKeywordList?.length > 0 ? (
                  intrKeywordList?.map((keyword: string, index: number) => (
                    <div className="write_tag_item" key={index}>
                      <span className="write_tag_text">{`#${keyword}`}</span>
                      <button
                        type="button"
                        className="remove_write_tag"
                        onClick={() => {
                          if (intrKeywordList.length < 2) {
                            setAlertPopContents('태그는 하나 이상 존재해야 합니다.');
                            setIsAlertPopupOpen(true);
                            return;
                          }
                          const temp = [...intrKeywordList];
                          temp.splice(index, 1);
                          setIntrKeywordList(temp);
                          putInterestKeyword(temp);
                        }}
                      >
                        <span className="ab_text">태그 삭제</span>
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="account_con_txt">
                    관심 태그를 등록해주세요.
                    <br className="is-mobile" />
                    <span className="txt-small">(관심 태그 등록시 콘텐츠 추천을 받으실 수 있습니다.)</span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TagPopup
        tagsFrom={intrKeywordList}
        suggestions={['신제품', '가전', 'ESG', '고객가치']}
        isPopupOpen={tagPopupActive}
        onClose={() => setTagPopupActive(false)}
        onSaveTags={intrKeywordList => {
          setIntrKeywordList(intrKeywordList);
          putInterestKeyword(intrKeywordList);
          setTagPopupActive(false);
        }}
      />
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
    </>
  );
};

export default Intro;
