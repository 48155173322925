import React, { useState, useEffect } from 'react';

interface AlertPopupProps {
  onClose: () => void;
}

const FontPopup = ({ onClose }: AlertPopupProps) => {
  const [smartfontPopupBtn1, setSmartfontPopupBtn1] = useState(false);
  const [smartfontPopupBtn2, setSmartfontPopupBtn2] = useState(false);

  useEffect(() => {
    document.querySelector('body')?.classList.add('fix-overflow');
    return () => {
      document.querySelector('body')?.classList.remove('fix-overflow');
    };
  }, []);

  return (
    <>
      <div className="popup lg_font_popup is-active">
        <div className="popup_dim"></div>
        <div className="popup_inner popup_large popup_mobile_full">
          <div className="popup_header">
            <button type="button" className="popup_close ico_close" onClick={onClose}>
              <span className="ab_text">닫기</span>
            </button>
          </div>
          <div className="popup_cont custom_scroll">
            <div className="lg_font_cont">
              <div
                className="lg_font_img"
                style={{ backgroundImage: `url('/assets/images/img/lg_font01.jpg')`, paddingBottom: '164.76%' }}
              ></div>
              <div
                className="lg_font_img"
                style={{ backgroundImage: `url('/assets/images/img/lg_font02.jpg')`, paddingBottom: '54.17%' }}
              >
                <a href="#" id="smartfontPopupBtn1" onClick={() => setSmartfontPopupBtn1(true)}></a>
              </div>
              <div
                className="lg_font_img"
                style={{ backgroundImage: `url('/assets/images/img/lg_font03.jpg')`, paddingBottom: '28.07%' }}
              >
                <a href="/files/template/document/LG_Smart_Font_Security.zip" id="smartfontDownload1" download></a>
              </div>
              <div
                className="lg_font_img"
                style={{ backgroundImage: `url('/assets/images/img/lg_font04.png')`, paddingBottom: '149.13%' }}
              >
                <a href="#" id="smartfontPopupBtn2" onClick={() => setSmartfontPopupBtn2(true)}></a>
                <a href="/files/template/document/LGSmartfont_v2.0_Manual.exe" id="smartfontDownload2" download></a>
                <a href="/files/template/document/LG_Smart_Font_v2.0_OTF_MAC.zip" id="smartfontDownload3" download></a>
                <a href="/files/template/document/LG_Smart_Font_Window_v20.zip" id="smartfontDownload4" download></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {smartfontPopupBtn1 && (
        <div id="smartfontPopup1" className="popup smartfont_sub_popup is-active">
          <div className="popup_inner">
            <div className="popup_header">
              <button type="button" className="popup_close ico_close" onClick={() => setSmartfontPopupBtn1(false)}>
                <span className="ab_text">닫기</span>
              </button>
            </div>
            <div className="popup_cont custom_scroll">
              <div
                className="smartfont_img"
                style={{
                  backgroundImage: `url('/assets/images/img/pop_smartfont_manually.png')`,
                  paddingBottom: '80.33%',
                }}
              ></div>
            </div>
          </div>
        </div>
      )}

      {smartfontPopupBtn2 && (
        <div id="smartfontPopup2" className="popup smartfont_sub_popup is-active">
          <div className="popup_inner">
            <div className="popup_header">
              <button type="button" className="popup_close ico_close" onClick={() => setSmartfontPopupBtn2(false)}>
                <span className="ab_text">닫기</span>
              </button>
            </div>
            <div className="popup_cont custom_scroll">
              <div
                className="smartfont_img"
                style={{
                  backgroundImage: `url('/assets/images/img/pop_smartfont_manually2.jpg')`,
                  paddingBottom: '89.66%',
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FontPopup;
