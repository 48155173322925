import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { useState } from 'react';
import useMyInfo from './useMyInfo';
import { PAGE_ITEM_COUNT } from 'commons/constants';

const useRecommends = (recommendHookProps?: {
  /** 한 페이지에 보여줄 아이템 개수 */
  pageItemSize?: number;
}) => {
  const { info } = useMyInfo();
  const [searchOptions, setSearchOptions] = useState<any>({
    size: recommendHookProps?.pageItemSize || PAGE_ITEM_COUNT,
    sort: 'postDt,desc',
  });

  const { data } = useSWR<any>(info ? [endpoints.recommends.recommends, searchOptions] : null, () =>
    fetcher.recommends.getRecommends(searchOptions).then(res => {
      return res;
    })
  );

  const { data: keywordData } = useSWR<any>(info ? endpoints.recommends.keywords : null, () =>
    fetcher.recommends.getRecommendKeywords().then(res => res)
  );

  return { data, searchOptions, setSearchOptions, keywordData };
};

export default useRecommends;
