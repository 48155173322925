import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { stringify } from 'query-string';
import ContentCard, { ContentCardProps } from 'components/molecules/ContentCard';
import { all_click_datalayer_push, getContentsGAProps, getGAClickProps } from 'utils/GAUtils';
import Paths from 'commons/paths';

interface MainAdProps {
  adList: Array<MediaCardResDto>;
  currentDt: string;
}

const MainAd = (props: MainAdProps) => {
  const history = useHistory();
  const { adList } = props;
  const [screenSize, setScreenSize] = useState('lg');

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderingCard = (ad: ContentCardProps, className: string, idx: number) => (
    <ContentCard
      {...ad}
      isAd={true}
      path={Paths.getPath('videoDetail').replace(':mediaId', ad.mediaId as string)}
      isSwiperSlide={className !== 'card'}
      key={ad.mediaId}
      GAObj={getContentsGAProps({
        contentsType: 'MEDIA',
        title: ad.title as string,
        tags: ad.mediaTag as string,
        category: ad.cateNm as string,
        dataContentBelong: 'LGADs',
        maker: ad.creatorNm as string,
        time: ad.videoTotalTime,
        brightcoveId: ad.videoId as string,
        mediaContentsType: ad.mediaContentsType as string,
        dataContentUrl: `${Paths.getPath('videoDetail').replace(':mediaId', ad.mediaId as string)}`,
        dataImgUrl: `${ad.thumbImageUri as string}`,
      })}
    />
  );

  return (
    <>
      <div className="main_section ad">
        <div className="main_inner">
          <div className="con_top">
            <h3 className="con_top-title">LG ADs</h3>
            <a
              href="#"
              className="con_top-link"
              {...getGAClickProps('모두보기', 'LGADs')}
              onClick={e => {
                e.preventDefault();
                history.push({
                  pathname: Paths.getPath('videoList'),
                  search: stringify({ keyword: 'LGADs' }),
                });
                all_click_datalayer_push(e.currentTarget);
              }}
            >
              모두보기
            </a>
          </div>
        </div>
        <div className="main_row">
          {screenSize === 'lg' ? (
            <div className="card_row is-small has-hover adSwiper">
              {adList.map((ad, idx) => renderingCard({ ...ad, currentDt: props.currentDt }, 'card', idx))}
            </div>
          ) : (
            <Swiper
              className="card_row is-small adSwiper"
              slidesPerView={1.4}
              threshold={10}
              breakpoints={{ 768: { slidesPerView: 3.1 } }}
            >
              {adList.map((ad, idx) => (
                <SwiperSlide className="card" key={idx}>
                  {renderingCard({ ...ad, currentDt: props.currentDt }, '', idx)}
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default MainAd;
