import { useState } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { sortAsc } from 'utils/LGLifeUtils';
import useMyInfo from './useMyInfo';

const useQna = () => {
  const [qnaId, setQnaId] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const { info } = useMyInfo();

  const qnaUrl = qnaId => endpoints.qna.qnaDetail.replace('{qnaId}', qnaId);

  let { data: category } = useSWR<CategoryListResDto>([endpoints.common.category, 'QNA'], () =>
    fetcher.common.getCategory({ cateType: 'QNA' }).then(res => {
      return res;
    })
  );

  let { data: qnas } = useSWR<QnaListResDto>(info ? [endpoints.qna.qna, page] : null, () =>
    fetcher.qna
      .getQnas({
        page: page,
        popularitySortType: 'ALL',
        size: 10,
        sort: 'postDt,desc',
      })
      .then(res => {
        return res;
      })
  );

  if (category && category.categoryList) {
    category = sortAsc(category.categoryList);
  }

  let { data: qna } = useSWR<QnaDetailResDto>(qnaId ? [qnaUrl(qnaId), qnaId] : null, url =>
    fetcher.qna.getQna(url, { qnaId: qnaId }).then(res => {
      return res;
    })
  );

  return {
    qnas,
    category: category as CategoryResDto[],
    setQnaId,
    qna,
    page,
    setPage,
    postQnaWrite(title: string, contents: string, cateId: string, attachFile?: File) {
      return fetcher.qna.postQnaWrite({
        attachFile,
        cateId,
        contents,
        title,
      });
    },
    putQnaUpdate(
      qnaId: string,
      title: string,
      contents: string,
      cateId: string,
      attachFileId: string,
      attachFile?: File
    ) {
      return fetcher.qna.putQnaEdit(qnaUrl(qnaId), {
        qnaId,
        cateId,
        title: title.trim(),
        contents: contents.trim(),
        attachFileId,
        attachFile,
      });
    },
    deleteQna(qnaId: string) {
      return fetcher.qna.deleteQna(qnaUrl(qnaId), {
        qnaId: qnaId,
      });
    },
    postQnaNonLoginWrite(
      title: string,
      contents: string,
      cateId: string,
      nonLoginEmail: string,
      nonLoginName: string,
      nonLoginPhoneNum: string,
      attachFile?: File
    ) {
      return fetcher.qna.postQnaNonLogin({
        title: title.trim(),
        contents: contents.trim(),
        cateId,
        attachFile,
        nonLoginEmail: nonLoginEmail.trim(),
        nonLoginName: nonLoginName.trim(),
        nonLoginPhoneNum: nonLoginPhoneNum.trim(),
      });
    },
  };
};

export default useQna;
