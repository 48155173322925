import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

export interface AccountSelectProps {
  initial: string;
  selectedIndex: number | undefined;
  list: (string | undefined)[] | undefined;
  onClickItem: (index) => void;
}

const AccountSelect = ({ initial, selectedIndex, list, onClickItem }: AccountSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('click', onClickWindow);

    return () => {
      window.removeEventListener('click', onClickWindow);
    };
  }, []);

  const onClickWindow = e => {
    const className = e.target.classList[0];

    if (className === 'account_label') {
      return;
    }

    setIsOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className={classNames(['account_label', { 'is-active': isOpen }])}
        disabled={!list}
        onClick={e => {
          if (!isOpen) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        }}
      >
        {selectedIndex === -1
          ? initial
          : list &&
            list.map((title, index) => {
              if (selectedIndex === index) return title;
            })}
      </button>
      <ul className={classNames(['account_select custom_scroll', { 'is-active': isOpen }])}>
        {list &&
          list.map((item, index) => (
            <li key={index}>
              <a
                href="#;"
                id="position_info"
                className="account_select_item"
                onClick={e => {
                  e.preventDefault();
                  onClickItem(index);
                }}
              >
                {item}
              </a>
            </li>
          ))}
      </ul>
    </>
  );
};

export default AccountSelect;
