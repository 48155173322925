import React, { useEffect, useMemo, useState } from 'react';
import Swiper from 'react-id-swiper';
import SwiperCore, { Navigation } from 'swiper';
import { imageUrl, relativeTime, zeroStringFormat } from 'utils/utils';
import { all_click_datalayer_push, getContentsGAProps, getEventGAProps } from 'utils/GAUtils';
import { MESSAGE_EVENT_IDS } from '../../../Event/constants';
import { GET_LOADED_ENVIRONMENT_TYPE } from '../../../../../commons/constants';
import Paths from '../../../../../commons/paths';
import useExecutiveAppointment from '../../../../../hooks/useExecutiveAppointment';
import { useHistory } from 'react-router-dom';

SwiperCore.use([Navigation]);

interface HeroProps extends MainHeroListResDto {
  currentDt: string;
}

/** 내년 신년사 컨텐츠 id 목록 (고정) */
const PINNED_NEWYEAR_CONTENTS_ID_LIST = [
  {
    environmentType: 'localhost',
    mediaId: '712',
  },
  {
    environmentType: 'h9development',
    mediaId: '712',
  },
  {
    environmentType: 'lgdevelopment',
    mediaId: 'MED000000000000000000000210',
  },
  {
    environmentType: 'production',
    mediaId: 'MED000000000000000000002682',
  },
];

/** 고정 노출할 LG 임원 인사 HOME HERO ID 목록 */
const PINNED_EXECUTIVE_APPOINTMENT_HOME_HERO_ID_LIST = [
  /** HOME 1번 슬롯 */
  {
    slowNum: 1,
    targetHeroList: [],
  },
  /** HOME 2번 슬롯 */
  {
    slowNum: 2,
    targetHeroList: [
      {
        environmentType: 'localhost',
        heroId: 'HER000000000000000000000302',
      },
      {
        environmentType: 'h9development',
        heroId: 'HER000000000000000000000302',
      },
      {
        environmentType: 'lgdevelopment',
        heroId: 'HER000000000000000000000360',
      },
      {
        environmentType: 'production',
        heroId: 'HER000000000000000000002832',
      },
    ],
  },
  /** HOME 3번 슬롯 */
  {
    slowNum: 3,
    targetHeroList: [
      {
        environmentType: 'localhost',
        heroId: 'HER000000000000000000000614',
      },
      {
        environmentType: 'h9development',
        heroId: 'HER000000000000000000000614',
      },
      {
        environmentType: 'lgdevelopment',
        heroId: 'HER000000000000000000000359',
      },
      {
        environmentType: 'production',
        heroId: 'HER000000000000000000002833',
      },
    ],
  },
];

const Hero = (props: HeroProps) => {
  const { mainHeroList } = props;
  const history = useHistory();
  const { getExecutiveAppointmentYearDisplayInfo } = useExecutiveAppointment();
  const { data: executeAppointmentDisplayInfo } = getExecutiveAppointmentYearDisplayInfo();

  const [screenSize, setScreenSize] = useState('');

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1440) {
      setScreenSize('xl');
    } else if (width >= 1024) {
      setScreenSize('lg');
    } else {
      setScreenSize('sm');
    }
  };

  /** 신년사 그룹 행사 컨텐츠 여부 */
  const isNewYearLgContents = useMemo(() => {
    if (!mainHeroList || !mainHeroList?.length) return false;
    return PINNED_NEWYEAR_CONTENTS_ID_LIST.some(
      item =>
        item.environmentType === GET_LOADED_ENVIRONMENT_TYPE() &&
        mainHeroList?.some(hero => hero.contentsId === item.mediaId)
    );
  }, [GET_LOADED_ENVIRONMENT_TYPE(), mainHeroList]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  if (!mainHeroList) {
    return <></>;
  }

  const messageEventIds = MESSAGE_EVENT_IDS.find(data => data.environmentType === GET_LOADED_ENVIRONMENT_TYPE());

  /**
   * 신년사 태그(NEWYEARLG) 포함 되어 있는지 여부
   * @param heroResDto 히어로 컨텐츠
   */
  const isContainNewYearTag = (heroResDto: HeroAreaResDto) => {
    if (heroResDto.tag && heroResDto.tag?.includes('#')) {
      const tagList = heroResDto.tag?.split('#')?.filter(Boolean) || [];
      return tagList.some(tag => tag === 'NEWYEARLG');
    }
    return false;
  };

  /**
   * 신년사 태그(NEWYEARLG) 포함 되어 있거나, 하드 코딩된 ID가 있을 경우 좋아요/조회수/댓글수 미노출 여부
   */
  const isHideReadLikeCommentCnt = (heroResDto: HeroAreaResDto) => {
    if (isContainNewYearTag(heroResDto)) return true;
    return isNewYearLgContents;
  };

  /**
   * 임원 인사 HOME 고정 배너 HERO ID 여부
   * @param slotNum 노출 배너 번호
   * @param heroId 히어로 ID
   */
  const isExecutiveAppointmentContents = ({ slotNum, heroId }: { slotNum: number; heroId: string }): boolean => {
    if (!heroId) return false;

    const staticHeroBannerList = PINNED_EXECUTIVE_APPOINTMENT_HOME_HERO_ID_LIST.find(
      item => item.slowNum === slotNum
    )?.targetHeroList;

    if (!staticHeroBannerList) return false;

    return staticHeroBannerList
      .filter(targetHero => targetHero.environmentType === GET_LOADED_ENVIRONMENT_TYPE())
      .some(hero => hero.heroId === heroId);
  };

  /**
   * 임원 인사 OnClick 이벤트
   */
  const onClickExecutiveAppointmentLink = () => {
    history.push(Paths.getPath('person') + '?yearId=' + executeAppointmentDisplayInfo?.yearId);
  };

  return (
    <>
      <Swiper
        {...params}
        rebuildOnUpdate
        containerClass="hero_swiper"
        centeredSlides={true}
        slidesPerView={'auto'}
        spaceBetween={12}
        speed={900}
        loop={true}
        loopedSlides={3}
        loopAdditionalSlides={1}
        threshold={screenSize !== 'sm' ? 70 : 10}
        navigation={{
          nextEl: '.swiper-button-next.is-pc',
          prevEl: '.swiper-button-prev.is-pc',
        }}
        pagination={<div></div>}
        breakpoints={{
          1024: {
            // break point보다 큰 사이즈에 적용 width > 1024
            slidesPerView: 1.148,
            spaceBetween: 12,
          },
          1440: {
            // width > 1440
            slidesPerView: 'auto',
            spaceBetween: 12,
          },
        }}
      >
        {mainHeroList ? (
          mainHeroList.map((hero, idx) => {
            // 75주년 보드 이벤트 임시 덮어쓰기
            if (messageEventIds && messageEventIds.eventIds.find(e => e.eventId === hero.contentsId)) {
              return (
                <div key={idx}>
                  <div className="hero_item">
                    <a
                      className="hero_thumb"
                      style={{
                        backgroundImage: `url('${imageUrl(hero.thumbImageUri)}')`,
                      }}
                      {...(hero.contentsType === 'MEDIA' || (hero?.contentsType as string) === 'NEWS'
                        ? getContentsGAProps({
                            contentsType: hero?.contentsType as string,
                            title: hero?.title as string,
                            tags: hero?.tag as string,
                            category: 'heroes',
                            dataContentBelong: 'hero 배너',
                            maker: hero?.creatorNm as string,
                            time: hero?.videoTotalTime,
                            brightcoveId: hero?.videoId as string,
                            mediaContentsType: hero?.mediaContentsType as string,
                            dataContentUrl: `${hero.linkUri}`,
                            dataImgUrl: `${hero.thumbImageUri as string}`,
                          })
                        : getEventGAProps({
                            type: hero.eventType as string,
                            url: window.location.hostname + hero.linkUri,
                            title: hero?.title as string,
                            dataContentBelong: 'hero 배너',
                            dataImgUrl: hero.thumbImageUri,
                          }))}
                      href={
                        messageEventIds?.eventIds.find(e => e.eventId === hero.contentsId)?.linkUrl ||
                        Paths.getPath('eventList')
                      }
                      onClick={e => {
                        all_click_datalayer_push(e.currentTarget);
                      }}
                    >
                      <div className="hero_dim is-pc"></div>
                      {hero.contentsType === 'MEDIA' && hero.mediaContentsType === 'VIDEO' && (
                        <div className="hero_play">
                          <img src="assets/images/icon/ico-action-32-play.svg" alt="play" />
                        </div>
                      )}
                      <div className="hero_sub">
                        <div className="hero_sub_record">
                          <div className="hero_sub_date is-pc">{relativeTime(hero.postDt, props.currentDt)}</div>
                        </div>
                        <div className="hero_page">
                          <span className="is-current">{zeroStringFormat(idx + 1, 2)}</span> / 03
                        </div>
                      </div>
                    </a>
                    <div className="hero_info">
                      <h3 className="hero_title">{hero.title}</h3>
                    </div>
                  </div>
                </div>
              );
            }
            if (
              !!executeAppointmentDisplayInfo?.yearId &&
              isExecutiveAppointmentContents({ slotNum: hero.slotNum as number, heroId: hero.heroId as string })
            ) {
              return (
                <div key={idx}>
                  <div className="hero_item">
                    <a
                      className="hero_thumb"
                      style={{
                        backgroundImage: `url('/assets/images/sample/hero04.png')`,
                        cursor: 'pointer',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        onClickExecutiveAppointmentLink();
                      }}
                    >
                      <div className="hero_dim is-pc"></div>
                      <div className="hero_sub">
                        <div className="hero_page">
                          <span className="is-current">{zeroStringFormat(idx + 1, 2)}</span> / 03
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              );
            }
            return (
              <div key={idx}>
                <div className="hero_item">
                  <a
                    className="hero_thumb"
                    style={{
                      backgroundImage: `url('${imageUrl(hero.thumbImageUri)}')`,
                    }}
                    {...(hero.contentsType === 'MEDIA' || (hero?.contentsType as string) === 'NEWS'
                      ? getContentsGAProps({
                          contentsType: hero?.contentsType as string,
                          title: hero?.title as string,
                          tags: hero?.tag as string,
                          category: 'heroes',
                          dataContentBelong: 'hero 배너',
                          maker: hero?.creatorNm as string,
                          time: hero?.videoTotalTime,
                          brightcoveId: hero?.videoId as string,
                          mediaContentsType: hero?.mediaContentsType as string,
                          dataContentUrl: `${hero.linkUri}`,
                          dataImgUrl: `${hero.thumbImageUri as string}`,
                        })
                      : getEventGAProps({
                          type: hero.eventType as string,
                          url: window.location.hostname + hero.linkUri,
                          title: hero?.title as string,
                          dataContentBelong: 'hero 배너',
                          dataImgUrl: hero.thumbImageUri,
                        }))}
                    href={hero.linkUri || '/'}
                    onClick={e => {
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  >
                    <div className="hero_dim is-pc"></div>
                    {hero.contentsType === 'MEDIA' && hero.mediaContentsType === 'VIDEO' && (
                      <div className="hero_play">
                        <img src="assets/images/icon/ico-action-32-play.svg" alt="play" />
                      </div>
                    )}
                    <div className="hero_sub">
                      <div className="hero_sub_record">
                        {/* 신년사 조회수/댓글/좋아요 미노출 */}
                        {/* 75주년 이벤트 수정 사항 */}
                        {!isHideReadLikeCommentCnt(hero) && (
                          <>
                            <ul className="card_record">
                              <li>
                                <img src="assets/images/icon/ico-action-20-view.svg" alt="views" />
                                {hero.readCnt}
                              </li>
                              <li>
                                <img src="assets/images/icon/ico-comments.png" alt="comments" />
                                {hero.commentCnt}
                              </li>
                              <li>
                                <img src="assets/images/icon/ico-likes.png" alt="likes" />
                                {hero.likeCnt}
                              </li>
                            </ul>
                          </>
                        )}
                        <div className="hero_sub_date is-pc">{relativeTime(hero.postDt, props.currentDt)}</div>
                      </div>
                      <div className="hero_page">
                        <span className="is-current">{zeroStringFormat(idx + 1, 2)}</span> / 03
                      </div>
                    </div>
                  </a>
                  <div className="hero_info">
                    <h3 className="hero_title">{hero.title}</h3>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div></div>
            <div></div>
            <div></div>
          </>
        )}
      </Swiper>
    </>
  );
};

Hero.displayName = 'Hero';

export default React.memo(Hero);
