import React from 'react';
import { Link } from 'react-router-dom';
import paths from 'commons/paths';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMyPage } from 'hooks';
import { getContentsGAProps } from 'utils/GAUtils';
import { ContentCard } from 'components/molecules';
import { ScreenSize } from 'components/pages/MyPage';

const MEDIA_COUNT_TO_SHOW = '4';

interface RowContentsProps {
  screenSize: ScreenSize;
  currentDt: string;
  list?: MediaCardResDto[];
  count?: number;
  getTimeLine: (mediaId?: string) => number | undefined;
}

const RowContents = ({ screenSize, currentDt, list = [], count = 0, getTimeLine }: RowContentsProps) => {
  if (count <= 0) return <div className="mypage_empty">찜한 콘텐츠가 없습니다</div>;

  return (
    <>
      {screenSize === 'lg' ? (
        <div className="card_row is-small has-hover storeSwiper">
          <div className="card_wrapper">
            {list?.map((media: MediaCardResDto) => (
              <ContentCard
                {...media}
                currentDt={currentDt}
                timeline={getTimeLine(media.mediaId)}
                path={paths.getPath('videoDetail').replace(':mediaId', media.mediaId || '')}
                isSwiperSlide={false}
                key={media.mediaId}
                GAObj={getContentsGAProps({
                  contentsType: media.contentsType as string,
                  title: media.title as string,
                  tags: media.mediaTag as string,
                  category: media.cateNm as string,
                  dataContentBelong: '마이페이지/찜한 콘텐츠',
                  maker: media.creatorNm as string,
                  time: media.videoTotalTime,
                  brightcoveId: media.videoId as string,
                  mediaContentsType: media.mediaContentsType as string,
                  dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', media.mediaId || '')}`,
                  dataImgUrl: `${media?.thumbImageUri as string}`,
                })}
              />
            ))}
          </div>
        </div>
      ) : (
        <Swiper
          className="card_row is-small storeSwiper"
          slidesPerView={1.4}
          threshold={70}
          breakpoints={{ 768: { slidesPerView: 3.1 } }}
        >
          {list?.map((media: MediaCardResDto) => (
            <SwiperSlide className="card" key={media.mediaId}>
              <ContentCard
                {...media}
                currentDt={currentDt}
                timeline={getTimeLine(media.mediaId)}
                path={paths.getPath('videoDetail').replace(':mediaId', media.mediaId || '')}
                isSwiperSlide={true}
                key={media.mediaId}
                GAObj={getContentsGAProps({
                  contentsType: media.contentsType as string,
                  title: media.title as string,
                  tags: media.mediaTag as string,
                  category: media.cateNm as string,
                  dataContentBelong: '마이페이지/찜한 콘텐츠',
                  maker: media.creatorNm as string,
                  time: media.videoTotalTime,
                  brightcoveId: media.videoId as string,
                  mediaContentsType: media.mediaContentsType as string,
                  dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', media.mediaId || '')}`,
                  dataImgUrl: `${media?.thumbImageUri as string}`,
                })}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

interface Props {
  screenSize?: ScreenSize;
  currentDt?: string;
  getTimeLine?: (mediaId?: string) => number | undefined;
}

const BookmarkSection = ({ screenSize = 'lg', currentDt = new Date().toString(), getTimeLine = () => 0 }: Props) => {
  const { bookmarks, bookmarksIsValidating } = useMyPage({ contentsCnt: MEDIA_COUNT_TO_SHOW });

  if (bookmarksIsValidating) return <></>;

  return (
    <div className="mypage_section">
      <div className="mypage_inner">
        <div className="mypage_top">
          <h3 className="mypage_top_title">찜한 콘텐츠</h3>
          {(bookmarks?.bookmarkCnt || 0) > parseInt(MEDIA_COUNT_TO_SHOW) ? (
            <Link to={`${paths.getPath('bookmark')}`} className="mypage_top_link">
              모두보기
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="search_row">
        <RowContents
          screenSize={screenSize}
          currentDt={currentDt}
          list={bookmarks?.mediaBookmarkList}
          count={bookmarks?.bookmarkCnt}
          getTimeLine={getTimeLine}
        />
      </div>
    </div>
  );
};

export default BookmarkSection;
