import React, { useState } from 'react';
import LoggingContext from 'contexts/LoggingContext';

const LoggingProvider = ({ children }) => {
  const [lastLoggingPage, setLastLoggingPage] = useState('');

  const initialValue = {
    lastLoggingPage: lastLoggingPage,
    setLastLoggingPage: setLastLoggingPage,
  };

  return <LoggingContext.Provider value={initialValue}>{children}</LoggingContext.Provider>;
};

export default LoggingProvider;
