import classNames from 'classnames';
import useMember from 'hooks/useMember';
import React, { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { all_click_datalayer_push, callMenuGAEvent, getGAMenuClickProps } from '../../../utils/GAUtils';

export interface SearchMemPopupProps {
  isActive: boolean;
  searchWord?: string;
  onClose: () => void;
}

const SearchMemPopup = (props: SearchMemPopupProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const { memberData, companiesData, setSearchOptions, searchOptions } = useMember();
  const [memberList, setMemberList] = useState([] as CicResDto[]);
  const [companies, setCompanies] = useState<GroupCompanyListResDto>();
  const [company, setCompany] = useState<GroupCompanyResDto>();
  const selectboxRef = useRef<HTMLDivElement>(null);
  const selectlistRef = useRef<HTMLUListElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  /** selectOpt class Inline style 적용 */
  const selectOptInlineStyle = useMemo(() => {
    if (!memberList?.length) return {};
    return { transform: 'none' };
  }, [memberList]);

  useEffect(() => {
    if (props.searchWord && props.isActive) {
      setSearchOptions({ ...searchOptions, searchWord: props.searchWord });
      if (searchInputRef.current) searchInputRef.current.value = props.searchWord;
    }
  }, [props.isActive]);

  useEffect(() => {
    if (memberData) setMemberList(memberData.cicList || []);
  }, [memberData]);

  useEffect(() => {
    if (companiesData) setCompanies(companiesData);
  }, [companiesData]);

  useEffect(() => {
    if (company) {
      if (company?.groupNm) setSearchOptions({ ...searchOptions, groupCode: company.groupCode as string });
      else {
        setSearchOptions({ ...searchOptions, groupCode: '' });
      }
    }
  }, [company]);

  // useEffect(() => {
  //   if (props.isActive) popupRef.current?.classList.add('is-active');
  //   else popupRef.current?.classList.remove('is-active');
  // });

  const onKeyPressInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const onSearch = () => {
    callMenuGAEvent('customMemberSearch', { dataClickName: '임직원 검색' });
    setSearchOptions({ ...searchOptions, searchWord: searchInputRef.current?.value.trim() || '' });
  };

  return (
    <>
      <div className={classNames(['popup', 'search_membs_popup', { 'is-active': props.isActive }])} ref={popupRef}>
        <div className="popup_dim"></div>
        <div className="popup_inner popup_large popup_mobile_full">
          <div className="popup_header">
            <button
              type="button"
              className="popup_back_button ico_back is-mobile"
              {...getGAMenuClickProps('POPUP', {
                dataClick: 'memberSearch',
                dataclickname: '임직원 검색',
              })}
              onClick={e => {
                if (searchInputRef.current) searchInputRef.current.value = '';
                setCompany({});
                setSearchOptions({ groupCode: '', searchWord: '' });
                props.onClose();
                all_click_datalayer_push(e.currentTarget);
              }}
            />
            <strong className="popup_title">임직원 검색</strong>
          </div>
          <div className="popup_cont">
            <p className="popup_desc">
              LG LIFE를 통해 습득한 회사와 임직원 관련 정보는 외부에 유출하거나 사적인 용도로 사용할 수 없습니다.
              <br />
              이를 위반하여 법적인 문제가 발생할 경우, 민/형사상 법적인 책임 및 처벌을 받을 수 있습니다.
            </p>
            {/* <!-- search input --> */}
            <div className="search_group">
              <div className="selectbox selectbox_small" ref={selectboxRef}>
                <a
                  className="selectbox_label"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (selectboxRef.current?.classList.contains('is-active')) {
                      selectlistRef.current?.style.setProperty('display', 'none');
                      selectboxRef.current?.classList.remove('is-active');
                    } else {
                      selectlistRef.current?.style.setProperty('display', 'block');
                      selectboxRef.current?.classList.add('is-active');
                    }
                  }}
                >
                  {company?.groupNm ? company.groupNm : '전체 계열사'}
                </a>
                {/* <!-- selectbox : pc --> */}
                <ul className="select_list" ref={selectlistRef}>
                  <li className={classNames('select_item', { 'is-selected': !company?.groupNm })}>
                    <a
                      className={'select_opt'}
                      style={selectOptInlineStyle}
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setCompany({});
                        selectlistRef.current?.style.setProperty('display', 'none');
                        selectboxRef.current?.classList.remove('is-active');
                      }}
                    >
                      전체 계열사
                    </a>
                  </li>
                  {companies?.groupCompanyList &&
                    companies.groupCompanyList
                      .sort((a, b) => {
                        if (a.orderNum && b.orderNum) return a.orderNum - b.orderNum;
                        else return 0;
                      })
                      .map((c, idx) => (
                        <li
                          className={classNames('select_item', { 'is-selected': c.groupNm === company?.groupNm })}
                          key={idx}
                        >
                          <a
                            className={'select_opt'}
                            style={selectOptInlineStyle}
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              setCompany(c);
                              selectlistRef.current?.style.setProperty('display', 'none');
                              selectboxRef.current?.classList.remove('is-active');
                            }}
                          >
                            {c.groupNm}
                          </a>
                        </li>
                      ))}
                </ul>
                {/* <!-- selectbox : mobile --> */}
                <select
                  className="select_mobile is-mobile"
                  defaultValue={'전체 계열사'}
                  onChange={e => {
                    if (e.target.value === 'ALL') setCompany({});
                    else setCompany(JSON.parse(e.target.value));
                  }}
                >
                  <option value="ALL" className={'select_opt'} style={selectOptInlineStyle}>
                    전체 계열사
                  </option>
                  {companies?.groupCompanyList &&
                    companies.groupCompanyList
                      .sort((a, b) => {
                        if (a.orderNum && b.orderNum) return a.orderNum - b.orderNum;
                        else return 0;
                      })
                      .map((c, idx) => (
                        <option
                          value={JSON.stringify(c)}
                          className={'select_opt'}
                          style={selectOptInlineStyle}
                          key={idx}
                        >
                          {c.groupNm}
                        </option>
                      ))}
                </select>
              </div>
              <div className="popup_search_box">
                <input
                  type="text"
                  className="search_input"
                  placeholder="이름, 전화번호 또는 이메일 주소를 입력해주세요."
                  ref={searchInputRef}
                  onKeyPress={onKeyPressInput}
                  id="popupSearchMembsIp"
                />
                <label htmlFor="popupSearchMembsIp" className="ab_text">
                  임직원 검색
                </label>
                <button
                  type="button"
                  {...getGAMenuClickProps('POPUP', { dataClick: 'memberSearch' })}
                  className="popup_search_button ico_search_white"
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                    onSearch();
                  }}
                ></button>
              </div>
            </div>
            {/* <!-- /search input --> */}
            {/* <!-- search result --> */}
            <div className="popup_search_result">
              <div className="popup_result_title">
                <span>
                  <span className="result_highlight">{memberList.length}</span>건이 검색되었습니다.
                </span>
              </div>
              {/* <!-- no search result: 검색 결과가 없을 경우 --> */}
              {memberList.length === 0 ? (
                <div className="popup_no_result">
                  <span>검색 결과가 없습니다.</span>
                </div>
              ) : (
                <ul className="popup_result_list custom_scroll">
                  {memberList.map((mem, idx) => (
                    <li className="popup_result_item" key={idx}>
                      <div className="membs_search_result">
                        <div className="membs_info">
                          <strong className="membs_info_name">
                            <span className="membs_name">{mem.name}</span>
                            {/* <span className="membs_pos">{}</span> */}
                          </strong>
                          <span className="membs_info_team">
                            <span className="membs_aff" dangerouslySetInnerHTML={{ __html: mem.groupNm || '' }}></span>
                            {/* <span className="membs_team">개발팀</span> */}
                          </span>
                        </div>
                        <div className="membs_contact">
                          <div className="membs_contact_info">
                            <span className="contact_info membs_tel">
                              {mem.telNum ? mem.telNum.replace(/null-/g, '').replace(/null/g, '') : ''}
                            </span>
                            <span className="contact_info membs_mob">
                              {mem.phoneNum ? mem.phoneNum.replace(/null-/g, '').replace(/null/g, '') : ''}
                            </span>
                          </div>
                          <div className="membs_contact_info">
                            <span className="contact_info membs_mail">
                              <a className="membs_mail_link">{mem.email}</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {/* <!-- /no search result --> */}
            </div>
            {/* <!-- /search result --> */}
            <div className="popup_notice">
              <p className="popup_notice_desc">
                <span className="popup_notice_highlight">* 임직원 정보에 수정이 필요한 경우</span>&nbsp;&nbsp;각 소속사
                업무포털에서 본인 정보 수정해주세요.(수정 후 다음날 반영됨)
              </p>
              <p className="popup_notice_desc">
                <span className="popup_notice_highlight">* 임직원 정보가 없는 경우</span>&nbsp;&nbsp;각 소속사 업무포털
                담당자에게 문의해주세요.
              </p>
            </div>
          </div>
          <button
            type="button"
            className="popup_close ico_close is-pc"
            onClick={() => {
              props.onClose();
              if (searchInputRef.current) searchInputRef.current.value = '';
              setSearchOptions({ groupCode: '', searchWord: '' });
              setCompany({});
            }}
          ></button>
        </div>
      </div>
    </>
  );
};

export default SearchMemPopup;
