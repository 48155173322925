import React, { useEffect, useRef, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { cache } from 'swr';
import { getGAMenuClickProps, all_click_datalayer_push } from 'utils/GAUtils';
import LogoutPopup from 'components/organisms/Popup/LogoutPopup';
import { useMyInfo } from 'hooks';
import paths from 'commons/paths';
import MyInfoContext from 'contexts/MyInfoContext';

interface PopupProps {
  onClose: () => void;
}

interface AccountPopupProps extends PopupProps {
  buttonRef: React.RefObject<HTMLAnchorElement>;
}

const AccountPopup = (props: AccountPopupProps) => {
  // const { setIsLogin } = useMyInfo();
  const history = useHistory();

  const popupRef = useRef<HTMLDivElement>(null);
  const [isLogoutPopupOpen, setIsLogoutPopuOpen] = useState<boolean>(false);
  const myInfoContext = useContext(MyInfoContext);

  useEffect(() => {
    window.addEventListener('click', handleClose);
    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, []);

  const handleClose = e => {
    if (!popupRef.current?.contains(e.target) && !props.buttonRef.current?.contains(e.target)) props.onClose();
  };

  const onClickLogout = e => {
    e.preventDefault();
    setIsLogoutPopuOpen(true);
  };

  const closeOkLogoutPopup = () => {
    cache.clear();

    // setIsLogin(false);
    myInfoContext.setIsLogin(false);
    myInfoContext.setIsAppLogined(false);

    localStorage.clear();
    myInfoContext.init();

    history.replace({
      pathname: paths.getPath('login'),
    });
  };

  const closeCancelLogoutPopup = () => {
    setIsLogoutPopuOpen(false);
  };

  return (
    <>
      <div className="popup account_popup is-active">
        <div className="popup_inner" ref={popupRef}>
          <ul className="account_popup_list">
            <li className="account_popup_item">
              <Link
                to={paths.getPath('mypage')}
                className="account_popup_link"
                {...getGAMenuClickProps('마이페이지')}
                onClick={e => {
                  all_click_datalayer_push(e.currentTarget);
                  props.onClose();
                }}
              >
                마이 페이지
              </Link>
            </li>
            <li className="account_popup_item">
              <a className="account_popup_link logout_link" href="#" onClick={onClickLogout}>
                로그아웃
              </a>
            </li>
          </ul>
        </div>
      </div>
      {isLogoutPopupOpen && <LogoutPopup closeOkPopup={closeOkLogoutPopup} closeCancelPopup={closeCancelLogoutPopup} />}
    </>
  );
};

export default AccountPopup;
