import { SERVER_URL } from '../commons/constants';

export const MOfficePlusMobileAppPopUpUtils = () => {
  /**
   * MOffice Plus 팝업 열기
   * @param {string} url - target url 정보
   * @constructor
   */
  const MOfficePlusPopUpOpen = url => {
    if (!url) {
      emsApp.pluginClose();
      return;
    }
    if (!isMOfficePlusMobileApp()) {
      emsApp.pluginClose();
      return;
    }

    emsApp.pluginWebLinkPopUp({
      webLink: url.includes(SERVER_URL) ? url : SERVER_URL.concat(url) || '',
      noToolbar: true, // 기본값: false
    });
  };

  /**
   * MOffice Plus 팝업 창 닫기
   * @constructor
   */
  const MOfficePlusPopUpClose = () => {
    emsApp.pluginClose();
  };

  return {
    MOfficePlusPopUpOpen(url) {
      MOfficePlusPopUpOpen(url);
    },
    MOfficePlusPopUpClose() {
      MOfficePlusPopUpClose();
    },
  };
};

/**
 * MOffice plus 인지 확인
 * - MOffice 의 경우, 네이티브 앱 사용으로 인해 mxp.js 사용 시 오류 발생함으로 예외 처리함
 * @return {boolean | undefined}
 */
export const isMOfficePlusMobileApp = () => {
  const userAgent = String(navigator.userAgent || navigator.vendor || window.opera);
  return userAgent?.toUpperCase().includes('EMSAPP');
};

/**
 * 유라클(Uracle) D&O 포탈 앱 인지 확인
 * - 네이티브 앱 사용으로 인해 mxp.js 사용 시 오류 발생함으로 예외 처리함
 * @return {boolean | undefined}
 */
export const isDNOPortalMobileApp = () => {
  const userAgent = String(navigator.userAgent || navigator.vendor || window.opera);
  return userAgent?.toUpperCase().includes('MORPHEUS');
};
