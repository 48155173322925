import request from '../request';
import { endpoints } from '../endpoints';

export default {
  /** 노출 중인 임원 인사 연도 정보 조회 */
  getExecutiveAppointmentYearDisplayInfo: (): Promise<ExecutiveAppointmentYearDetailResDto> => {
    return request({
      url: endpoints.executiveAppointment.getExecutiveAppointmentYearDisplayInfo,
      method: 'GET',
    });
  },
  /** 노출 중인 임원 인사 (통합) 상세 정보 조회 */
  getExecutiveAppointmentIntegratedDetail: (params: {
    yearId: string;
  }): Promise<ExecutiveAppointmentIntegratedDetailResDto> => {
    return request({
      url: endpoints.executiveAppointment.getExecutiveAppointmentIntegratedDetail.replace('{yearId}', params.yearId),
      method: 'GET',
    });
  },
};
