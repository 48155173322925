import { useContext } from 'react';
import { fetcher } from 'services';
import { parse } from 'query-string';
import LoggingContext from 'contexts/LoggingContext';
import { callGAEvent } from 'utils/GAUtils';

const useLogging = () => {
  const logging = useContext(LoggingContext);

  const postLogging = () => {
    const reqUri = window.location.href;

    if (logging.lastLoggingPage === reqUri) {
      return;
    }

    const lastPage = logging.lastLoggingPage;

    logging.setLastLoggingPage(reqUri);

    callGAEvent();

    const tfrom = parse(location.search).tfrom as string;

    callLogging(reqUri, lastPage ? lastPage : '', tfrom);
  };

  const shareLogging = () => {
    callLogging(window.location.href + '/contentshare', window.location.href);
  };

  const downloadLogging = () => {
    callLogging(window.location.href + '/contentdownload', window.location.href);
  };

  const callLogging = (reqUri: string, preUri: string, tfrom?: string) => {
    fetcher.common
      .postLogging({
        reqUri,
        preUri,
        tfrom,
      })
      .then(res => {});
  };

  return {
    postLogging,
    shareLogging,
    downloadLogging,
  };
};

export default useLogging;
