import request from 'services/request';
import { endpoints } from 'services/endpoints';

export default {
  /** 노출 중인 온보딩 팝업 목록 조회 */
  getOnboardingInfo: (params?: { isBypassOnboardingYn: boolean }): Promise<PopupInfoDetailResDto> => {
    return request({
      url: endpoints.popup.displayOnboarding,
      method: 'GET',
      params,
    });
  },

  /** 온보딩 팝업 열람 완료 */
  readOnboarding: (): Promise<BaseResDto> => {
    return request({
      url: endpoints.popup.readOnboarding,
      method: 'PUT',
    });
  },
};
