import React, { useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Paths from 'commons/paths';
import { useMyInfo, useLogging } from 'hooks';
import { isDetailsFor } from 'utils/utils';
import { initGAObject, setGACommon } from 'utils/GAUtils';
import paths from 'commons/paths';

const useGAObject = () => {
  const location = useLocation();
  const { info } = useMyInfo();
  const { postLogging } = useLogging();

  const isTargetDetailPage = useMemo(
    () =>
      isDetailsFor([
        Paths.getPath('videoList').replace('/list', ''),
        Paths.getPath('recommendList').replace('/list', ''),
        Paths.getPath('newsList').replace('/list', ''),
        Paths.getPath('pressList').replace('/list', ''),
        Paths.getPath('cardNewsList').replace('/list', ''),
        Paths.getPath('webtoonList').replace('/list', ''),
        Paths.getPath('boardList').replace('/list', ''),
        Paths.getPath('topEatsBoardList').replace('/list', ''),
        Paths.getPath('departmentBoardList').replace('/list', ''),
        Paths.getPath('societyBoardList').replace('/list', ''),
        Paths.getPath('eventList').replace('/list', ''),
        Paths.getPath('eventQuizList').replace('/list', ''),
        Paths.getPath('eventSurveyList').replace('/list', ''),
        Paths.getPath('ESGList').replace('/list', ''),
      ]),
    [location.pathname]
  );

  useEffect(() => {
    const pathname = location.pathname;

    const pageName = Paths.getPageName(pathname);

    document.title = ('LG LIFE - ' + pageName) as string;

    if (!Paths.isPrivate(pathname) && !info) {
      initGAObject();
    } else {
      setGACommon(info);
    }

    if (isTargetDetailPage) {
      return;
    }

    if (!!matchPath(pathname, paths.getPath('search'))) {
      return;
    }

    if (!!matchPath(pathname, paths.getPath('ep'))) {
      return;
    }

    postLogging();
  }, [location.pathname, info, isTargetDetailPage]);
};

export default useGAObject;
