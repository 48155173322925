import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';

const useUserInfo = (userId: string) => {
  const { info } = useMyInfo();

  const { data: userInfoData } = useSWR<any>(
    info && userId ? [`${endpoints.common.user}/${userId}`, userId] : null,
    url => fetcher.common.getUserInfo(url).then(res => res)
  );

  return {
    userInfoData,
  };
};

export default useUserInfo;
