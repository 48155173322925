import { parse } from 'querystring';
import moment, { Moment } from 'moment';
import iconv from 'iconv-lite';
import Cookies from 'js-cookie';
import { WEB_RESOURCE_DOMAIN_URL } from 'commons/constants';

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isNumber = n => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const dateYYYYMMDD = timestamp => moment(timestamp).format('YYYY.MM.DD');

export const datehhmmss = timestamp => moment(timestamp).format('HH:mm:ss');

export const relativeTime = (timestamp, now) => {
  if (dateYYYYMMDD(now) === dateYYYYMMDD(timestamp)) {
    // 당일
    const diff = moment(now).diff(moment(timestamp));
    if (diff < 60 * 1000) {
      return '방금 전';
    } else if (diff < 60 * 60 * 1000) {
      return `${Math.floor(diff / (60 * 1000))}분 전`;
    } else {
      return `${Math.floor(diff / (60 * 60 * 1000))}시간 전`;
    }
  } else {
    // 그 전
    return dateYYYYMMDD(timestamp);
  }
};

export const ddayCalc = timeStamp => {
  const ts = moment(timeStamp).format('YYYYMMDD');
  const now = moment().format('YYYYMMDD');
  const diff = moment(ts).diff(moment(now), 'days');
  return diff > 0 ? diff : diff === 0 ? 'day' : Math.abs(diff);
};

const handleInvalidDate = (timeStamp: string) => {
  return moment(timeStamp, 'YYYY.MM.DD');
};

/**
 * 근무일 계산 (LG Days)
 * @param timeStamp
 * @param now
 */
export const workPeriodCalc = (timeStamp?: string | Date, now?: string | Date): number => {
  if (timeStamp && now) {
    let _timeStamp = timeStamp;
    if (typeof timeStamp === 'string') {
      _timeStamp = timeStamp.replace(/\./gi, '-');
    }
    let momentedTimeStamp = moment(_timeStamp);
    if (!momentedTimeStamp.isValid()) {
      momentedTimeStamp = handleInvalidDate(_timeStamp as string);
    }

    const diff = moment(now).diff(momentedTimeStamp);
    return Math.ceil(diff / (24 * 60 * 60 * 1000));
  }
  return 0;
};

/**
 * 기념일 확인 및 기념일 일 경우, 해당 일수 반환
 * @param timeStamp
 * @param now
 */
export const anniversaryCalc = (timeStamp?: string | Date, now?: string | Date): number => {
  if (timeStamp && now) {
    let _timeStamp = timeStamp;
    if (typeof timeStamp === 'string') {
      _timeStamp = timeStamp.replace(/\./gi, '-');
    }
    let momentedTimeStamp = moment(_timeStamp);
    if (!momentedTimeStamp.isValid()) {
      momentedTimeStamp = handleInvalidDate(_timeStamp as string);
    }

    if (
      workPeriodCalc(_timeStamp, now) > 1 &&
      momentedTimeStamp.get('M') === moment().get('M') &&
      momentedTimeStamp.get('D') === moment().get('D')
    ) {
      return moment(now).get('year') - momentedTimeStamp.get('year');
    }
  }
  return 0;
};

export const videoTimeFormat = milisec => {
  const sec = Math.floor(milisec / 1000);
  if (sec / (60 * 60) >= 1)
    return `${Math.floor(sec / (60 * 60))}:${
      Math.floor(sec / 60) % 60 < 10 ? `0${Math.floor(sec / 60) % 60}` : Math.floor(sec / 60) % 60
    }:${sec % 60 < 10 ? `0${sec % 60}` : sec % 60}`;
  else
    return `${Math.floor(sec / 60) < 10 ? `0${Math.floor(sec / 60) % 60}` : Math.floor(sec / 60) % 60}:${
      sec % 60 < 10 ? `0${sec % 60}` : sec % 60
    }`;
};

export const zeroStringFormat = (n, num_length) => {
  n = n + '';
  return n.length >= num_length ? n : new Array(num_length - n.length + 1).join('0') + n;
};

export const getYearsofService = (startDate: Moment) => {
  return Math.floor(moment.duration(moment().diff(startDate)).asYears());
};

export const decodeJwt = jwt => {
  try {
    const ca = jwt;
    const payload = ca.split('.')[1];

    const decodeBase64Token = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
    const utf8DecodePayload = decodeURIComponent(escape(decodeBase64Token));
    const parseDecodeToken = JSON.parse(utf8DecodePayload);

    return parseDecodeToken;
  } catch (e) {
    return false;
  }
};

/**
 * 상세(detail) pathname 인지 여부 확인
 * - 만일, 맨 앞의 문자가 '/' 로 시작할 경우 제거 후 테스트
 * @param title
 */
export const isDetailsFor = (title: string | string[]) => {
  const titles = Array.isArray(title) ? title : [title];
  return titles.some(t => {
    const regex = new RegExp(`^\/${t.substr(0, 1) === '/' ? t.substr(1) : t}\/detail`);
    return regex.test(location.pathname);
  });
};

export const paramsToFormData = params => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, val]) => {
    if (val) {
      const hackVal = val as string | File;
      formData.append(key, hackVal);
    }
  });

  return formData;
};

export const imageUrl = url => {
  return url
    ? url.indexOf('/files') === 0
      ? (window.location.hostname.includes('localhost') ? WEB_RESOURCE_DOMAIN_URL : window.location.origin) + url
      : url
    : undefined;
};

export const encodingEucKr = source => {
  const buffer = iconv.encode(source, 'euckr');
  const param = buffer.toString('binary');
  return btoa(param);
};

export const isIE = () => {
  if (window.document['documentMode']) {
    return true;
  }

  return false;
};

export const setDataInStore = (name: string, value: string) => {
  if (isIE()) {
    Cookies.set(name, value);
  } else {
    localStorage.setItem(name, value);
  }
};

export const getDataInStore = (name: string) => {
  if (isIE()) {
    return Cookies.get(name);
  }

  return localStorage.getItem(name);
};

/**
 * IE 브라우저 여부
 * - IE 6.0 ~ IE11
 */
export const isIEBrowser = () => {
  const agent = navigator.userAgent.toLowerCase();
  return (
    (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || agent.indexOf('msie') != -1
  );
};
