import classNames from 'classnames';
import { useMyInfo } from 'hooks';
import React, { useState, useEffect } from 'react';
import { getQnaProps, all_click_datalayer_push } from 'utils/GAUtils';

export interface ControlSortProps {
  className?: string;
  initial: string;
  initialElementId?: string;
  categoryIndex: number;
  initPop?: string;
  selectBoxList: Array<string>;
  clickSelectBox: (index) => void;
  isRequired?: boolean;
  tagging?: string;
  selectedValue?: string;
  customClassList?: {
    divClassName: string;
    aTagItemClassName?: string;
    useArrowImage?: boolean;
  };
}

const SelectBox = ({
  className,
  initial,
  initialElementId,
  categoryIndex,
  selectBoxList,
  clickSelectBox,
  isRequired,
  tagging,
  selectedValue,
  customClassList,
}: ControlSortProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { info } = useMyInfo();

  useEffect(() => {
    window.addEventListener('click', onClickWindow);

    return () => {
      window.removeEventListener('click', onClickWindow);
    };
  }, []);

  const onClickWindow = e => {
    const className = e.target.classList[0];

    if (className === 'selectbox_label') {
      return;
    }

    if (customClassList && className === customClassList.divClassName + '_label') return;

    setIsOpen(false);
  };

  /** SelectBox HTML */
  function selectBoxCoreHTML() {
    return (
      <>
        <a
          className={classNames([
            customClassList ? customClassList.divClassName + '_label' : 'selectbox_label',
            { 'is-required': isRequired && categoryIndex === -1 },
            customClassList && customClassList?.useArrowImage
              ? { 'is-active': isOpen }
              : { 'is-selected': categoryIndex > -1 },
          ])}
          href="#"
          onClick={e => {
            e.preventDefault();
            if (!isOpen) {
              setIsOpen(true);
            } else {
              setIsOpen(false);
            }
          }}
          id={initialElementId}
        >
          {categoryIndex === -1
            ? initial
            : selectBoxList &&
              (selectedValue && selectBoxList.some(obj => obj === selectedValue)
                ? selectBoxList.map(title => {
                    if (title === selectedValue) return title;
                  })
                : selectBoxList.map((title, index) => {
                    if (categoryIndex === index) return title;
                  }))}
        </a>
        <ul
          className={
            customClassList ? customClassList.divClassName + '_list custom_scroll' : 'select_list custom_scroll'
          }
          style={isOpen ? { display: 'block' } : { display: 'none' }}
        >
          {selectBoxList &&
            selectBoxList.map((title, idx) => {
              return (
                <li
                  className={classNames([
                    customClassList ? customClassList.divClassName + '_item' : 'select_item',
                    customClassList && customClassList.useArrowImage
                      ? { 'is-current': selectedValue ? selectedValue === title : categoryIndex === idx }
                      : { 'is-selected': selectedValue ? selectedValue === title : categoryIndex === idx },
                  ])}
                  key={idx}
                  data-value={title}
                  style={{
                    display:
                      tagging && tagging === 'board'
                        ? info?.adminYn === 'Y'
                          ? ''
                          : title === '공지'
                          ? 'none'
                          : ''
                        : '',
                  }}
                >
                  <a
                    className={classNames(
                      customClassList && customClassList.aTagItemClassName
                        ? customClassList.aTagItemClassName
                        : 'select_opt'
                    )}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setIsOpen(false);
                      clickSelectBox(idx);
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    {...(tagging === 'qna' && getQnaProps(title))}
                  >
                    {title}
                  </a>
                </li>
              );
            })}
        </ul>
      </>
    );
  }

  return (
    <>
      <div className={customClassList ? customClassList.divClassName : classNames(['board_select', className])}>
        {customClassList ? (
          selectBoxCoreHTML()
        ) : (
          <div className={classNames(['selectbox', { 'is-active': isOpen }])}>`${selectBoxCoreHTML()}`</div>
        )}
      </div>
    </>
  );
};

export default SelectBox;
