import { fetcher } from 'services';

const useCommentMention = () => {
  const getMentionUserInfo = (searchName: string) => {
    return fetcher.common.getUserInfos({ searchName: searchName }).then(res => {
      return res;
    });
  };

  return {
    getMentionUserInfo,
  };
};

export default useCommentMention;
