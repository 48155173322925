import React from 'react';

interface PopupProps {
  onCancel: () => void;
  onOk: () => void;
}

const CicUpdatePopup = ({ onCancel, onOk }: PopupProps) => {
  return (
    <div className="confirm_popup cic_popup is-active">
      <div className="popup_dim"></div>
      <div className="popup_inner">
        <p className="confirm_popup_text">
          통합시스템에 등록된 회원정보와 LG LIFE 회원정보 중 일치하지 않는 정보가 있습니다.
          <br />
          LG LIFE 회원정보를 최신 정보로 업데이트 하시겠습니까?
        </p>
        <ul className="confirm_button_list">
          <li className="confirm_button_item">
            <button type="button" className="confirm_popup_button is-dim popup_button_cancel" onClick={onCancel}>
              아니요
            </button>
          </li>
          <li className="confirm_button_item">
            <button type="button" className="confirm_popup_button popup_button_confirm" onClick={onOk}>
              업데이트
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CicUpdatePopup;
