import React from 'react';
import { getGABannerClickProps, all_click_datalayer_push } from 'utils/GAUtils';

interface MainBannerProps extends MainBannerResDto {}

const MainBanner = (props: MainBannerProps) => {
  return (
    <div className="main_banner">
      <a
        href={props.linkUri || '/'}
        className="main_banner_box"
        {...getGABannerClickProps(props.linkUri as string)}
        onClick={e => {
          all_click_datalayer_push(e.currentTarget);
        }}
      >
        <img src={props.bannerImageUri} alt="" className="main_banner_img is-pc" />
        <img src={props.mobileBannerImageUri} alt="" className="main_banner_img is-mobile" />
      </a>
    </div>
  );
};

export default MainBanner;
