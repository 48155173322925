import React, { useRef } from 'react';
import { getFAQProps, all_click_datalayer_push } from 'utils/GAUtils';
import { Faq } from '..';

export interface FaqItemProps extends FaqResDto {}

const FaqItem = (props: FaqItemProps) => {
  const faqItemRef = useRef<HTMLLIElement>(null);

  const clickFaqItem = e => {
    e.preventDefault();
    faqItemRef.current?.classList.toggle('is-active');
  };

  return (
    <>
      <li className="faq_accor_item" ref={faqItemRef}>
        <a
          href="#"
          className="faq_accor_link"
          onClick={e => {
            clickFaqItem(e);
            all_click_datalayer_push(e.currentTarget);
          }}
          {...getFAQProps(`${props.cateNm}`, { dataclickname: props.question })}
        >
          <div className="faq_accor_q">{props.question}</div>
        </a>
        <div className="faq_accor_a">
          <p className="text" dangerouslySetInnerHTML={{ __html: props.reply?.replace(/\n/g, '<br/>') || '' }}></p>
        </div>
      </li>
    </>
  );
};

export default FaqItem;
