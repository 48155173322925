import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { useState } from 'react';
import useMyInfo from './useMyInfo';

const useFaq = () => {
  const { info } = useMyInfo();
  const [searchOptions, setSearchOptions] = useState<any>({
    size: 10,
    sort: '',
  });

  const { data: faqCategory } = useSWR<any>(info ? endpoints.common.category : null, () =>
    fetcher.common.getCategory({ cateType: 'FAQ' }).then(res => {
      return res;
    })
  );

  const { data: faqData } = useSWR<any>(info ? endpoints.faq.faq : null, () =>
    fetcher.faq.getFaq().then(res => {
      return res;
    })
  );

  return { faqCategory, faqData, searchOptions, setSearchOptions };
};

export default useFaq;
