import { fetcher } from 'services';

const useAutoLoginCheck = () => {
  const postAutoLoginCheck = (domain: string, email: string) => {
    return fetcher.auth.postAutoLoginCheck({
      domainCode: domain,
      encryptEmailValue: email,
    });
  };
  return {
    postAutoLoginCheck,
  };
};

export default useAutoLoginCheck;
