import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import { dateYYYYMMDD } from 'utils/utils';
import { all_click_datalayer_push, getContentsButtonGAProps, getContentTagGAProps } from 'utils/GAUtils';
import { useVideo } from 'hooks';
import paths from 'commons/paths';

interface VideoDetailInfoProps {
  like: () => void;
  bookmark: (bookmark: boolean) => void;
  openScript: () => void;
  info?: MediaDetailResDto;
  openSharePopup: () => void;
}

const VideoDetailInfo = (props: VideoDetailInfoProps) => {
  const { info } = props;
  const history = useHistory();
  const { setVideoVisible } = useVideo();
  const [motion, setMotion] = useState(false);
  const likeRef = useRef<any>();

  return (
    <>
      <div className="detail_info" style={{ paddingTop: info?.mediaContentType === 'MAGAZINE' ? '0' : '' }}>
        <div className="detail_info_con">
          <ul className="detail_info_tag">
            {info?.mediaTag &&
              info?.mediaTag
                .split('#')
                .slice(1)
                .map((t, idx) => (
                  <Link
                    to={{
                      pathname: paths.getPath('search'),
                      search: stringify({ keyword: t }),
                    }}
                    key={idx}
                    {...getContentTagGAProps('영상태그', t)}
                    onClick={e => {
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  >
                    <li key={idx}>#{t}</li>
                  </Link>
                ))}
          </ul>
          <div className="detail_info_title">{info?.title}</div>
          <div className="detail_info_sub">
            <span className="detail_info_views">
              조회수<span className="views">{info?.readCnt}</span>회
            </span>
            <span className="detail_info_date">{dateYYYYMMDD(info?.postDt)}</span>
          </div>
        </div>
        <ul className="detail_info_more" style={{ textAlign: 'right' }}>
          <li>
            <a
              href="#"
              className={classNames('detail_info_link', 'is-likes', {
                'is-active': info?.likeStatus === 'Y',
                'is-motion': motion,
              })}
              onClick={e => {
                e.preventDefault();
                setMotion(true);
                if (info?.likeStatus === 'N') props.like();
                all_click_datalayer_push(e.currentTarget);
              }}
              {...getContentsButtonGAProps({
                buttonType: '좋아요',
                contentType: 'MEDIA',
                tags: info?.mediaTag as string,
                title: info?.title as string,
                time: info?.videoTotalTime ? info.videoTotalTime : -1,
                dataContentCategory: info?.cateNm as string,
                creatorNm: info?.creatorNm as string,
                brightcoveId: info?.videoId as string,
                mediaContentsType: info?.mediaContentType as string,
              })}
            ></a>
            <div className="detail_info_text">{info?.likeCnt}</div>
          </li>
          <li>
            <a
              href="#"
              className={classNames('detail_info_link', 'is-store', { 'is-active': info?.bookmarkStatus === 'Y' })}
              onClick={e => {
                e.preventDefault();
                props.bookmark(info?.bookmarkStatus === 'N');
                all_click_datalayer_push(e.currentTarget);
              }}
              {...getContentsButtonGAProps({
                buttonType: '찜',
                contentType: 'MEDIA',
                tags: info?.mediaTag as string,
                title: info?.title as string,
                time: info?.videoTotalTime ? info.videoTotalTime : -1,
                dataContentCategory: info?.cateNm as string,
                creatorNm: info?.creatorNm as string,
                brightcoveId: info?.videoId as string,
                mediaContentsType: info?.mediaContentType as string,
              })}
            >
              <img src="/assets/images/icon/ico-info-store.png" alt="store" />
            </a>
            <div className="detail_info_text">찜</div>
          </li>
          {info?.mediaContentType === 'VIDEO' && (
            <li>
              <a
                href="#"
                className="detail_info_link script"
                onClick={e => {
                  e.preventDefault();
                  props.openScript();
                  all_click_datalayer_push(e.currentTarget);
                }}
                {...getContentsButtonGAProps({
                  buttonType: '원고',
                  contentType: info?.cateType as string,
                  tags: info?.mediaTag as string,
                  title: info?.title as string,
                  time: info?.videoTotalTime ? info.videoTotalTime : -1,
                  dataContentCategory: info?.cateNm as string,
                  creatorNm: info?.creatorNm as string,
                  brightcoveId: info?.videoId as string,
                  mediaContentsType: info?.mediaContentType as string,
                })}
              >
                <img src="/assets/images/icon/ico-info-script.png" alt="script" />
              </a>
              <div className="detail_info_text">원고</div>
            </li>
          )}
          {info?.downloadableYn !== 'N' && (
            <li>
              <a
                href="#"
                className="detail_info_link"
                onClick={e => {
                  e.preventDefault();
                  if (props.info?.mediaContentType === 'MAGAZINE') return;
                  history.push(paths.getPath('videoDownload'), props.info);
                  setVideoVisible(false);
                  all_click_datalayer_push(e.currentTarget);
                }}
                {...getContentsButtonGAProps({
                  buttonType: '다운로드',
                  contentType: 'MEDIA',
                  tags: info?.mediaTag as string,
                  title: info?.title as string,
                  time: info?.videoTotalTime ? info.videoTotalTime : -1,
                  dataContentCategory: info?.cateNm as string,
                  creatorNm: info?.creatorNm as string,
                  brightcoveId: info?.videoId as string,
                  mediaContentsType: info?.mediaContentType as string,
                })}
              >
                <img src="/assets/images/icon/ico-info-download.png" alt="download" />
              </a>
              <div className="detail_info_text">다운로드</div>
            </li>
          )}
          <li>
            <a
              href="#"
              className="detail_info_link"
              onClick={e => {
                e.preventDefault();
                props.openSharePopup();
                all_click_datalayer_push(e.currentTarget);
              }}
              {...getContentsButtonGAProps({
                buttonType: '공유',
                contentType: 'MEDIA',
                tags: info?.mediaTag as string,
                title: info?.title as string,
                time: info?.videoTotalTime ? info.videoTotalTime : -1,
                dataContentCategory: info?.cateNm as string,
                creatorNm: info?.creatorNm as string,
                brightcoveId: info?.videoId as string,
                mediaContentsType: info?.mediaContentType as string,
              })}
            >
              <img src="/assets/images/icon/ico-info-share.png" alt="share" />
            </a>
            <div className="detail_info_text">공유</div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default VideoDetailInfo;
