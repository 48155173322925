import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useMain from 'hooks/useMain';
import { BoardCardData, EventCardData, MediaCard } from 'types/index';
import { clearListCache } from 'utils/LGLifeUtils';

import Hero from './components/Hero';
// import MainBanner from './components/MainBanner';
import MainRecommend from './components/MainRecommend';
import MainBest from './components/MainBest.tsx';
import MainNews from './components/MainNews';
import MainSns from './components/MainSns';
import MainAd from './components/MainAd';
import Editors from './components/Editors';
import MainBoard from './components/MainBoard';
import MainBanner from './components/MainBanner';

export interface HomeProps {}

export interface Banner {
  bannerImageUrl: string;
  linkUrl: string;
  resultCode: number;
  resultMessage: string;
}

const Home = ({}: React.PropsWithChildren<HomeProps>): React.ReactElement => {
  // const { users, setUser } = useUsers(2);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { hero, banner, popularContent, news, playList, sns, ad, board, recommend, section } = useMain();
  const [heroList, setHeroList] = useState<MainHeroListResDto>();
  const [sections, setSections] = useState([] as MainSectionResDto[]);
  const [recommendData, setRecommendData] = useState<MainRecommendListResDto>();
  const [recommendList, setRecommendList] = useState<Array<MediaCardResDto>>([]);
  const [rcmEvent, setRcmEvent] = useState<EventCardData>();
  const [bannerInfo, setBanner] = useState<MainBannerResDto>();
  const [popularList, setPopularList] = useState([]);
  // mondayNews(첫번째 고정), mainDsplNewsList(두번째), newestNewsList(마지막 나머지 채우기)
  const [newsList, setNewsList] = useState<Array<MediaCardResDto>>([]);
  const [newsData, setNewsData] = useState<MainNewsListResDto>();
  const [editorsPlanList, setEditorsPlanList] = useState<Array<MainEditorsPlanResDto>>([]);
  const [snsData, setSnsData] = useState<MainMediaSectionListResDto>();
  const [snsList, setSnsList] = useState<Array<MediaCardResDto>>([]);
  const [adData, setAdData] = useState<MainMediaSectionListResDto>();
  const [adList, setAdList] = useState<Array<MediaCardResDto>>([]);
  const [boardList, setBoardList] = useState<Array<BoardCardData>>([]);

  const history = useHistory();

  const scrollSave = () => {
    localStorage.setItem('scrollPos', window.pageYOffset.toString());
  };

  useEffect(() => {
    if (history.action === 'POP') {
      const scrollPos = localStorage.getItem('scrollPos');
      if (scrollPos) {
        setTimeout(() => {
          window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
        }, 100);
      }
    } else if (history.action === 'PUSH') {
      clearListCache();
    }

    const listen = history.listen(scrollSave);
    return () => {
      listen();
    };
  });

  useEffect(() => {
    if (hero) setHeroList(hero);
    if (banner) setBanner(banner);
    if (popularContent?.popularContentList) setPopularList(popularContent.popularContentList);
    if (news) {
      setNewsList(news.newsList || []);
      setNewsData(news);
    }
    if (playList) setEditorsPlanList(playList.editorsPlanList);
    if (sns) {
      setSnsList(sns.mediaList || []);
      setSnsData(sns);
    }
    if (ad) {
      setAdList(ad.mediaList || []);
      setAdData(ad);
    }
    // if (ad) setAdList([...(ad.mainDsplMediaList || []), ...(ad.newestMediaList || [])]);
    if (board) setBoardList(board.mainBoardList);
    if (section) setSections(section.mainSectionList);
  }, [hero, banner, popularContent, news, playList, sns, ad, board, section]);

  useEffect(() => {
    if (recommend) {
      setRecommendData(recommend);
      if (recommend.mainRecommendEvent) {
        setRecommendList([...recommend.mainRecommendList.slice(0, 2)]);
        setRcmEvent(recommend.mainRecommendEvent);
      } else {
        setRecommendList(recommend.mainRecommendList);
      }
    }
  }, [recommend]);

  return (
    <>
      <div className="hero">
        {/* 신년사 이미지로 대체 */}
        {/* <div className="hero_img">
          <a href="/video/detail/MED000000000000000000000671">
            <img src="assets/images/2022/new_year.jpg" alt="" />
          </a>
        </div> */}
        <Hero mainHeroList={heroList?.mainHeroList} currentDt={heroList?.currentDt as string} />
      </div>
      <div className="content_inner">
        {sections
          .sort((a, b) => {
            if (a.orderNum && b.orderNum) return a.orderNum - b.orderNum;
            else return 0;
          })
          .map((se, i) => {
            switch (se.sectionCode) {
              case 'RECOM':
                return (
                  <MainRecommend
                    mainRecommendList={recommendList}
                    mainRecommendEvent={rcmEvent}
                    currentDt={recommendData?.currentDt as string}
                    key={i}
                  />
                );
              case 'BANNER':
                return bannerInfo?.bannerImageUri && <MainBanner {...bannerInfo} key={i} />;
              case 'POPUL':
                return <MainBest popularContentList={popularList} key={i} />;
              case 'NEWS':
                return <MainNews newsList={newsList} currentDt={newsData?.currentDt as string} key={i} />;
              case 'EDITOR':
                return <Editors editorsPlanList={editorsPlanList} key={i} />;
              case 'SNS':
                return <MainSns snsList={snsList} currentDt={snsData?.currentDt as string} key={i} />;
              case 'ADS':
                return <MainAd adList={adList} currentDt={adData?.currentDt as string} key={i} />;
            }
          })}
      </div>
    </>
  );
};

export default Home;
