import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { all_click_datalayer_push, getGAClickProps } from 'utils/GAUtils';
import paths from '../../../commons/paths';

SwiperCore.use([Navigation]);

export interface SeriesTag {
  sclasId: string;
  sclasNm: string;
  logoImageUri: string;
  orderNum: number;
  contentsType: string;
}

export interface ControlTagProps {
  tags: Array<SeriesTag>;
  activeSeriesTagIndex: any;
  setActiveSeriesTagIndex: any;
  seriesContentsType: 'CARD' | 'VIDEO';
}

const ControlImageTag = (props: ControlTagProps) => {
  const history = useHistory();

  const [swiper, setSwiper] = useState<SwiperCore>();

  useEffect(() => {
    document.querySelector('.section_movie_list .swiper-wrapper')?.classList.add('movie_list_area');
  }, []);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(props.activeSeriesTagIndex, 0);
    }
  }, [swiper]);

  const pathUrlFromSeriesContentsType = (tag: SeriesTag) => {
    if (props.seriesContentsType.toString() === 'CARD')
      return `${paths.getPath('cardNewsSeries').replace(':sclasId', tag.sclasId)}`;
    return `${paths.getPath('videoSeries').replace(':sclasId', tag.sclasId)}`;
  };

  return (
    <>
      <Swiper
        className="movieSwiper"
        slidesPerView={3.4}
        spaceBetween={20}
        loop={false}
        onSwiper={setSwiper}
        onActiveIndexChange={index => {
          props.setActiveSeriesTagIndex(index.isEnd ? index.activeIndex + 6 : index.activeIndex);
        }}
        breakpoints={{
          960: {
            slidesPerView: 6,
            slidesPerGroup: 6,
            loop: false,
          },
        }}
        navigation={{
          nextEl: `.swiper-button-next`,
          prevEl: `.swiper-button-prev`,
        }}
      >
        {props.tags.map((tag, idx) => {
          return (
            <SwiperSlide tag="li" key={`${idx}${tag.sclasNm}`}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  all_click_datalayer_push(e.currentTarget);
                  history.push(pathUrlFromSeriesContentsType(tag));
                }}
                {...getGAClickProps(tag.sclasNm, '미디어시리즈')}
              >
                <img src={tag.logoImageUri} alt="logo" className="logo_img" />
                <span>{tag.sclasNm}</span>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="swiper-button-prev">
        <img src="/assets/images/icon/movie-list-prev.png" alt="" />
      </div>
      <div className="swiper-button-next">
        <img src="/assets/images/icon/movie-list-next.png" alt="" />
      </div>
    </>
  );
};

export default ControlImageTag;
