// env 설정 파일 불러오기
require('dotenv').config();

/**
 * 서버 URL 값을 로컬 여부에 따라 .env 에서 불러 온다.
 *
 * @return {string} API_URL - API URL 정보
 * @param {string} loadedEnvironmentType - 현재 loaded env 정보
 * @example GET_SERVER_URL_FROM_PARAMS(process.env.REACT_APP_CURRENT_ENV_NAME)
 */
export const GET_SERVER_URL_FROM_PARAMS = (loadedEnvironmentType: string = 'lgdevelopment') => {
  // 현재 load 된 env 설정 파일 타입
  const loadedType = String(loadedEnvironmentType.toLowerCase() ?? '');

  // Production(배포) 경우, DNS 가 여러 개를 사용하기 떄문에 현재 접속된 URL 정보를 가져온다
  // const API_URL = loadedType === 'production' ? window.location.origin : process.env.REACT_APP_API_URL;
  let API_URL;

  // 운영 서버의 경우 내부망(newlife.lg.com), 외부망(lifewu.lg.com 등등 계열사 별로 상이)
  switch (loadedType) {
    case 'production':
      API_URL = location.hostname !== 'localhost' ? window.location.origin : process.env.REACT_APP_WEB_URL;
      break;
    case 'lgdevelopment':
      API_URL = process.env.REACT_APP_WEB_URL;
      break;
    case 'localhost':
    case 'h9development':
      API_URL = process.env.REACT_APP_API_URL;
      break;
    default:
      API_URL = process.env.REACT_APP_API_URL;
  }

  console.log(`*** 구동된 ENV 타입 (Loaded ENV Type) --> ${loadedType} : ${API_URL}`);

  return API_URL;
};

/**
 * 불러온 .evn 의 Type
 *
 * - H9 개발 : h9development
 * - 로컬 : localhost
 * - LG LIFE 개발 : lgdevelopment
 * - LG LIFE 운영 : production
 */
export const GET_LOADED_ENVIRONMENT_TYPE = () => {
  const loadedType = process.env.REACT_APP_CURRENT_ENV_NAME;
  const typeNameList = ['h9development', 'localhost', 'lgdevelopment', 'production'];
  if (!loadedType || !typeNameList.includes(loadedType.toLowerCase())) return;
  return typeNameList.find(item => item === loadedType.toLowerCase()) || 'localhost';
};

/** 로컬 API 서버 기동 시, isLocal 값 true 로 변경 필요 */
export const SERVER_URL = GET_SERVER_URL_FROM_PARAMS(process.env.REACT_APP_CURRENT_ENV_NAME);

/** 현재 로드된 ENV 타입에 따라 75주년 메시지 당첨 결과 이벤트 ID 교체 */
export const CELEBRATION_MESSAGE_WINNER = (loadedEnvironmentType: string = 'lgdevelopment') => {
  const loadType = loadedEnvironmentType === 'production' ? 'production' : 'lgdevelopment';
  return CELEBRATION_MESSAGE_EVENT_ID_LIST.filter(item => item.type === loadType)?.[0]?.value ?? undefined;
};

export const WEB_RESOURCE_DOMAIN_URL: string = process.env.REACT_APP_WEB_RESOURCE_URL as string;
export const TEMP_IMAGE_UPLOAD_API_URL = `${SERVER_URL}/api/fo/common/files/upload`;

export const NAME_CIC_UPDATE = 'shouldSyncCic';

export const PAGE_ITEM_COUNT = 50;
export const VERTUAL_SCROLL_THRESHOLD = 6;
export const WINDOW_RESIZE_THROTTLE = 500;

/** 자동로그인 허용 doamin 목록 */
export const AUTOLOGIN_ACCEPT_DOMAIN_LIST = [{ name: 'LG인화원', code: 'LGACADEMY' }];

/** 75주년 메시지 당첨 결과 이벤트 ID 목록 */
export const CELEBRATION_MESSAGE_EVENT_ID_LIST = [
  { type: 'lgdevelopment', value: 'EVT000000000000000000000144' },
  { type: 'production', value: 'EVT000000000000000000000085' },
];

/** 쿠키와 도메인 값이 일치 해야 하는 도메인 코드 목록 */
export const ALLOWED_COOKIES_DOMAIN_TYPE_LIST = [
  { name: 'LG 생활건강', code: 'LGHNH' },
  { name: 'LG 유플러스', code: 'LGUPLUS' },
  { name: 'LG SNI', code: 'LGSNI' },
];
