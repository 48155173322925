import request from 'services/request';
import { endpoints } from 'services/endpoints';

export default {
  getBoards: (params: { page: string }): Promise<any> =>
    request({
      url: endpoints.myPage.boards,
      method: 'get',
      params,
    }),
  getTopEatsBoards: (params: { page: string }): Promise<any> =>
    request({
      url: endpoints.myPage.topEatsBoards,
      method: 'get',
      params,
    }),
  getMentionsBoards: (params: { page: string }): Promise<MentionsBoardListResDto> =>
    request({
      url: endpoints.myPage.mentionsBoards,
      method: 'get',
      params,
    }),
  getDepartmentBoards: (params: { page: string }): Promise<DepartmentBoardListResDto> =>
    request({
      url: endpoints.myPage.departmentBoards,
      method: 'get',
      params,
    }),
  /** 마이 페이지 - 회사 밖 소모임 게시판 */
  getSocietyBoards: (params: { page: string }): Promise<SocietyBoardListResDto> =>
    request({
      url: endpoints.myPage.societyBoards,
      method: 'get',
      params,
    }),
  // 사용 하지 않는 자유 게시판 좋아요 hook 제거 - 2024.06.27
  // getBoardsLikes: (): Promise<any> =>
  //   request({
  //     url: endpoints.myPage.boardsLikes,
  //     method: 'get',
  //   }),
  getBookMarks: (params: { contentsCnt: string }): Promise<any> =>
    request({
      url: endpoints.myPage.bookmarks,
      method: 'get',
      params,
    }),
  getBriefInfo: (): Promise<any> =>
    request({
      url: endpoints.myPage.briefInfo,
      method: 'get',
    }),
  // 사용 하지 않는 마이 페이지 이벤트 좋아요 hook 제거 - 2024.06.27
  // getEventsLikes: (): Promise<any> =>
  //   request({
  //     url: endpoints.myPage.eventsLikes,
  //     method: 'get',
  //   }),
  getInfo: (): Promise<any> =>
    request({
      url: endpoints.myPage.info,
      method: 'get',
    }),
  putInfo: (params: FormData): Promise<any> =>
    request(
      {
        url: endpoints.myPage.info,
        method: 'put',
        data: params,
      },
      { multiPart: true }
    ),
  putInterestKeyword: (params: IntrKeywordReqDto): Promise<any> =>
    request({
      url: endpoints.myPage.interestKeyword,
      method: 'put',
      data: params,
    }),
  // 사용 하지 않는 마이 페이지 콘텐츠 좋아요 hook 제거 - 2024.06.27
  // getMediasLikes: (params: { contentsCnt: string }): Promise<any> =>
  //   request({
  //     url: endpoints.myPage.mediasLikes,
  //     method: 'get',
  //     params,
  //   }),
  putPassword: (params: UserPasswordReqDto): Promise<any> =>
    request({
      url: endpoints.myPage.password,
      method: 'put',
      data: params,
    }),
  getRecent: (params: { contentsCnt: string }): Promise<any> =>
    request({
      url: endpoints.myPage.recent,
      method: 'get',
      params,
    }),
};
