import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { useTokens } from 'hooks';

import Router from 'routes';
import ScrollTop from './components/common/Scroll/ScrollTop';

const App = (): React.ReactElement => {
  const { saveTokens } = useTokens();

  const removeURLParameters = removeParams => {
    const deleteRegex = new RegExp(removeParams.join('=|') + '=');

    const params = location.search.slice(1).split('&');
    let search = [];
    for (let i = 0; i < params.length; i++) if (deleteRegex.test(params[i]) === false) search.push(params[i] as never);

    window.history.replaceState(
      {},
      document.title,
      location.pathname + (search.length ? '?' + search.join('&') : '') + location.hash
    );
  };

  const accessToken = parse(location.search).accessToken as string;
  const refreshToken = parse(location.search).refreshToken as string;

  if (accessToken && refreshToken) {
    saveTokens(accessToken, refreshToken);
  }

  removeURLParameters(['accessToken', 'refreshToken']);

  return (
    <BrowserRouter>
      <ScrollTop />
      <Router />
    </BrowserRouter>
  );
};

export default App;
