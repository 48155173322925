import React, { ReactElement } from 'react';
import moment from 'moment/moment';
import { getDayOfWeekFromTargetDate } from '../../../utils/LGLifeUtils';

interface ErrorSystemProps {
  startErrorDate: Date;
  endErrorDate: Date;
}

const ErrorSystem = (props: ErrorSystemProps): ReactElement => {
  return (
    <div className="container login_sub">
      <div className="login_inner">
        <div className="login_top">
          <div className="login_logo">
            <h1>
              <span className="ab_text">LG LIFE</span>
            </h1>
          </div>
          <div className="error_404">
            <img src="/assets/images/illust/illust-browser-error.png" alt="login" className="error_404_img" />
          </div>
        </div>
        <div className="login_box is-complete">
          <div className="login_complete">
            <div className="title">[LG LIFE 정기 점검 안내]</div>
            <div className="subtxt">
              현재 LG LIFE 시스템 정기 점검 중 입니다.
              <br />
              점검이 완료될 때까지 잠시만 기다려 주십시오
              <br />
              (점검 일정 : {moment(props.startErrorDate).format('YYYY.MM.DD')} (
              {getDayOfWeekFromTargetDate(props.startErrorDate)}) {moment(props.startErrorDate).format('HH:mm')} ~{' '}
              {moment(props.endErrorDate).format('YYYY.MM.DD')} ({getDayOfWeekFromTargetDate(props.endErrorDate)}){' '}
              {moment(props.endErrorDate).format('HH:mm')})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorSystem;
