import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';

const useContinue = () => {
  const [mergedContinue, setMergedContinue] = useState<MediaContinueReqDto[]>([]);

  const { info } = useMyInfo();

  const { data: continueData } = useSWR<any>(info ? [endpoints.media.continue, info] : null, () =>
    fetcher.media.getMediaContinue().then(res => res)
  );

  const postMediaContinue = (params: MediaContinueReqDto[]) =>
    fetcher.media
      .postMediaContinue({
        mediaContinueReqList: params,
      })
      .then(res => {
        if (res.resultCode === 200) setLocalContinue([]);
      });

  const getlocalContinue = useCallback((): MediaContinueReqDto[] => {
    return JSON.parse(localStorage.getItem('continue') || JSON.stringify([]));
  }, []);

  const setLocalContinue = useCallback(data => {
    localStorage.setItem('continue', JSON.stringify(data));
  }, []);

  const getTimeLine = useCallback(
    (mediaId?: string): number =>
      (
        mergedContinue?.filter(
          (mediaContinue: MediaContinueResDto) => mediaContinue.mediaId === mediaId
        )[0] as MediaContinueResDto
      )?.timeLine || 0,
    [continueData, mergedContinue]
  );

  const updateTime = useCallback((mediaId: string, time: number) => {
    const localContinue = getlocalContinue();
    let targetIndex = localContinue?.length;

    localContinue?.some((local, index) => {
      if (local.mediaId === mediaId) {
        targetIndex = index;
        return true;
      }
      return false;
    });

    localContinue?.splice(targetIndex, 1, { mediaId: mediaId, timeLine: time } as MediaContinueReqDto);
    setLocalContinue(localContinue);
  }, []);

  const updateMediaContinue = useCallback(() => {
    postMediaContinue(getlocalContinue());
  }, []);

  useEffect(() => {
    if (continueData) {
      const localContinue = getlocalContinue();
      if (localContinue?.length) {
        let copiedOriginal = continueData?.mediaContinueList?.map((original: MediaContinueResDto) => ({
          ...original,
        })) as MediaContinueReqDto[];

        let targetIndex: number;

        localContinue?.forEach((local: MediaContinueReqDto) => {
          const newData = { mediaId: local.mediaId, timeLine: local.timeLine };
          targetIndex = copiedOriginal.length;

          copiedOriginal?.some((original: MediaContinueResDto, index) => {
            if (original.mediaId === local.mediaId) {
              targetIndex = index;
              return true;
            }
            return false;
          });

          copiedOriginal.splice(targetIndex, 1, newData);
        });
        setMergedContinue(copiedOriginal);
      } else {
        setMergedContinue(continueData?.mediaContinueList);
      }
    }
  }, [continueData]);

  return { mergedContinue, getTimeLine, updateTime, updateMediaContinue };
};

export default useContinue;
