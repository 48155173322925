import { useMemo } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { useHistory } from 'react-router-dom';
import Paths from 'commons/paths';
import useMyInfo from '../../useMyInfo';
import { isDetailsFor } from 'utils/utils';
import useBoardList from './useBoardList';

const useBoardDetail = (boardId: string) => {
  const history = useHistory();
  const { info } = useMyInfo();
  const { boardListMutate } = useBoardList();

  const isBoardDetailPage = useMemo(
    () => isDetailsFor(Paths.getPath('boardList').replace('/list', '')),
    [location.pathname]
  );

  const { data: boardDetail, mutate } = useSWR<any>(
    info && isBoardDetailPage && boardId ? [`${endpoints.boards.board}/${boardId}`, boardId] : null,
    url =>
      fetcher.boards.getBoardDetail(url).then(res => {
        return res;
      })
  );

  return {
    boardDetail,
    mutate,
    postBoardLike() {
      fetcher.boards.postBoardLike({ boardId }).then(res => {
        if (res.resultCode === 200) mutate();
      });
    },
    deleteBoard() {
      fetcher.boards.deleteBoard(`${endpoints.boards.board}/${boardId}`).then(res => {
        if (res.resultCode === 200) boardListMutate().finally(() => history.push(Paths.getPath('boardList')));
      });
    },
  };
};

export default useBoardDetail;
