import React from 'react';

const DetailBack = () => {
  return (
    <div className="detail_back">
      <button className="detail_back_btn" onClick={() => window.history.back()}>
        <img className="is-mobile" src="/assets/images/icon/ico-back-btn.png" alt="back" />
        <img className="is-pc" src="/assets/images/icon/ico-action-64-m-back.png" alt="back" />
      </button>
    </div>
  );
};

export default DetailBack;
