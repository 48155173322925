import { createContext } from 'react';

export const initialState = {
  email: '',
  setEmail: () => {},
  name: '',
  setName: () => {},
  empNum: '',
  setEmpNum: () => {},
  password: '',
  setPassword: () => {},
  groupNm: '',
  setGroupNm: () => {},
  groupCode: '',
  setGroupCode: () => {},
  positionNm: '',
  setPositionNm: () => {},
  phoneNum: '',
  setPhoneNum: () => {},
  department: '',
  setDepartment: () => {},
} as {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  empNum: string;
  setEmpNum: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  groupNm: string;
  setGroupNm: React.Dispatch<React.SetStateAction<string>>;
  groupCode: string;
  setGroupCode: React.Dispatch<React.SetStateAction<string>>;
  positionNm: string;
  setPositionNm: React.Dispatch<React.SetStateAction<string>>;
  phoneNum: string;
  setPhoneNum: React.Dispatch<React.SetStateAction<string>>;
  department: string;
  setDepartment: React.Dispatch<React.SetStateAction<string>>;
};

const RegisterContext = createContext(initialState);

export default RegisterContext;
