import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import InnerHTML from 'dangerously-set-html-content';
import { SERVER_URL, WEB_RESOURCE_DOMAIN_URL } from '../../../commons/constants';
import usePopupInfo from '../../../hooks/usePopupInfo';
import { matchPath, useHistory } from 'react-router-dom';
import paths from '../../../commons/paths';
import Paths from '../../../commons/paths';
import { getDisplayedOnboardingContentsUrl } from '../../../utils/LGLifeUtils';

interface OnboardingPopupProps {
  /** 팝업 오픈 여부 */
  isOpen: boolean;
  /** 팝업 이미지 URI */
  popupImageUri: string;
  /** 클릭 시, 이동할 URL */
  linkUrl: string;
  onClose: () => void;
}

const OnboardingPopup = ({ isOpen, popupImageUri, linkUrl, onClose }: OnboardingPopupProps) => {
  const { readOnboardingPopupInfo } = usePopupInfo();

  /** 이미지 URI */
  const onboardingPopupImageUri = useMemo(() => {
    if (!popupImageUri) return '';
    return String(
      popupImageUri.indexOf('/files') > -1
        ? window.location.hostname.includes('localhost')
          ? WEB_RESOURCE_DOMAIN_URL + popupImageUri
          : window.location.origin + popupImageUri
        : popupImageUri || ''
    );
  }, [popupImageUri]);

  /**
   * EP 페이지인지  확인
   */
  const isEpPage = useMemo(() => {
    return !!matchPath(location.pathname, paths.getPath('ep'));
  }, [location.pathname]);

  /**
   * 온보딩 팝업 닫기
   */
  const onClosedOnboardingPopup = () => {
    readOnboardingPopupInfo()
      .then(res => {
        if (res.resultCode !== 200) {
          console.error(res.resultMessage);
        }
      })
      .finally(() => {
        onClose();
      });
    onClose();
  };

  /**
   * 온보딩 팝업 이미지 클릭
   */
  const onClickOnboardingPopupImage = () => {
    if (!linkUrl) return;
    getDisplayedOnboardingContentsUrl({
      linkUrl: linkUrl,
    })
      .then(redirectUrl => {
        if (!redirectUrl) return;
        window.open(redirectUrl, '_blank');
      })
      .finally(() => onClosedOnboardingPopup());
  };

  return (
    <>
      {isOpen && !isEpPage && onboardingPopupImageUri && (
        <div className="popup onboarding_popup is-active">
          <div className="popup_dim"></div>
          <div className="onboarding_pop">
            <div className="pop_scroll" style={{ cursor: 'pointer' }} onClick={onClickOnboardingPopupImage}>
              <img src={onboardingPopupImageUri} alt="" />
            </div>
            <button type="button" onClick={() => onClosedOnboardingPopup()} className="close popup_close">
              앞으로 보지 않기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

OnboardingPopup.displayName = 'OnboardingPopup';

export default OnboardingPopup;
