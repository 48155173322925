import React, { useState } from 'react';
import VideoContext from 'contexts/VideoContext';

const VideoProvider = ({ children }) => {
  const [pip, setPip] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mediaId, setMediaId] = useState('');

  const initialValue = {
    pip: pip,
    setPip: setPip,
    visible: visible,
    setVisible: setVisible,
    mediaId: mediaId,
    setMediaId: setMediaId,
  };

  return <VideoContext.Provider value={initialValue}>{children}</VideoContext.Provider>;
};

export default VideoProvider;
