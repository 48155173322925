import React from 'react';

export interface TagInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  noSelect: boolean;
}

const TagInput = React.forwardRef<HTMLInputElement, TagInputProps>(
  ({ onChange, onKeyPress, value, noSelect }: TagInputProps, ref) => {
    return (
      <input
        type="text"
        className="write_input write_tag"
        placeholder={noSelect ? '태그를 입력해주세요' : '태그 선택 또는 입력해주세요'}
        ref={ref}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        title="검색 태그 입력"
      />
    );
  }
);

export default TagInput;
