import { useEffect, useRef, useState } from 'react';
import useCommentMention from '../../../hooks/useCommentMention';
import { all_click_datalayer_push, getCommentGAProps } from '../../../utils/GAUtils';
import { useCommentList, useCommentReply } from '../../../hooks';
import { mobileCheck } from '../../../utils/utils';
import { endpoints, fetcher } from '../../../services';
import { DetailCommentProps } from '../../organisms/DetailComment';
import {
  convertMentionCodeDataFromHtmlMentionTypeData,
  getCommentContentsByReplacedMentionTagToGoalBangYee,
} from '../../../utils/mentionUtils';
import { getHtmlFormatByEscapeHtmlData } from '../../../utils/LGLifeUtils';
import useMyInfo from '../../../hooks/useMyInfo';

interface MentionCommentProps extends DetailCommentProps {
  isReply: boolean;
  commentId?: string; // 답글의 경우 1 Depth CommentId 필요
  setReplyList?: any; // 답글의 경우 필요
  update?: () => void; // 댓글 mutate
  cmtDivRef: any;
  cmtInputRef: any;
  isMobileCmtInput?: boolean;
  isMobile?: boolean;
  cmtReplyRef?: any;
  commentSearchMailWidth: () => void;
  mobModifyRef?: any;
  setAddCancelPopup?: any;
  openReplyCommentArea?: () => void;
}

const MentionAddComment = (props: MentionCommentProps) => {
  const { info } = useMyInfo();
  const { addComment } = useCommentList(props.contentsId, props.commentPostType);
  const { addReply } = useCommentReply(props.commentId);

  const { getMentionUserInfo } = useCommentMention();

  const [comment, setComment] = useState<string>('');
  const [mentionSearchList, setMentionSearchList] = useState([] as any[]);
  const [isSubmitBtn, setIsSubmitBtn] = useState<boolean>(false);
  const [focusStart, setFocusStart] = useState<boolean>(true);
  /** 멘션 임직원 검색 placeholder */
  const [mentionSearchPlaceHolderContents, setMentionSearchPlaceHolderContents] =
    useState<string>('임직원 이름 입력 후 엔터를 눌러 주세요.');

  const mentionSearchDivRef = useRef<HTMLDivElement>(null);
  const mentionSearchInputRef = useRef<HTMLInputElement>(null);

  const prevCommentRef = useRef<String>('');
  const mentionIdx = useRef<number>(0);
  const focusAfterRemoveAt = useRef<boolean>(false);

  useEffect(() => {
    if (comment) {
      commentDivFocus();
      if (focusAfterRemoveAt.current === true) mentionSearchInputRef.current?.focus();
    }
  }, [comment]);

  useEffect(() => {
    if (mentionSearchList) {
      props.commentSearchMailWidth();
    }
  }, [mentionSearchList]);

  const mentionUserSearch = (searchName: string) => {
    getMentionUserInfo(searchName).then(res => {
      if (res.resultCode === 200) {
        setMentionSearchList(res.userSimpleResDtoList);
        if (!res.userSimpleResDtoList?.length) {
          setMentionSearchPlaceHolderContents('검색 결과가 없습니다.');
        }
      }
    });
  };

  const addMentionUser = (user: UserSimpleResDto) => {
    const mentionTag =
      `<div class="mention_tag" contenteditable="false"` +
      ` data-mention-name="${user.name}"` +
      ` data-mention-user-id="${user.userId}"` +
      ` data-mention-group-name="${user.groupNm}">` +
      `<p>@</p><span>${user.name}</span><span>${user.groupNm}</span>` +
      `</div>&nbsp;`;

    if (props.cmtDivRef.current) {
      const originComment = props.cmtDivRef.current?.innerHTML.replace('<br><br>', '<br>');
      // 멘션 검색창에서 클릭 시, 입력한 골뱅이 있는 자리에 멘션 태그 대체
      setComment(
        originComment.substr(0, mentionIdx.current) +
          mentionTag +
          originComment.substr(mentionIdx.current + 1, originComment.length)
      );
    }

    mentionSearchDivRef.current?.classList.remove('on');
    setMentionSearchList([]);
    setMentionSearchPlaceHolderContents('임직원 이름 입력 후 엔터를 눌러 주세요.');

    if (mentionSearchInputRef.current) {
      mentionSearchInputRef.current.value = '';
    }

    if (props.isMobile && props.mobModifyRef) {
      props.mobModifyRef.current?.focus();
    }

    setFocusStart(false);
  };

  const commentDivFocus = () => {
    const sel = window.getSelection();
    const innerDiv = props.cmtDivRef.current;

    if (innerDiv) {
      if (focusStart) {
        sel?.collapse(innerDiv, 0);
        setFocusStart(false);
      } else {
        if (innerDiv.lastChild) {
          if (innerDiv.lastChild.innerHTML) {
            sel?.collapse(innerDiv.lastChild, 1);
          } else {
            sel?.collapse(innerDiv.lastChild, innerDiv.lastChild.textContent?.length || 0);
          }
        } else {
          innerDiv.innerHTML = '';
          sel?.collapse(innerDiv, 0);
        }
      }

      document.querySelector('.detail_comment_inner')?.classList.add('is-active');

      if (props.isMobile && props.mobModifyRef) {
        props.mobModifyRef.current?.focus();
      }
    }
  };

  /**
   * 댓글 작성 시, keyDown Event 감지 - 멘션 감지
   * @param e KeyboardEvent<HTMLDivElement> 이벤트
   */
  const onCommentKeyDownEvent = e => {
    prevCommentRef.current = e.target.innerHTML;
    if (e.key === 'Enter' && props.cmtDivRef.current?.innerText.length === 0) {
      e.preventDefault();
      return;
    }
  };

  /**
   * '@' 입력 직전 입력 값 체크
   * @param nextPrevComment @ 입력한 바로 직전 댓글 내용
   */
  const checkMentionAt = (nextPrevComment: string) => {
    switch (nextPrevComment) {
      case ' ': // @앞이 공백일 때
      case '\n': // 줄바꿈 했을 때
      case '>': // 줄바꿈 했을 때
      case ';': // 맨션 뒤 공백일 때
        return true;
      default:
        return false;
    }
  };

  /**
   * 댓글 작성 시, Element 변화 감지
   * @param e FormEvent<HTMLDivElement> 이벤트
   */
  const onInputComment = e => {
    const nextComment = String(e.target.innerHTML);

    focusAfterRemoveAt.current = false;

    if (e.nativeEvent.data === '@') {
      for (let i = 0; i < nextComment.length; i++) {
        let prevVal = prevCommentRef.current.charAt(i);
        let nextVal = nextComment.charAt(i);
        let nextPrevComment = nextComment.charAt(i - 1);

        if (prevVal === nextVal) continue;
        if (prevCommentRef.current === '' || (nextVal === '@' && (i === 0 || checkMentionAt(nextPrevComment)))) {
          const removedAtComment = nextComment.substr(0, i) + nextComment.substr(i, nextComment.length);
          props.cmtDivRef.current.innerHTML = removedAtComment;
          setComment(removedAtComment);
          mentionIdx.current = i;
          focusAfterRemoveAt.current = true;
          mentionSearchDivRef.current?.classList.add('on');
          mentionSearchInputRef.current?.focus();
          break;
        }
      }
    }
  };

  return (
    <>
      <div
        contentEditable
        suppressContentEditableWarning
        ref={props.cmtDivRef}
        title={props.isReply ? '답글.' : '댓글'}
        placeholder={`'@'를 입력해 보세요. 동료 임직원을 소환할 수 있어요!`}
        style={{ resize: 'none' }}
        className="comment_textarea"
        dangerouslySetInnerHTML={{ __html: getHtmlFormatByEscapeHtmlData(comment) }}
        onInput={e => onInputComment(e)}
        onKeyDown={e => onCommentKeyDownEvent(e)}
        onKeyUp={e => {
          if (
            mentionSearchDivRef.current?.classList.contains('on') &&
            (e.key === ' ' || e.key === 'Backspace' || e.key === 'Delete')
          ) {
            setMentionSearchList([]);
            setMentionSearchPlaceHolderContents('임직원 이름 입력 후 엔터를 눌러 주세요.');
            mentionSearchDivRef.current?.classList.remove('on');

            setComment(comment);
            commentDivFocus();
          }
        }}
        onMouseDown={() => {
          if (mentionSearchDivRef.current?.classList.contains('on')) {
            const curComment = props.cmtDivRef.current?.innerHTML;
            setComment(curComment);
            mentionSearchDivRef.current?.classList.remove('on');

            setMentionSearchList([]);
            setMentionSearchPlaceHolderContents('임직원 이름 입력 후 엔터를 눌러 주세요.');

            if (mentionSearchInputRef.current) {
              mentionSearchInputRef.current.value = '';
            }

            commentDivFocus();
          }
        }}
        onBlur={() => {
          if (props.isMobile && props.isReply) {
            if (!mentionSearchDivRef.current?.classList.contains('on') || isSubmitBtn) {
              if (!mentionSearchDivRef.current?.classList.contains('on') && !isSubmitBtn) {
                props.setAddCancelPopup(true);
                return;
              }

              props.cmtReplyRef.current?.classList.remove('is-reply-active');
              document.querySelector('nav')?.style.setProperty('display', 'block');

              setComment('');
              props.cmtDivRef.current.innerHTML = '';

              setIsSubmitBtn(false);
              setFocusStart(true);
            }
          } else {
            document.querySelector('nav')?.style.setProperty('display', '');
            document.querySelector('.detail_comment_inner')?.classList.remove('is-active');
          }
        }}
      ></div>

      <div className="comment_btn_wrap">
        {props.isMobile && !props.isMobileCmtInput && props.isReply && (
          <button className="comment_btn btn-cancel is-mobile">취소</button>
        )}
        <button
          className="comment_btn"
          onMouseDownCapture={e => {
            if (props.isMobile) {
              setIsSubmitBtn(true);
            }
          }}
          onMouseDown={() => {
            if (props.cmtDivRef.current?.innerHTML) {
              const dataHtml = convertMentionCodeDataFromHtmlMentionTypeData(props.cmtDivRef.current);

              if (props.isReply) {
                addReply({
                  contentsId: props.contentsId,
                  contentsType: props.commentPostType,
                  contents: dataHtml,
                  contentsText: getCommentContentsByReplacedMentionTagToGoalBangYee(dataHtml, {
                    hasRemoveHtmlTag: true,
                    skipBrTag: true,
                  }),
                  mobileRegYn: mobileCheck() ? 'Y' : 'N',
                  refCommentId: props.commentId,
                }).then(res => {
                  if (res.resultCode === 200) {
                    props.update && props.update();
                    fetcher.comment.getReplyComment(`${endpoints.comment.comment}/${props.commentId}`).then(res => {
                      if (res.resultCode === 200) props.setReplyList(res.commentReplyList);
                    });
                  }
                });
              } else {
                addComment({
                  contents: dataHtml,
                  contentsText: getCommentContentsByReplacedMentionTagToGoalBangYee(dataHtml, {
                    hasRemoveHtmlTag: true,
                    skipBrTag: true,
                  }),
                  contentsId: props.contentsId,
                  contentsType: props.commentPostType,
                  mobileRegYn: mobileCheck() ? 'Y' : 'N',
                });
              }

              setComment('');
              props.cmtDivRef.current.innerHTML = '';

              if (props.isMobileCmtInput) {
                props.cmtReplyRef.current?.classList.remove('is-reply-active');
                document.querySelector('nav')?.style.setProperty('display', 'block');

                setComment('');
                props.cmtDivRef.current.innerHTML = '';
              } else {
                props.cmtInputRef.current.classList.remove('is-active');
                document.querySelector('nav')?.style.setProperty('display', '');
                document.querySelector('.detail_comment_inner')?.classList.remove('is-active');
              }
            }
          }}
          {...getCommentGAProps({
            commentType: props.isReply ? '답글' : '댓글',
            tags: props.tags as string,
            type: props.isNews && props.newsType ? props.newsType : props.magazineType ? 'CARD' : props.commentPostType,
            name: props.title,
            time: props.time ? props.time : -1,
            category: props.category,
            creatorNm: info?.name || '',
            brightcoveId: props.brightcoveId || '',
            eventType: props.eventType,
            magazineType: props.magazineType?.toString() || '',
            mediaContentsType: props.mediaContentType,
            eventStatus: props.eventStatus,
            groupNm: info?.groupNm || '',
            dataContentUrl: location.pathname,
          })}
          onClick={e => {
            all_click_datalayer_push(e.currentTarget);
            if (props.isReply && props.openReplyCommentArea) {
              props.openReplyCommentArea();
            }
          }}
        >
          <span className="is-blind">입력</span>
        </button>
      </div>

      <div className="comment_search_box" ref={mentionSearchDivRef}>
        <div className="search_box_area">
          <div className="search_box_inner">
            <div className="search_box_input">
              <input
                type="text"
                placeholder="이름을 입력해주세요."
                ref={mentionSearchInputRef}
                onKeyDown={e => {
                  if (e.key === 'Enter' && mentionSearchInputRef.current?.value) {
                    mentionUserSearch(mentionSearchInputRef.current?.value);
                  } else if (e.key === 'Delete' || e.key === 'Backspace') {
                    setMentionSearchList([]);
                    setMentionSearchPlaceHolderContents('임직원 이름 입력 후 엔터를 눌러 주세요.');
                  } else if (e.key === 'Escape') {
                    const curComment = props.cmtDivRef.current?.innerHTML;

                    if (mentionSearchDivRef.current?.classList.contains('on')) {
                      setComment(curComment);
                      mentionSearchDivRef.current?.classList.remove('on');
                    }

                    setMentionSearchList([]);
                    setMentionSearchPlaceHolderContents('임직원 이름 입력 후 엔터를 눌러 주세요.');

                    if (mentionSearchInputRef.current) {
                      mentionSearchInputRef.current.value = '';
                    }

                    commentDivFocus();
                  }
                }}
              />
              <input
                type="button"
                onMouseDown={e => {
                  if (mentionSearchInputRef.current?.value) {
                    mentionUserSearch(mentionSearchInputRef.current?.value);
                  }
                }}
              />
            </div>

            <div className="comment_search_result">
              {mentionSearchList && mentionSearchList.length > 0 ? (
                <>
                  <div className="search_result_list">
                    <ul>
                      {mentionSearchList.map((user: UserSimpleResDto, idx: number) => {
                        return (
                          <li
                            key={idx}
                            onMouseDown={e => {
                              if (props.isMobile && props.mobModifyRef) {
                                props.mobModifyRef.current?.focus();
                              }

                              addMentionUser(user);
                            }}
                          >
                            <div className="result_list_name">
                              <strong>{user.name}</strong>
                              <strong>{user.positionNm}</strong>
                              <span>{user.groupNm}</span>
                              <span>{user.departmentNm}</span>
                            </div>
                            <div className="result_list_mail">
                              <a href="#">{user.email}</a>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              ) : (
                <p>{mentionSearchPlaceHolderContents}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentionAddComment;
