import React, { useEffect, useRef } from 'react';
import { CommentPostType } from '../../../types/enum/CommentPostType';

interface EmojiCommentProps {
  clickEmoji: (emoji: string) => void;
  commentPostType: CommentPostType;
  tags: string;
  title: string;
  category: string;
  contentCreatorNm: string;
  time?: number;
  brightcoveId?: string;
  eventType?: 'EDITOR' | 'SURVEY' | 'M_QUIZ' | 'QUIZ_WINNER' | 'SIMPLE_SURVEY';
  eventStatus?: 'ING' | 'END' | 'ALL';
  mediaContentType?: string;
  isNews?: boolean;
}

const EmojiComment = (props: EmojiCommentProps) => {
  const emojiBoxRef = useRef<HTMLDivElement>(null);
  const emojiMoreRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    window.addEventListener('click', handleClose);
    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, []);

  const handleClose = e => {
    if (!emojiMoreRef.current?.contains(e.target) && !emojiBoxRef.current?.contains(e.target))
      emojiBoxRef.current?.classList.remove('is-active');
  };

  const clickMore = e => {
    e.preventDefault();
    emojiBoxRef.current?.classList.toggle('is-active');
  };

  const clickEmoji = e => {
    e.preventDefault();
    props.clickEmoji(e.currentTarget.innerText);
  };

  const emojiList = ['😊', '😭', '❤️', '👍', '🍺', '👏'].map((emoji, idx) => (
    <li key={idx}>
      <a
        className="emoji_item"
        href="#"
        onClick={e => {
          clickEmoji(e);
        }}
      >
        {emoji}
      </a>
    </li>
  ));

  const emojiMore = [
    '😁',
    '😭',
    '❤️',
    '👍',
    '🍺',
    '👏',
    '😍',
    '😎',
    '🥇',
    '🎁',
    '🤭',
    '🥳',
    '😱',
    '🤩',
    '😵',
    '😷',
    '😥',
    '😝',
    '💯',
    '👌',
    '☀️',
    '☂️',
    '⛄️',
    '🍀',
    '🎮',
    '🎧',
    '📢',
    '🥄',
    '⚾',
    '🚙',
    '🧡',
    '💛',
    '💚',
    '💙',
    '💜',
    '🖤',
  ].map((emoji, idx) => (
    <li key={'more' + idx}>
      <a
        className="emoji_box_item"
        href="#"
        onClick={e => {
          clickEmoji(e);
        }}
      >
        {emoji}
      </a>
    </li>
  ));

  return (
    <>
      <ul className="emoji_list">{emojiList}</ul>
      <a className="emoji_more" onClick={clickMore} ref={emojiMoreRef} href="#">
        <img src="/assets/images/icon/ico-imoji-more.png" alt="more" className="emoji_more_img" />
      </a>
      <div className="emoji_box" ref={emojiBoxRef}>
        <ul className="emoji_box_list custom_scroll">{emojiMore}</ul>
      </div>
    </>
  );
};

export default EmojiComment;
