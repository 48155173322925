import React from 'react';
import { Link } from 'react-router-dom';
import { all_click_datalayer_push, getBoardGAProps } from 'utils/GAUtils';
import { dateYYYYMMDD } from 'utils/utils';
import ProfileImage from 'components/atoms/ProfileImage';
import { getHtmlFormatByEscapeHtmlData } from '../../../../../utils/LGLifeUtils';
import Paths from '../../../../../commons/paths';

interface Props extends BoardCardResDto {
  ga_belong: string;
}

const BoardListItem = (props: Props) => {
  return (
    <tr className="list_item">
      <td className="list_cell cell_tag">
        <span className="card_thumb_flag is-cate">{props.categoryNm}</span>
      </td>
      <td className="list_cell cell_writer">
        <ProfileImage
          name={props.creatorNm as string}
          score={props.creatorActScore as number}
          image={props.creatorProfileUri}
          isAdmin={props.creatorAdminYn === 'Y'}
        />
        <span className="list_writer">
          {props.creatorAdminYn === 'Y'
            ? '관리자'
            : `${props.creatorNm} ${props.creatorGroupNm ? props.creatorGroupNm : ''}`}
        </span>
      </td>
      <td className="list_cell cell_title">
        <Link
          to={`${Paths.getPath('boardDetail').replace(':boardId', `${props.boardId}`)}`}
          className="list_title_link"
          {...getBoardGAProps(
            {
              boardType: props.boardType,
              title: getHtmlFormatByEscapeHtmlData(props.title || ''),
              category: props.categoryNm,
              dataContentBelong: '커뮤니티',
              maker: props.creatorNm,
              postAffiliate: props.creatorGroupNm,
              dataContentUrl: `${Paths.getPath('boardDetail').replace(':boardId', `${props.boardId}`)}`,
            },
            {
              dataclick: 'boardClick',
            }
          )}
          onClick={e => {
            all_click_datalayer_push(e.currentTarget);
          }}
        >
          <span className="list_title">{getHtmlFormatByEscapeHtmlData(props.title || '')}</span>
        </Link>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-action-20-view.svg" className="list_text_icon" alt="views" />
        <span className="list_text list_view">{props.readCnt}</span>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-comments.png" className="list_text_icon" alt="comments" />
        <span className="list_text list_comments">{props.commentCnt}</span>
      </td>
      <td className="list_cell cell_small">
        <img src="/assets/images/icon/ico-likes.png" className="list_text_icon" alt="likes" />
        <span className="list_text list_likes">{props.likeCnt}</span>
      </td>
      <td className="list_cell">
        <div className="list_date">{dateYYYYMMDD(props.createdDt)}</div>
      </td>
    </tr>
  );
};

export default BoardListItem;
