import useSWR from 'swr';
import queryString, { parse } from 'query-string';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';
import { useMemo, useState } from 'react';

const useSearch = () => {
  const { info } = useMyInfo();

  const keyword = parse(location.search).keyword as string;

  /** 검색어 쿼리 스트링 */
  const searchKeyword = useMemo(() => {
    if (!location.search) return '';
    const query = queryString.parse(location.search);
    return String(query.keyword || '');
  }, [location.search]);

  const isSearchPage = useMemo(() => /^\/search/gi.test(location.pathname), [location.pathname]);

  const [searchString, setSearchString] = useState(searchKeyword);

  const { data: searchResult } = useSWR<any>(
    info && keyword && isSearchPage ? [endpoints.search.search, keyword] : null,
    () =>
      fetcher.search.getSearchResult({ searchWord: keyword }).then(res => {
        if (res.resultCode === 200) {
          const { resultCode, resultMessage, ...data } = res;
          return data;
        } else {
          console.log(res.resultMessage);
        }
      })
  );

  const { data: autoCompleteList } = useSWR<any>(
    info && searchString.trim() ? [endpoints.search.autoComplete, searchString.trim()] : null,
    () =>
      fetcher.search.getAutoComplete({ searchWord: searchString.trim() }).then((res: SearchAutoCompleteListResDto) => {
        if (res.resultCode === 200) {
          const { resultCode, resultMessage, ...data } = res;
          return data;
        } else {
          console.log(res.resultMessage);
        }
      })
  );

  return {
    searchResult,
    autoCompleteList,
    searchKeyword,
    searchString,
    setSearchString,
  };
};

export default useSearch;
