import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import BestCard from 'components/molecules/BestCard';
import { getContentsGAProps } from 'utils/GAUtils';
import paths from '../../../../../commons/paths';

const MainBest = (props: MainPopularContentListResDto) => {
  const { popularContentList } = props;
  const [screenSize, setScreenSize] = useState('lg');

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="main_section best">
        <div className="main_inner">
          <div className="con_top">
            <h3 className="con_top-title">인기 콘텐츠</h3>
          </div>
        </div>
        <div className="main_row">
          {screenSize === 'sm' ? (
            <Swiper threshold={10} slidesPerView={1.4} className="card_row bestSwiper">
              {popularContentList &&
                popularContentList.map((p, idx) => (
                  <SwiperSlide className="card" key={idx}>
                    <BestCard
                      {...p}
                      rank={idx + 1}
                      path={`${paths.getPath('videoDetail').replace(':mediaId', p.mediaId as string)}`}
                      isSwiperSlide={true}
                      key={idx}
                      isTitle2={true}
                      GAObj={getContentsGAProps({
                        contentsType: 'MEDIA',
                        title: p?.title as string,
                        tags: p?.mediaTag as string,
                        category: p?.cateNm as string,
                        dataContentBelong: '인기콘텐츠',
                        maker: p?.creatorNm as string,
                        time: p.videoTotalTime,
                        brightcoveId: p?.videoId as string,
                        mediaContentsType: p?.mediaContentsType as string,
                        dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', p.mediaId as string)}`,
                        dataImgUrl: `${p?.thumbImageUri as string}`,
                      })}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            <div className="card_row has-hover bestSwiper">
              <div className="card_wrapper">
                {popularContentList?.map((p, idx) => (
                  <BestCard
                    {...p}
                    rank={idx + 1}
                    path={`${paths.getPath('videoDetail').replace(':mediaId', p.mediaId as string)}`}
                    isSwiperSlide={false}
                    key={idx}
                    isTitle2={true}
                    GAObj={getContentsGAProps({
                      contentsType: 'MEDIA',
                      title: p?.title as string,
                      tags: p?.mediaTag as string,
                      category: p?.cateNm as string,
                      dataContentBelong: '인기콘텐츠',
                      maker: p?.creatorNm as string,
                      time: p.videoTotalTime,
                      brightcoveId: p?.videoId as string,
                      mediaContentsType: p?.mediaContentsType as string,
                      dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', p.mediaId as string)}`,
                      dataImgUrl: `${p?.thumbImageUri as string}`,
                    })}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MainBest;
