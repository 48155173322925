import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';
import { NoticeListRequest } from '../services/fetcher/notice';

/** 알림 상세 유형 */
export type NoticeDetailType = 'ALL' | 'MENTIONS' | 'LIKE' | 'BRANDING';

const useNotice = (initNoticeType?: NoticeDetailType) => {
  const { info } = useMyInfo();

  const { data: notice, mutate: noticeMutate } = useSWR<NoticeListResDto>(
    info ? endpoints.notice.notice : null,
    async () => {
      return await fetcher.notice.getNotice({
        noticeDetailType: initNoticeType ? initNoticeType : 'BRANDING',
      });
    }
  );

  const { data: noneReadNoticeCountListResDto, mutate: noticeCntMutate } = useSWR<NoneReadNoticeCountListResDto>(
    info ? endpoints.notice.newNoticeCount : null,
    () =>
      fetcher.notice.getNewNoticeCount().then(res => {
        return res;
      })
  );

  /**
   * 알림 상세 유형에 따른 읽지 않은 알림 개수 조회
   * - 'ALL' 일 경우: 전체 알림 목록 조회
   * @param params 알림 상세 유형
   */
  const getNewNoticeCountByNoticeType = async (params?: NoticeListRequest) => {
    if (params && params?.noticeDetailType) {
      return await fetcher.notice.getNewNoticeCount(params);
    }

    return await fetcher.notice.getNewNoticeCount();
  };

  /**
   * 알림 상세 유형에 따른 알림 읽음 처리
   * - noticeId 값이 있을 경우: 해당 알림만 읽음 처리
   * - noticeId 값이 없을 경우: 전체 알림 읽음 처리
   * @param params
   */
  const readNotice = async (params: NoticeReadReqDto) => {
    return await fetcher.notice.readNotice(params).then(res => {
      noticeMutate();
      noticeCntMutate();
      return res;
    });
  };

  /**
   * 알림 상세 유형에 따른, 알림 목록 조회
   * @param noticeDetailType 알림 상세 유형
   */
  const getNoticeListByNoticeType = async (noticeDetailType: NoticeDetailType) => {
    if (noticeDetailType !== 'ALL') {
      return await fetcher.notice.getNotice({
        noticeDetailType: noticeDetailType,
      } as NoticeListRequest);
    }
    return await fetcher.notice.getNotice();
  };

  const { data: userProfileData } = useSWR<any>(info ? endpoints.common.profile : null, () =>
    fetcher.common.getUserProfile().then(res => {
      return res;
    })
  );

  return {
    notice,
    newNoticeTotalCount:
      noneReadNoticeCountListResDto && noneReadNoticeCountListResDto?.noticeTotalCnt
        ? noneReadNoticeCountListResDto.noticeTotalCnt
        : 0,
    newNoticeCountList: noneReadNoticeCountListResDto as NoneReadNoticeCountListResDto,
    readNotice,
    userProfileData,
    getNoticeListByNoticeType,
    getNewNoticeCountByNoticeType,
    noticeMutate,
    noticeCntMutate,
  };
};

export default useNotice;
