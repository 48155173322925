import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { SelectBox, FileUploader, Popup } from 'components/atoms';
import QnaTemplate from './components/QnaTemplate';
import Editor from 'components/atoms/Editor';
import { useQna } from 'hooks';
import { TEMP_IMAGE_UPLOAD_API_URL } from 'commons/constants';
import Paths from 'commons/paths';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import { callQnaWriteGaEvent } from '../../../utils/GAUtils';

const placeHolderRegistStr = '회원가입 관련 FAQ 참고해주세요(FAQ 내용)';
const placeHolderUpdateMemberStr = '회원정보 수정 관련 FAQ를 참고해주세요(FAQ 내용)';
const placeHolderVideoPlayStr =
  '영상 재생 관련 FAQ 학인부탁드립니다. FAQ에 해당사항이 없을 경우, 영상 재생 환경을 자세히 입력 부탁드립니다. 1.근무지(건물 및 층수까지 입력 부탁드립니다, 재택근무일경우 VPN 사용여부 기재) 2.인터넷 연결 상태(랜선 or 와이파이) 3.클라우드 PC사용 여부(O,X) 4.사용 브라우저 및 버전 정보 5.오류 현상 스크린 샷 6.오류 현상 기술(상세히 기록 부탁드립니디)';
const placeHolderInnerStr = '사내방송에 다뤄졌으면하는 내용을 제보해주세요!(jebo@lgcc.co.kr로도 제보 가능합니다)';
const placeHolderEtcStr = '';

const QnaWrite = () => {
  const history = useHistory();
  const { category, postQnaWrite, putQnaUpdate, setQnaId, qna } = useQna();
  const { qnaId } = useParams<{ qnaId: string }>();
  const [categoryIndex, setCategoryIndex] = useState<number>(-1);
  const [title, setTitle] = useState<string>('');
  const [contents, setContents] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<AttachFileResDto[]>([]);
  const [IsAllChecked, setIsAllChecked] = useState(false);
  const [placeHolder, setPlaceHoder] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState<boolean>(false);
  const [isOkPopupOpen, setIsOPkPopupOpen] = useState<boolean>(false);
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');
  const [wrongAccess, setWrongAccess] = useState<boolean>(false);

  useEffect(() => {
    setIsAllChecked(false);

    if (categoryIndex > -1 && title.trim().length > 0 && contents.trim().length > 0) {
      setIsAllChecked(true);
    }
  }, [categoryIndex, title, contents]);

  useEffect(() => {
    if (qnaId) {
      setQnaId(qnaId);
    }
  }, [qnaId]);

  useEffect(() => {
    if (qna) {
      if (qna?.resultCode !== 200) {
        setAlertPopContents(qna?.resultMessage as string);
        setIsAlertPopupOpen(true);
        setWrongAccess(true);
        return;
      }

      setCategoryIndex(category && category.findIndex(cate => cate.cateId === qna.cateId));
      setTitle(qna.title as string);
      setContents(qna.contents as string);
      setUploadedFiles(
        qna.attachFileId
          ? [
              {
                fileDsplNm: qna.fileDsplNm as string,
                fileId: qna.attachFileId,
              },
            ]
          : []
      );
    }
  }, [qna]);

  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const onAddFiles = (addedfiles: File[]) => {
    setFiles(files.concat(addedfiles));
  };

  const onRemoveFile = (i: number, isLocal: boolean) => {
    if (isLocal) {
      const temp = [...files];
      temp.splice(i, 1);
      setFiles(temp);
    } else {
      const temp = [...uploadedFiles];
      temp.splice(i, 1);
      setUploadedFiles(temp);
    }
  };

  const onRegistPopup = () => {
    if (IsAllChecked) {
      setIsOPkPopupOpen(true);
    } else {
      const formItems = [] as string[];
      if (categoryIndex <= -1) formItems.push('문의 유형');
      if (title.trim().length <= 0) formItems.push('제목');
      if (contents.trim().length <= 0) formItems.push('내용');
      setAlertPopContents(`${formItems.join(', ')}을(를) 작성했는지 확인해주세요.`);
      setIsAlertPopupOpen(true);
    }
  };

  const setRegistOk = () => {
    history.push(Paths.getPath('qnaWriteCompleted').replace(':mode', qnaId ? 'edit' : 'write'));
  };

  const onRegist = () => {
    if (qnaId) {
      putQnaUpdate(
        qnaId,
        title,
        contents,
        category[categoryIndex].cateId as string,
        uploadedFiles.length > 0 ? (uploadedFiles[0].fileId as string) : '0',
        files.length > 0 ? files[0] : undefined
      ).then(res => {
        if (res.resultCode === 200) {
          setTimeout(() => {
            setLoading(false);
            setRegistOk();
          }, 1000);
        } else {
          setAlertPopContents(res.resultMessage);
          setIsAlertPopupOpen(true);
        }
      });
    } else {
      postQnaWrite(
        title,
        contents,
        category[categoryIndex].cateId as string,
        files.length > 0 ? files[0] : undefined
      ).then(res => {
        if (res.resultCode === 200) {
          callQnaWriteGaEvent({
            askType: category[categoryIndex].cateNm as string,
          });

          setTimeout(() => {
            setLoading(false);
            setRegistOk();
          }, 1000);
        } else {
          setAlertPopContents(res.resultMessage);
          setIsAlertPopupOpen(true);
        }
      });
    }

    setIsOPkPopupOpen(false);
  };

  const setPlaceHolderStr = index => {
    if (index < 0) {
      return;
    }

    setPlaceHoder(
      category[index].guideSentence !== null
        ? String(category[index].guideSentence)
            .replace(/&nbsp;/g, ' ')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&')
            .replace(/&quot;/g, '"')
            .replace(/&#035;/g, '#')
            .replace(/&#039;/g, ';')
        : ''
    );
  };

  return (
    <>
      <QnaTemplate>
        <div className="write_cont is-show" data-qna-content="write">
          <form id="write">
            <div className="wirte_section">
              <div className="qna_select board_select">
                <SelectBox
                  className={'qna_select'}
                  initial={'문의 유형을 선택해주세요.'}
                  categoryIndex={categoryIndex}
                  clickSelectBox={index => {
                    setCategoryIndex(index);
                    setPlaceHolderStr(index);
                  }}
                  isRequired={categoryIndex === -1}
                  selectBoxList={category && category?.length ? (category.map(cate => cate.cateNm) as string[]) : []}
                  tagging={'qna'}
                  initialElementId="ask_type_ga"
                />
              </div>
            </div>
            <div className="wirte_section">
              <div className="write_section_input write_content">
                <div className="write_content_input">
                  {title.length === 0 && <span className="write_required">*</span>}
                  <input
                    type="text"
                    className="write_input write_title is-required"
                    title="제목"
                    placeholder="제목을 입력해주세요."
                    onChange={onChangeTitle}
                    value={title}
                  />
                </div>
                <div className="write_content_input">
                  {/* {contents.length === 0 && <span className="write_required">*</span>} */}
                  <Editor
                    type="classic"
                    data={contents}
                    height={220}
                    placeHolder={placeHolder}
                    uploadUrl={TEMP_IMAGE_UPLOAD_API_URL}
                    onChange={evt => {
                      if (evt.editor.getData()) {
                        setPlaceHoder('');
                      } else {
                        setPlaceHolderStr(categoryIndex);
                      }
                      setContents(evt.editor.getData());
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="wirte_section">
              <FileUploader
                onAddFiles={onAddFiles}
                onRemoveFile={onRemoveFile}
                maxCount={1}
                maxMBSize={10}
                selectedFiles={files}
                uploadedFiles={uploadedFiles}
              />
            </div>
            <div className="write_footer">
              <div className="write_button_section is-right">
                <button type="button" className="write_button write_cancel" onClick={() => setIsCancelPopupOpen(true)}>
                  취소
                </button>
                <button
                  type="button"
                  id="ask_click_ga"
                  className={classNames('write_button', 'write_register')}
                  onClick={onRegistPopup}
                >
                  등록
                </button>
              </div>
            </div>
          </form>
        </div>
      </QnaTemplate>
      <div className={classNames('confirm_popup', { 'is-active': loading })}>
        <div className="popup_dim"></div>
        <div className="popup_inner">
          <p className="confirm_popup_text" style={{ textAlign: 'center' }}>
            문의 글을 등록 중입니다.
            <br />
            잠시만 기다려주세요.
          </p>
        </div>
      </div>
      <Popup
        className={'cancel_write_popup'}
        okString={'확인'}
        contents={'게시글 작성을 취소하시겠습니까?<br />취소시 작성중이던 게시글 내용은 모두 삭제됩니다.'}
        isOpen={isCancelPopupOpen}
        onCancel={() => setIsCancelPopupOpen(false)}
        onRegist={() => {
          window.history.back();
        }}
      />
      <Popup
        isOpen={isOkPopupOpen}
        className={'register_write_popup'}
        okString={'등록'}
        contents={'작성한 게시글 등록 하시겠습니까?'}
        onCancel={() => setIsOPkPopupOpen(false)}
        onRegist={() => {
          setIsOPkPopupOpen(false);
          setLoading(true);
          onRegist();
        }}
      />
      <AlertPopup
        isOpen={isAlertPopupOpen}
        onClose={() => {
          setIsAlertPopupOpen(false);

          if (wrongAccess) {
            window.history.back();
          }
        }}
      >
        {alertPopupContents}
      </AlertPopup>
    </>
  );
};

export default QnaWrite;
