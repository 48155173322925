import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, Grid, InfiniteLoader, WindowScroller } from 'react-virtualized';
import { Link, useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import useRecommends from 'hooks/useRecommends';
import { all_click_datalayer_push, getContentsGAProps, getContentTagGAProps } from 'utils/GAUtils';
import ControlPopup from '../../organisms/Popup/ControlPopup';
import ContentCard from 'components/molecules/ContentCard';
import ControlDropdown from 'components/atoms/ControlDropdown';
import SectionTitle from 'components/atoms/SectionTitle';
import { useContinue, useMyInfo } from 'hooks';
import paths from 'commons/paths';
import Paths from 'commons/paths';
import throttle from 'lodash/throttle';
import { clearListCache } from 'utils/LGLifeUtils';
import { VERTUAL_SCROLL_THRESHOLD, WINDOW_RESIZE_THROTTLE } from 'commons/constants';

const Recommend = () => {
  const history = useHistory();
  const { data, searchOptions, setSearchOptions, keywordData } = useRecommends();
  const { info } = useMyInfo();
  const { getTimeLine } = useContinue();

  const [recommendList, setRecommendList] = useState([] as MediaCardResDto[]);
  const [currentDt, setCurrentDt] = useState('');
  const [keywords, setKeywords] = useState([] as string[]);
  const [userInfo, setUserInfo] = useState<UserDetailInfoResDto>();
  const [controlPopup, setControlPopup] = useState(false);
  const [sort, setSort] = useState('recommend,desc');
  const [popularitySortType, setPopularitySortType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopState, setIsPopState] = useState(false);

  const controlRef = useRef<HTMLDivElement>(null);

  const [columnCount, setColumnCount] = useState(4);
  const [rowHeight, setRowHeight] = useState<number>(0);

  const scrollSave = () => {
    const scrollPos = window.pageYOffset;
    localStorage.setItem('videoList', JSON.stringify(recommendList));
    localStorage.setItem('scrollPos', scrollPos.toString());
    localStorage.setItem('currentPage', currentPage.toString());
  };

  useEffect(() => {
    if (history.action === 'POP' && recommendList.length === 0 && localStorage.getItem('videoList')) {
      setRecommendList(JSON.parse(localStorage.getItem('videoList') as string));
      setCurrentPage(Number(localStorage.getItem('currentPage')));
      setIsPopState(true);

      setTimeout(() => {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }, 100);
    } else if (history.action === 'PUSH') {
      clearListCache();
    }
  });

  useEffect(() => {
    const listen = history.listen(scrollSave);
    return () => {
      listen();
    };
  });

  useEffect(() => {
    if (controlPopup) document.querySelector('body')?.classList.add('fix-overflow');
    else document.querySelector('body')?.classList.remove('fix-overflow');
  }, [controlPopup]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateThrottleHandler);
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));
    if (listData && history.action === 'POP' && listData.list === 'recommend') {
      setSort(listData.sort);
      setPopularitySortType(listData.popularitySortType);
    } else {
      window.sessionStorage.clear();
    }

    updateThrottleHandler();

    window.onbeforeunload = () => {
      clearListCache();
    };

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateThrottleHandler);
    };
  }, []);

  const updateSize = () => {
    const windowSize = { width: window.innerWidth, height: window.innerHeight };

    if (windowSize) {
      // console.log("windowSize", windowSize);

      if (windowSize.width < 1024) {
        setColumnCount(1);
      } else {
        setColumnCount(4);
      }
    }
  };

  const updateThrottleHandler = useCallback(throttle(updateSize, WINDOW_RESIZE_THROTTLE), []);

  useEffect(() => {
    if (data) {
      if (isPopState && data.page === currentPage) {
        setIsPopState(false);
        return;
      }

      setCurrentDt(data.currentDt);
      if (currentPage === 1 && data.page === 1) {
        setRecommendList(data.recommendMediaList);
      } else if (data.totalAmount > recommendList.length) {
        setRecommendList(recommendList.concat(data.recommendMediaList));
      }
    }
    if (keywordData && keywordData.intrKeywords) {
      setKeywords(keywordData.intrKeywords.split('#').slice(1));
    }
  }, [data, keywordData]);

  useEffect(() => {
    if (info) setUserInfo(info);
  }, [info]);

  useEffect(() => {
    setSearchOptions({ ...searchOptions, sort, page: currentPage, popularitySortType });
    window.sessionStorage.setItem(
      'listData',
      JSON.stringify({
        list: 'recommend',
        sort: sort,
        currentPage: currentPage,
        popularitySortType: popularitySortType,
      })
    );
  }, [sort, popularitySortType, currentPage]);

  const handleScroll = () => {
    // lodash throttle?
    if ((window.scrollY >= 88 && window.innerWidth > 1023) || (window.scrollY >= 61 && window.innerWidth < 1024))
      controlRef.current?.classList.add('is-sticky');
    else controlRef.current?.classList.remove('is-sticky');
  };

  const _isRowLoaded = ({ index }) => {
    const rowCount = Math.floor(recommendList.length / columnCount);
    return index < rowCount - 1;
  };
  const _loadMore = ({ startIndex, stopIndex }) => {
    if (data && currentPage < data.totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    // console.log("_cellRenderer", key)
    const r = recommendList[rowIndex * columnCount + columnIndex];

    if (!r) return;

    return (
      <div key={key} style={style}>
        <ContentCard
          {...r}
          currentDt={currentDt}
          timeline={getTimeLine(r.mediaId)}
          path={`${Paths.getPath('recommendDetail').replace(':mediaId', r.mediaId as string)}`}
          isSwiperSlide={false}
          key={r.mediaId}
          GAObj={getContentsGAProps({
            contentsType: 'MEDIA',
            title: r.title as string,
            tags: r.mediaTag as string,
            category: r.cateNm as string,
            dataContentBelong: '내 맞춤 추천',
            maker: r.creatorNm as string,
            time: r.videoTotalTime,
            brightcoveId: r.videoId as string,
            mediaContentsType: r.mediaContentsType as string,
            dataContentUrl: `${Paths.getPath('recommendDetail').replace(':mediaId', r.mediaId as string)}`,
            dataImgUrl: `${r?.thumbImageUri as string}`,
          })}
        />
      </div>
    );
  };

  const _calculateRowHeight = (width: number) => {
    // console.log("_calculateRowHeight");

    if (window.innerWidth < 1024) {
      setRowHeight(width * 0.6047 + 126);
    } else {
      setRowHeight((width / columnCount) * 0.5644 + 140);
    }
  };

  const onAutoSizerResize = param => {
    const { height, width } = param;
    // console.log("onAutoSizerResize (height, width)", height, width);
    _calculateRowHeight(width);
  };

  const _recommendList = useMemo(() => {
    return (
      <>
        {recommendList && (
          <InfiniteLoader
            isRowLoaded={_isRowLoaded}
            loadMoreRows={_loadMore}
            rowCount={Math.ceil(recommendList.length / columnCount)}
            threshold={VERTUAL_SCROLL_THRESHOLD}
            // ref={ref => (infiniteLoaderRef.current = ref)}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <AutoSizer disableHeight onResize={onAutoSizerResize}>
                    {({ width }) => {
                      if (width == 0) return;
                      return (
                        <Grid
                          cellRenderer={_cellRenderer}
                          columnWidth={width / columnCount}
                          columnCount={columnCount}
                          rowCount={Math.ceil(recommendList.length / columnCount)}
                          rowHeight={rowHeight}
                          width={width}
                          height={height}
                          ref={registerChild}
                          overscanRowCount={VERTUAL_SCROLL_THRESHOLD}
                          onSectionRendered={({
                            columnOverscanStartIndex,
                            columnOverscanStopIndex,
                            columnStartIndex,
                            columnStopIndex,
                            rowOverscanStartIndex,
                            rowOverscanStopIndex,
                            rowStartIndex,
                            rowStopIndex,
                          }) => {
                            onRowsRendered({
                              startIndex: rowStartIndex,
                              stopIndex: rowStopIndex,
                            });
                          }}
                          scrollTop={scrollTop}
                          autoHeight
                        />
                      );
                    }}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </>
    );
  }, [recommendList, columnCount, rowHeight]);

  return (
    <>
      <div className="content_inner">
        <div id="recommend" className="section recommend">
          <SectionTitle title="내 맞춤 추천" />
          <div className="section_cont section_sticky" ref={controlRef}>
            <div className="section_cont_top">
              <div className="section_info">
                <div className="control">
                  <strong className="section_sub_title">{userInfo?.name} 님 추천 키워드</strong>
                  <div className="control_tag is-over">
                    <div className="control_tag_wrap">
                      {/* swiper */}
                      <div className="swiper-container tagSwiper">
                        <ul className="control_tag_list swiper-wrapper">
                          {keywords &&
                            keywords.map((key, idx) => (
                              <li className="control_keyword_item swiper-slide" key={idx}>
                                <Link
                                  to={{
                                    pathname: paths.getPath('search'),
                                    search: stringify({ keyword: key }),
                                  }}
                                  className="control_keyword"
                                  {...getContentTagGAProps('추천키워드', key)}
                                  onClick={e => {
                                    all_click_datalayer_push(e.currentTarget);
                                  }}
                                >
                                  {key}
                                </Link>
                              </li>
                            ))}
                          <li className="control_keyword_item swiper-slide">
                            <Link to="/mypage" className="control_keyword_link"></Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="control_right is-pc">
                    <ControlDropdown
                      initial={sort}
                      initPop={popularitySortType}
                      clickSort={s => {
                        setSort(s);
                        setCurrentPage(1);
                      }}
                      clickPop={p => {
                        setPopularitySortType(p);
                        setCurrentPage(1);
                      }}
                      sortList={[
                        { title: '최신순', data: 'postDt,desc' },
                        { title: '추천순', data: 'recommend,desc' },
                        {
                          title: '인기순',
                          data: 'popularity,desc',
                          sub: [
                            { title: '6개월 기준', data: 'SIXMONTH' },
                            { title: '1주 기준', data: 'ONEWEEK' },
                            { title: '1개월 기준', data: 'ONEMONTH' },
                            { title: '3개월 기준', data: 'THREEMONTH' },
                            { title: '1년 기준', data: 'ONEYEAR' },
                            { title: '전체', data: 'ALL' },
                          ],
                        },
                        { title: '조회수순', data: 'readCnt,desc' },
                        { title: '좋아요순', data: 'likeCnt,desc' },
                        { title: '댓글순', data: 'commentCnt,desc' },
                      ]}
                    />
                  </div>

                  <button type="button" className="filter-button is-mobile" onClick={() => setControlPopup(true)}>
                    <span className="ab_text">검색 필터</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="main_row card_list">
              <div className="card_row is-small has-hover">
                <div className="card_wrapper">{_recommendList}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ControlPopup
        initial={sort}
        initPop={popularitySortType}
        sortList={[
          { title: '최신순', data: 'postDt,desc' },
          { title: '추천순', data: 'recommend,desc' },
          {
            title: '인기순',
            data: 'popularity,desc',
            sub: [
              { title: '6개월 기준', data: 'SIXMONTH' },
              { title: '1주 기준', data: 'ONEWEEK' },
              { title: '1개월 기준', data: 'ONEMONTH' },
              { title: '3개월 기준', data: 'THREEMONTH' },
              { title: '1년 기준', data: 'ONEYEAR' },
              { title: '전체', data: 'ALL' },
            ],
          },
          { title: '조회수순', data: 'readCnt,desc' },
          { title: '좋아요순', data: 'likeCnt,desc' },
          { title: '댓글순', data: 'commentCnt,desc' },
        ]}
        isActive={controlPopup}
        clickSort={s => {
          setSort(s);
          setCurrentPage(1);
        }}
        clickPop={p => {
          setPopularitySortType(p);
          setCurrentPage(1);
        }}
        onClose={() => setControlPopup(false)}
      />
    </>
  );
};

export default Recommend;
