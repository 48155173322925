import { useState, useContext } from 'react';
import { endpoints, fetcher } from 'services';
import useSWR from 'swr';
import MyInfoContext from 'contexts/MyInfoContext';
import useMyInfo from './useMyInfo';

const useMember = () => {
  const [searchOptions, setSearchOptions] = useState({
    groupCode: '',
    searchWord: '',
  });
  // const { info } = useMyInfo();
  const info = useContext(MyInfoContext);

  const { data: companiesData } = useSWR<any>(endpoints.common.groupCompanies, () =>
    fetcher.common.getGroupCompanies({}).then(res => res)
  );

  const { data: memberData } = useSWR<any>(info ? [endpoints.common.cic, searchOptions] : null, () =>
    fetcher.common.getCic(searchOptions).then(res => res)
  );

  return { memberData, companiesData, searchOptions, setSearchOptions };
};

export default useMember;
