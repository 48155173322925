import VideoTimeStampItem from './VideoTimeStampItem';
import moment from 'moment';

interface VideoTimeStampAreaProps {
  /** 영상 타임 스탬프 목록 */
  videoDurationTimeCodeList: VideoDurationTimeCodeResDto[];
  /** 타임 스탬프 클릭 이벤트 */
  onClickTimeStampHandler: (timeStamp: moment.Moment) => void;
}

const VideoTimeStampArea = (props: VideoTimeStampAreaProps) => {
  const { videoDurationTimeCodeList } = props;
  return (
    <div className="detail_timecode">
      <ul>
        <li>
          {videoDurationTimeCodeList?.map(timeStampItem => (
            <VideoTimeStampItem
              key={timeStampItem.timeCodeId}
              onClickTimeStampHandler={props.onClickTimeStampHandler}
              videoDurationTimeStampItem={timeStampItem}
            />
          ))}
        </li>
      </ul>
    </div>
  );
};

VideoTimeStampArea.displayName = 'VideoTimestampArea';

export default VideoTimeStampArea;
