import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { all_click_datalayer_push, getGAMenuClickProps } from 'utils/GAUtils';
import ReactTooltip from 'react-tooltip';
import { openLeftSideMenu } from '../../../utils/LGLifeUtils';

export interface NavItemProps {
  path: string;
  dataClickName?: string;
  dataClickArea: string;
  icon?: string;
  title: string;
  isMobile?: boolean;
  isPC?: boolean;
  isBlank?: boolean;
  isCurrent?: boolean;
  isDifferSite?: boolean;
  hasTopLine?: boolean;
  /** "New" 라벨 이미지 노출 여부 */
  showNewLabel?: boolean;
  /** 화면 노출 여부 */
  isHideMenu?: boolean;
  /** LNB 메뉴 클릭 시, 이벤트 액션 */
  onClickNavItem?: Function;
}

const NavItem = (props: NavItemProps) => {
  return (
    <>
      <li
        className={classNames([
          'nav_item',
          props.isMobile && props.hasTopLine ? { 'top-line2': props.hasTopLine } : { 'top-line2': props.hasTopLine },
          { 'is-mobile': props.isMobile, 'is-pc': props.isPC, 'is-current': props.isCurrent },
        ])}
        style={
          props.isHideMenu ? { display: 'none' } : (props.showNewLabel ? { position: 'relative' } : {}) || undefined
        }
      >
        <Link
          to={props.path}
          className="nav_link"
          {...getGAMenuClickProps(props.dataClickArea, { dataclickname: props.dataClickName })}
          onClick={e => {
            openLeftSideMenu(true);
            if (props.onClickNavItem) {
              props.onClickNavItem();
            }
            all_click_datalayer_push(e.currentTarget);
          }}
        >
          <span className={`nav_link_text ${props.icon}`}>
            {props.title}
            {props.showNewLabel && (
              <img
                alt="new"
                style={{ position: 'absolute', right: 0 }}
                src="/assets/images/img/NavigationItem_New_Badge.png"
              />
            )}
          </span>
        </Link>
      </li>
    </>
  );
};

export const NavItemD2 = (props: NavItemProps) => {
  const params = {
    className: `${props.icon ? String('nav_depth2_link ' + props.icon) : 'nav_depth2_link'}`,
    target: props.isBlank ? '_blank' : '_self',
  };

  return (
    <li
      className={classNames('nav_depth2_item', { 'is-current': props.isCurrent })}
      style={props.isHideMenu ? { display: 'none' } : (props.showNewLabel ? { position: 'relative' } : {}) || undefined}
    >
      {props.isDifferSite ? (
        <a
          href={props.path}
          {...params}
          {...getGAMenuClickProps(props.dataClickArea, { dataclickname: props.dataClickName })}
          onClick={e => {
            openLeftSideMenu(true);
            if (props.onClickNavItem) {
              props.onClickNavItem();
            }
            all_click_datalayer_push(e.currentTarget);
          }}
        >
          {props.title}
          {props.showNewLabel && (
            <img
              alt="new"
              style={{ position: 'absolute', right: 0 }}
              src="/assets/images/img/NavigationItem_New_Badge.png"
            />
          )}
        </a>
      ) : (
        <Link
          to={props.path}
          {...params}
          {...getGAMenuClickProps(props.dataClickArea, { dataclickname: props.dataClickName })}
          onClick={e => {
            if (props.onClickNavItem) {
              props.onClickNavItem();
            }
            all_click_datalayer_push(e.currentTarget);
          }}
        >
          {props.title}
          {props.showNewLabel && (
            <img
              alt="new"
              style={{ position: 'absolute', right: 0 }}
              src="/assets/images/img/NavigationItem_New_Badge.png"
            />
          )}
        </Link>
      )}
    </li>
  );
};

export const NavItemSimple = (props: NavItemProps) => {
  return (
    <li
      className={classNames('nav_simple_item', { 'is-current': props.isCurrent })}
      style={props.isHideMenu ? { display: 'none' } : (props.showNewLabel ? { position: 'relative' } : {}) || undefined}
    >
      <Link
        to={props.path}
        className={classNames('nav_simple_link', props.icon)}
        {...getGAMenuClickProps(props.dataClickArea)}
        onClick={e => {
          openLeftSideMenu(true);
          if (props.onClickNavItem) {
            props.onClickNavItem();
          }
          all_click_datalayer_push(e.currentTarget);
        }}
        data-for={props.title}
        data-tip={props.title}
      >
        <span className="ab_text"></span>
      </Link>
      <ReactTooltip id={props.title} getContent={() => props.title} />
    </li>
  );
};

export default NavItem;
