import React, { useState } from 'react';
import GradeContext from 'contexts/GradeContext';

const GradeProvider = ({ children }) => {
  const [gradeData, setGradeData] = useState([] as UserRankResDto[]);
  const [a, seta] = useState('');

  const initialValue = {
    gradeData: gradeData,
    setGradeData: setGradeData,
  };

  return <GradeContext.Provider value={initialValue}>{children}</GradeContext.Provider>;
};

export default GradeProvider;
