import React from 'react';

export interface FooterProps {}

const Footer = ({}: React.PropsWithChildren<FooterProps>): React.ReactElement => (
  <div className="footer">
    <div className="footer_inner"></div>
  </div>
);

export default Footer;
