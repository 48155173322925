import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import NavItem, { NavItemD2, NavItemSimple } from 'components/atoms/NavItem';
import KeywordList from 'components/molecules/KeywordList';
import SearchMemPopup from './SearchMemPopup';
import { useAuth, usePopularityKeyword } from 'hooks';
import { PopularityKeyword } from 'types';
import { all_click_datalayer_push, callMenuGAEvent, getGAMenuClickProps } from 'utils/GAUtils';
import paths from 'commons/paths';
import Paths from 'commons/paths';
import classNames from 'classnames';
import AlertPopup from 'components/organisms/Popup/AlertPopup';
import useExecutiveAppointment from '../../../hooks/useExecutiveAppointment';

const Sider = (): React.ReactElement => {
  const navRef = useRef<HTMLDivElement>(null);
  const navFullRef = useRef<HTMLDivElement>(null);
  const navMenuRef = useRef<HTMLDivElement>(null);
  const navIntroDepth2MenuRef = useRef<HTMLDivElement>(null);
  const navIntroDropRef = useRef<HTMLLIElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const navSearchRef = useRef<HTMLDivElement>(null);

  const [screenSize, setScreenSize] = useState('xlg');
  const [searchMemPopup, setSearchMemPopup] = useState(false);
  const [popularityKeywordList, setPopularityKeywordList] = useState<Array<PopularityKeyword>>([]);
  const [searchWord, setSearchWord] = useState('');
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');
  const [showPersonLNB, setShowPersonLNB] = useState<boolean>(false);

  const { popularityKeyword } = usePopularityKeyword();
  const { postAutoLoginLGAwards, postAutoLoginLGBPHome } = useAuth();
  const { getExecutiveAppointmentYearDisplayInfo } = useExecutiveAppointment();
  const { data: executeAppointmentDisplayInfo } = getExecutiveAppointmentYearDisplayInfo();

  const location = useLocation();
  const history = useHistory();

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1328) setScreenSize('xlg');
    else if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  const isIntroducePage = useMemo(
    () => !!matchPath(location.pathname, paths.getPath('introduce')),
    [location.pathname]
  );

  useEffect(() => {
    setShowPersonLNB(!!executeAppointmentDisplayInfo?.yearId);
  }, [executeAppointmentDisplayInfo]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isIntroducePage) {
      navDropActive(navIntroDropRef, navIntroDepth2MenuRef);
    } else {
      navDropClose(navIntroDropRef, navIntroDepth2MenuRef);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (popularityKeyword && popularityKeyword.popularityKeywordList) {
      setPopularityKeywordList(
        popularityKeyword.popularityKeywordList.sort((a, b) => {
          a.orderNum - b.orderNum;
        })
      );
    }
  }, [popularityKeyword]);

  useEffect(() => {
    if (searchMemPopup) document.querySelector('body')?.classList.add('fix-overflow');
    else document.querySelector('body')?.classList.remove('fix-overflow');
  }, [searchMemPopup]);

  useEffect(() => {
    document.querySelector('body')?.classList.remove('no-scroll-mobile');
    navMenuRef.current?.classList.remove('is-active');
    navRef.current?.classList.remove('is-more');
  }, [location.pathname]);

  const navDrop = (e, dropRef, depth2MenuRef) => {
    // if (isIntroducePage || isNewsPage || isEventPage) e.preventDefault();

    if (!dropRef.current?.classList.contains('is-active')) {
      navDropActive(dropRef, depth2MenuRef);
    } else {
      navDropClose(dropRef, depth2MenuRef);
    }
  };

  /**
   * LG 소개 Nav Drop 열림
   * @param dropRef
   * @param depth2MenuRef
   */
  const navDropActive = (dropRef, depth2MenuRef) => {
    dropRef.current?.classList.add('is-active');
    depth2MenuRef.current?.style.setProperty('display', 'block');
  };

  /**
   * LG 소개 Nav Drop 닫힘
   * @param dropRef
   * @param depth2MenuRef
   */
  const navDropClose = (dropRef, depth2MenuRef) => {
    dropRef.current?.classList.remove('is-active');
    depth2MenuRef.current?.style.setProperty('display', 'none');
  };

  const onKeyPressInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (searchInputRef.current?.value.trim() === '') {
        setAlertPopContents('검색어를 입력하세요.');
        setIsAlertPopupOpen(true);
        return;
      }

      onSearchMember();
      callMenuGAEvent('customMemberSearch', { dataClickName: '임직원 검색' });
    }
  };

  const onSearchMember = () => {
    setSearchMemPopup(!searchMemPopup);
    setSearchWord(searchInputRef.current?.value.trim() || '');
    if (searchInputRef.current) searchInputRef.current.value = '';
  };

  return (
    <>
      <nav className="nav" ref={navRef}>
        <div className="nav_full custom_scroll" ref={navFullRef}>
          <div className="nav_full_button is-mobile">
            <button
              type="button"
              className="nav_close ico_close"
              onClick={() => {
                document.querySelector('body')?.classList.remove('no-scroll-mobile');
                navMenuRef.current?.classList.remove('is-active');
                navRef.current?.classList.remove('is-more', 'is-simple');
              }}
            >
              <span className="ab_text">close</span>
            </button>
          </div>

          <div className="nav_inner">
            {/* LNB - 출력, PC & 모바일 출력할 메뉴  */}
            <ul className="nav_list">
              <li
                className={classNames('nav_item is-mobile', {
                  'is-current': location.pathname === '/',
                })}
              >
                <Link
                  to={Paths.getPath('Home')}
                  className="nav_link"
                  {...getGAMenuClickProps('홈')}
                  onClick={e => all_click_datalayer_push(e.currentTarget)}
                >
                  <span className="nav_link_text ico_home">홈</span>
                </Link>
              </li>
              <li
                className={classNames('nav_item nav_drop is-active', {
                  'is-current': location.pathname.includes('/contents'),
                })}
              >
                <Link
                  to={Paths.getPath('videoList')}
                  className="nav_link"
                  {...getGAMenuClickProps('콘텐츠')}
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                  }}
                >
                  <span className="nav_link_text ico-contents">콘텐츠</span>
                </Link>
                {/* PC LNB - 출력, 모바일 더보기 메뉴에 출력할 메뉴  */}
                <div className="nav_depth2 is-pc" style={{ display: 'block' }}>
                  <ul className="nav_depth2_list">
                    <NavItemD2
                      title="영상"
                      path={Paths.getPath('videoList')}
                      dataClickArea={'콘텐츠'}
                      dataClickName={'영상'}
                      isCurrent={
                        location.pathname === Paths.getPath('videoList') ||
                        location.pathname.includes(Paths.getPath('videoDetail').replace('/:mediaId', ''))
                      }
                    />
                    <NavItemD2
                      title="매거진"
                      path={Paths.getPath('cardNewsList')}
                      dataClickArea={'콘텐츠'}
                      dataClickName={'매거진'}
                      isCurrent={
                        location.pathname === Paths.getPath('cardNewsList') ||
                        location.pathname.includes(Paths.getPath('cardNewsDetail').replace('/:mediaId', ''))
                      }
                    />
                    <NavItemD2
                      title="웹툰(갓찌)"
                      path={Paths.getPath('webtoonList')}
                      dataClickArea={'콘텐츠'}
                      dataClickName={'웹툰(갓찌)'}
                      isCurrent={
                        location.pathname === Paths.getPath('webtoonList') ||
                        location.pathname.includes(Paths.getPath('webtoonDetail').replace('/:mediaId', ''))
                      }
                    />
                    <NavItemD2
                      title="보도자료"
                      path={Paths.getPath('newsList')}
                      dataClickArea={'콘텐츠'}
                      dataClickName={'보도자료'}
                      isCurrent={
                        location.pathname === Paths.getPath('newsList') ||
                        location.pathname.includes(Paths.getPath('newsDetail').replace('/:mediaId', ''))
                      }
                    />
                    <NavItemD2
                      title="주간 뉴스레터"
                      path={Paths.getPath('weekNewsletterList')}
                      dataClickArea={'콘텐츠'}
                      dataClickName={'주간 뉴스레터'}
                      isCurrent={
                        location.pathname === Paths.getPath('weekNewsletterList') ||
                        location.pathname.includes(Paths.getPath('weekNewsletterDetail').replace('/:mediaId', ''))
                      }
                    />
                    {/* 고객 통해 언론 속 LG 메뉴 삭제 요청 - 2023.12.27 */}
                    {/*<NavItemD2*/}
                    {/*    title="언론 속 LG"*/}
                    {/*    path={Paths.getPath('pressList')}*/}
                    {/*    dataClickArea={'콘텐츠'}*/}
                    {/*    dataClickName={'언론 속 LG'}*/}
                    {/*    isCurrent={location.pathname === (Paths.getPath('pressList')) || location.pathname.includes((Paths.getPath('pressDetail').replace('/:mediaId','')))}*/}
                    {/*/>*/}
                  </ul>
                </div>
              </li>

              {/* LNB - 모바일 하단 메뉴에 출력할 메뉴  */}
              <li
                className={classNames('nav_item nav_drop is-mobile is-active', {
                  'is-current': location.pathname.includes('/community'),
                })}
              >
                <Link
                  to={Paths.getPath('societyBoardList')}
                  className="nav_link"
                  {...getGAMenuClickProps('커뮤니티')}
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                  }}
                >
                  <span className="nav_link_text ico-community">커뮤니티</span>
                </Link>
              </li>
              <li
                className={classNames('nav_item nav_drop is-mobile is-active', {
                  'is-current': location.pathname.includes('/event'),
                })}
              >
                <Link
                  to={Paths.getPath('eventList')}
                  className="nav_link"
                  {...getGAMenuClickProps('이벤트')}
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                  }}
                >
                  <span className="nav_link_text ico_event">이벤트</span>
                </Link>
              </li>
              <li
                className={classNames('nav_item nav_drop is-mobile is-active', {
                  'is-current': location.pathname.includes(Paths.getPath('aboutLGLIFE')),
                })}
              >
                <Link
                  to={Paths.getPath('aboutLGLIFE')}
                  className="nav_link"
                  {...getGAMenuClickProps('LG LIFE 소개')}
                  onClick={e => {
                    all_click_datalayer_push(e.currentTarget);
                  }}
                >
                  <span className="nav_link_text ico-lifeintro">LG LIFE 소개</span>
                </Link>
              </li>
              {/* // LNB - 모바일 하단 메뉴에 출력할 메뉴  */}

              {/* 더보기 메뉴 버튼의 경우, 모바일 경우에만 하단 메뉴에 출력하므로, is-mobile class 추가 */}
              <li className="nav_item is-mobile">
                <a
                  className="nav_link nav_more_link"
                  onClick={() => {
                    document.querySelector('body')?.classList.add('no-scroll-mobile');
                    navMenuRef.current?.classList.add('is-active');
                    navRef.current?.classList.add('is-more');

                    navRef.current?.classList.add('is-simple');
                  }}
                >
                  <span className="nav_link_text ico_menu">더보기</span>
                </a>
              </li>
              {/* // 더보기 메뉴 버튼의 경우, 모바일 경우에만 하단 메뉴에 출력하므로, is-mobile class 추가 */}
            </ul>
            {/* // PC LNB - 출력, 모바일 - 하단 메뉴에 출력할 메뉴 */}

            {/* PC LNB 메뉴 - 모바일 하단 메뉴에 해당 하는 영역 제외한 영역 */}
            <div className="nav_menu" ref={navMenuRef}>
              {/* PC LNB -  모바일 하단 메뉴에 해당 하는 영역 제외한 영역 출력, 모바일 - 더보기 버튼 클릭 시, 출력할 메뉴 */}
              <ul
                className="nav_list nav_more"
                onClick={() => {
                  document.querySelector('body')?.classList.remove('no-scroll-mobile');
                  navMenuRef.current?.classList.remove('is-active');
                  navRef.current?.classList.remove('is-more');
                }}
              >
                <li
                  className={classNames('nav_item nav_drop nav_drop_item is-active is-mobile', {
                    'is-current': location.pathname.includes('/contents'),
                  })}
                >
                  <Link
                    to={Paths.getPath('videoList')}
                    {...getGAMenuClickProps('콘텐츠')}
                    onClick={e => {
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    className="nav_link"
                  >
                    <span className="nav_link_text ico-contents">콘텐츠</span>
                  </Link>
                  <div className="nav_depth2" style={{ display: 'block' }}>
                    <ul className="nav_depth2_list">
                      <NavItemD2
                        title="영상"
                        dataClickArea={'콘텐츠'}
                        dataClickName={'영상'}
                        path={Paths.getPath('videoList')}
                        isCurrent={
                          location.pathname === Paths.getPath('videoList') ||
                          location.pathname.includes(Paths.getPath('videoDetail').replace('/:mediaId', ''))
                        }
                      />
                      <NavItemD2
                        title="매거진"
                        dataClickArea={'콘텐츠'}
                        dataClickName={'매거진'}
                        path={Paths.getPath('cardNewsList')}
                        isCurrent={
                          location.pathname === Paths.getPath('cardNewsList') ||
                          location.pathname.includes(Paths.getPath('cardNewsDetail').replace('/:mediaId', ''))
                        }
                      />
                      <NavItemD2
                        title="웹툰(갓찌)"
                        dataClickArea={'콘텐츠'}
                        dataClickName={'웹툰(갓찌)'}
                        path={Paths.getPath('webtoonList')}
                        isCurrent={
                          location.pathname === Paths.getPath('webtoonList') ||
                          location.pathname.includes(Paths.getPath('webtoonDetail').replace('/:mediaId', ''))
                        }
                      />
                      <NavItemD2
                        title="보도자료"
                        dataClickArea={'콘텐츠'}
                        dataClickName={'보도자료'}
                        path={Paths.getPath('newsList')}
                        isCurrent={
                          location.pathname === Paths.getPath('newsList').replace('/list', '') ||
                          location.pathname.includes(Paths.getPath('newsDetail').replace('/:mediaId', ''))
                        }
                      />
                      <NavItemD2
                        title="주간 뉴스레터"
                        path={Paths.getPath('weekNewsletterList')}
                        dataClickArea={'콘텐츠'}
                        dataClickName={'주간 뉴스레터'}
                        isCurrent={
                          location.pathname === Paths.getPath('weekNewsletterList') ||
                          location.pathname.includes(Paths.getPath('weekNewsletterDetail').replace('/:mediaId', ''))
                        }
                      />
                      {/* 고객 통해 언론 속 LG 메뉴 삭제 요청 - 2023.12.27 */}
                      {/*<NavItemD2*/}
                      {/*    title="언론 속 LG"*/}
                      {/*    dataClickArea={'콘텐츠'}*/}
                      {/*    dataClickName={'언론 속 LG'}*/}
                      {/*    path={Paths.getPath('pressList')}*/}
                      {/*    isCurrent={location.pathname === (Paths.getPath('pressList').replace("/list","")) || location.pathname.includes((Paths.getPath('pressDetail').replace('/:mediaId','')))}*/}
                      {/*/>*/}
                    </ul>
                  </div>
                </li>
                <li
                  className={classNames('nav_item nav_drop is-active', {
                    'is-current': location.pathname.includes(Paths.getPath('community')),
                  })}
                >
                  <Link
                    to={Paths.getPath('societyBoardList')}
                    {...getGAMenuClickProps('커뮤니티')}
                    onClick={e => {
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    className="nav_link"
                  >
                    <span className="nav_link_text ico-community">커뮤니티</span>
                  </Link>
                  <div className="nav_depth2" style={{ display: 'block' }}>
                    <ul className="nav_depth2_list">
                      <NavItemD2
                        title="회사 밖 소모임"
                        dataClickArea={'커뮤니티'}
                        dataClickName={'회사 밖 소모임'}
                        path={Paths.getPath('societyBoardList')}
                        isCurrent={
                          location.pathname === Paths.getPath('societyBoardList') ||
                          location.pathname.includes(Paths.getPath('societyBoardDetail').replace('/:boardId', ''))
                        }
                      />
                      <NavItemD2
                        title="회사 속 직무"
                        dataClickArea={'커뮤니티'}
                        dataClickName={'회사 속 직무'}
                        path={Paths.getPath('departmentBoardList')}
                        isCurrent={
                          location.pathname === Paths.getPath('departmentBoardList') ||
                          location.pathname.includes(Paths.getPath('departmentBoardDetail').replace('/:boardId', ''))
                        }
                      />
                      <NavItemD2
                        title="회사 옆 맛집"
                        dataClickArea={'커뮤니티'}
                        dataClickName={'회사 옆 맛집'}
                        path={Paths.getPath('topEatsBoardList')}
                        isCurrent={
                          location.pathname === Paths.getPath('topEatsBoardList') ||
                          location.pathname.includes(Paths.getPath('topEatsBoardDetail').replace('/:boardId', ''))
                        }
                      />
                      <NavItemD2
                        title="LG인의 선택"
                        dataClickArea={'커뮤니티'}
                        dataClickName={'LG인의 선택'}
                        path={Paths.getPath('eventSimpleSurveyList')}
                        showNewLabel={true}
                        isCurrent={
                          location.pathname === Paths.getPath('eventSimpleSurveyList') ||
                          location.pathname.includes(Paths.getPath('eventSimpleSurveyDetail').replace('/:eventId', ''))
                        }
                      />
                      <NavItemD2
                        title="칭찬합니다"
                        dataClickArea={'커뮤니티'}
                        dataClickName={'칭찬합니다'}
                        path={Paths.getPath('mentionsBoardList')}
                        isCurrent={
                          location.pathname === Paths.getPath('mentionsBoardList') ||
                          location.pathname.includes(Paths.getPath('mentionsBoardDetail').replace('/:boardId', ''))
                        }
                      />
                      <NavItemD2
                        title="자유게시판"
                        dataClickArea={'커뮤니티'}
                        dataClickName={'자유게시판'}
                        path={Paths.getPath('boardList')}
                        isCurrent={
                          location.pathname === Paths.getPath('boardList') ||
                          location.pathname.includes(Paths.getPath('boardDetail').replace('/:boardId', ''))
                        }
                      />
                    </ul>
                  </div>
                </li>
                <li
                  className={classNames('nav_item nav_drop is-active', {
                    'is-current': location.pathname.includes(Paths.getPath('event')),
                  })}
                >
                  <Link
                    to={Paths.getPath('eventQuizList')}
                    {...getGAMenuClickProps('이벤트')}
                    onClick={e => {
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    className="nav_link"
                  >
                    <span className="nav_link_text ico_event">이벤트</span>
                  </Link>
                  <div className="nav_depth2" style={{ display: 'block' }}>
                    <ul className="nav_depth2_list">
                      <NavItemD2
                        title="LG인 모의고사"
                        dataClickArea={'이벤트'}
                        dataClickName={'LG인 모의고사'}
                        path={Paths.getPath('eventQuizList')}
                        isCurrent={
                          location.pathname === Paths.getPath('eventQuizList') ||
                          location.pathname.includes(Paths.getPath('eventQuizDetail').replace('/:eventId', ''))
                        }
                      />
                      <NavItemD2
                        title="LG인 설문조사"
                        dataClickArea={'이벤트'}
                        dataClickName={'LG인 설문조사'}
                        path={Paths.getPath('eventSurveyList')}
                        isCurrent={
                          location.pathname === Paths.getPath('eventSurveyList') ||
                          location.pathname.includes(Paths.getPath('eventSurveyDetail').replace('/:eventId', ''))
                        }
                      />
                      <NavItemD2
                        title="상시 이벤트"
                        dataClickArea={'이벤트'}
                        dataClickName={'상시 이벤트'}
                        path={Paths.getPath('eventList')}
                        isCurrent={
                          location.pathname === Paths.getPath('eventList') ||
                          location.pathname.includes(Paths.getPath('eventDetail').replace('/:eventId', ''))
                        }
                      />
                    </ul>
                  </div>
                </li>
                <NavItem
                  path={Paths.getPath('aboutLGLIFE')}
                  dataClickArea={'LG LIFE 소개'}
                  icon={'ico-lifeintro'}
                  title={'LG LIFE 소개'}
                  hasTopLine={true}
                  isCurrent={location.pathname.includes(Paths.getPath('aboutLGLIFE'))}
                />
                {/* 아래 2개의 메뉴(신년사, 추모사)는 평소에는 주석 처리되고 필요시 메뉴를 노출합니다. */}
                {/* <NavItem
                  title="신년사"
                  path="/group/detail/120533571637"
                  icon="ico_list"
                  dataClickArea="신년사"
                  isCurrent={location.pathname.includes('/group/detail/120533571637')}
                /> */}
                {/* <NavItem
                  title="추모사"
                  path="/"
                  icon="ico_list"
                  dataClickArea="추모사"
                  isCurrent={location.pathname.includes('/test')}
                /> */}
                {/* 임원 인사 기능 추가로 인해 페이지 링크 교체 - 2023.11.15 */}
                {showPersonLNB && executeAppointmentDisplayInfo?.yearId && (
                  <NavItem
                    title="임원인사"
                    dataClickArea="임원인사"
                    path={`${Paths.getPath('person')}?yearId=${executeAppointmentDisplayInfo?.yearId}`}
                    icon="ico_person"
                    isCurrent={location.pathname.includes(Paths.getPath('person'))}
                    showNewLabel={false}
                  />
                )}
                <li
                  className={classNames('nav_item nav_drop', {
                    'is-current': location.pathname.includes(Paths.getPath('introduce')),
                  })}
                  ref={navIntroDropRef}
                >
                  <Link
                    to={Paths.getPath('LGWay')}
                    {...getGAMenuClickProps('LG소개')}
                    className="nav_link"
                    onClick={e => {
                      all_click_datalayer_push(e.currentTarget);
                      navDrop(e, navIntroDropRef, navIntroDepth2MenuRef);
                    }}
                  >
                    <span className="nav_link_text ico_know">LG소개</span>
                    <span className="nav_drop_button"></span>
                  </Link>
                  <div className="nav_depth2" ref={navIntroDepth2MenuRef}>
                    <ul className="nav_depth2_list">
                      <NavItemD2
                        title="LG Way"
                        dataClickArea={'LG소개'}
                        dataClickName={'LG Way'}
                        path={Paths.getPath('LGWay')}
                        isCurrent={location.pathname.includes(Paths.getPath('LGWay'))}
                      />
                      <NavItemD2
                        title="계열사"
                        dataClickArea={'LG소개'}
                        dataClickName={'계열사'}
                        path={Paths.getPath('Management')}
                        isCurrent={location.pathname.includes(Paths.getPath('Management'))}
                      />
                      {/* LNB 메뉴 변경하면서 고객 요구사항에 따라 해당 항목 주석 처리 - 2023.06.08 */}
                      {/*<NavItemD2*/}
                      {/*    title="ESG 경영"*/}
                      {/*    dataClickArea={'LG소개'}*/}
                      {/*    dataClickName={'ESG 경영'}*/}
                      {/*    path={Paths.getPath('ESGList')}*/}
                      {/*    isCurrent={location.pathname.includes(Paths.getPath('ESG'))}*/}
                      {/*/>*/}
                      {/* <li className="nav_depth2_item">
                      <a href="#" target="_blank" className="nav_depth2_link">
                        LG.com
                      </a>
                    </li> */}
                    </ul>
                  </div>
                </li>
                {/* 신규 LG Awards 페이지 개발 되어 기존 LNB 메뉴 주석 처리 - 2023.11.16 */}
                {/*<NavItem*/}
                {/*    title="LG Awards"*/}
                {/*    dataClickArea="LG Awards"*/}
                {/*    icon="ico_award"*/}
                {/*    path="/LgAward/list"*/}
                {/*    isCurrent={location.pathname === (Paths.getPath('LgAwardList'))}*/}
                {/*/>*/}
                <NavItem
                  title="LG Awards"
                  dataClickArea="LG Awards"
                  icon="ico_award"
                  path={location.pathname}
                  isCurrent={false}
                  isPC={true}
                  onClickNavItem={() => {
                    postAutoLoginLGAwards().then(res => {
                      if (res.resultCode !== 200) {
                        console.error(res.resultMessage);
                        setAlertPopContents('페이지를 연결할 수 없습니다.');
                        setIsAlertPopupOpen(true);
                        return;
                      }

                      window.open(res.autoLoginTargetUrl, '_blank');
                    });
                  }}
                />
                <NavItem
                  title="DX BP Library"
                  dataClickArea="DX BP Library"
                  icon="ico_bp_library"
                  path={location.pathname}
                  isCurrent={false}
                  isPC={true}
                  onClickNavItem={() => {
                    postAutoLoginLGBPHome().then(res => {
                      if (res.resultCode !== 200) {
                        console.error(res.resultMessage);
                        setAlertPopContents('페이지를 연결할 수 없습니다.');
                        setIsAlertPopupOpen(true);
                        return;
                      }

                      window.open(res.autoLoginTargetUrl, '_blank');
                    });
                  }}
                />
              </ul>
              {/* // PC LNB -  모바일 하단 메뉴에 해당 하는 영역 제외한 영역 출력, 모바일 - 더보기 버튼 클릭 시, 출력할 메뉴 */}

              <div className="nav_members is-pc">
                <div className="nav_search" ref={navSearchRef}>
                  <input
                    type="text"
                    className="nav_search_input"
                    placeholder="임직원 검색"
                    ref={searchInputRef}
                    onKeyPress={onKeyPressInput}
                    onFocus={() => {
                      navSearchRef.current?.classList.add('is-focus');
                    }}
                    onBlur={() => {
                      navSearchRef.current?.classList.remove('is-focus');
                    }}
                    id="searchMembsIp"
                  />
                  <label htmlFor="searchMembsIp" className="ab_text">
                    임직원 검색
                  </label>
                  <button
                    {...getGAMenuClickProps('LNB', {
                      dataClick: 'memberSearch',
                      dataclickname: '임직원 검색',
                    })}
                    className="nav_search_button ico_search_small search_membs_button"
                    onClick={e => {
                      onSearchMember();
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  ></button>
                </div>
              </div>

              <ul className="nav_list nav_more nav_sub_list">
                <li className="nav_item is-mobile">
                  <a
                    {...getGAMenuClickProps('LNB', { dataClick: 'memberSearch' })}
                    className="nav_link search_membs_button"
                    onClick={e => {
                      if (navRef.current?.classList.contains('is-more')) navRef.current?.classList.remove('is-more');
                      setSearchMemPopup(!searchMemPopup);
                      navMenuRef.current?.classList.remove('is-active');
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  >
                    <span className="nav_link_text ico_search">임직원 검색</span>
                  </a>
                </li>
                {/* <NavItem title="임직원 검색" path="#" icon="ico_search" isMobile /> */}
                <NavItem
                  title="FAQ"
                  dataClickArea="FAQ"
                  path={Paths.getPath('faq')}
                  icon="ico_faq"
                  isCurrent={location.pathname.includes(Paths.getPath('faq'))}
                />
                <NavItem
                  title="문의하기"
                  dataClickArea="문의하기"
                  path={Paths.getPath('qnaWrite')}
                  icon="ico_inquiry"
                  isCurrent={location.pathname.includes(Paths.getPath('qna'))}
                />
                <NavItem
                  title="유용한 모음"
                  dataClickArea="유용한 모음"
                  path={Paths.getPath('tools')}
                  icon="ico_combine"
                  isCurrent={location.pathname.includes(Paths.getPath('tools'))}
                />
              </ul>
            </div>
            {/* // PC LNB 메뉴 - 모바일 하단 메뉴에 해당 하는 영역 제외한 영역 */}

            <KeywordList
              title="인기 키워드"
              data={popularityKeywordList.map(keyword => ({
                title: keyword.keyword,
                path: `#${keyword.keyword}`,
              }))}
            />
            <div className="nav_footer">
              <div className="nav_footer_inner">
                <ul className="footer_list">
                  <li className="footer_item">
                    <Link to="/privacy" className="footer_link" style={{ fontWeight: 600 }}>
                      개인정보취급방침
                    </Link>
                  </li>
                  <li className="footer_item">
                    <Link to="/sitemap" className="footer_link">
                      사이트맵
                    </Link>
                  </li>
                </ul>
                <div className="footer_copyright">
                  <span className="copyright">
                    COPYRIGHT LG COMMUNICATION CENTER.
                    <br className="is-pc" />
                    ALL RIGHTS RESERVED
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PC - LNB 접힘 상태 */}
        <div className="nav_simple custom_scroll">
          <ul className="nav_simple_list">
            <NavItemSimple
              title="콘텐츠"
              dataClickArea="콘텐츠"
              path={Paths.getPath('videoList')}
              icon="ico-contents"
              isCurrent={location.pathname.includes(Paths.getPath('contents'))}
            />
            <NavItemSimple
              title="커뮤니티"
              dataClickArea="커뮤니티"
              path={Paths.getPath('societyBoardList')}
              icon="ico-community"
              isCurrent={location.pathname.includes(Paths.getPath('community'))}
            />
            <NavItemSimple
              title="이벤트"
              dataClickArea="이벤트"
              path={Paths.getPath('eventQuizList')}
              icon="ico_event"
              isCurrent={location.pathname.includes(Paths.getPath('event'))}
            />
            <NavItemSimple
              title="LG LIFE 소개"
              dataClickArea="LG LIFE 소개"
              path={Paths.getPath('aboutLGLIFE')}
              icon="ico-lifeintro"
              isCurrent={location.pathname.includes(Paths.getPath('aboutLGLIFE'))}
            />
            {/* 아래 2개의 메뉴(신년사, 추모사)는 평소에는 주석 처리되고 필요시 메뉴를 노출합니다. */}
            {/* <NavItemSimple
              title="신년사"
              path={'/group/detail/120533571637'}
              icon="ico_list"
              dataClickArea="신년사"
              isCurrent={location.pathname.includes('/group/detail/120533571637')}
            /> */}
            {/* <NavItemSimple
              title="추모사"
              path={'/'}
              icon="ico_list"
              dataClickArea="추모사"
              isCurrent={location.pathname.includes('/test')}
            /> */}
            {/* 임원 인사 기능 추가로 인해 페이지 링크 교체 - 2023.11.15 */}
            {showPersonLNB && executeAppointmentDisplayInfo?.yearId && (
              <NavItemSimple
                title="임원인사"
                dataClickArea="임원인사"
                path={`${Paths.getPath('person')}?yearId=${executeAppointmentDisplayInfo?.yearId}`}
                icon="ico_person"
                isCurrent={location.pathname.includes('/person')}
              />
            )}
            <NavItemSimple
              title="LG소개"
              dataClickArea={'LG소개'}
              dataClickName={'LG Way'}
              path={paths.getPath('LGWay')}
              icon="ico_know"
              isCurrent={location.pathname.includes(Paths.getPath('LGWay'))}
            />
            {/* 신규 LG Awards 페이지 개발 되어 기존 LNB 메뉴 주석 처리 - 2023.11.16 */}
            {/*<NavItemSimple*/}
            {/*    title="LG Awards"*/}
            {/*    dataClickArea="LG Awards"*/}
            {/*    path={Paths.getPath('LgAwardList')}*/}
            {/*    icon="ico_awards"*/}
            {/*    isCurrent={location.pathname.includes(Paths.getPath('LgAwardList'))}*/}
            {/*/>*/}
            <NavItemSimple
              title="LG Awards"
              dataClickArea="LG Awards"
              icon="ico_award"
              path={location.pathname}
              isCurrent={false}
              onClickNavItem={() => {
                postAutoLoginLGAwards().then(res => {
                  if (res.resultCode !== 200) {
                    console.error(res.resultMessage);
                    setAlertPopContents('페이지를 연결할 수 없습니다.');
                    setIsAlertPopupOpen(true);
                    return;
                  }

                  window.open(res.autoLoginTargetUrl, '_blank');
                });
              }}
            />
            <NavItemSimple
              title="DX BP Library"
              dataClickArea="DX BP Library"
              icon="ico_award"
              path={location.pathname}
              isCurrent={false}
              onClickNavItem={() => {
                postAutoLoginLGBPHome().then(res => {
                  if (res.resultCode !== 200) {
                    console.error(res.resultMessage);
                    setAlertPopContents('페이지를 연결할 수 없습니다.');
                    setIsAlertPopupOpen(true);
                    return;
                  }

                  window.open(res.autoLoginTargetUrl, '_blank');
                });
              }}
            />
          </ul>
        </div>
        {/* // PC - LNB 접힘 상태 */}
      </nav>

      {searchMemPopup && (
        <SearchMemPopup
          isActive={searchMemPopup}
          searchWord={searchWord}
          onClose={() => {
            if (!navRef.current?.classList.contains('is-more')) navRef.current?.classList.add('is-more');
            setSearchMemPopup(false);
            navMenuRef.current?.classList.add('is-active');
          }}
        />
      )}
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
    </>
  );
};

export default Sider;
