export enum CommentPostType {
  MEDIA = 'MEDIA',
  EVENT = 'EVENT',
  /** 자유 게시판 */
  FREE = 'FREE',
  /** 회사 옆 맛집 게시판 */
  TOP_EATS = 'TOP_EATS',
  /** 칭찬합니다 게시판 */
  MENTIONS = 'MENTIONS',
  /** 회사 속 직무 게시판 */
  DEPARTMENT_BOARD = 'DEPARTMENT_BOARD',
  /** 회사 밖 소모임 게시판 */
  SOCIETY_BOARD = 'SOCIETY_BOARD',
}
