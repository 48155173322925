import classNames from 'classnames';
import ProfileImage from 'components/atoms/ProfileImage';
import React, { useEffect } from 'react';
import { relativeTime } from 'utils/utils';
import { all_click_datalayer_push, getAlarmGAProps } from 'utils/GAUtils';
import { getCommentContentsByReplacedMentionTagToGoalBangYee } from 'utils/mentionUtils';

export interface TotalNoticeProps {
  noticeList: Array<NoticeResDto>;
  onClose: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
  currentDt: string;
  /** unmount 시 알림 읽음 처리 위한 callBack */
  unmountCallback: (status: boolean) => void;
  /** 개별 알림 아이템 클릭 */
  clickNoticeItemCallback: (noticeItem: NoticeResDto) => void;
}

const TotalNotice = (props: TotalNoticeProps) => {
  const { noticeList } = props;

  useEffect(() => {
    return () => {
      props.unmountCallback?.(true);
    };
  });

  return (
    <>
      {noticeList && noticeList.length > 0 ? (
        <ul className="notice_list custom_scroll">
          {noticeList
            .flatMap(
              item =>
                ({
                  ...item,
                  contents: getCommentContentsByReplacedMentionTagToGoalBangYee(item.contents || '', {
                    hasRemoveHtmlTag: true,
                    skipBrTag: true,
                  }),
                } as NoticeResDto)
            )
            .map((n, idx) => (
              <li
                className={classNames('notice_item', {
                  'is-new': n.readYn === 'N',
                })}
                key={`${n.noticeDetailType}_${idx}_${n.noticeId}`}
              >
                {(n.noticeType === 'USER' || n.noticeType === 'QNA' || n.noticeType === 'ANNI') && (
                  <a
                    href="#"
                    className="notice"
                    {...getAlarmGAProps({
                      contents: n.contents as string,
                      isEpClick: false,
                    })}
                    onClick={e => {
                      e.preventDefault();
                      props.clickNoticeItemCallback?.(n);
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  >
                    <div className="notice_img">
                      <ProfileImage
                        name={n.senderName || ''}
                        score={n.senderActScore as number}
                        image={n.senderProfileUri}
                        isAdmin={n.noticeType === 'QNA'}
                      />
                    </div>
                    <div className="notice_cont">
                      <span className="notice_text" dangerouslySetInnerHTML={{ __html: n.contents || '' }}></span>
                      <span className="notice_time">{relativeTime(n.createdDt, props.currentDt)}</span>
                    </div>
                  </a>
                )}
                {n.noticeType === 'RECOM' && (
                  <a
                    href="#"
                    className="notice"
                    {...getAlarmGAProps({
                      contents: n.contents as string,
                      isEpClick: false,
                    })}
                    onClick={e => {
                      e.preventDefault();
                      props.clickNoticeItemCallback?.(n);
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  >
                    <div className="notice_img">
                      <img src={'/assets/images/icon/ico-info-contens_add-fit.svg'} className="notice_profile" alt="" />
                    </div>
                    <div className="notice_cont">
                      <span className="notice_text" dangerouslySetInnerHTML={{ __html: n.contents || '' }}></span>
                      <span className="notice_time">{relativeTime(n.createdDt, props.currentDt)}</span>
                    </div>
                  </a>
                )}
                {n.noticeType === 'EVENT' && (
                  <a
                    href="#"
                    className="notice"
                    {...getAlarmGAProps({
                      contents: n.contents as string,
                      isEpClick: false,
                    })}
                    onClick={e => {
                      e.preventDefault();
                      props.clickNoticeItemCallback?.(n);
                      all_click_datalayer_push(e.currentTarget);
                    }}
                  >
                    <div className="notice_img">
                      <img src={'/assets/images/icon/ico-info-contens_add-new.svg'} className="notice_profile" alt="" />
                    </div>
                    <div className="notice_cont">
                      <span className="notice_text" dangerouslySetInnerHTML={{ __html: n.contents || '' }}></span>
                      <span className="notice_time">{relativeTime(n.createdDt, props.currentDt)}</span>
                    </div>
                  </a>
                )}
              </li>
            ))}
        </ul>
      ) : (
        <div className="popup_empty">
          <span>알림이 없습니다.</span>
        </div>
      )}
    </>
  );
};

TotalNotice.displayName = 'TotalNotice';

export default TotalNotice;
