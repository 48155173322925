import React, { createContext } from 'react';

export const initialState = {
  isLogin: false,
  setIsLogin: () => {},
  isAppLogined: false,
  setIsAppLogined: () => {},
  profileImage: '',
  setProfileImage: () => {},
  actScore: 0,
  setActScore: () => {},
  isAdmin: '',
  setIsAdmin: () => {},
  userId: '',
  setUserId: () => {},
  name: '',
  setName: () => {},
  groupNm: '',
  setGroupNm: () => {},
  phoneNum: '',
  setPhoneNum: () => {},
  department: '',
  setDepartment: () => {},
  init: () => {},
} as {
  isLogin: boolean;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  isAppLogined: boolean;
  setIsAppLogined: React.Dispatch<React.SetStateAction<boolean>>;
  profileImage: string;
  setProfileImage: React.Dispatch<React.SetStateAction<string>>;
  actScore: number;
  setActScore: React.Dispatch<React.SetStateAction<number>>;
  isAdmin: string;
  setIsAdmin: React.Dispatch<React.SetStateAction<string>>;
  userId: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  groupNm: string;
  setGroupNm: React.Dispatch<React.SetStateAction<string>>;
  phoneNum: string;
  setPhoneNum: React.Dispatch<React.SetStateAction<string>>;
  department: string;
  setDepartment: React.Dispatch<React.SetStateAction<string>>;
  init: any;
};

const MyInfoContext = createContext(initialState);

export default MyInfoContext;
