import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { imageUrl, videoTimeFormat } from 'utils/utils';
import { all_click_datalayer_push } from 'utils/GAUtils';

export interface BestCardProps extends MediaCardResDto {
  isTitle2?: boolean;
  rank: number;
  path?: string;
  isSwiperSlide?: boolean;
  GAObj?: any;
}

const BestCard = (props: BestCardProps) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const cardRef = useRef<HTMLAnchorElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <>
      <Link
        to={props.path || '/'}
        className={classNames([{ card: !props.isSwiperSlide }, { 'has-Video': props.mediaContentsType === 'VIDEO' }])}
        ref={cardRef}
        {...props.GAObj}
        onClick={e => {
          all_click_datalayer_push(e.currentTarget);
        }}
        onMouseEnter={() => {
          if (props.mediaContentsType === 'MAGAZINE' || !props.previewVideoUri) return;

          cardRef.current?.classList.add('is-play');
          if (videoRef.current && !videoRef.current.src) {
            videoRef.current.src = imageUrl(props.previewVideoUri);
            videoRef.current.load();
          }
          setTimeout(() => {
            if (videoRef.current) {
              videoRef.current.currentTime = 0;
              videoRef.current.play();
            }
          }, 1500);
          setTimer(
            setTimeout(() => {
              cardRef.current?.classList.remove('is-play');
              cardRef.current?.classList.add('is-play-over');
            }, 7000)
          );
        }}
        onMouseLeave={() => {
          if (props.mediaContentsType === 'MAGAZINE' || !props.previewVideoUri) return;

          cardRef.current?.classList.remove('is-play');
          cardRef.current?.classList.remove('is-play-over');
          if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.pause();
          }
          clearTimeout(timer || setTimeout(() => {}));
          setTimer(undefined);
        }}
      >
        <div className="card_item">
          <div className="card_thumb">
            <img
              src={imageUrl(props.thumbImageUri) || '/assets/images/illust/illust-img-media.png'}
              alt=""
              className="card_thumb_img"
            />
            {props.badgeNm && (
              <span
                className="card_thumb_flag is-special"
                style={{ backgroundColor: `#${props.badgeColor}` || '#be004e' }}
              >
                {props.badgeNm}
              </span>
            )}
            {props.previewVideoUri && <video className="card_thumb_video" ref={videoRef} muted></video>}
            {props.mediaContentsType === 'VIDEO' && (
              <>
                <span className="card_thumb_play">
                  <img src="/assets/images/icon/ico-play.png" alt="play" />
                </span>
                <span className="card_thumb_time">{videoTimeFormat(props.videoTotalTime)}</span>
              </>
            )}
            <span className="card_thumb_lank">
              <img src={`/assets/images/icon/ico-lank0${props.rank}.png`} alt={`${props.rank}`} />
            </span>
          </div>
          <div
            className={classNames('card_con', {
              'no-desc': !props.summary?.trim() || props.summary?.trim().length === 0,
            })}
          >
            <div className={classNames(['card_title', { 'is-two': props.isTitle2 }])}>{props.title}</div>
            {props.summary && props.summary.trim().length > 0 && !props.isTitle2 ? (
              <div className="card_desc">{props.summary.trim()}</div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Link>
    </>
  );
};

export default BestCard;
