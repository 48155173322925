import { getHtmlFormatByEscapeHtmlData, removeHtmlTag } from './LGLifeUtils';

const escapeCorpCode = '{주}';
const escapeCorpCodeReg = /{주}/g;
const realCorpCode = '(주)';
const realCorpCodeReg = /\(주\)/g;

/**
 * 댓글 본문을 멘션 태그에 해당하는 부분을 Replace 한, 데이터를 반환
 * @param commentContents 댓글 본문
 * @param hasRemoveHtmlTag Html 태그 제거 여부
 * @param skipBrTag br 태그 skip 여부
 */
export const getCommentContentsByReplacedMentionTagToGoalBangYee = (
  commentContents: string,
  { hasRemoveHtmlTag = true, skipBrTag = false } = {}
) => {
  if (!commentContents) return commentContents;

  const mentionRegex = /(\[__.+?__\))/g;

  if (!commentContents.match(mentionRegex)) return commentContents;

  const arr = getHtmlFormatByEscapeHtmlData(commentContents).split(mentionRegex).filter(Boolean) || [];

  if (!arr?.length) {
    return commentContents;
  }

  return arr
    .map(contents => {
      const unCheckedMentionData = contents.replace(/&amp;nbsp;/g, '').replace(realCorpCodeReg, escapeCorpCode);

      if (unCheckedMentionData.match(mentionRegex)) {
        const mentionUserName = contents.match(/[^\[__]+([0-9a-zA-Z-_~!@#$%^&*ㄱ-하-ㅣ가-힣]?)+(?=__\])/g);
        const mentionUserId = contents.match(/[^\{__]+([0-9a-zA-Z-_~!@#$%^&*ㄱ-하-ㅣ가-힣]?)+(?=__\})/g);
        const mentionGroupNm = contents.match(/[^\(__]+([0-9a-zA-Z-_~!@#$%^&*ㄱ-하-ㅣ가-힣]?)+(?=__\))/g);

        if (mentionUserName && mentionUserId && mentionGroupNm) {
          return `@${mentionUserName}`;
        }
      }

      return contents;
    })
    .flatMap(str => {
      if (hasRemoveHtmlTag) {
        if (skipBrTag) return removeHtmlTag(str, { skipBrTag: true });
        return removeHtmlTag(str);
      }
      return str;
    })
    .join(' ')
    .replace(escapeCorpCodeReg, realCorpCode);
};

/**
 * 댓글 내용을 전달 받아 HTML 멘션 태그 형태로 Replace
 * @param commentContents 뎃글 내용
 * @param hasRemoveHtmlTag Html 태그 제거 여부
 */
export const getCommentContentsByReplacedMentionTagToHTMLTag = (commentContents: string, {} = {}) => {
  if (!commentContents) return commentContents;

  const mentionRegex = /(\[__.+?__\))/g;

  if (!commentContents.match(mentionRegex)) return commentContents;

  const arr = getHtmlFormatByEscapeHtmlData(commentContents).split(mentionRegex).filter(Boolean) || [];

  if (!arr?.length) {
    return commentContents;
  }

  return arr
    .map(contents => {
      const unCheckedMentionData = contents.replace(/&amp;nbsp;/g, '').replace(realCorpCodeReg, escapeCorpCode);

      if (unCheckedMentionData.match(mentionRegex)) {
        const mentionUserName = unCheckedMentionData.match(/[^\[__]+([0-9a-zA-Z-_~!@#$%^&*ㄱ-하-ㅣ가-힣]?)+(?=__\])/g);
        const mentionUserId = unCheckedMentionData.match(/[^\{__]+([0-9a-zA-Z-_~!@#$%^&*ㄱ-하-ㅣ가-힣]?)+(?=__\})/g);
        const mentionGroupNm = unCheckedMentionData.match(/[^\(__]+([0-9a-zA-Z-_~!@#$%^&*ㄱ-하-ㅣ가-힣]?)+(?=__\))/g);

        if (mentionUserName && mentionUserId && mentionGroupNm) {
          return (
            `<div class="mention_tag" contenteditable="false"` +
            ` data-mention-name="${mentionUserName}"` +
            ` data-mention-user-id="${mentionUserId}"` +
            ` data-mention-group-name="${mentionGroupNm}">` +
            `<p>@</p><span>${mentionUserName}</span><span>${mentionGroupNm}</span>` +
            `</div>&nbsp;`
          );
        }
      }

      return contents;
    })
    .join('')
    .replace(escapeCorpCodeReg, realCorpCode);
};

/**
 * HTML 형태의 멘션 데이터를 전달받아 멘션 코드 " [__$mentionName}__]{__${mentionUserId}__}(__${mentionGroupNm}__) " 형태로 치환
 * @param cmtDivRef 멘션 html Data
 */
export const convertMentionCodeDataFromHtmlMentionTypeData = (cmtDivRef: HTMLElement) => {
  let dataHtml = getCommentContentsByReplacedMentionTagToHTMLTag(String(cmtDivRef.innerHTML));
  const childNodes = cmtDivRef.children;

  for (let i = 0; i < childNodes.length; i++) {
    const data = childNodes[i].childElementCount === 1 ? childNodes[i].children?.[0] : childNodes[i];

    if (data?.classList.contains('mention_tag')) {
      const mentionName = data.getAttribute('data-mention-name');
      const mentionUserId = data.getAttribute('data-mention-user-id');
      const mentionGroupNm = data.getAttribute('data-mention-group-name');

      if (mentionName && mentionUserId && mentionGroupNm) {
        dataHtml = dataHtml.replace(
          data.outerHTML,
          '[__' + mentionName + '__]{__' + mentionUserId + '__}(__' + mentionGroupNm + '__)'
        );
      }
    }
  }

  return dataHtml;
};
