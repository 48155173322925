import React from 'react';
import CardRecord from 'components/atoms/CardRecord';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { imageUrl, relativeTime, videoTimeFormat } from 'utils/utils';
import { getContentsGAProps, all_click_datalayer_push } from 'utils/GAUtils';
import Paths from 'commons/paths';
interface EditorsItemProps extends MainEditorsPlanResDto {
  currentDt: string;
}

const EditorsItem = (props: EditorsItemProps) => {
  const { editorsPlan, editorsPlanPlaylist } = props;
  const history = useHistory();

  return (
    <>
      <div
        className={classNames([
          'editors_item',
          { 'is-one': editorsPlanPlaylist?.length === 1 },
          { 'is-two': editorsPlanPlaylist?.length === 3 },
          { 'is-three': editorsPlanPlaylist?.length === 4 },
        ])}
      >
        <a
          // to={Paths.getPath('videoDetail').replace(':mediaId', editorsPlan?.mediaId as string)}
          href="#"
          onClick={e => {
            e.preventDefault();
            history.push({
              pathname: Paths.getPath('videoDetail').replace(':mediaId', editorsPlan?.mediaId as string),
              state: editorsPlan?.firstMediaId,
            });
            all_click_datalayer_push(e.currentTarget);
          }}
          className="editors_first"
          {...getContentsGAProps({
            contentsType: 'MEDIA',
            title: editorsPlan?.title as string,
            tags: editorsPlan?.mediaTag as string,
            category: editorsPlan?.cateNm as string,
            dataContentBelong: 'Editor’s 기획',
            maker: editorsPlan?.creatorNm as string,
            time: editorsPlan?.videoTotalTime,
            brightcoveId: editorsPlan?.videoId as string,
            mediaContentsType: editorsPlan?.mediaContentsType as string,
            dataContentUrl: `${Paths.getPath('videoDetail').replace(':mediaId', editorsPlan?.mediaId as string)}`,
            dataImgUrl: `${editorsPlan?.thumbImageUri as string}`,
          })}
        >
          <div className="editors_first_thumb">
            <img
              src={imageUrl(editorsPlan?.thumbImageUri) || '/assets/images/illust/illust-img-media.png'}
              alt=""
              className="card_thumb_img"
            />
            {!editorsPlan?.thumbImageUri && <span className="card_thumb_group">+{editorsPlan?.playListCnt}</span>}
            <span className="card_thumb_play">
              <img src="/assets/images/icon/ico-play.png" alt="play" />
            </span>
            {/* <span className="card_thumb_time">{videoTimeFormat(editorsPlan?.videoTotalTime)}</span> */}
          </div>
          <div className="card_thumb_info">
            <div className="card_thumb_title">{editorsPlan?.title}</div>
            <div className="card_thumb_desc">{editorsPlan?.summary}</div>
            {/* <div className="card_sub">
              <CardRecord
                views={editorsPlan?.readCnt as number}
                comments={editorsPlan?.commentCnt as number}
                likes={editorsPlan?.likeCnt as number}
              />
              <div className="card_time">{relativeTime(editorsPlan?.postDt, props.currentDt)}</div>
            </div> */}
          </div>
        </a>

        {editorsPlanPlaylist && editorsPlanPlaylist.length === 2 ? (
          // 그룹 영상 2개인 경우
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              history.push({
                pathname: Paths.getPath('videoDetail').replace(':mediaId', editorsPlan?.mediaId as string),
                state: editorsPlanPlaylist[1].mediaId,
              });
              all_click_datalayer_push(e.currentTarget);
            }}
            {...getContentsGAProps({
              contentsType: 'MEDIA',
              title: editorsPlanPlaylist[1].title as string,
              tags: editorsPlanPlaylist[1].mediaTag as string,
              category: editorsPlanPlaylist[1].cateNm as string,
              dataContentBelong: `'Editor's 기획'`,
              maker: editorsPlanPlaylist[1].creatorNm as string,
              time: editorsPlanPlaylist[1].videoTotalTime,
              brightcoveId: editorsPlanPlaylist[1].videoId as string,
              mediaContentsType: editorsPlanPlaylist[1].mediaContentsType as string,
              dataContentUrl: `${Paths.getPath('videoDetail').replace(':mediaId', editorsPlan?.mediaId as string)}`,
              dataImgUrl: `${editorsPlanPlaylist[1].thumbImageUri as string}`,
            })}
            className="editors_first"
          >
            <div className="editors_first_thumb">
              <img
                src={imageUrl(editorsPlanPlaylist[1].thumbImageUri) || '/assets/images/illust/illust-img-media.png'}
                alt=""
                className="card_thumb_img"
              />
              <span className="card_thumb_play is-pc">
                <img src="/assets/images/icon/ico-play.png" alt="play" />
              </span>
              <span className="card_thumb_time">{videoTimeFormat(editorsPlanPlaylist[1].videoTotalTime)}</span>
            </div>
            <div className="card_thumb_info">
              <div className="card_thumb_title">{editorsPlanPlaylist[1].title}</div>
              <div className="card_thumb_desc">{editorsPlanPlaylist[1].summary}</div>
              <div className="card_sub">
                <CardRecord
                  views={editorsPlanPlaylist[1].readCnt as number}
                  comments={editorsPlanPlaylist[1].commentCnt as number}
                  likes={editorsPlanPlaylist[1].likeCnt as number}
                />
                <div className="card_time">{relativeTime(editorsPlanPlaylist[1].postDt, props.currentDt)}</div>
              </div>
            </div>
          </a>
        ) : (
          <div className="editors_group">
            {editorsPlanPlaylist &&
              (editorsPlanPlaylist.length > 1 ? (
                // 그룹 영상 3개 이상인 경우
                editorsPlanPlaylist.slice(1).map((item, idx) => (
                  <a
                    className="editors_group_item"
                    key={idx}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      history.push({
                        pathname: Paths.getPath('videoDetail').replace(':mediaId', editorsPlan?.mediaId as string),
                        state: item.mediaId,
                      });
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    {...getContentsGAProps({
                      contentsType: 'MEDIA',
                      title: item.title as string,
                      tags: item.mediaTag as string,
                      category: item.cateNm as string,
                      dataContentBelong: 'Editor’s 기획',
                      maker: item.creatorNm as string,
                      time: item.videoTotalTime,
                      brightcoveId: item.videoId as string,
                      mediaContentsType: item.mediaContentsType as string,
                      dataContentUrl: `${Paths.getPath('videoDetail').replace(
                        ':mediaId',
                        editorsPlan?.mediaId as string
                      )}`,
                      dataImgUrl: `${item.thumbImageUri as string}`,
                    })}
                  >
                    <div className="group_thumb">
                      <img
                        src={imageUrl(item.thumbImageUri) || '/assets/images/illust/illust-img-media.png'}
                        alt=""
                        className="group_thumb_img"
                      />
                      <span className="card_thumb_time">{videoTimeFormat(item.videoTotalTime)}</span>
                    </div>
                    <div className="group_info">
                      <div className="group_title">{item.title}</div>
                      {/*{(editorsPlan?.playListCnt as number) < 4 && (*/}
                      {/*  <div className="group_desc is-tablet">{item.summary}</div>*/}
                      {/*)}*/}
                      <div className="group_sub">
                        <div className="card_sub">
                          <CardRecord
                            views={item.readCnt as number}
                            comments={item.commentCnt as number}
                            likes={item.likeCnt as number}
                          />
                          <div className="card_time">{relativeTime(item.postDt, props.currentDt)}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                ))
              ) : (
                // 그룹 영상 1개인 경우
                <div className="editors_group_item">
                  <div className="group_info">
                    <div className="group_title">{editorsPlanPlaylist[0].title}</div>
                    {/*<div className="group_desc">{editorsPlanPlaylist[0].summary}</div>*/}
                    <div className="group_sub">
                      <div className="card_sub">
                        <CardRecord
                          views={editorsPlanPlaylist[0].readCnt as number}
                          comments={editorsPlanPlaylist[0].commentCnt as number}
                          likes={editorsPlanPlaylist[0].likeCnt as number}
                        />
                        <div className="card_time">{relativeTime(editorsPlanPlaylist[0].postDt, props.currentDt)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default EditorsItem;
