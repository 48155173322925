import React, { ReactNode, useCallback, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import InnerHTML from 'dangerously-set-html-content';

interface WarningPopupProps {
  /** 팝업 오픈 여부 */
  isOpen: boolean;
  /** 팝업 내용 */
  popUpContents: string;
  /** 팝업 숨기기 옵션 */
  registerHidePopUpOptions?: {
    term: number;
    contents: ReactNode;
  };
  onClose: () => void;
}

const WarningPopup = ({ isOpen, popUpContents, onClose, registerHidePopUpOptions }: WarningPopupProps) => {
  const [isCheckHideOption, setIsCheckHideOption] = useState(false);

  /** 일정 기간 동안 팝업 닫기 localStorage 등록 */
  const handleRegisterHidePopupFor1Week = useCallback(() => {
    if (!registerHidePopUpOptions) return;
    if (Object.keys(registerHidePopUpOptions)?.length === 0) return;

    const hideTerm = registerHidePopUpOptions.term > 1 ? registerHidePopUpOptions.term - 1 : 1;

    localStorage.setItem('hide-warning-popup-expired-date', moment().add(hideTerm, 'days').format('YYYY.MM.DD'));
    onClose();
  }, []);

  return (
    <>
      {isOpen && (
        <div className="popup ie_browser_popup is-active">
          <div className="popup_dim"></div>
          <div className="popup_inner popup_large popup_mobile_full">
            <div className="popup_header"></div>
            <div className="popup_cont">
              <div className="popup_desc">
                <p>
                  <InnerHTML html={popUpContents || ''} />
                </p>
              </div>
            </div>
            <div className="popup_bottom">
              <button
                className={classNames({ on: isCheckHideOption })}
                onClick={() => {
                  setIsCheckHideOption(!isCheckHideOption);
                  handleRegisterHidePopupFor1Week();
                }}
              >
                {registerHidePopUpOptions &&
                  Object.keys(registerHidePopUpOptions).length > 0 &&
                  registerHidePopUpOptions.contents}
              </button>
            </div>
            <button type="button" className="popup_close ico_close" onClick={() => onClose()}></button>
          </div>
        </div>
      )}
    </>
  );
};

export default WarningPopup;
