import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import paths from 'commons/paths';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getContentsGAProps } from 'utils/GAUtils';
import { clearListCache } from '../../../utils/LGLifeUtils';
import { ContentCard } from 'components/molecules';
import { ScreenSize } from 'components/pages/MyPage';
import useRecommends from '../../../hooks/useRecommends';
import Paths from 'commons/paths';

const MEDIA_COUNT_TO_SHOW = '4';
const MEDIA_LIST_FULL_COUNT_TO_SHOW = '16';

interface RowContentsProps {
  screenSize: ScreenSize;
  currentDt: string;
  list?: Array<MediaCardResDto>;
  count?: number;
  getTimeLine: (mediaId?: string) => number | undefined;
}

const RowContents = ({ screenSize, currentDt, list = [], count = 0, getTimeLine }: RowContentsProps) => {
  if (count <= 0) return <div className="mypage_empty">추천 콘텐츠가 없습니다</div>;

  return (
    <>
      {screenSize === 'lg' ? (
        <div className="card_row is-small has-hover recentSwiper">
          <div className="card_wrapper">
            {list?.map((media: MediaCardResDto) => (
              <ContentCard
                {...media}
                currentDt={currentDt}
                timeline={getTimeLine(media.mediaId)}
                path={`${Paths.getPath('recommendDetail').replace(':mediaId', media.mediaId as string)}`}
                isSwiperSlide={false}
                key={media.mediaId}
                GAObj={getContentsGAProps({
                  contentsType: 'MEDIA',
                  title: media.title as string,
                  tags: media.mediaTag as string,
                  category: media.cateNm as string,
                  dataContentBelong: '마이페이지/추천 콘텐츠',
                  maker: media.creatorNm as string,
                  time: media.videoTotalTime,
                  brightcoveId: media.videoId as string,
                  mediaContentsType: media.mediaContentsType as string,
                  dataContentUrl: `${Paths.getPath('recommendDetail').replace(':mediaId', media.mediaId as string)}`,
                  dataImgUrl: `${media?.thumbImageUri as string}`,
                })}
              />
            ))}
          </div>
        </div>
      ) : (
        <Swiper
          className="card_row is-small recentSwiper"
          slidesPerView={1.4}
          threshold={70}
          breakpoints={{ 768: { slidesPerView: 3.1 } }}
        >
          {list?.map((media: MediaCardResDto) => (
            <SwiperSlide className="card" key={media.mediaId}>
              <ContentCard
                {...media}
                currentDt={currentDt}
                timeline={getTimeLine(media.mediaId)}
                path={`${Paths.getPath('recommendDetail').replace(':mediaId', media.mediaId as string)}`}
                isSwiperSlide={true}
                key={media.mediaId}
                GAObj={getContentsGAProps({
                  contentsType: 'MEDIA' as string,
                  title: media.title as string,
                  tags: media.mediaTag as string,
                  category: media.cateNm as string,
                  dataContentBelong: '마이페이지/추천 콘텐츠',
                  maker: media.creatorNm as string,
                  time: media.videoTotalTime,
                  brightcoveId: media.videoId as string,
                  mediaContentsType: media.mediaContentsType as string,
                  dataContentUrl: `${Paths.getPath('recommendDetail').replace(':mediaId', media.mediaId as string)}`,
                  dataImgUrl: `${media?.thumbImageUri as string}`,
                })}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

interface Props {
  screenSize?: ScreenSize;
  currentDt?: string;
  getTimeLine?: (mediaId?: string) => number | undefined;
}

const RecommendContentsSection = ({
  screenSize = 'lg',
  currentDt = new Date().toString(),
  getTimeLine = () => 0,
}: Props) => {
  const { data, searchOptions, setSearchOptions } = useRecommends({
    pageItemSize: parseInt(MEDIA_LIST_FULL_COUNT_TO_SHOW),
  });

  const history = useHistory();

  const [recommendList, setRecommendList] = useState([] as MediaCardResDto[]);
  const [sort, setSort] = useState('recommend,desc');
  const [popularitySortType, setPopularitySortType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopState, setIsPopState] = useState(false);

  const controlRef = useRef<HTMLDivElement>(null);

  const recommendContentsList = useMemo(() => {
    return data?.recommendMediaList?.slice(0, parseInt(MEDIA_COUNT_TO_SHOW));
  }, [data]);

  const scrollSave = () => {
    const scrollPos = window.pageYOffset;
    localStorage.setItem('videoList', JSON.stringify(recommendList));
    localStorage.setItem('scrollPos', scrollPos.toString());
    localStorage.setItem('currentPage', currentPage.toString());
  };

  const handleScroll = () => {
    if ((window.scrollY >= 88 && window.innerWidth > 1023) || (window.scrollY >= 61 && window.innerWidth < 1024))
      controlRef.current?.classList.add('is-sticky');
    else controlRef.current?.classList.remove('is-sticky');
  };

  useEffect(() => {
    if (history.action === 'POP' && recommendList.length === 0 && localStorage.getItem('videoList')) {
      setRecommendList(JSON.parse(localStorage.getItem('videoList') as string));
      setCurrentPage(Number(localStorage.getItem('currentPage')));
      setIsPopState(true);

      setTimeout(() => {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }, 100);
    } else if (history.action === 'PUSH') {
      clearListCache();
    }
  });

  useEffect(() => {
    const listen = history.listen(scrollSave);
    return () => {
      listen();
    };
  });

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));
    if (listData && history.action === 'POP' && listData.list === 'recommend') {
      setSort(listData.sort);
      setPopularitySortType(listData.popularitySortType);
    } else {
      window.sessionStorage.clear();
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.onbeforeunload = () => {
      clearListCache();
    };

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (data) {
      if (isPopState && data.page === currentPage) {
        setIsPopState(false);
        return;
      }

      if (currentPage === 1 && data.page === 1) {
        setRecommendList(data.recommendMediaList);
      } else if (data.totalAmount > recommendList?.length) {
        setRecommendList(recommendList.concat(data.recommendMediaList));
      }
    }
  }, [data]);

  useEffect(() => {
    setSearchOptions({ ...searchOptions, sort, page: currentPage, popularitySortType });
    window.sessionStorage.setItem(
      'listData',
      JSON.stringify({
        list: 'recommend',
        sort: sort,
        currentPage: currentPage,
        popularitySortType: popularitySortType,
      })
    );
  }, [sort, popularitySortType, currentPage]);

  if (!recommendList) return <></>;

  return (
    <div className="mypage_section">
      <div className="mypage_inner">
        <div className="mypage_top">
          <h3 className="mypage_top_title">추천 콘텐츠</h3>
          {(recommendList?.length || 0) > parseInt(MEDIA_COUNT_TO_SHOW) ? (
            <Link to={`${paths.getPath('recommendContents')}`} className="mypage_top_link">
              모두보기
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="search_row">
        <RowContents
          screenSize={screenSize}
          currentDt={currentDt}
          list={recommendContentsList}
          count={recommendContentsList?.length || 0}
          getTimeLine={getTimeLine}
        />
      </div>
    </div>
  );
};

export default RecommendContentsSection;
