import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import paths from 'commons/paths';

const QnaTemplate = ({ children }) => {
  const history = useHistory();

  return (
    <>
      <div className="content_inner">
        <div className="section qna_write">
          <div className="section_title">
            <div className="detail_top">
              <h3 className="detail_top_title">Q&A</h3>
            </div>
          </div>
          <div className="section_cont">
            <div className="cont_header">
              <div className="cont_header_inner" style={{ backgroundImage: "url('/assets/images/img/img-qna.png')" }}>
                <strong className="cont_title">문의하기</strong>
                <p className="cont_desc">“LG LIFE는 임직원 여러분의 소중한 의견에 항상 귀 기울이고 있습니다.”</p>
                <p className="cont_desc_sub">
                  LG LIFE 사이트내 궁금하신 점이나 의견을 남겨주시면 최대한 신속하고 자세히 답변해 드리겠습니다.
                </p>
              </div>
            </div>
            <div className="cont_inner">
              <div className="qna_tab">
                <ul className="qna_tab_list">
                  <li className="qna_tab_item">
                    <a
                      href="#"
                      className={classNames([
                        'qna_tab_button',
                        location.pathname.indexOf(paths.getPath('qnaWrite')) > -1 ? 'is-current' : null,
                      ])}
                      data-qna-tab="write"
                      onClick={e => {
                        e.preventDefault();
                        history.push(paths.getPath('qnaWrite'));
                      }}
                    >
                      1:1 문의하기
                    </a>
                  </li>
                  <li className="qna_tab_item">
                    <a
                      href="#"
                      className={classNames([
                        'qna_tab_button',
                        location.pathname === paths.getPath('qnaList') ? 'is-current' : null,
                      ])}
                      onClick={e => {
                        e.preventDefault();
                        history.push(paths.getPath('qnaList'));
                      }}
                    >
                      나의 문의 내역
                    </a>
                  </li>
                </ul>
              </div>
              <div className="qna_content">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QnaTemplate;
