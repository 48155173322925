import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { CommentAddReq } from 'services/fetcher/comment';
// import useMyInfo from './useMyInfo';

const useCommentReply = commentId => {
  // const { info } = useMyInfo();

  // const { data, mutate } = useSWR<any>(info ? `${endpoints.comment.comment}/${commentId}` : null, (url) =>
  //   fetcher.comment.getReplyComment(url).then((res) => {
  //     return res;
  //   }),
  // );

  return {
    // data,
    addReply(addCmtReq: CommentAddReqDto) {
      return fetcher.comment.postComment(addCmtReq);
    },
    likeReply(commentId: string) {
      return fetcher.comment.postCommentLike({ commentId });
    },
    modifyReply: (params: CommentModifyReqDto) => {
      return fetcher.comment.putCommentModify(params);
    },
    deleteReply: (params: CommentDeleteReqDto) => {
      return fetcher.comment.deleteComment(params);
    },
  };
};

export default useCommentReply;
