import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { NoticeDetailType } from '../../hooks/useNotice';

export interface NoticeReadRequest {
  noticeId: string;
}

export interface NoticeListRequest {
  noticeDetailType: NoticeDetailType;
}

export default {
  /** 알림 목록 조회 */
  getNotice: (params?: NoticeListRequest): Promise<NoticeListResDto> =>
    request({
      url: endpoints.notice.notice,
      method: 'get',
      params: !!params ? params : undefined,
    }),
  /** 알림 읽음 처리 */
  readNotice: (params?: NoticeReadReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.notice.noticeRead,
      method: 'put',
      data: params,
    }),
  /** 상단 알림탭 - 읽지 않은 알림 개수 */
  getNewNoticeCount: (params?: NoticeListRequest): Promise<NoneReadNoticeCountListResDto> => {
    if (params && params?.noticeDetailType) {
      return request({
        url: endpoints.notice.newNoticeCount,
        method: 'get',
        params,
      });
    }
    return request({
      url: endpoints.notice.newNoticeCount,
      method: 'get',
    });
  },
  /** 상세 알림 유형에 따른 읽지 않은 알림 목록 조회 */
  getNewNoticeListByNoticeDetailType: () => {
    return request({});
  },
};
