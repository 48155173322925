import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';
import { PagingReqDto } from '../../types';

export interface TempBoardListRequest extends PagingReqDto {
  userId: string;
  /** 게시판 유형 */
  boardType: string;
}

export interface TempBoardWriteReq {
  title?: string;
  boardType: string;
  contents: string;
}

export interface TempBoardDetailReq {
  tempBoardId: string;
}

export interface TempBoardDeleteReq {
  tempBoardId: string;
}

export default {
  getTempBoardList: (params: TempBoardListRequest): Promise<TempBoardListResDto> =>
    request({
      url: endpoints.tempBoards.tempBoard,
      method: 'get',
      params,
    }),
  getTempBoardDetail: (url: string, params: TempBoardDetailReq): Promise<TempBoardDetailResDto> =>
    request({
      url,
      method: 'get',
      params,
    }),
  postTempBoardWrite: (params: TempBoardWriteReq): Promise<BaseResDto> => {
    return request({
      url: endpoints.tempBoards.tempBoardWrite,
      method: 'post',
      data: paramsToFormData(params),
    });
  },
  deleteTempBoard: (url: string, params: TempBoardDeleteReq): Promise<BaseResDto> =>
    request({
      url,
      method: 'delete',
      params,
    }),
};
