import { mobileCheck } from './utils';
import { isDNOPortalMobileApp, isMOfficePlusMobileApp, MOfficePlusMobileAppPopUpUtils } from './lgUtilsByJs';
import { NAME_CIC_UPDATE } from '../commons/constants';
import usePopupInfo from '../hooks/usePopupInfo';

export const sortAsc = list => {
  return list.sort((a, b) => {
    a.orderNum - b.orderNum;
  });
};

export function millisToMinutesAndSeconds(millis: number | undefined) {
  if (millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds;
  }
  return '0:00';
}

export const setCicUpdate = (shouldSyncCic: boolean) => {
  if (shouldSyncCic) {
    localStorage.setItem(NAME_CIC_UPDATE, '1');
  }
};

export const checkCicUpdate = () => {
  const cicUpdate = localStorage.getItem(NAME_CIC_UPDATE) === '1';
  localStorage.removeItem(NAME_CIC_UPDATE);
  return cicUpdate;
};

export const MobilePopUpOpen = (fileContextPath: string) => {
  /**
   * 모바일에서 팝업 창 열기
   * @param {string} fileContextPath 열고자 하는 팝업창 URL 또는 URL fileContextPath
   */
  if (!fileContextPath) return;
  if (!mobileCheck()) return;
  if (isDNOPortalMobileApp()) return;

  if (isMOfficePlusMobileApp()) {
    const { MOfficePlusPopUpOpen } = MOfficePlusMobileAppPopUpUtils();
    MOfficePlusPopUpOpen(fileContextPath);
    return;
  }
};

export const clearListCache = () => {
  localStorage.removeItem('videoList');
  localStorage.removeItem('currentPage');
  localStorage.removeItem('scrollPos');
  localStorage.removeItem('boardList');
  localStorage.removeItem('topEatsBoardList');
  localStorage.removeItem('mentionsBoardList');
  localStorage.removeItem('departmentBoardList');
  localStorage.removeItem('societyBoardList');
};

/**
 * HTML 태그 제거
 * @param htmlString html 태그 포함된 데이터
 * @param skipBrTag br tag 치환 skip 할지 여부
 */
export const removeHtmlTag = (htmlString: string, { skipBrTag = false } = {}) => {
  if (!htmlString) return htmlString;

  // HTML 태그 형태로 전환
  let removedHtml = getHtmlFormatByEscapeHtmlData(htmlString);

  if (skipBrTag) {
    removedHtml = removedHtml.replace('<br>', '##@@##');
  }

  removedHtml = removedHtml.replace(/(<[^>]*>)/gi, ''); // 모든 꺽쇠 태그 제거
  removedHtml = removedHtml.replace(/&amp;/gi, ''); // '&' 제거
  removedHtml = removedHtml.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, '');
  removedHtml = removedHtml.replace(/&nbsp;/gi, ''); // '&nbsp; nbsp;' 공백 제거
  removedHtml = removedHtml.replace(/&lt;/gi, ''); // '<' 제거
  removedHtml = removedHtml.replace(/<(\/?)p>/gi, ''); // p태그 제거
  removedHtml = removedHtml.replace(/&gt;/gi, ''); // '>' 제거
  removedHtml = removedHtml.replace(/&quot;/gi, ''); // '"' 제거
  removedHtml = removedHtml.replace(/&#035;/gi, ''); // '#' 제거
  removedHtml = removedHtml.replace(/&#039;/gi, ''); // ''' 제거
  removedHtml = removedHtml.replace(/<head>(.*?)<(\/?)head>/gi, ''); // head 에 포한됨 모든 내용 제거
  removedHtml = removedHtml.replace(/<style>(.*?)<(\/?)style>/gi, ''); // style 태그에 포함된 모든 내용 제거
  removedHtml = removedHtml.replace(/<(\/?)body>/gi, ''); // body 태그 제거

  if (skipBrTag) {
    removedHtml = removedHtml.replace('##@@##', '<br>');
  }

  return removedHtml;
};

/**
 * Escape 문자로 치환 되어 있는 데이터를 Escape -> HTML 포맷을 변경
 * @param str Escape 문자 형태의 데이터
 * @param revertEscapeHtmlData HTML -> Escape 문자로 되돌릴 지 여부
 */
export const getHtmlFormatByEscapeHtmlData = (str: string, { revertEscapeHtmlData = false } = {}) => {
  if (!str) return str;

  let changedData;
  if (revertEscapeHtmlData) {
    changedData = str.replace(/<br\/>/gi, '\n');
    changedData = changedData.replace(/&/gi, '&amp;');
    changedData = changedData.replace(/</gi, '&lt;');
    changedData = changedData.replace(/>/gi, '&gt;');
    changedData = changedData.replace(/\(/gi, '&#40;');
    changedData = changedData.replace(/\)/gi, '&#41;');
    return changedData;
  }

  changedData = str.replace(/\r\n|\r|\n/gi, '<br/>');
  changedData = changedData.replace(/&amp;/gi, '&');
  changedData = changedData.replace(/&lt;/gi, '<');
  changedData = changedData.replace(/&gt;/gi, '>');
  changedData = changedData.replace(/&#40;/gi, '(');
  changedData = changedData.replace(/&#41;/gi, ')');

  return changedData;
};

export const getFromEPParam = () => {
  return '&tfrom=EP';
};

/**
 * 좌측 LNB 메뉴 열기/닫기
 * - 1329 ~ : PC 화면 크기
 * - 1024 ~ 1328 : 태블릿 화면 크기
 * - ~ 1023 : 모바일 화면 크기
 * @param isOpen
 */
export const openLeftSideMenu = (isOpen: boolean) => {
  // Local Storage 값 저장
  localStorage.setItem('isOpenedLNB', JSON.stringify(isOpen));

  const navElement = document.querySelector('.nav');
  const contentsElement = document.querySelector('.content');

  if (isOpen) {
    // LNB 확대
    contentsElement?.classList.remove('is-expanded');
    navElement?.classList.remove('is-simple');
    navElement?.classList.remove('nav-active');
    navElement?.classList.remove('is-collapsed');

    // 모바일 화면 크기가 아닐 때
    if (window.innerWidth > 1023) {
      //navFullElement?.setAttribute('style','transition: all 0.3s ease 0s;')
    }
    return;
  }

  // LNB 축소
  // 모바일 화면 크기
  if (window.innerWidth < 1024) {
    contentsElement?.classList.remove('is-collapsed', 'is-expanded');
    return;
  }

  // LNB 축소
  navElement?.classList.add('is-simple');
  //navFullElement?.setAttribute('style', 'transition: all 0.3s ease 0s;');
  contentsElement?.classList.add('is-expanded');
  navElement?.classList.add('is-collapsed');
};

/**
 * 현재 온보딩 팝업 노출 중인 온보딩 콘텐츠 주소 가져오기
 * @param linkUrl {string} 연결할 온보딩 콘텐츠 URL 주소
 * @param isBypassOnboardingYn {boolean} 온보딩 노출할 사용자인지 체크할 것인지 여부
 */
export const getDisplayedOnboardingContentsUrl = async ({
  linkUrl,
  isBypassOnboardingYn,
}: {
  linkUrl?: string;
  isBypassOnboardingYn?: boolean;
}) => {
  const { getOnboardingPopupInfo } = usePopupInfo();
  const response = await getOnboardingPopupInfo(isBypassOnboardingYn);
  if (response.resultCode !== 200) {
    console.error('팝업 콘텐츠를 가져오지 못했습니다.');
    return '';
  }
  const targetUrl = !!linkUrl ? linkUrl : (response.linkUri as string);
  if (!targetUrl) return '';
  return targetUrl.includes('http://') || targetUrl.includes('https://')
    ? targetUrl
    : window.location.origin + targetUrl;
};

/**
 * 요일 구하기
 * @param targetDate
 */
export const getDayOfWeekFromTargetDate = (targetDate: Date) => {
  //ex) getDayOfWeek('2022-06-13')
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  return week[new Date(targetDate).getDay()];
};
