import { useState } from 'react';
import { endpoints, fetcher } from 'services';
import { CelebrationMessageWriteRequest, CelebrationRequest } from 'services/fetcher/celebration';
import useSWR from 'swr';
import useMyInfo from './useMyInfo';

const useCelebration = () => {
  const { info } = useMyInfo();
  const [searchOptions, setSearchOptions] = useState<CelebrationRequest>({
    pageSize: 12,
    messageEventType: '',
    sortField: 'createdDt',
    sortType: 'desc',
    pageNum: 1,
  });

  const { data: celebrationListData, mutate } = useSWR<any>(
    info ? [endpoints.celebration75.celebration, searchOptions] : null,
    () =>
      fetcher.celebration.getCelebration(searchOptions).then(res => {
        return res;
      })
  );

  const { data: messageDetail } = useSWR<any>(
    info && celebrationListData && celebrationListData.myMessageId
      ? [
          `${endpoints.celebration75.celebration}/${searchOptions.messageEventType}/${celebrationListData.myMessageId}`,
          celebrationListData,
        ]
      : null,
    url => fetcher.celebration.getMessageDetail(url).then(res => res)
  );

  return {
    celebrationListData,
    searchOptions,
    setSearchOptions,
    deleteMessage(messageId) {
      fetcher.celebration.deleteMessage(`${endpoints.celebration75.celebration}/${messageId}`).then(res => {
        if (res.resultCode === 200) {
          mutate();
          return res;
        }
      });
    },
    writeMessage(params: CelebrationMessageWriteRequest) {
      return fetcher.celebration.postMessageWrite(params);
    },
    modifyMessage(params: CelebrationMessageWriteRequest, messageId: string) {
      return fetcher.celebration.putMessageModify(params, endpoints.celebration75.celebration + '/' + messageId);
    },
    mutate,
    messageDetail,
  };
};

export default useCelebration;
