import React from 'react';
import classNames from 'classnames';
import { getRelatedContentsGAProps, all_click_datalayer_push } from 'utils/GAUtils';
import { dateYYYYMMDD, imageUrl, videoTimeFormat } from 'utils/utils';
import { WEB_RESOURCE_DOMAIN_URL } from 'commons/constants';

export interface PlaylistItem {
  commentCnt: number;
  likeCnt: number;
  mediaId: string;
  postDt: string;
  readCnt: number;
  thumbImageUri: string;
  title: string;
  videoTotalTime: number;
}
interface PlaylistProps {
  controlPlaylist: () => void;
  playlist: MediaPlaylistResDto[];
  nowPlaying: string;
  clickVideo: (id: string) => void;
}

const Playlist = (props: PlaylistProps) => {
  return (
    <>
      <div className="detail_right_title">
        재생목록
        <a
          href="#"
          className="collapse_btn"
          onClick={e => {
            e.preventDefault();
            props.controlPlaylist();
          }}
        >
          <span className="is-blind">더보기</span>
        </a>
      </div>
      <ul className="detail_relation custom_scroll">
        {/* <!-- 재생중인 영상에 is-current 클래스 추가 --> */}
        {props.playlist.map((pl, idx) => (
          <li className={classNames({ 'is-current': pl.mediaId === props.nowPlaying })} key={idx}>
            <a
              className="detail_relation_link"
              href="#"
              onClick={e => {
                e.preventDefault();
                props.clickVideo(pl.mediaId || '');
                all_click_datalayer_push(e.currentTarget);
              }}
              {...getRelatedContentsGAProps({
                area: '콘텐츠추가탐색',
                contentsType: 'MEDIA',
                title: pl.title,
                time: pl.videoTotalTime,
                isVideo: Boolean(pl.videoTotalTime),
                category: pl.cateNm,
                belong: '재생목록',
                maker: pl.creatorNm,
                tags: pl.mediaTag?.slice(1).replace(/#/g, '_'),
                brightcoveId: pl.videoId,
                mediaContentsType: pl.mediaContentsType as string,
              })}
            >
              <div className="detail_relation_wrap">
                <div className="relation_thumb">
                  <img
                    src={imageUrl(pl.thumbImageUri) || '/assets/images/illust/illust-img-media.png'}
                    alt=""
                    className="relation_thumb_img"
                  />
                  {pl.mediaId === props.nowPlaying && (
                    <div className="relation_progress">
                      <div className="circle_progress_wrap">
                        <svg className="circle_progress" width="32" height="32" viewBox="0 0 32 32">
                          <circle className="circle_frame" cx="16" cy="16" r="15" strokeWidth="1.5" />
                          <circle className="circle_bar" cx="16" cy="16" r="15" strokeWidth="1.5" data-target="70" />
                        </svg>
                        <strong className="circle_value"></strong>
                      </div>
                    </div>
                  )}
                  <span className="card_thumb_time">{videoTimeFormat(pl.videoTotalTime)}</span>
                </div>
                <div className="relation_desc">
                  <div className="relation_title">{pl.title}</div>
                  <div className="relation_sub is-pc">
                    <ul className="card_record">
                      <li>
                        <img src="/assets/images/icon/ico-views.png" alt="views" />
                        {pl.readCnt}
                      </li>
                      <li>
                        <img src="/assets/images/icon/ico-comments.png" alt="comments" />
                        {pl.commentCnt}
                      </li>
                      <li>
                        <img src="/assets/images/icon/ico-likes.png" alt="likes" />
                        {pl.likeCnt}
                      </li>
                    </ul>
                    <div className="relation_sub_date">{dateYYYYMMDD(pl.postDt)}</div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
        {/* <li className="is-current">
          <a href="#;" className="detail_relation_link">
            <div className="detail_relation_wrap">
              <div className="relation_thumb">
                <img src="/assets/images/sample/hero01.png" alt="" className="relation_thumb_img" />
                <div className="relation_progress">
                  <div className="circle_progress_wrap">
                    <svg className="circle_progress" width="32" height="32" viewBox="0 0 32 32">
                      <circle className="circle_frame" cx="16" cy="16" r="15" strokeWidth="1.5" />
                      <circle className="circle_bar" cx="16" cy="16" r="15" strokeWidth="1.5" data-target="70" />
                    </svg>
                    <strong className="circle_value"></strong>
                  </div>
                </div>
                <span className="card_thumb_time">5:00</span>
              </div>
              <div className="relation_desc">
                <div className="relation_title">[LG신년사랑해 1편] 신년사 완전정복 (feat.amazon 본부장)</div>
                <div className="relation_sub is-pc">
                  <ul className="card_record">
                    <li>
                      <img src="/assets/images/icon/ico-views.png" alt="views" />
                      100
                    </li>
                    <li>
                      <img src="/assets/images/icon/ico-comments.png" alt="comments" />
                      200
                    </li>
                    <li>
                      <img src="/assets/images/icon/ico-likes.png" alt="likes" />
                      400
                    </li>
                  </ul>
                  <div className="relation_sub_date">2021.03.29</div>
                </div>
              </div>
            </div>
          </a>
        </li> */}
      </ul>
    </>
  );
};

export default Playlist;
