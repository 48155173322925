import request from 'services/request';
import { endpoints } from 'services';

export interface StoryRequest {
  page?: number;
  popularitySortType?: string;
  size: number;
  sort: string;
}

export interface LikeStoryReq {
  storyId: string;
}

export interface ShareStoryReq {
  storyId: string;
}

export default {
  getStory: (params: StoryRequest): Promise<any> =>
    request({
      url: endpoints.story70.story,
      method: 'get',
      params,
    }),
  getStoryDetail: (url): Promise<any> =>
    request({
      url,
      method: 'get',
    }),
  postStoryLike: (params: LikeStoryReq): Promise<any> =>
    request({
      url: endpoints.story70.like,
      method: 'post',
      params,
    }),
  postStoryShare: (params: ShareReqDto): Promise<any> =>
    request({
      url: endpoints.story70.share,
      method: 'post',
      data: params,
    }),
};
