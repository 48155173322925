export enum ContentsPostType {
  /** 콘텐츠 */
  MEDIA = 'MEDIA',
  /** 자유 게시판 */
  BOARD = 'BOARD',
  /** 이벤트 */
  EVENT = 'EVENT',
  /** 스토리 */
  STORY = 'STORY',
  /** 맛집 게시판 */
  TOP_EATS = 'TOP_EATS',
  /* 칭찬 게시판 */
  MENTIONS = 'MENTIONS',
  /** 회사 속 직무 게시판 */
  DEPARTMENT_BOARD = 'DEPARTMENT_BOARD',
  /** 회사 밖 소모임 게시판 */
  SOCIETY_BOARD = 'SOCIETY_BOARD',
}
