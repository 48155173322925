import request from 'services/request';
import { endpoints } from 'services/endpoints';

export interface ConfirmMemberRequest {
  email: string;
  empNum: string;
}

export interface FindPasswordRequest {
  name: string;
  email: string;
  groupCode: string;
}

export interface SigninRequest {
  email: string;
  password: string;
}

export interface SignoutRequest {}

export interface SignOutRequest {}

export interface SSOCheckRequest {
  secureSessionId: string;
  secureToken: string;
  clientIp: string;
}

export interface TokenRefreshRequest {
  refreshToken: string;
}

export interface MobileCheckRequest {
  domain: string;
  paramValue: string;
}

export interface AutoLoginEmailRequest {
  domainCode: string;
  encryptEmailValue: string;
}

export default {
  putCicUserInfo: (): Promise<any> =>
    request({
      url: endpoints.auth.cic_user_info,
      method: 'put',
    }),
  postConfirmMember: (params: ConfirmMemberRequest): Promise<ConfirmMemberResDto> =>
    request({
      url: endpoints.auth.confirm_member,
      method: 'post',
      data: params,
    }),
  postFindPassword: (params: FindPasswordRequest): Promise<any> =>
    request({
      url: endpoints.auth.find_password,
      method: 'post',
      data: params,
    }),
  postSignIn: (params: SigninRequest): Promise<any> =>
    request({
      url: endpoints.auth.sign_in,
      method: 'post',
      data: params,
    }),
  postSignOut: (params: SignOutRequest): Promise<any> =>
    request({
      url: endpoints.auth.sign_out,
      method: 'post',
      data: params,
    }),
  postSignUp: (params: SignUpReqDto): Promise<any> =>
    request({
      url: endpoints.auth.sign_up,
      method: 'post',
      data: params,
    }),
  postSSOCheck: (params: SSOCheckRequest): Promise<any> =>
    request({
      url: endpoints.auth.ssocheck,
      method: 'post',
      data: params,
    }),
  postTokenRefresh: (params: TokenRefreshRequest): Promise<any> =>
    request({
      url: endpoints.auth.tokenRefresh,
      method: 'POST',
      data: params,
    }),
  postMobileCheck: (params: MobileCheckRequest): Promise<any> =>
    request({
      url: endpoints.auth.mobile_check,
      method: 'POST',
      data: params,
    }),
  postAutoLoginCheck: (params: AutoLoginEmailRequest): Promise<TokenResDto> =>
    request({
      url: endpoints.auth.autoLoginEmail_check,
      method: 'POST',
      data: params,
    }),
  postAutoLoginLGAcademy: (params: AutoLoginToLGAcademyReqDto): Promise<AutoLoginToLGAcademyResDto> => {
    return request({
      url: endpoints.auth.autoLoginToLGAcademy,
      method: 'POST',
      data: params,
    });
  },
  postAutoLoginLGAwards: (): Promise<AutoLoginToLGAwardsResDto> => {
    return request({
      url: endpoints.auth.autoLoginToLGAwards,
      method: 'POST',
    });
  },
  /** (Life 내부) LG BP Library 자동 로그인 - Home 으로 이동 */
  postAutoLoginLGBPHome: (): Promise<AutoLoginToLGBPLibraryResDto> => {
    return request({
      url: endpoints.auth.autoLoginToLGBPLibraryHome,
      method: 'POST',
    });
  },
};
