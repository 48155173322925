import { endpoints, fetcher } from 'services';
import useSWR from 'swr';
import useMyInfo from './useMyInfo';
import { useState } from 'react';

const useExecutiveAppointment = () => {
  const { info } = useMyInfo();
  const [selectedYearId, setSelectedYearId] = useState<string>('');

  /**
   * 노출 중인 임원 인사 연도 정보 조회
   * @return
   */
  const getExecutiveAppointmentYearDisplayInfo = () => {
    return useSWR<ExecutiveAppointmentYearDetailResDto>(
      info ? [endpoints.executiveAppointment.getExecutiveAppointmentYearDisplayInfo] : null,
      () => fetcher.executiveAppointment.getExecutiveAppointmentYearDisplayInfo()
    );
  };

  /**
   * 노출 중인 임원 인사 통합 상세 정보 조회
   * @param params 조회 할 연도 ID
   */
  const getExecutiveAppointmentIntegratedDetail = () => {
    return useSWR<ExecutiveAppointmentIntegratedDetailResDto>(
      info && selectedYearId
        ? [endpoints.executiveAppointment.getExecutiveAppointmentIntegratedDetail, selectedYearId]
        : null,
      () =>
        fetcher.executiveAppointment
          .getExecutiveAppointmentIntegratedDetail({
            yearId: selectedYearId,
          })
          .then(res => {
            return res;
          })
    );
  };

  return {
    getExecutiveAppointmentYearDisplayInfo,
    getExecutiveAppointmentIntegratedDetail,
    selectedYearId,
    setSelectedYearId,
  };
};

export default useExecutiveAppointment;
