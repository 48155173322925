import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNotice } from 'hooks';
import TotalNotice from './component/TotalNotice';
import { NoticeDetailType } from '../../../../hooks/useNotice';
import MentionsNotice from './component/MentionsNotice';
import LikeNotice from './component/LikeNotice';
import BrandingNotice from './component/BrandingNotice';

export interface NoticeAreaPopupProps {
  onClose: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
  newNoticeCountList: NoneReadNoticeCountListResDto;
}

const NoticeArea = (props: NoticeAreaPopupProps) => {
  const { notice: initNoticeList, getNoticeListByNoticeType, readNotice } = useNotice();

  const [selectedNoticeDetailType, setSelectedNoticeDetailType] = useState<NoticeDetailType>('BRANDING');
  const [selectedNoticeList, setSelectedNoticeList] = useState(initNoticeList?.noticeList || ([] as NoticeResDto[]));

  const [currentDt, setCurrentDt] = useState<string>('');
  const popupRef = useRef<HTMLDivElement>(null);

  /** 읽지 않은 알림 개수 정보 목록 */
  const nonReadNoticeCountInfoList = useMemo(() => {
    return props.newNoticeCountList?.noneReadNoticeCountList || ([] as NoneReadNoticeCountResDto[]);
  }, [props.newNoticeCountList]);

  /**
   * 각 상단 알림탭 unmount 될 때, 알림 읽음 처리 api 호출
   * @param status
   */
  const tabStatusChangeHandler = (status: boolean) => {
    if (!status) return;
    if (selectedNoticeDetailType !== 'ALL') {
      readNotice({
        noticeDetailType: selectedNoticeDetailType,
        readAllNoticeYN: 'Y',
      } as NoticeReadReqDto).then(r => {
        if (r.resultCode !== 200) {
          console.error(`[Error] ${r.resultCode} ::: ${r.resultMessage}`);
          return;
        }
      });
      return;
    }
    readNotice({
      noticeDetailType: selectedNoticeDetailType || 'ALL',
      readAllNoticeYN: 'Y',
    } as NoticeReadReqDto).then(r => {
      if (r.resultCode !== 200) {
        console.error(`[Error] ${r.resultCode} ::: ${r.resultMessage}`);
        return;
      }
    });
  };

  /**
   * 상단 알림탭 개별 알림 아이템 클릭 액션
   * @param notice 알림 아이템
   */
  const clickNoticeItemHandler = (notice: NoticeResDto) => {
    props.onClose();

    // 알림 종류에 따라 page 이동 후 스크롤 이동
    const url =
      (notice.linkUri as string) +
      (notice.commentId ? '?commentId=' + notice.commentId : '') +
      (notice.replyId ? '&replyId=' + notice.replyId : '');

    location.href = url;
  };

  /** 알림탭 컴포넌트 목록 */
  const _noticeList = useMemo(() => {
    if (selectedNoticeDetailType && selectedNoticeDetailType !== 'ALL') {
      switch (selectedNoticeDetailType) {
        case 'MENTIONS':
          return (
            <MentionsNotice
              noticeList={selectedNoticeList}
              onClose={props.onClose}
              buttonRef={props.buttonRef}
              currentDt={currentDt}
              unmountCallback={tabStatusChangeHandler}
              clickNoticeItemCallback={clickNoticeItemHandler}
            />
          );
        case 'LIKE':
          return (
            <LikeNotice
              noticeList={selectedNoticeList}
              onClose={props.onClose}
              buttonRef={props.buttonRef}
              currentDt={currentDt}
              unmountCallback={tabStatusChangeHandler}
              clickNoticeItemCallback={clickNoticeItemHandler}
            />
          );
        case 'BRANDING':
          return (
            <BrandingNotice
              noticeList={selectedNoticeList}
              onClose={props.onClose}
              buttonRef={props.buttonRef}
              currentDt={currentDt}
              unmountCallback={tabStatusChangeHandler}
              clickNoticeItemCallback={clickNoticeItemHandler}
            />
          );
      }
    }
    return (
      <TotalNotice
        noticeList={selectedNoticeList}
        onClose={props.onClose}
        buttonRef={props.buttonRef}
        currentDt={currentDt}
        unmountCallback={tabStatusChangeHandler}
        clickNoticeItemCallback={clickNoticeItemHandler}
      />
    );
  }, [selectedNoticeList]);

  const handleClose = (e: any) => {
    if (!popupRef.current?.contains(e.target) && !props.buttonRef.current?.contains(e.target)) props.onClose();
  };

  /**
   * 상단 알림 상세 구분 탭 클릭 이벤트
   */
  const onClickTopNoticeTab = (clickedType: NoticeDetailType) => {
    setSelectedNoticeDetailType(clickedType);
  };

  /**
   * 상단 알림탭 상세 유형별 알림 읽음 여부 확인
   * @param noticeDetailType 알림 상세 유형
   */
  const hasNonReadNoticeItem = (noticeDetailType: NoticeDetailType) => {
    if (!noticeDetailType || !nonReadNoticeCountInfoList?.length) return false;
    const _selectedNonReadNoticeCountInfo = nonReadNoticeCountInfoList.find(
      item => item.noticeDetailType === noticeDetailType
    );

    if (!_selectedNonReadNoticeCountInfo) return false;

    return _selectedNonReadNoticeCountInfo.noticeCnt > 0;
  };

  useEffect(() => {
    window.addEventListener('click', handleClose);
    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, []);

  useEffect(() => {
    setCurrentDt((initNoticeList?.currentDt || '') as string);
  }, [initNoticeList]);

  useEffect(() => {
    getNoticeListByNoticeType(selectedNoticeDetailType).then(res => {
      if (res.resultCode !== 200) {
        console.error('[에러] 상단탭 알림을 가져 오는데 실패했습니다!');
        console.error(`\n${res.resultCode} : ${res.resultMessage}\n`);
        setSelectedNoticeList([] as NoticeResDto[]);
        return;
      }
      setSelectedNoticeList(res.noticeList);
      return;
    });
  }, [selectedNoticeDetailType]);

  return (
    <div className="popup notice_popup is-active">
      <div className={classNames(['popup_inner', 'popup_mobile_full'])} ref={popupRef}>
        <div className="popup_header is-mobile">
          <button type="button" className="popup_back_button ico_back" onClick={props.onClose}></button>
          <strong className="popup_title">알림</strong>
        </div>

        {/* 상단 알림탭 메뉴*/}
        <div className="popup_menu">
          <div className="popup_menu_list">
            <div className={classNames('popup_menu_item', { 'is-unchecked': hasNonReadNoticeItem('BRANDING') })}>
              <button
                className={classNames('menu_item_btn', { 'is-active': selectedNoticeDetailType === 'BRANDING' })}
                onClick={() => onClickTopNoticeTab('BRANDING')}
              >
                알림
              </button>
            </div>
            <div className={classNames('popup_menu_item', { 'is-unchecked': hasNonReadNoticeItem('MENTIONS') })}>
              <button
                className={classNames('menu_item_btn', { 'is-active': selectedNoticeDetailType === 'MENTIONS' })}
                onClick={() => onClickTopNoticeTab('MENTIONS')}
              >
                멘션
              </button>
            </div>
            <div className={classNames('popup_menu_item', { 'is-unchecked': hasNonReadNoticeItem('LIKE') })}>
              <button
                className={classNames('menu_item_btn', { 'is-active': selectedNoticeDetailType === 'LIKE' })}
                onClick={() => onClickTopNoticeTab('LIKE')}
              >
                좋아요
              </button>
            </div>
          </div>
          <div className="popup_menu_all">
            <div className={classNames('popup_menu_item', { 'is-unchecked': hasNonReadNoticeItem('ALL') })}>
              <button
                className={classNames('menu_item_btn', { 'is-active': selectedNoticeDetailType === 'ALL' })}
                onClick={() => onClickTopNoticeTab('ALL')}
              >
                전체
              </button>
            </div>
          </div>
        </div>
        {/* // 상단 알림탭 메뉴*/}

        <div className="notice_wrapper">{_noticeList}</div>
      </div>
    </div>
  );
};

NoticeArea.displayName = 'NoticeArea';

export default NoticeArea;
