import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { TagInput } from 'components/atoms';

export interface TagWriteProps {
  tags: Array<string>;
  suggestions: Array<string>;
  onAddTag(tag: string);
  onRemoveTag(tag: string);
  isAccountType?: boolean;
}

const TagWrite = ({ tags = [], onAddTag, onRemoveTag, isAccountType, suggestions }: TagWriteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [inputTag, setInputTag] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onFocusInput = () => {
    setIsOpen(true);
  };

  const onClickWindow = e => {
    const className = e.target.classList[0];

    if (
      className === 'write_input' ||
      className === 'write_tag' ||
      className === 'remove_write_tag' ||
      className === 'select_opt' ||
      className === 'tag_box'
    ) {
      return;
    }

    setIsOpen(false);
  };

  const onClickSuggestion = (e, index: number) => {
    e.preventDefault();

    setSelectedIndex(index);

    if (tags.length > 4) {
      return;
    }

    addTag(suggestions[index]);
  };

  const addTag = (keyword: string) => {
    if (tags.filter(item => item === keyword).length > 0) {
      return;
    }
    onAddTag(keyword);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tags.length > 4) {
      return;
    }

    if (e.target.value.length > 20) {
      return;
    }

    setInputTag(e.target.value);
  };

  const onKeyPressInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputTag.length > 0) {
      addTag(inputTag);
      setInputTag('');
    }
  };

  useEffect(() => {
    inputRef.current?.addEventListener('focus', onFocusInput);
    window.addEventListener('click', onClickWindow);

    return () => {
      inputRef.current?.removeEventListener('focus', onFocusInput);
      window.removeEventListener('click', onClickWindow);
    };
  }, []);

  const List = () => {
    if (tags.length > 4) {
      return <></>;
    }

    return (
      <div className="selectbox" style={{ display: isOpen ? '' : 'none' }}>
        <ul className="board_select_list custom_scroll">
          {suggestions.map((suggestion, index) => (
            <li
              className={classNames(['select_item', { 'is-selected': index === selectedIndex }])}
              data-value={suggestion}
              key={index}
            >
              <a href="#" className="select_opt" onClick={e => onClickSuggestion(e, index)}>
                {suggestion}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="write_section_input">
        <div className={classNames(['write_section_tag', { 'open-selectbox': isOpen }])}>
          <div className="write_tag_list">
            {tags.map((tag, index) => (
              <div className="write_tag_item" key={index}>
                <span className="write_tag_text">#{tag}</span>
                <button type="button" className="remove_write_tag" onClick={() => onRemoveTag(tag)}>
                  <span className="ab_text">태그 삭제</span>
                </button>
              </div>
            ))}
          </div>
          <TagInput
            ref={inputRef}
            onChange={onChangeInput}
            value={inputTag}
            onKeyPress={onKeyPressInput}
            noSelect={suggestions.length === 0}
          />
          {!isAccountType && <List />}
        </div>
      </div>
      {isAccountType && <List />}
    </>
  );
};

export default TagWrite;
