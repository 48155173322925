import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';

export interface CelebrationRequest {
  pageSize: number;
  messageEventType: string;
  sortField: string;
  sortType: string;
  delYn?: string;
  openYn?: string;
  pageNum: number;
}

export interface CelebrationMessageWriteRequest {
  messageEventType: string;
  contents: string;
  fontNameEn: string;
}

export default {
  getCelebration: (params: CelebrationRequest): Promise<any> =>
    request({
      url: endpoints.celebration75.celebration,
      method: 'get',
      params,
    }),
  deleteMessage: (url: string): Promise<any> =>
    request({
      url,
      method: 'delete',
    }),
  postMessageWrite: (params: CelebrationMessageWriteRequest): Promise<any> =>
    request({
      url: endpoints.celebration75.celebration,
      method: 'post',
      data: paramsToFormData(params),
    }),
  putMessageModify: (params: CelebrationMessageWriteRequest, url: string): Promise<any> =>
    request({
      url,
      method: 'post',
      data: paramsToFormData(params),
    }),
  getMessageDetail: (url: string): Promise<any> =>
    request({
      url,
      method: 'get',
    }),
};
