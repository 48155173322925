import React from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import paths from 'commons/paths';
import { callGASearchEvent } from 'utils/GAUtils';

export interface TagProps {
  title: string;
  path: string;
}

const Tag = (props: TagProps) => {
  return (
    <li className="nav_keyword_item">
      <Link
        to={{
          pathname: paths.getPath('search'),
          search: stringify({ keyword: props.title }),
        }}
        className="tag"
        onClick={e => {
          callGASearchEvent({
            keyword: props.path as string,
            searchType: 'tagSearch',
          });
        }}
      >
        {props.path}
      </Link>
    </li>
  );
};

export default Tag;
