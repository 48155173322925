import React from 'react';

interface SectionTitleProps {
  title: string;
}

const SectionTitle = (props: SectionTitleProps) => {
  return (
    <>
      <div className="section_title">
        <div className="detail_top">
          <h3 className="detail_top_title">{props.title}</h3>
        </div>
      </div>
    </>
  );
};

export default React.memo(SectionTitle);
