import React from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { parse, stringify } from 'query-string';
import paths from 'commons/paths';
import { getEventGAProps, all_click_datalayer_push, getGAClickProps } from 'utils/GAUtils';
import EventCard from 'components/molecules/EventCard';
import { ScreenSize } from '../MyPage';
import { MESSAGE_EVENT_IDS } from '../Event/constants';
import { GET_LOADED_ENVIRONMENT_TYPE } from '../../../commons/constants';

interface Props {
  list?: EventResDto[];
  count?: number;
  /** 상단 메뉴 노출 */
  showTopTitle?: boolean;
  currentDt?: string;
  screenSize?: ScreenSize;
}

const SearchSimpleSurvey = ({
  list = [],
  count = 0,
  currentDt = new Date().toString(),
  screenSize = 'lg',
  showTopTitle = true,
}: Props) => {
  const history = useHistory();
  const keyword = parse(location.search).keyword as string;

  const messageEventIds = MESSAGE_EVENT_IDS.find(data => data.environmentType === GET_LOADED_ENVIRONMENT_TYPE());

  return (
    <>
      <div className="search_inner">
        <div className="search_top">
          {showTopTitle && (
            <h3 className="search_top_title">
              LG인의 선택
              <span className="is-num" {...{ id: 'eventSearchNum', datasearchnum: count }}>
                ({count}개)
              </span>
            </h3>
          )}
          {count > 3 ? (
            <a
              {...getGAClickProps('모두보기', 'LG인의 선택')}
              href="#seeAllEvents"
              className="search_top_link"
              onClick={e => {
                e.preventDefault();
                all_click_datalayer_push(e.currentTarget);
                history.push({
                  pathname: paths.getPath('eventSimpleSurveyList'),
                  search: stringify({ keyword: keyword }),
                });
              }}
            >
              모두보기
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="search_row">
        {screenSize === 'sm' ? (
          <Swiper className="card_row has-hover eventSwiper" slidesPerView={1} threshold={70}>
            {list?.map(event =>
              messageEventIds && messageEventIds.eventIds.find(e => e.eventId === event.eventId) ? (
                <SwiperSlide
                  tag="a"
                  className="card"
                  key={event.eventId}
                  onClick={e => {
                    e.preventDefault();
                    history.push(
                      messageEventIds?.eventIds.find(e => e.eventId === event.eventId)?.linkUrl ||
                        paths.getPath('eventSimpleSurveyList')
                    );
                    all_click_datalayer_push(e.currentTarget);
                  }}
                  {...getEventGAProps({
                    type: event.eventType,
                    url: window.location.hostname + event.linkUri,
                    title: event.title,
                    dataContentBelong: '커뮤니티',
                    dataContentType: 'LG인의 선택',
                    status: event.eventStatus,
                    dataImgUrl: event.thumbImageUri,
                  })}
                >
                  <div className="card_item">
                    <EventCard {...event} currentDt={currentDt} messageEvent={true} />
                  </div>
                </SwiperSlide>
              ) : (
                <SwiperSlide
                  tag="a"
                  className="card"
                  key={event.eventId}
                  onClick={e => {
                    e.preventDefault();
                    history.push(
                      event.linkUri || paths.getPath('eventSimpleSurveyDetail').replace(':eventId', event.eventId || '')
                    );
                    all_click_datalayer_push(e.currentTarget);
                  }}
                  {...getEventGAProps({
                    type: event.eventType,
                    url: window.location.hostname + event.linkUri,
                    title: event.title,
                    dataContentBelong: '커뮤니티',
                    dataContentType: 'LG인의 선택',
                    status: event.eventStatus,
                    dataImgUrl: event.thumbImageUri,
                  })}
                >
                  <div className="card_item">
                    <EventCard {...event} currentDt={currentDt} />
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        ) : (
          <div className="card_row has-hover eventSwiper">
            <div className="card_wrapper">
              {list?.map(event =>
                messageEventIds && messageEventIds.eventIds.find(e => e.eventId === event.eventId) ? (
                  <SwiperSlide
                    tag="a"
                    className="card"
                    key={event.eventId}
                    onClick={e => {
                      e.preventDefault();
                      history.push(
                        messageEventIds?.eventIds.find(e => e.eventId === event.eventId)?.linkUrl ||
                          paths.getPath('eventSimpleSurveyList')
                      );
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    {...getEventGAProps({
                      type: event.eventType,
                      url: window.location.hostname + event.linkUri,
                      title: event.title,
                      dataContentBelong: '커뮤니티',
                      dataContentType: 'LG인의 선택',
                      status: event.eventStatus,
                      dataImgUrl: event.thumbImageUri,
                    })}
                  >
                    <div className="card_item">
                      <EventCard {...event} currentDt={currentDt} messageEvent={true} />
                    </div>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide
                    tag="a"
                    className="card"
                    key={event.eventId}
                    onClick={e => {
                      e.preventDefault();
                      history.push(
                        event.linkUri ||
                          paths.getPath('eventSimpleSurveyDetail').replace(':eventId', event.eventId || '')
                      );
                      all_click_datalayer_push(e.currentTarget);
                    }}
                    {...getEventGAProps({
                      type: event.eventType,
                      url: window.location.hostname + event.linkUri,
                      title: event.title,
                      dataContentBelong: '커뮤니티',
                      dataContentType: 'LG인의 선택',
                      status: event.eventStatus,
                      dataImgUrl: event.thumbImageUri,
                    })}
                  >
                    <div className="card_item">
                      <EventCard {...event} currentDt={currentDt} />
                    </div>
                  </SwiperSlide>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchSimpleSurvey;
