const useTokens = () => {
  return {
    saveTokens(accessToken: string, refreshToken: string) {
      localStorage.setItem('llAccessToken', accessToken);
      localStorage.setItem('llRefreshToken', refreshToken);
    },
    removeTokens() {
      localStorage.removeItem('llAccessToken');
      localStorage.removeItem('llRefreshToken');
    },
    getTokenParams() {
      return (
        '&accessToken=' +
        localStorage.getItem('llAccessToken') +
        '&refreshToken=' +
        localStorage.getItem('llRefreshToken')
      );
    },
  };
};

export default useTokens;
