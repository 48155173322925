export enum ContentsDetailType {
  /** 일반 */
  NORMAL = 'NORMAL',
  /** Editors 기획 */
  EDITOR = 'EDITOR',
  /** SNS */
  SNS = 'SNS',
  /** Ads */
  ADS = 'ADS',
  /** 70주년 스토리 */
  STORY = 'STORY',
  /** 보도 자료 */
  REPORT = 'REPORT',
  /** ESG */
  ESG = 'ESG',
  /** 주간 뉴스 */
  WEEK = 'WEEK',
  /** 언론 속 LG - 뉴스봤G */
  NEWSG = 'NEWSG',
  /** 언론 속 LG - 매거진 */
  SCRAP = 'SCRAP',
  /** 웹툰(갓찌) - 만화 */
  CARD_COMICS = 'CARD_COMICS',
  /** 주간 뉴스레터 */
  WEEK_NEWSLETTER = 'WEEK_NEWSLETTER',
  /** 신년사 */
  NEWYEAR = 'NEWYEAR',
  /** 추모사 */
  MEMORIAL = 'MEMORIAL',
}
