import { createContext } from 'react';

export const initialState = {
  gradeData: [],
  setGradeData: () => {},
  a: '',
  seta: () => {},
} as {
  gradeData: Array<UserRankResDto>;
  setGradeData: React.Dispatch<React.SetStateAction<UserRankResDto[]>>;
};

const GradeContext = createContext(initialState);

export default GradeContext;
