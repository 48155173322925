import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';

export interface QnasReq {
  page: number;
  popularitySortType: string;
  size: number;
  sort: string;
}

export interface QnaReq {
  qnaId: string;
}

export interface QnaWriteReq {
  attachFile?: File;
  cateId: string;
  contents: string;
  title: string;
}

export interface QnaNonLoginWriteReq {
  attachFile?: File;
  cateId: string;
  contents: string;
  nonLoginEmail: string;
  nonLoginName: string;
  nonLoginPhoneNum: string;
  title: string;
}

export interface QnaUpdateReq extends QnaWriteReq {
  qnaId: string;
  attachFileId: string;
}

export default {
  getQnas: (params: QnasReq): Promise<any> =>
    request({
      url: endpoints.qna.qna,
      method: 'get',
      params,
    }),

  getQna: (url, params: QnaReq): Promise<any> =>
    request({
      url: url,
      method: 'get',
      params,
    }),

  postQnaWrite: (params: QnaWriteReq): Promise<any> => {
    const formData = paramsToFormData(params);

    return request(
      {
        url: endpoints.qna.qna,
        method: 'post',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  putQnaEdit: (url: string, params: QnaUpdateReq): Promise<any> => {
    const putParams = {
      qnaId: params.qnaId,
      title: params.title,
      cateId: params.cateId,
      contents: params.contents,
    };

    putParams['attachFileReqDto.attachFile'] = params.attachFile;

    if (params.attachFile || params.attachFileId != '0') {
      putParams['attachFileReqDto.attachFileId'] = params.attachFileId;
    }

    const formData = paramsToFormData(putParams);

    return request(
      {
        url: url,
        method: 'put',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  deleteQna: (url, params: QnaReq): Promise<any> => {
    return request({
      url: url,
      method: 'delete',
      data: params,
    });
  },
  postQnaNonLogin: (params: QnaNonLoginWriteReq): Promise<any> => {
    const formData = paramsToFormData(params);

    return request(
      {
        url: endpoints.qna.qnaNonLogin,
        method: 'post',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
};
