import React, { useState, useEffect, Children } from 'react';
import classNames from 'classnames';
import { TagWrite } from 'components/atoms';
import AlertPopup from 'components/organisms/Popup/AlertPopup';

export interface TagPopupProps {
  tagsFrom: Array<string>;
  isPopupOpen: boolean;
  onClose: () => void;
  onSaveTags: (tags: Array<string>) => void;
  suggestions: Array<string>;
}

const TagPopup = ({ tagsFrom = [], suggestions, isPopupOpen, onClose, onSaveTags }: TagPopupProps) => {
  const [tags, setTags] = useState<Array<string>>([]);

  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');

  useEffect(() => {
    setTags(tagsFrom);
  }, [isPopupOpen]);

  const onAddTag = (tag: string) => {
    setTags([...tags, tag]);
  };

  const onRemoveTag = (tag: string) => {
    if (tags.length < 2) {
      setAlertPopContents('태그는 하나 이상 존재해야 합니다.');
      setIsAlertPopupOpen(true);

      return;
    }

    setTags(tags.filter(item => item !== tag));
  };

  return (
    <>
      <div className={classNames(['popup tag_popup', { 'is-active': isPopupOpen }])}>
        <div className="popup_dim"></div>
        <div className="popup_inner">
          <div className="popup_header">
            <strong className="popup_title">관심 키워드 추가</strong>
          </div>
          <div className="popup_cont">
            <div className="tag_box">
              <div className="tag_info">
                *<span className="enter">Enter</span>
                엔터키로 태그 구분
                <br className="is-mobile" /> (최대 20자, 최대 5개의 태그 입력 가능)
              </div>
              <TagWrite
                tags={tags}
                onAddTag={onAddTag}
                onRemoveTag={onRemoveTag}
                isAccountType
                suggestions={suggestions}
              />
            </div>

            <div className="popup_bottom">
              <button type="button" className="popup_btn is-close is-pc" onClick={onClose}>
                취소
              </button>
              <button type="button" className="popup_btn is-em" onClick={() => onSaveTags(tags)}>
                저장
              </button>
            </div>
          </div>
          <button type="button" className="popup_close ico_close" onClick={onClose}></button>
        </div>
      </div>
      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => setIsAlertPopupOpen(false)}>
        {alertPopupContents}
      </AlertPopup>
    </>
  );
};

export default TagPopup;
