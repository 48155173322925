import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import { useState } from 'react';
import useMyInfo from './useMyInfo';
import { PAGE_ITEM_COUNT } from 'commons/constants';

const useVideoList = (videoHookProps?: {
  /** 한 페이지에 보여줄 아이템 개수 */
  pageItemSize?: number;
}) => {
  const { info } = useMyInfo();

  const [searchOption, setSearchOption] = useState<any>({
    cateType: 'VIDEO',
    // : 'MEDIA',
    size: videoHookProps?.pageItemSize || PAGE_ITEM_COUNT,
    sort: 'postDt,desc',
  });

  // let onVideoLoaded = (value?: unknown) => {};

  // let onTest = () => {};
  // const onVideLoaded2 = (callbackFunc) => {
  //   onTest = callbackFunc;
  // }

  const { data: videosData } = useSWR<any>(info ? [endpoints.media.media, searchOption] : null, () =>
    fetcher.media.getMedia(searchOption).then(res => {
      // console.log("useSWR getMedia (videosData.length)", res.mediaCardList.length);
      // onTest();
      return res;
    })
  );

  const { data: videoTagsData } = useSWR<any>(info ? [endpoints.media.basicTags, 'VIDEO'] : null, () =>
    fetcher.media.getMediaBasicTags({ tagType: 'VIDEO' }).then(res => {
      return res;
    })
  );

  return {
    videosData,
    searchOption,
    setSearchOption,
    // onVideoLoaded,
    // onVideLoaded2,
    // data,
    // size,
    // setSize,
    videoTagsData,
  };
};

export default useVideoList;
