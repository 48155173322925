import request from 'services/request';
import { endpoints } from 'services/endpoints';
import { paramsToFormData } from 'utils/utils';
import { PagingReqDto } from '../../types';

export interface DepartmentBoardListRequest extends PagingReqDto {
  /** 카테고리 목록 */
  cateList?: string;
  /** 서브 카테고리 목록 */
  subCateList?: string;
  /** 게시판 유형 */
  boardType?: string;
  /** 상단 통함 검색어 */
  topSearchWord?: string;
  /** 게시판 내 검색어 */
  searchWordIn?: string;
}

export interface DepartmentBoardWriteReq {
  attachFiles: File[];
  cateId: string;
  contents: string;
  contentsText: string;
  subCateId: string;
  boardType: string;
  tags: Array<string>;
  title: string;
}

export interface DepartmentBoardUpdateReq extends DepartmentBoardDetailReqDto {
  uploadedFiles: AttachFileResDto[];
}

export interface DepartmentBoardLikeReq {
  boardId: string;
}

export interface DepartmentBoardDeleteReq {
  boardId: string;
}

export default {
  /** 게시글 목록 조회 */
  getDepartmentBoardList: (params: DepartmentBoardListRequest): Promise<DepartmentBoardListResDto> =>
    request({
      url: endpoints.departmentBoard.departmentBoard,
      method: 'get',
      params,
    }),
  /** 게시글 상세 내용 조회 */
  getDepartmentBoardDetail: (url: string): Promise<DepartmentBoardDetailResDto> =>
    request({
      url,
      method: 'get',
    }),
  /** 게시글 작성 */
  postDepartmentBoardWrite: (params: DepartmentBoardWriteReq): Promise<BaseResDto> => {
    const postParams = {
      ...params,
      boardType: params.boardType || 'DEPARTMENT_BOARD',
    } as DepartmentBoardDetailReqDto;

    if (!!params.attachFiles?.length) {
      for (let i = 0; i < params.attachFiles.length; i++) {
        postParams['attachFiles[' + i + '].attachFile'] = params.attachFiles[i];
        postParams['attachFiles[' + i + '].attachFileId'] = '0';
      }
    }

    delete postParams.attachFiles;

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: endpoints.departmentBoard.departmentBoardWrite,
        method: 'post',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  /** 게시글 수정 */
  putDepartmentBoardUpdate: (url: string, params: DepartmentBoardUpdateReq): Promise<BaseResDto> => {
    const postParams = {
      ...params,
      boardType: params.boardType || 'DEPARTMENT_BOARD',
    };

    const uploadedFilesLength = postParams.uploadedFiles.length;

    for (let i = 0; i < uploadedFilesLength; i++) {
      postParams['attachFiles[' + i + '].attachFileId'] = postParams.uploadedFiles[i].fileId;
    }

    if (!!postParams.attachFiles?.length) {
      for (let i = 0; i < postParams.attachFiles.length; i++) {
        postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFile'] = postParams.attachFiles[i];
        postParams['attachFiles[' + (uploadedFilesLength + i) + '].attachFileId'] = '0';
      }
    }

    delete postParams.attachFiles;

    const formData = paramsToFormData(postParams);

    return request(
      {
        url: url,
        method: 'put',
        data: formData,
      },
      {
        multiPart: true,
      }
    );
  },
  /** 게시글 좋아요 */
  postDepartmentBoardLike: (params: DepartmentBoardLikeReq): Promise<BaseResDto> =>
    request({
      url: endpoints.departmentBoard.departmentBoardLike,
      method: 'post',
      params,
    }),
  /** 게시글 삭제 */
  deleteDepartmentBoard: (url: string, params?: DepartmentBoardDeleteReq): Promise<BaseResDto> =>
    request({
      url,
      method: 'delete',
      params,
    }),
  /** 게시글 공유 */
  shareDepartmentBoard: (params: ShareReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.departmentBoard.departmentBoardShare,
      method: 'post',
      data: params,
    }),
};
