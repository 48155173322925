import React from 'react';

interface ShareCancelPopupProps {
  closeOkPopup: () => void;
  closeCancelPopup: () => void;
}

const LogoutPopup = (props: ShareCancelPopupProps) => {
  return (
    <div className="confirm_popup logout_popup is-active">
      <div className="popup_dim"></div>
      <div className="popup_inner">
        <p className="confirm_popup_text">정말 로그아웃 하시겠습니까?</p>
        <ul className="confirm_button_list">
          <li className="confirm_button_item">
            <button
              type="button"
              className="confirm_popup_button is-dim popup_button_cancel"
              onClick={props.closeCancelPopup}
            >
              취소
            </button>
          </li>
          <li className="confirm_button_item">
            <button type="button" className="confirm_popup_button popup_button_confirm" onClick={props.closeOkPopup}>
              확인
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LogoutPopup;
