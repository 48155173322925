import request from 'services/request';
import { endpoints } from 'services/endpoints';

export interface VideosRequest {}

export interface VideosDetailRequest {
  videoId: string;
}

export interface BasicTagsRequest {}

export default {
  getVideos: (params: VideosRequest): Promise<any> => {
    return request({
      url: endpoints.videos.videos,
      method: 'get',
      params,
    });
  },
  getDetail: (params: VideosDetailRequest): Promise<any> => {
    return request({
      url: `${endpoints.videos.videoDetail}/${params.videoId}`,
      method: 'get',
      params,
    });
  },
  getBasicTags: (params: BasicTagsRequest): Promise<any> => {
    return request({
      url: endpoints.videos.basicTags,
      method: 'get',
      params,
    });
  },
};
