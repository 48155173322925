import { useEffect, useState } from 'react';
import { useContinue, useMyPage } from 'hooks';
import Intro from '../../templates/MyPage/Intro';
import BookmarkSection from 'components/templates/MyPage/BookmarkSection';
import BoardSection from 'components/templates/MyPage/BoardSection';
import CurrentlyContentsSection from '../../templates/MyPage/CurrentlyContentsSection';
import RecommendContentsSection from '../../templates/MyPage/RecommendContentsSection';
import TopEatsBoardSection from '../../templates/MyPage/TopEatsBoardSection';
import MentionsBoardSection from '../../templates/MyPage/MentionsBoardSection';
import DepartmentBoardSection from '../../templates/MyPage/DepartmentBoardSection';
import SocietyBoardSection from '../../templates/MyPage/SocietyBoardSection';

export type ScreenSize = 'sm' | 'md' | 'lg';

const MEDIA_COUNT_TO_SHOW = '4';

const MyPage = () => {
  const { briefInfo } = useMyPage({ contentsCnt: MEDIA_COUNT_TO_SHOW });
  const { getTimeLine } = useContinue();

  const [screenSize, setScreenSize] = useState<ScreenSize>('lg');

  const handleResize = () => {
    if (window.innerWidth > 1023) {
      setScreenSize('lg');
    } else {
      setScreenSize('sm');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!briefInfo) return <></>;

  return (
    <>
      <div className="content_inner">
        <div className="mypage">
          <Intro />

          {/* 고객 마이페이지 콘텐츠 구성 변경 요청 인해 주석 처리 - 2022.09.22 */}
          {/*<RecentSection screenSize={screenSize} currentDt={briefInfo.currentDt} getTimeLine={getTimeLine} />*/}
          {/*<LikeSection screenSize={screenSize} currentDt={briefInfo.currentDt} getTimeLine={getTimeLine} />*/}

          {/* 신규 콘텐츠 */}
          <CurrentlyContentsSection screenSize={screenSize} currentDt={briefInfo.currentDt} getTimeLine={getTimeLine} />

          {/* 추천 콘텐츠 */}
          <RecommendContentsSection screenSize={screenSize} currentDt={briefInfo.currentDt} getTimeLine={getTimeLine} />

          {/* 찜한 콘텐츠 */}
          <BookmarkSection screenSize={screenSize} currentDt={briefInfo.currentDt} getTimeLine={getTimeLine} />

          {/* 사용자가 작성한 게시판 게시글 - 회사 밖 소모임 */}
          <SocietyBoardSection />

          {/* 사용자가 작성한 게시판 게시글 - 회사 속 직무 */}
          <DepartmentBoardSection />

          {/* 사용자가 작성한 게시판 게시글 - 회사 옆 맛집 */}
          <TopEatsBoardSection />

          {/* 사용자가 작성한 게시판 게시글 - 칭찬합니다 */}
          <MentionsBoardSection />

          {/* 사용자가 작성한 게시판 게시글 - 자유 게시판 */}
          <BoardSection />
        </div>
      </div>
    </>
  );
};

export default MyPage;
