import React from 'react';
import Tag, { TagProps } from 'components/atoms/Tag';

export interface KeywordListProps {
  title: string;
  data: Array<TagProps>;
}

const KeywordList = (props: KeywordListProps) => {
  return (
    <div className="nav_list nav_sub_list is-pc">
      <strong className="nav_list_title">{props.title}</strong>
      <ul className="nav_keyword_list">
        {props.data.map((tagData: TagProps, idx) => (
          <Tag title={tagData.title} path={tagData.path} key={`tag${idx}`} />
        ))}
      </ul>
    </div>
  );
};

export default KeywordList;
