import { createContext } from 'react';

export const initialState = {
  pip: false,
  setPip: () => {},
  visible: false,
  setVisible: () => {},
  mediaId: '',
  setMediaId: () => {},
} as {
  pip: boolean;
  setPip: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  mediaId: string;
  setMediaId: React.Dispatch<React.SetStateAction<string>>;
};

const VideoContext = createContext(initialState);

export default VideoContext;
