import request from 'services/request';
import { endpoints } from 'services/endpoints';

export interface AdRequest {}

export interface BannerRequest {}

export interface BoardRequest {}

export interface HeroRequest {}

export interface NewsRequest {}

export interface PlayListRequest {}

export interface PopularContentRequest {}

export interface RecommendRequest {}

export interface SectionRequest {}

export interface SnsRequest {}

export default {
  getAd: (params: AdRequest): Promise<any> =>
    request({
      url: endpoints.main.ad,
      method: 'get',
      params,
    }),
  getBanner: (params: BannerRequest): Promise<any> =>
    request({
      url: endpoints.main.banner,
      method: 'get',
      params,
    }),
  getBoard: (params: BoardRequest): Promise<any> =>
    request({
      url: endpoints.main.board,
      method: 'get',
      params,
    }),
  getHero: (params: HeroRequest): Promise<any> =>
    request({
      url: endpoints.main.hero,
      method: 'get',
      params,
    }),
  getNews: (params: NewsRequest): Promise<any> =>
    request({
      url: endpoints.main.news,
      method: 'get',
      params,
    }),
  getPlayList: (params: PlayListRequest): Promise<any> =>
    request({
      url: endpoints.main.playlist,
      method: 'get',
      params,
    }),
  getPopularContent: (params: PopularContentRequest): Promise<any> =>
    request({
      url: endpoints.main.popularContent,
      method: 'get',
      params,
    }),
  getRecommend: (params: RecommendRequest): Promise<any> =>
    request({
      url: endpoints.main.recommend,
      method: 'get',
      params,
    }),
  getSection: (params: SectionRequest): Promise<any> =>
    request({
      url: endpoints.main.section,
      method: 'get',
      params,
    }),
  getSns: (params: SnsRequest): Promise<any> =>
    request({
      url: endpoints.main.sns,
      method: 'get',
      params,
    }),
};
